import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { ConstantMapping } from "../../Configurations/ConstantsMapping";
import {
  getSettings,
  getImageUrls,
  saveCMSImagesDataInRedux
} from "Utils/GeneralUtils";
import { getClientName } from "Utils/GeneralUtils";
import defaultLogo from "Configurations/Clients/vodafone/Assets/Images/VMR-loader-white.gif";
import HttpClient from "Utils/Http";
import { ImagesMapping } from "Configurations/ImagesMapping";

const clientName = getClientName();
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      height: "100vh",
      backgroundPosition: "center",
      width: "100%"
    },
    heroImageAndTextContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100vh",
      alignContent: "center",
      justifyContent: "center"
    },
    welcomeText: {
      fontSize: "29px",
      color: theme.palette.primary.contrastText
    },
    logoContainer: {
      width: "100%",
      height: "100%"
    },
    logoWrapper: {
      position: "absolute",
      margin: "auto",
      top: "46%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      zIndex: 2,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center"
    },
    logoImage: {
      width: "24.7%",
      marginBottom: "40px"
    },
    logoBackground: {
      width: "100%",
      objectFit: "cover",
      height: "100%",
      position: "relative"
    }
  })
);

const regularWelcomeText = (classes, clientName) => {
  return (
    <div className={classes.welcomeText}>
      {ConstantMapping[clientName].SPLASH_SCREEN_TEXT}
    </div>
  );
};

const SplashScreen: React.FC = props => {
  const classes = useStyles();
  const configKeys = ConstantMapping[clientName].IMAGES_DERIVE_FROM_CMS;
  const [loading, setLoading] = useState(true);
  const [imageUrls, setImageUrls] = useState({
    backgroundImageUrl: "",
    logoImageUrl: ""
  });
  let loadingImg = new Image();
  loadingImg.src = ImagesMapping[clientName].loadingScreenBg;

  useEffect(() => {
    getAppSettings();
  }, []);

  const getAppSettings = async () => {
    try {
      const settings = await getSettings();
      const clientData = settings.clientDetails.vodafone;
      const imageURL = clientData.loadingScreen;
      const http = new HttpClient();
      const configObj = await http.getWithoutHeaders(imageURL);
      const backgroundImageUrl = getImageUrls(
        configObj.data,
        configKeys?.background
      );
      const logoImageUrl = getImageUrls(configObj.data, configKeys?.logo);

      saveCMSImagesDataInRedux(configObj.data);
      setImageUrls({ backgroundImageUrl, logoImageUrl });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error, "fetching images");
    }
  };

  const renderScreen = () => {
    const { backgroundImageUrl, logoImageUrl } = imageUrls;
    return (
      <div className={classes.logoContainer}>
        {backgroundImageUrl && (
          <img
            src={backgroundImageUrl}
            className={classes.logoBackground}
            alt="logo-background"
          />
        )}
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          {logoImageUrl ? (
            <img
              src={logoImageUrl}
              id="logoImage"
              className={classes.logoImage}
              alt="logo"
              onError={() => {
                let logoImg = document.getElementById("logoImage");
                if (logoImg) logoImg.style.display = "none";
              }}
            />
          ) : (
            <img src={defaultLogo} className={classes.logoImage} alt="logo" />
          )}
          {/* {!backgroundImageUrl && !logoImageUrl
            ? regularWelcomeText(classes, clientName)
            : ""} */}
        </div>
      </div>
    );
  };

  return (
    <div
      className={classes.mainContainer}
      style={{ backgroundImage: `url(${loadingImg?.src})` }}
    >
      <div className={classes.heroImageAndTextContainer}>
        {loading ? (
          <div
            className={classes.mainContainer}
            style={{ backgroundImage: `url(${loadingImg?.src})` }}
          ></div>
        ) : (
          renderScreen()
        )}
      </div>
    </div>
  );
};

export default SplashScreen;
