import { BaseModel } from "ReduxStore/index";

export interface ProfileInterest {
  id: number;
  name: string;
}

export interface IInterests {
  [key: number]: ProfileInterest;
}

export default class Interests extends BaseModel<IInterests> {
  static resource = "Interests";
}
