import { BaseModel } from "ReduxStore/index";

export interface IUserDetails {
  firstName: string;
  lastName: string;
  userId: string;
  externalId: string;
  totalClaimedOffers: number;
  appVersion: AppVersion;
  appAccessLevel: string;
  email?: string;
  halfVisitToWinPopup?: boolean;
  fullVisitToWinPopup?: boolean;
  offerEntries?: number;
  isConvergedUser?: boolean;
  currentUserType?: string;
  chatToken?: string;
  externalRegistrationId: string;
  referralsData?: ReferalDetails;
  eatLocalToken?: string;
  coffeeClubToken?: string;
}

export interface AppVersion {
  major: number;
  minor: number;
}

export interface ReferalDetails {
  referralLink: string;
  closedReferrals: number;
  pendingReferrals: number;
  selfReferralPending?: Boolean;
}

export default class UserDetails extends BaseModel<IUserDetails> {
  static resource = "UserDetails";
}
