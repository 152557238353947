import { eatLocalBackground } from "Constants/Require/Require";

export const membershipConstants = Object.freeze({
  SUBHEADING: "Ready to Eat Local?",
  SUBTITLE: `Tap <b>Activate</b> to start your 12-month Gourmet Society membership and enjoy exclusive dining discounts at thousands of big-name chains and independent gems.`,
  WELCOME_SUBHEADING: "Success!",
  WELCOME_SUBTITLE: `
    <b>Your Eat Local membership is now active</b><br><br>
    Start exploring and enjoy exclusive deals. Search for your favourite restaurants and discover new dining spots with your Eat Local membership powered by Gourmet Society.
  `,
  LOCALBLEND_SUBHEADING: "Ready to Explore Local Blend?",
  LOCALBLEND_SUBTITLE: `
    Your favourite barista-made drinks are just a tap away. Tap <b>Activate membership</b> to become a Local Blend member and start enjoying 25% off at popular chains, plus thousands of local coffee shops.<br><br>
    Don't forget to check that your chosen location accepts the Coffee Club offer before you use it. Once you've tapped <b>Activate membership</b>, your membership will be active for 12 months.
  `,
  LOCALBLEND_WELCOME_SUBTITLE: `
    <b>Your Local Blend membership is now active</b><br><br>
    Enjoy 25% off your barista-made drinks. Start exploring your favourite coffee shops now.
  `
});
