//@ts-nocheck

import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { getClientName } from "Utils/GeneralUtils";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    primerContainer: {
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      zIndex: 1400,
      flex: "1 1 auto"
    },
    closeIcon: {
      position: "absolute",
      top: "19px",
      right: "19px",
      height: "22px"
    },
    primerWelcomeTextOne: {
      fontSize: theme.typography.h3.fontSize,
      paddingTop: "110vw",
      paddingBottom: theme.spacing(4),
      marginLeft:'5px',
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.contrastText
    },
    primerWelcomeTextTwo: {
      fontSize: theme.typography.h6.fontSize,
      padding: theme.spacing(0, 8, 6),
      alignItems: "center",
      textAlign: "center",
      color: theme.palette.primary.contrastText,
      lineHeight: "23px"
    },
    primerButtonWrapper: {
      display: "flex",
      justifyContent: "center"
    },
    primerButton: {
      width: "125px",
      fontSize: theme.typography.h6.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: "none"
    },
    christmasPrimerText: {
      whiteSpace: "pre-line",
      textAlign: "center",
      fontSize: theme.typography.h3.fontSize,
      paddingTop: "110vw",
      paddingBottom: theme.spacing(4),
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.contrastText,
      lineHeight: "25px"
    },

    multiImagePrimerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      backgroundSize: "cover",
      backgroundPosition: "center",
      // position: "fixed",
      width: "100vw",
      height: "100vh",
      backgroundColor: "#e00201"
    },
    multiImagePrimerScreen: {
      top: 0,
      width: "100%",
      display: "flex",
      position: "relative",
      marginLeft: "auto",
      marginRight: "auto",
      justifyContent: "center"
    },
    visitToWin_Logo: {
      width: "44%",
      zIndex: 99,
      position: "absolute",
      marginTop: "6%"
    },
    visitToWin_HeartImg: {
      width: "100%",
      position: "absolute",
      marginTop: "-16%"
    },
    multiImageCovergedPrimerContainer: {
      display: "flex",
      flexDirection: "column",
      // justifyContent: "space-around",
      justifyContent: "center",

   //   backgroundSize: "cover",
      backgroundImage: `url(${
        ImagesMapping[getClientName()].vfGradientBackGroundImage
      })`,
      // position: "fixed",
      width: "100vw",
      height: "100vh"
    },
    multiImageCovergedPrimerScreen: {
      width: "100%",
      display: "flex",
      position: "absolute",
      top: "20%",
      flexDirection: "row",
      justifyContent: "center"
    },
    primerCovergedTextOne: {
      fontSize: "36px",
      paddingTop: "33vw",
      paddingBottom: theme.spacing(4),
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.contrastText,
      fontFamily:theme.typography.h3.fontFamily,
      lineHeight:'normal'
    },
    primerCovergedTextContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginTop:'60px'
    },
    primerCovergedTextTwoSubContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginLeft: "10px"
    },
    primerCovergedTextTwoFirst: {
      fontSize: theme.typography.subtitle2.fontSize,
      alignItems: "center",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      color: theme.palette.primary.contrastText,
      fontFamily:theme.typography.h3.fontFamily,
      marginBottom:'1rem'
      //  lineHeight: "23px"
    },
    primerCovergedTextTwoSecond: {
      fontSize: theme.typography.h6.fontSize,
      alignItems: "center",
      textAlign: "center",
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightBold,
      fontFamily:theme.typography.h3.fontFamily,
      //   lineHeight: "23px"
    },

    primerCovergedTextTwoSecondSub: {
      fontSize: theme.typography.subtitle2.fontSize,
      alignItems: "center",
      textAlign: "start",
      color: theme.palette.primary.contrastText
    },
    
    visitToWin_ContainerImg: {
      width: "100%",
      position: "absolute",
      marginTop: "20%"
    },
    visitToWin_BackgroundImg: {
      width: "100%",
      position: "absolute",
      height:'100vh',
     // top: "-16%"
    },
  })
);
