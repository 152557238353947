import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import { Button } from "@material-ui/core";
import { Header } from "gce-cxinteracts-ui-components-library";
import { ConstantMapping } from "../../../Configurations/ConstantsMapping";
import { History } from "history";
import { connect } from "react-redux";
import { ClientModel } from "../../../Models/ReduxModels/ClientDetails";
import UserProfile, {
  UserProfileDetails
} from "../../../Models/ReduxModels/UserProfileModel";
import { userOnboardingRoutes } from "../../../Configurations/Clients/vodafone/Routes";
import {
  iterateHierarchy,
  getImageDetails,
  getClientName,
  redirectToNextRoute
} from "../../../Utils/GeneralUtils";
import {
  EVENT_TYPE,
  logEvents,
  pushAllEventsToServer
} from "Services/EventService";
import { ImagesMapping } from "Configurations/ImagesMapping";

interface IProps {
  history: History;
  clientData: any;
  userProfileData: UserProfileDetails;
}

const TrafficScreen: React.FC<IProps> = props => {
  const [showCircleAnimation, setCircleAnimation] = useState("");
  const classes = useStyles();

  const onLoadParams = {
    page_subsection: "permissions"
  };

  useEffect(() => {
    logEvents({ eventType: EVENT_TYPE.ONBOARDING_TRAFFIC_PERMISSION_LANDED });
    setTimeout(() => {
      setCircleAnimation(classes.loaded);
    }, 200);
    pushAllEventsToServer();
  }, []);

  const clientName = getClientName();
  const { history, clientData, userProfileData } = props;

  const onClickAllowButton = () => {
    new UserProfile({
      id: "local",
      isFirstUse: userProfileData?.isFirstUse,
      dob: userProfileData?.dob,
      interests: userProfileData?.interests,
      permissions: {
        ...userProfileData?.permissions,
        traffic: true
      }
    }).$save();
    nextPage(true);
  };

  const onClickDontAllowLink = () => {
    new UserProfile({
      id: "local",
      isFirstUse: userProfileData?.isFirstUse,
      dob: userProfileData?.dob,
      interests: userProfileData?.interests,
      permissions: {
        ...userProfileData?.permissions,
        traffic: false
      }
    }).$save();
    nextPage(false);
  };

  const nextPage = (permissionOpted: boolean) => {
    const nextRoute = redirectToNextRoute("traffic");
    history.push(nextRoute, { onLoadParams });
    logEvents({ eventType: EVENT_TYPE.NEXT_PAGE_ONBOARDING });
    logEvents({
      eventType: EVENT_TYPE.ONBOARDING_TRAFFIC_PERMISSION_EXITED,
      traffic: permissionOpted
    });
    pushAllEventsToServer();
  };

  return (
    <div className={classes.mainContainer}>
      <Header
        className={classes.header}
        isHeaderInverted={false}
        clientLogoPrimary={getImageDetails(
          ImagesMapping[clientName].clientLogoPrimary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        clientLogoSecondary={getImageDetails(
          ImagesMapping[clientName].clientLogoSecondary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        isScrollHeader={false}
        primaryLogoTabIndex={-1}
        secondaryLogoTabIndex={-1}
      />

      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          <div className={classes.imageWrapper}>
            <div style={{ display: "block", position: "relative" }}>
              <img
                className={classes.trafficImage}
                alt="traffic_img"
                src={ImagesMapping[clientName].trafficPermissionNewImage}
              />
              <img
                className={classes.heroImage}
                alt="Traffic_Screen"
                src={ImagesMapping[clientName].trafficPermissionHeroImg}
              />
            </div>
          </div>
          <div className={classes.textWrapper}>
            <h2 className={classes.heading}>
              {ConstantMapping[clientName].ONBOARDING_TRAFFIC_HEADING}
            </h2>
            <p className={classes.descriptionText}>
              {ConstantMapping[clientName].ONBOARDING_TRAFFIC_CONTENT}
            </p>
          </div>
          <div className={classes.buttonWrapper}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Button
                variant="contained"
                color="primary"
                className={classes.trafficButtonOn}
                onClick={onClickAllowButton}
              >
                {ConstantMapping[clientName].TRAFFIC_BUTTON_TEXT}
              </Button>
              <Button
                className={classes.trafficButtonOff}
                onClick={onClickDontAllowLink}
              >
                {ConstantMapping[clientName].TRAFFIC_LINK}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  let clientData = ClientModel.getInsatnce("", state)?.props;
  let userProfileData = UserProfile.getInsatnce("local", state)?.props;
  return {
    clientData,
    userProfileData
  };
};

export default connect(mapStateToProps)(TrafficScreen);
