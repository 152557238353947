//@ts-nocheck

import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useStyles } from "./styles";
import { Header } from "gce-cxinteracts-ui-components-library";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import FavouriteOffersComponent from "./FavouriteOffersComponent/FavouriteOffersComponent";
import ClaimedOffersComponent from "./ClaimedOffersComponent/ClaimedOffersComponent";
import { ConstantMapping } from "../../../Configurations/ConstantsMapping";
import { getClientName, getImageDetails } from "Utils/GeneralUtils";
import ApplicationFlag from "Models/ReduxModels/ApplicationFlag";
import { useHistory } from "react-router-dom";

import { ImagesMapping } from "Configurations/ImagesMapping";

const MyRewards: React.FC<any> = props => {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const { activeTabDetails } = props;
  const clientName = getClientName();
  useEffect(() => {
    if (activeTabDetails?.isMyCodesActive) {
      setValue(1);
    }
  }, [activeTabDetails]);
  useEffect(() => {
    new ApplicationFlag({
      id: "",
      isShowFooter: true,
      isMyCodesActive: false
    }).$save();
  }, []);
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  function TabPanel(props: any) {
    const { children, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3} style={{ padding: "12px 9px" }}>
            <Fragment>{children}</Fragment>
          </Box>
        )}
      </div>
    );
  }
  const redirectToHome = () => {
    history.push("/home");
  };
  return (
    <Fragment>
      <Header
        headerText=""
        isHeaderInverted={false}
        svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
        clientLogoPrimary={getImageDetails(
          ImagesMapping[clientName].clientLogoPrimary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        clientLogoSecondary={getImageDetails(
          ImagesMapping[clientName].clientLogoSecondary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        onSvgSelection={() => window.history.back()}
        onLogoSelection={redirectToHome}
        isScrollHeader={false}
      />
      <div className={classes.myRewardsContainer}>
        <div className={classes.myRewardsTabsContainer}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Saved" tabIndex={0} aria-label="My saved rewards" />
            <Tab label="My codes" tabIndex={0} aria-label="My reward codes" />
          </Tabs>
        </div>
        <div className={classes.offersContainer}>
          <TabPanel value={value} index={0}>
            <FavouriteOffersComponent />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ClaimedOffersComponent />
          </TabPanel>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  let activeTabDetails = ApplicationFlag.getInsatnce("", state).props;
  return {
    activeTabDetails
  };
};
export default connect(mapStateToProps)(MyRewards);
