import EditInterest from "../../../Routes/Interest/EditInterest";
import { FavouriteOffers } from "../../../Routes/FavouriteOffers/FavouriteOffers";
import LandingScreen from "../../../Routes/UserOnboarding/LandingScreen/LandingScreen";
import TrafficScreen from "../../../Routes/UserOnboarding/TrafficScreen/TrafficScreen";
import SmsPermission from "../../../Routes/UserOnboarding/SmsPermission/SmsPermission";
import OnboardingSummary from "../../../Routes/UserOnboarding/SummaryScreen/SummaryScreen";
import ProfilePermissionPage from "../../../Routes/UserOnboarding/Profiling/ProfilePermission";
import LocationPermissionPage from "../../../Routes/UserOnboarding/Location/LocationPermission";
import RedemptionCode from "../../../Routes/Offers/RedemptionCode";
import HeaderFooterWrapper from "../../../Routes/Wrapper/index";
import OfferDetails from "Routes/OfferDetails/OfferDetails";
import Faq from "../../../Routes/Wrapper/Help/FAQSection/FaqSection";
import CollectionCardDetails from "Routes/Wrapper/CollectionCardDetails/CollectionCardDetails";
import ErrorPage from "Routes/ErrorPage/ErrorPage";
import PrepaidErrorPage from "Routes/ErrorPage/PrepaidErrorPage/PrepaidErrorPage";
import MaintenanceErrorPage from "Routes/ErrorPage/MaintenanceErrorPage/MaintenanceErrorPage";
import Chat from "Routes/Wrapper/Help/Chat";
import EatLocalOffer from "Routes/OfferDetails/EatLocalOffer";
import IFrame from "Routes/IFrame/IFrame";
import CommonJourneyComponent from "../../../Routes/UserOnboarding/common/CommonJourneyComponent";
import SearchRestaurant from "Routes/RestaurantJourney/SearchRestaurant";
import Membership from "Routes/RestaurantJourney/Membership/Membership";
import WelcomeMembership from "Routes/RestaurantJourney/Membership/WelcomeMembership";
import UseCard from "Routes/RestaurantJourney/Membership/UseCard";
import QrCode from "Routes/RestaurantJourney/RestaurantDetails/QrCode";
import CustomFilters from "Routes/RestaurantJourney/Filters/CustomFilter";
import OfferTypeFilter from "Routes/RestaurantJourney/Filters/OfferTypeFilter";
import AvailabilityFilter from "Routes/RestaurantJourney/Filters/AvailabilityFilter";
import CuisineFilter from "Routes/RestaurantJourney/Filters/CuisineFilter";
import SearchScreen from "Routes/RestaurantJourney/SearchScreen";
import RestaurantDetails from "Routes/RestaurantJourney/RestaurantDetails/RestaurantDetails";

export const VodafoneRoutes = {
  iFrame: {
    path: "/external/:type",
    component: IFrame
  },
  errorPage: {
    path: "/error-500",
    component: ErrorPage
  },
  maintenancePage: {
    path: "/mantainence-page",
    component: MaintenanceErrorPage
  },
  chat: {
    path: "/chat",
    component: Chat
  },
  prepaidErrorPage: {
    path: "/prepaid-error",
    component: PrepaidErrorPage
  },
  collectionCardDetails: {
    path: "/collection-card/:id",
    component: CollectionCardDetails
  },
  faq: {
    path: "/faq/:id",
    component: Faq
  },
  redemptionCode: {
    path: "/code/:id",
    component: RedemptionCode
  },
  offerDetails: {
    path: "/offer-details/:id",
    component: OfferDetails
  },
  interest: {
    path: "/interest/:id",
    component: EditInterest
  },
  onboardingInterests: {
    path: "/interest",
    component: EditInterest
  },
  onboardingSummary: {
    path: "/summary",
    component: OnboardingSummary
  },
  smsPermission: {
    path: "/sms-permission",
    component: SmsPermission
  },
  trafficScreen: {
    path: "/traffic-permission",
    component: TrafficScreen
  },
  locationPermission: {
    path: "/location-permission",
    component: LocationPermissionPage
  },
  profilePermission: {
    path: "/profile-permission",
    component: ProfilePermissionPage
  },
  landingScreen: {
    path: "/landing",
    component: CommonJourneyComponent
  },
  favouriteOffers: {
    path: "/favourite-offers",
    component: FavouriteOffers
  },
  welcomeMembership: {
    path: "/membership",
    component: Membership
  },
  membership: {
    path: "/welcome-membership",
    component: WelcomeMembership
  },
  useCard: {
    path: "/use-card",
    component: UseCard
  },
  qrCode: {
    path: "/show-qr-code",
    component: QrCode
  },
  cuisineFilter: {
    path: "/cuisine-filter",
    component: CuisineFilter
  },
  availabilityFilter: {
    path: "/avail-filter",
    component: AvailabilityFilter
  },
  offerTypeFilter: {
    path: "/offertype-filter",
    component: OfferTypeFilter
  },
  filters: {
    path: "/custom-filters",
    component: CustomFilters
  },
  searchScreen: {
    path: "/search-screen",
    component: SearchScreen
  },
  searchRestaurant: {
    path: "/search-custom-list",
    component: SearchRestaurant
  },
  eatLocal: {
    path: "/custom-vendor/:id",
    component: EatLocalOffer
  },
  restaurantDetails: {
    path: "/restaurant-details/:id",
    component: RestaurantDetails
  },
  home: {
    path: "/",
    component: HeaderFooterWrapper
  }
};

export const VodafoneHashRoutes = {
  "wallet/favourites": "myRewards",
  "wallet/claimed": "myRewards",
  help: "help",
  "help/article/:id": "faq",
  "code/:id": "code",
  "offer/:id": "offer-details"
};

export const userOnboardingRoutes = {
  // 1: {
  //   route: "profile-permission",
  //   isConditional: true,
  //   sequence: 1,
  //   hierarchy: "profile.permissions.profiling"
  // },
  2: {
    route: "location-permission",
    isConditional: true,
    sequence: 2,
    hierarchy: "profile.permissions.location"
  },
  3: {
    route: "traffic-permission",
    isConditional: true,
    sequence: 3,
    hierarchy: "profile.permissions.traffic"
  },
  4: {
    route: "sms-permission",
    isConditional: true,
    sequence: 4,
    hierarchy: "profile.permissions.sms"
  }
};

export const onBoardingRoutesV2 = {
  profile: {
    route: "profile-permission",
    condition: "permissions.profiling",
    showAlways: false
  },
  location: {
    route: "location-permission",
    condition: "permissions.location",
    showAlways: false
  },
  traffic: {
    route: "traffic-permission",
    condition: "permissions.traffic",
    showAlways: false
  },
  sms: {
    route: "sms-permission",
    condition: "permissions.sms",
    showAlways: false
  },
  interest: {
    route: "interest",
    showAlways: true
  },
  summary: {
    route: "summary",
    showAlways: true
  }
};

export const onBoardingRoutesV3 = {
  profile: {
    route: "profile",
    condition: "permissions.profiling",
    showAlways: false
  },
  location: {
    route: "location",
    condition: "permissions.location",
    showAlways: false
  },
  traffic: {
    route: "traffic",
    condition: "permissions.traffic",
    showAlways: false
  },
  sms: {
    route: "sms",
    condition: "permissions.sms",
    showAlways: false
  },
  summary: {
    route: "summary",
    showAlways: true
  }
};
