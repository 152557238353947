import { BaseModel } from "ReduxStore/index";

export interface IApplication {
  isShowFooter: Boolean;
  isMyCodesActive?: Boolean;
  isRedirectedFromGiftingSuccessPage?: Boolean;
  offerId?: number;
  visitToWinId?: string | number;
  isShowAnnouncementPopup?: Boolean;
  showOfferDetailsDeepLink?: string;
  selectedShopOrResto?: any;
  eatLocalCoffeClubID?: any;
  eatLocalCoffeClubOffer?: any;
}

export default class ApplicationFlag extends BaseModel<IApplication> {
  static resource = "ApplicationFlag";
}
