import React, { useEffect } from "react";
import { useStyles } from "./styles";
import { Typography, Button, useTheme } from "@material-ui/core";
import { getImageDetails } from "Utils/GeneralUtils";
import { Header } from "gce-cxinteracts-ui-components-library";
import { ConstantMapping } from "../../Configurations/ConstantsMapping";
import { useHistory } from "react-router-dom";
import ApplicationFlag from "Models/ReduxModels/ApplicationFlag";
import { ImagesMapping } from "Configurations/ImagesMapping";
interface IProps {
  clientName: string;
  goBack(): void;
  offerId: number;
}

const GiftedScreen: React.FC<IProps> = ({ clientName, goBack, offerId }) => {
  useEffect(() => {
    return () => {
      if (window["_rulai_widget"]) {
        window["_rulai_widget"].refresh();
        window["_rulai_widget"].minimize();
        window["_rulai_widget"].hideLaunchIcon();
      }
    };
  }, []);

  const classes = useStyles();
  const history = useHistory();
  const redirectToHome = () => {
    history.push("/home");
  };
  const theme = useTheme();

  const openRulaiChat = () => {
    new ApplicationFlag({
      id: "",
      isShowFooter: false,
      isRedirectedFromGiftingSuccessPage: true,
      offerId: offerId
    }).$save();
    history.push("/chat");
  };

  let svgProps = Object.assign(
    {},
    ConstantMapping[clientName].SVG.headerSvgStyle
  );
  svgProps.fill = theme.palette.text.secondary;
  return (
    <div className={classes.containerPrizeDraw} style={{ marginTop: "0" }}>
      <div className={classes.headerContainerPrizeDraw}>
        <Header
          headerText="Back"
          svgProps={svgProps}
          clientLogoPrimary={getImageDetails(
            ImagesMapping[clientName].clientLogoPrimary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          clientLogoSecondary={getImageDetails(
            ImagesMapping[clientName].clientLogoSecondary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          onSvgSelection={goBack}
          isHeaderInverted={true}
          onLogoSelection={redirectToHome}
        />
      </div>
      <div className={classes.giftedContainer}>
        <div className={classes.sendGiftWrapper}>
          <img
            alt="giftIcon"
            src={ImagesMapping[clientName].iconGiftOpenedWhite}
            className={classes.giftIconBoxImage}
          />
          <div className={`${classes.sendGiftContainer}`}>
            <Typography className={classes.sendGiftHeaderText}>
              You've sent this gift
            </Typography>
            <Typography
              className={`${classes.giftedContent} ${classes.sendGiftContent}`}
            >
              Your gift recipient will shortly get a link with the gift details.
            </Typography>
            <Typography
              className={`${classes.giftedProblemText} ${classes.sendGiftContent}`}
            >
              If you've experienced a problem, please contact us on{" "}
              <u style={{ cursor: "pointer" }} onClick={openRulaiChat}>
                Live Chat
              </u>
            </Typography>
          </div>
        </div>
        <Button
          className={classes.giftedButton}
          color={"secondary"}
          variant={"contained"}
          onClick={goBack}
        >
          Done
        </Button>
      </div>
    </div>
  );
};

export default GiftedScreen;
