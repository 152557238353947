import React from "react";
import { useStyles } from "./styles";

interface IProps {
  guidelines: string | null;
  isLightTheme?: boolean | undefined;
}
const RedemptionGuidelines: React.FC<IProps> = ({
  guidelines,
  isLightTheme
}) => {
  const classes = useStyles();
  if (guidelines)
    return (
      <div className={classes.guidelinesContainer}>
        <div
          dangerouslySetInnerHTML={{
            __html: guidelines
          }}
          className={
            isLightTheme ? classes.guidelinesLight : classes.guidelines
          }
        />
      </div>
    );
  else return null;
};

export default RedemptionGuidelines;
