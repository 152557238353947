import { makeStyles, Theme, createStyles } from "@material-ui/core";
import bgImage from "Configurations/Clients/vodafone/Assets/Images/ReferalAssets/referal_background.png";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      background: "linear-gradient(180deg, #F6F6F6 63.43%, #D9D8D8 100%)",
      display: "flex",
      flexDirection: "column"
    },
    mainWrapper: {
      background: "linear-gradient(180deg, #F6F6F6 63.43%, #D9D8D8 100%)",
      flex: 1,
      overflowY: "auto",
      height: "calc(100% - 45px)"
    },
    bgImgContainer: {
      backgroundImage: `url(${bgImage})`,
      borderRadius: "12px",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      padding: "18px"
    },
    referContainer: {
      margin: "0vw 5vw",
      paddingTop: "7vw",
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      position: "relative",
      height: "100vh",
      '@media (min-height: 500px)': {
        height: "125vh", 
      },
      '@media (min-height: 660px)': {
        height: "100vh", 
      },
      '@media (min-height: 840px)': {
        height: "calc(100vh - 10vh)", 
      },
    },
    referHeading: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      color: "#fff",
      "& h1": {
        margin: "16px 0px 0px 0px",
        flex: "1",
        lineHeight: "34px",
        letterSpacing: "1px"
      }
    },
    referGiftIcon: {
      width: "30vw"
    },
    referalCountBox: {
      minHeight: "76px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      color: "#fff",
      border: "2px solid #fff",
      borderRadius: "12px",
      fontSize: "26px",
      fontWeight: "bolder",
      padding: "12px 16px"
    },
    referText: {
      fontSize: "18px"
    },
    shareText: {
      color: "#fff",
      fontSize: "30px",
      marginTop: "14px",
      lineHeight: "35px"
    },
    referSmallText: {
      fontSize: "22px",
      color: "#fff",
      margin: "3px 0px 20px 0px",
      fontWeight: "bold"
    },
    referalLinkButtonWrapper: {
      width: "54%",
      position: "sticky",
      bottom: "3vw",
      display: "flex",
      justifyContent: "center",
      left: "5vw",
      marginTop: "1rem"

    },
    referalLinkButton: {
      height: "54px",
      width: "100%",
      borderRadius: "16px",
      "& span": {
        textTransform: "none",
        fontSize: "22px",
        fontWeight: "bold"
      }
    },
    shareIconButton: {
      padding: "0px",
      marginBottom: "8px",
      "& .MuiButton-iconSizeMedium > *:first-child": {
        display: "flex"
      }
    },
    shareData: {
      display: "flex",
      flexDirection: "row"
    },
    platform: {
      color: "#fff",
      fontSize: "26px",
      marginLeft: "10px",
      textTransform: "none"
    },
    termsAndConditions: {
      "& p": {
        marginTop: "0px"
      }
    },
    referHappyFrndsImg: {
      marginTop: "10%",
    },
    referAFriendImg: {
      height: "auto",
      width: "100%",
      position: "absolute",
      bottom: "0",
    },
    sharePageHeadingOne: {
      fontSize: "32px",
      fontFamily: "VodafoneRgBold"
    },
    sharePageHeadingTwo: {
      fontSize: "36px",
      fontFamily: "VodafoneRgBold"
    },
  })
);
