//@ts-nocheck

import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomWrapper: {
      background: "rgb(242, 242, 242)",
      boxSizing: "border-box",
      paddingTop: theme.spacing(1.5),
      borderTop: "1px solid rgb(208, 208, 208)",
      display: "flex",
      minHeight: "215px",
      flexDirection: "column"
    },
    helpBottomContainer: {
      minHeight: "228px"
    },
    info: {
      margin: "18px 5vw"
    },
    infoHeading: {
      margin: theme.spacing(0, 0, 3),
      fontSize: "24px",
      fontFamily: theme.typography.h3.fontFamily,
      color: theme.palette.secondary.light
    },
    infoText: {
      margin: theme.spacing(0, 0, 4.5),
      lineHeight: "22px",
      color: theme.palette.secondary.light
    },
    infoContent: {
      lineHeight: "unset",
      fontSize: theme.typography.h6.fontSize
    },
    infoBoldText: {
      lineHeight: "unset",
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.h6.fontSize
    },
    startChat: {
      borderRadius: theme.spacing(2.3),
      padding: theme.spacing(0, 4.5),
      fontWeight: theme.typography.fontWeightBold,
      display: "flex",
      fontSize: theme.typography.h6.fontSize,
      minHeight: theme.spacing(12),
      alignItems: "center",
      outline: "none",
      border: "none",
      textTransform: "none",
      boxShadow: theme.shadows[4],
      "& .MuiButton-label": {
        margin: "0px 16px"
      }
    },
    webchat_button_center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "85%",
      flexDirection: "row",
      margin: "0 auto",
      boxShadow: theme.shadows[6],
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.h6.fontSize,
      minHeight: "48px",
      textTransform: "none"
    }
  })
);
