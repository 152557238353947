import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      marginRight: theme.spacing(-5),
      height: "100vh",
      "@media (max-width : 960px)": {
        marginRight: theme.spacing(0)
      }
    },
    content: {
      width: "100%",
      height: "93vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      paddingTop: "calc(20px)"
    },
    question: {
      color: theme.palette.primary.main,
      margin: `${theme.spacing(4.5)}px 5vw`
    },
    questionTitle: {
      marginBottom: theme.spacing(2.5)
    },
    questionBody: {
      marginTop: theme.spacing(7),
      margin: `${theme.spacing(4.5)}px 5vw`,
      color: "rgb(68, 68, 68)",
      "& a": {
        color: "rgb(68, 68, 68)"
      },
      lineHeight: "22px"
    }
  })
);