import React, { useEffect } from "react";
import { Header } from "gce-cxinteracts-ui-components-library";
import { useHistory } from "react-router-dom";

import { ConstantMapping } from "Configurations/ConstantsMapping";
import { getClientName, getImageDetails } from "Utils/GeneralUtils";
import {
  pushAllEventsToServer,
  logEvents,
  EVENT_TYPE
} from "Services/EventService";
import { useStyles } from "./styles";
import { ImagesMapping } from "Configurations/ImagesMapping";

const ErrorPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const clientName = getClientName();

  useEffect(() => {
    pushAllEventsToServer();
    setRulAITags();
  }, []);

  const setRulAITags = () => {
    if (window["_rulai"]) {
      const variables = window["_rulai"]?.slot?.variables;
      const custom_tag = {
        name: "custom.tag",
        value: "User was redirected from error page"
      };
      const redirectionFlag = {
        name: "custom.isRedirectedFromErrorPage",
        value: "true"
      };
      variables.push(redirectionFlag);
      variables.push(custom_tag);
    }
  };

  const onClickHere = e => {
    e.preventDefault();
    history.push(ConstantMapping[clientName].ERROR_PAGE_SUPPORT_URL , "/error-500");
    logEvents({ eventType: EVENT_TYPE.REDIRECTED_FROM_ERROR_PAGE });
    pushAllEventsToServer();
  };

  return (
    <div className={classes.container}>
      <div style={{ zIndex: 5, top: 0, position: "sticky" }}>
        <Header
          headerText="My Vodafone"
          svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
          clientLogoPrimary={getImageDetails(
            ImagesMapping[clientName].clientLogoPrimary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          clientLogoSecondary={getImageDetails(
            ImagesMapping[clientName].clientErrorPageLogo,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          onSvgSelection={() =>
            window.open(ConstantMapping[clientName].ERROR_PAGE_BACK_URL)
          }
          onLogoSelection={() =>
            window.open(ConstantMapping[clientName].ERROR_PAGE_BACK_URL)
          }
          className={classes.header}
        />
      </div>
      <div className={classes.errorMessage}>
        <p>{ConstantMapping[clientName].ERROR_PAGE_MESSAGE.LINE1}</p>
        <p>{ConstantMapping[clientName].ERROR_PAGE_MESSAGE.LINE2}</p>
        <p>
          {ConstantMapping[clientName].ERROR_PAGE_MESSAGE.LINE3}
          <span className={classes.link}>
            &nbsp;
            <a onClick={onClickHere} href={"#"}>
              {ConstantMapping[clientName].ERROR_PAGE_CLICK_HERE}
            </a>
            &nbsp;
          </span>
          {ConstantMapping[clientName].ERROR_PAGE_MESSAGE.LINE4}
        </p>
      </div>
    </div>
  );
};

export default ErrorPage;
