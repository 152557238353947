export const getClaimedDate = (props) => {
  let claimedDate = props?.claimDateTime ? new Date(props?.claimDateTime) : new Date();

  if (!props?.claimDateTime) {
    claimedDate.setHours(1, 59, 59, 999);
  }

  const date = new Date(claimedDate);
  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");

  const convertedDateString = `${day}.${month}.${year} ${hours}.${minutes}.${seconds}`;
  return convertedDateString;
};
export const getExpiryDate = (props) => {
  let expiryDate = props?.expiryDateTime ? new Date(props?.expiryDateTime) : new Date();

  if (!props?.expiryDateTime) {
    expiryDate.setHours(23, 59, 59, 999);
  }
  const date = new Date(expiryDate);
  const day = date.getDate();
  const year = date.getFullYear();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  const monthIndex = date.getMonth();
  const month = monthNames[monthIndex];

  const formattedDate = `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
  return formattedDate;
};

function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export const checkOfferExpiry = (data, offerId) => {
  const currentDate = new Date();
  for (const key in data) {
    const obj = data[key];
    if (
      obj.offerId == offerId?.offerId &&
      new Date(obj.expiryDateTime) >= currentDate
    ) {
      return true;
    }
  }
  return false;
};