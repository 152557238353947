import React, { Fragment, useEffect, useRef, useState } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import MyRewards from "./MyRewards/MyRewards";
import MyProfile from "./MyProfile/MyProfile";
import Home from "./Home/Home";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { ClientModel } from "../../Models/ReduxModels/ClientDetails";
import { History } from "history";
import Help from "./Help/Help";
import PrimaryNav from "../../Components/Navigation/Navigation";
import ApplicationFlag from "../../Models/ReduxModels/ApplicationFlag";
import HomeScreenPrimer from "./Home/HomeScreenPrimer";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import { checkChristmasMonth } from "Utils/GeneralUtils";
import UserDetails, { IUserDetails } from "Models/ReduxModels/UserDetails";
import CMSImages, {
  IIntroScreenConfigurations,
  ICMSImagesDetails
} from "Models/ReduxModels/CMSImages";
import { getClientName, getIntroScreenToShow } from "Utils/GeneralUtils";
import ReferFriends from "./Referee/ReferFriends";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      marginRight: theme.spacing(-5),
      height: "calc(100% - 57px)",
      overflow: "auto",
      transform: "translate3d(0, 0, 0)",
      transition: "all 0.3s ease",
      position: "fixed",
      zIndex: 1100,
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: theme.palette.common.white,
      "@media (max-width : 960px)": {
        marginRight: theme.spacing(0)
      }
    },
    footerContainer: {
      zIndex: 999,
      position: "fixed",
      width: "100%",
      left: 0,
      bottom: "-2px",
      "& .MuiBottomNavigation-root": {
        borderTop: `1px solid ${theme.palette.divider}`,
        paddingTop: "1px",
        "& .MuiBottomNavigationAction-root": {
          paddingBottom: theme.spacing(1)
        }
      }
    }
  })
);

const routes = [
  {
    path: "/referfriend",
    exact: false,
    component: ReferFriends
  },
  {
    path: "/help",
    exact: false,
    component: Help
  },
  {
    path: "/myRewards",
    exact: false,
    component: MyRewards
  },
  {
    path: "/myProfile",
    exact: false,
    component: MyProfile
  },
  {
    path: "/home",
    exact: false,
    component: Home
  },
  {
    path: "/",
    exact: false,
    component: Home
  }
];

interface IProps {
  clientData: any;
  history: History;
  footerData: any;
  userDetails: IUserDetails;
  cmsDetails: ICMSImagesDetails;
}

const HeaderFooterWrapper: React.FC<IProps> = props => {
  let classes = useStyles();
  const { clientData } = props;
  const clientName = getClientName();
  let accessToken = props.clientData?.token;
  const scrollAbleContainerRef = useRef(null);
  const history = useHistory();
  const { isShowFooter } = props?.footerData || false;
  const isChristmas =
    checkChristmasMonth() && ConstantMapping[clientName].IS_SHOW_CHRISTMAS;
  const {
    fullVisitToWinPopup,
    isConvergedUser,
    currentUserType,
    appAccessLevel
  } = props?.userDetails;
  const { CMS_introScreenConfigurations } = props?.cmsDetails;
  const _CMSDrivenImage = CMS_introScreenConfigurations?.introScreenImageURL;
  const isVisitToWin = fullVisitToWinPopup;

  const showPrimerType = getIntroScreenToShow(
    appAccessLevel,
    isVisitToWin,
    _CMSDrivenImage,
    isConvergedUser,
    currentUserType,
    CMS_introScreenConfigurations
  );

  const [showPrimer, setShowPrimer] = React.useState<boolean>(false);

  useEffect(() => {
    if (clientName === "vodafone") {
      if (showPrimerType === "DEFAULT" || props?.userDetails?.referralsData?.selfReferralPending === true) {
        setShowPrimer(false);
      } else {
        setShowPrimer(true);
        // // Remove below primer, once intro screen issues are resolved
        // setShowPrimer(false);
      }

      /* if (
        // !localStorage.getItem(
        //   ConstantMapping[clientName].PRIMER_LOCAL_STORAGE_KEY
        // ) ||
        (isChristmas &&
          !localStorage.getItem(
            ConstantMapping[clientName].XMAS_PRIMER_LOCAL_STORAGE_KEY
          )) ||
        ( (appAccessLevel==="PAYG_LITE") &&
          !localStorage.getItem(
            ConstantMapping[clientName].VMRLITE_PRIMER_LOCAL_STORAGE_KEY
          )) ||
        isVisitToWin  || (isConvergedUser&&
          !localStorage.getItem(
            ConstantMapping[clientName].CONVERGED_PRIMER_LOCAL_STORAGE_KEY
          ))
      ) {
        setShowPrimer(true);
      }*/
    }
  }, [showPrimerType]);

  history.listen(location => {
    resetScrollEffect({ element: scrollAbleContainerRef });
  });

  const resetScrollEffect = ({ element }) => {
    // if (element.current) element.current.scrollTop = 0;
  };

  const renderRoutes = () => {
    return routes.map((route, index) => {
      return (
        <Route
          key={index}
          path={route.path}
          component={route.component}
          exact={route.exact}
        />
      );
    });
  };

  const updateShowPrimer = () => {
    setShowPrimer(false);
  };

  const renderUI = (showPrimer, clientName, showPrimerType) => {
    //   console.log("renderUI",showPrimer,showPrimerType)
    if (showPrimer && clientName === "vodafone") {
      return (
        <div
          className={classes.mainContainer}
          ref={scrollAbleContainerRef}
          id="main-container"
          style={{ height: "100%" }}
        >
          <HomeScreenPrimer
            updateShowPrimer={updateShowPrimer}
            cshowPrimerType={showPrimerType}
            c_CMSDrivenImage={_CMSDrivenImage}
          ></HomeScreenPrimer>
        </div>
      );
    }
    return (
      <div
        className={classes.mainContainer}
        ref={scrollAbleContainerRef}
        id="main-container"
      >
        <Switch>
          {renderRoutes()}
          <Redirect to={"/error-500"} />
        </Switch>
      </div>
    );
  };

  return (
    <Fragment>
      <>
        {/* {showPrimer && clientName === "vodafone" && (appAccessLevel==="PAYG_LITE") ? (
          <HomeScreenPrimer
            updateShowPrimer={updateShowPrimer}
            isVMRLiteUser={(appAccessLevel==="PAYG_LITE")}
          ></HomeScreenPrimer>
        ) : (
          <Switch>
            {renderRoutes()}
            <Redirect to={"/error-500"} />
          </Switch>
        )} */}
        {renderUI(showPrimer, "vodafone", showPrimerType)}
      </>
      {isShowFooter && !showPrimer && (
        <div className={classes.footerContainer}>
          <PrimaryNav />
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  let clientData = ClientModel.getInsatnce("", state)?.props;
  let footerData = ApplicationFlag.getInsatnce("", state)?.props;
  let userDetails = UserDetails.getInsatnce("", state)?.props;
  let cmsDetails = CMSImages.getInsatnce("", state)?.props;
  return {
    clientData,
    footerData,
    userDetails,
    cmsDetails
  };
};

export default connect(mapStateToProps)(HeaderFooterWrapper);
