import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    myRewardsContainer: {
      height: "calc(100% - 50px)",
      position: "fixed",
      top: "50px",
      width: "100%",
      backgroundColor: "#f2f2f2",
      "& .MuiTab-wrapper": {
        color: theme.palette.secondary.light,
        textTransform: "none",
        fontSize: "24px"
      },
      "& .MuiTouchRipple-root": {
        color: "transparent"
      }
    },
    myRewardsTabsContainer: {
      top: "0",
      zIndex: 2,
      backgroundColor: theme.palette.background.default,
      width: "100%",
      paddingTop: theme.spacing(2.5)
    },
    offersContainer: {
      overflow: "scroll",
      height: "-webkit-calc(100% - 64px)",
      paddingBottom: theme.spacing(15),
      paddingTop: theme.spacing(6.5)
    }
  })
);
