import SnackBarModel, {
  SnackBarConfig
} from "Models/ReduxModels/SnackBarModel";
const autoHideDuration = 3000;
export const showSnackBar = (message?: string, duration?: number) => {
  const config: SnackBarConfig = {
    openSnackBar: true,
    message: message || "Something went wrong, please try again",
    autoHideDuration: duration || autoHideDuration
  };
  new SnackBarModel({
    id: "",
    ...config
  }).$save();
};

export const manuallyHideSnackBar = () => {
  const config: SnackBarConfig = {
    openSnackBar: false,
    message: "",
    autoHideDuration: autoHideDuration
  };
  new SnackBarModel({
    id: "",
    ...config
  }).$save();
};
