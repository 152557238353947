import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import vmrBg from "Configurations/Clients/vodafone/Assets/Images/VMR_Onboarding_blank_background_converged.png";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    offerContainer: {
      display: "grid",
      position: "relative",
      gridAutoFlow: "dense"
    },
    convergedBg: {
      backgroundImage: `url(${vmrBg})`,
      backgroundRepeat: "no-repeat",
      WebkitBackgroundSize: "cover",
      paddingLeft: "9px",
      borderRadius: "15px"
    },
    convergedHeading: {
      color: "white",
      margin: "0",
      fontWeight: 500,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    }
  })
);

interface IProps {
  convergedOffer?: any;
  renderOfferById?: any;
}

const Converged: React.FC<IProps> = ({ convergedOffer, renderOfferById }) => {
  const [expanded, setExpanded] = React.useState(true);
  const classes = useStyles();
  let offer = convergedOffer.length > 0;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      {offer && (
        <div className={classes.convergedBg}>
          <h2 className={classes.convergedHeading}>
            <span>Vodafone Together</span>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={handleExpandClick}
            >
              {expanded ? (
                <ExpandLessIcon fontSize="large" />
              ) : (
                <ExpandMoreIcon fontSize="large" />
              )}
            </IconButton>
          </h2>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <div
              className={classes.offerContainer}
              style={{ gridTemplateColumns: "1fr 1fr" }}
            >
              {(convergedOffer || []).map((offerId, index) => {
                return (
                  <span key={offerId} className="converged-offer">
                    {renderOfferById(offerId, index)}
                  </span>
                );
              })}
            </div>
          </Collapse>
        </div>
      )}
    </>
  );
};

export const ConvergedOffer = React.memo(Converged);
