//@ts-nocheck

import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: "100%"
      // marginTop: theme.spacing(-13)
    },
    mainContainer: {
      margin: "32px 5vw 18px"
    },
    divider: {
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    heading: {
      fontSize: theme.typography.h5.fontSize,
      color: theme.palette.secondary.light,
      margin: `${theme.spacing(2.5)}px ${theme.spacing(0)}px ${theme.spacing(
        4.5
      )}px`,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1,
      fontFamily: theme.typography.h5.fontFamily
    },
    buttonDefault: {
      padding: "0px",
      border: "none",
      background: "none",
      textAlign: "left"
    },
    descriptionText: {
      marginBottom: theme.spacing(7.5),
      fontSize: theme.typography.h5.fontSize
    },
    keyTermsContainer: {
      marginBottom: theme.spacing(7.5)
    },
    keyTermsWrapper: {
      fontSize: theme.typography.h5.fontSize
    },
    tcWrapper: {
      display: "flex",
      alignItems: "center"
    },
    tcText: {
      fontSize: "14px",
      marginBottom: theme.spacing(5)
    },
    plusIcon: {
      height: theme.spacing(4.5),
      marginLeft: theme.spacing(2.5)
    },
    minusIcon: {
      width: theme.spacing(4),
      marginLeft: theme.spacing(2.5)
    },
    buttonWrapper: {
      bottom: theme.spacing(6),
      zIndex: 1000,
      display: "flex",
      justifyContent: "flex-end",
      alignSelf: "flex-end",
      borderRadiusL: theme.shape.borderRadius
    },
    stickyButton: {
      height: theme.spacing(12),
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: "capitalize",
      "&.Mui-disabled": {
        color: theme.palette.primary.contrastText
      }
    },
    buttonContainer: {
      left: 0,
      bottom: 24,
      display: "flex",
      position: "sticky",
      alignItems: "center",
      justifyContent: "flex-end",
      margin: "18px 5vw",
      marginTop: "-13px"
    },
    ratingbuttonContainerTop: {
      left: 0,
      bottom: 24,
      display: "flex",
      // position: "sticky",
      alignItems: "center",
      justifyContent: "center",
      margin: "18px 5vw",
    },
    ratingbuttonContainerBottom: {
      left: 0,
      bottom: 24,
      display: "flex",
      // position: "sticky",
      alignItems: "center",
      justifyContent: "center",
      margin: "18px 5vw",
      paddingBottom: "20px",
    },
    blueButton: {
      height: theme.spacing(12),
      minWidth: "130px",
      background: "rgb(0, 176, 202)",
      color: theme.palette.primary.contrastText,
      fontSize: theme.typography.h5.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: "capitalize",
      boxShadow: theme.shadows[5],
      "&.Mui-disabled": {
        color: theme.palette.primary.contrastText
      }
    },
    redButton: {
      height: theme.spacing(12),
      minWidth: "149px",
      color: theme.palette.primary.contrastText,
      fontSize: theme.typography.h5.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      fontFamily: "VodafoneRgBold",
      textTransform: "inherit",
      padding: theme.spacing(0),
      "&.Mui-disabled": {
        color: theme.palette.primary.contrastText
      },
      "& .MuiButton-label": {
        padding: theme.spacing(0, 2.5)
      }
    },
    recommendedOffersContainer: {
      background: "rgb(242, 242, 242)",
      borderTop: `1px solid ${theme.palette.divider}`
    },
    recommendedOfferSection: {
      height: "304px",
      margin: "-3px 3vw 0 5vw",
      "@media (min-width:414px) and (min-height:736px)": {
        height: "311px"
      }
    },
    recommendedOfferText: {
      fontSize: "24px",
      fontWeight: theme.typography.fontWeightRegular,
      margin: "18px 5px 0px",
      color: "rgb(68, 68, 68)",
      paddingTop: "5px",
      "@media (min-width:414px) and (min-height:736px)": {
        paddingTop: "0px"
      }
    },
    recommendedOffers: {
      display: "grid",
      position: "relative",
      gridTemplateColumns: "1fr 1fr",
      gridAutoFlow: "dense",
      paddingTop: "8px"
    },
    recommendedOffer: {
      margin: theme.spacing(3)
    },
    termsAndConditionsUrl: {
      color: theme.palette.text.hint,
      marginLeft: theme.spacing(1)
    },
    dialogContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start"
    },
    dialogHeaderIcon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%"
    },
    dialogActionButtonFirst: {
      marginRight: theme.spacing(2.25),
      height: theme.spacing(12),
      textTransform: "none",
      textAlign: "center",
      flex: 1,
      boxShadow: theme.shadows[4],
      "& .MuiButton-label .MuiTypography-body1": {
        fontSize: "18px"
      }
    },
    firstButton: {
      marginRight: theme.spacing(2.25),
      height: theme.spacing(12),
      textTransform: "none",
      textAlign: "center",
      flex: 1,
      boxShadow: theme.shadows[1],
      "& .MuiButton-label .MuiTypography-body1": {
        fontSize: "18px",
        fontWeight: "bold",
        color: theme.palette.primary.main
      }
    },
    dialogActionButtonLast: {
      marginLeft: theme.spacing(2.25),
      height: theme.spacing(12),
      textTransform: "none",
      textAlign: "center",
      flex: 1,
      boxShadow: theme.shadows[4],
      "& .MuiButton-label .MuiTypography-body1": {
        fontSize: "18px"
      }
    },
    secondButton: {
      marginLeft: theme.spacing(2.25),
      height: theme.spacing(12),
      textTransform: "none",
      textAlign: "center",
      flex: 1,
      boxShadow: theme.shadows[1],
      "& .MuiButton-label .MuiTypography-body1": {
        fontSize: "18px",
        fontWeight: "bold",
        color: theme.palette.primary.main
      }
    },
    dialogActionButtonContainer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: theme.spacing(0.5)
    },
    dialogHeading: {
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: "25px",
      // marginBottom: theme.spacing(3.5),
      animation: "$easing_in .4s linear 0s 1",
      letterSpacing: "0.686px",
      fontFamily: "VodafoneRgBold"
    },
    dialogBody: {
      fontSize: theme.typography.h5.fontSize,
      lineHeight: "22px",
      margin: "5vw 0px",
      "& p": {
        lineHeight: "21px",
        animation: "$easing_in .2s linear 0s 1",
        margin: "0px"
      }
    },
    iwGifContainer: {
      width: "100vw",
      display: "flex",
      position: "absolute",
      flexDirection: "column",
      alignItems: "center",
      overflowX: "hidden"
      // bottom: "-6px"
    },
    iwGifIntro: {
      height: "100vh",
      width: "max(56.2vh,100vw)",
      zIndex: 1
    },
    luckydipIntro: {
      height: "19%",
      top: "20px",
      zIndex: 10,
      position: "absolute"
    },
    iwGifPopBalloonContainer: {
      zIndex: 4,
      position: "absolute",
      left: 0,
      top: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      width: "100vw"
    },
    iwGifPopBalloon: {
      height: "100%"
    },
    overlayContent: {
      position: "absolute",
      padding: "22vw 0px",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      lineHeight: "0.9 !important",
      zIndex: 3
    },
    contentFirst: {
      fontSize: "min(110px, 27vw)",
      color: theme.palette.text.secondary,
      fontWeight: "bolder",
      lineHeight: "0.76 !important",
      fontFamily: "VodafoneRgBold"
    },
    contentSecond: {
      fontSize: "min(43px, 9vw)",
      color: theme.palette.text.secondary,
      fontWeight: "bolder",
      fontFamily: "VodafoneRgBold"
    },
    contentBottomButton: {
      fontSize: "min(5.5vw, 2.5vh)",
      color: theme.palette.text.secondary,
      position: "absolute",
      bottom: "1vh",
      right: "1vw",
      whiteSpace: "pre",
      padding: theme.spacing(4, 7.5),
      display: "flex",
      zIndex: 5,
      alignItems: "center",
      justifyContent: "center"
    },
    claimPrizeButtonContainer: {
      zIndex: 5,
      position: "fixed",
      bottom: "8.5vh",
      left: 0,
      right: 0,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      "@media (min-width:414px) and (min-height:736px)": {
        bottom: "9.5vh"
      },
      "@media (min-width:375px) and (min-height:812px)": {
        bottom: "9.8vh"
      }
    },
    claimPrizeButton: {
      color: theme.palette.text.primary,
      borderRadius: theme.spacing(1),
      textTransform: "none",
      height: "41px",
      width: "50vw",
      minWidth: "200px",
      maxWidth: "250px"
    },
    iwHeader: {
      zIndex: 6,
      top: 0,
      position: "absolute",
      width: "100vw"
    },
    horizontalBarContainer: {
      position: "absolute",
      width: "100%",
      zIndex: 5,
      background: theme.palette.secondary.main
    },
    horizontalBarText: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // fontSize: "min(4vh,7.5vw)",
      fontWeight: "bold",
      fontFamily: "VodafoneRgBold",
      textAlign: "center",
      padding: "0 5px",
      fontSize: "22px"
    },
    skipTextSign: {
      transform: "scale(0.8, 2.1)",
      lineHeight: 1.3,
      textAlign: "center",
      paddingTop: "1px",
      fontSize: theme.typography.subtitle1.fontSize
    },

    richText: {
      fontFamily: theme.typography.fontFamily,
      "& p": {
        color: theme.palette.secondary.light,
        lineHeight: 1.2,
        margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(
          4.5
        )}px`
      },
      "& ul": {
        paddingLeft: "4vw",
        margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(
          4.5
        )}px`,
        color: theme.palette.secondary.light,
        lineHeight: 1,
        "& li": {
          margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(
            3
          )}px`
        }
      },
      "& ol": {
        paddingLeft: "4vw",
        margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(
          4.5
        )}px`,
        color: theme.palette.secondary.light,
        lineHeight: 1,
        "& li": {
          margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(
            3
          )}px`
        }
      }
    },
    sharedBackgroundImage: {
      height: "calc(54vw + 45px)",
      flex: 1,
      zIndex: 0,
      display: "flex",
      flexDirection: "column",
      backgroundSize: "100%",
      backgroundRepeat: "no-repeat"
    },
    overlayStripText: {
      position: "absolute",
      display: "flex",
      bottom: 0,
      width: "100%",
      height: theme.spacing(10),
      alignItems: "center",
      paddingLeft: theme.spacing(5),
      color: theme.palette.text.secondary,
      fontSize: "20px",
      fontWeight: "normal"
    },

    skipContentText: {
      fontSize: "16px",
      marginRight: "-1px"
    },

    claimRewardsText: {
      fontSize: "18px",
      fontFamily: "Arial"
    },

    restrictedContainer: {
      margin: "32px 5vw 50px"
    },

    restrictedTermContainer: {
      paddingBottom: "30px",
      background: `linear-gradient(0deg, #F4F4F4 0%, rgba(255,255,255,0) 99.93%)`,
      margin: "0 -5vw"
    },
    restrictedSpacing: {
      margin: "0 5vw"
    },

    restrictedHeading: {
      color: theme.palette.secondary.dark,
      fontFamily: "VodafoneRgBold",
      fontSize: theme.typography.h4.fontSize,
      letterSpacing: "-0.06px",
      lineHeight: "24px"
    },

    restrictedText: {
      color: theme.palette.secondary.dark,
      fontFamily: "VodafoneRg",
      fontSize: theme.typography.h5.fontSize,
      letterSpacing: "-0.06px",
      lineHeight: "22px"
    },

    restrictedRedBtn: {
      width: "55%"
    },

    dialogOpenAimation: {
      animation: "$openDialogTransition .2s linear 0s 1"
    },
    "@keyframes openDialogTransition": {
      from: {
        height: 0
      },
      to: {
        height: "40%"
      }
    },
    "@keyframes easing_in": {
      "0%": {
        opacity: 0
      },
      "80%": {
        opacity: 0
      },
      "100%": {
        opacity: 1
      }
    },
    luckyDipHeader: {
      zIndex: 15,
      top: 0,
      position: "absolute",
      width: "100vw"
    },
    transparentBackground: {
      backgroundColor: "transparent !important"
    },
    luckyDipOverlayContent: {
      padding: "11vw 0px",
      zIndex: 10
    },
    luckyDipIntroGif: {
      height: "100%",
      position: "absolute"
    },
    luckyDipTap: {
      fontSize: theme.typography.h5.fontSize,
      color: theme.palette.common.white,
      zIndex: 6,
      width: "80%",
      height: "55%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    luckyDipContainer: {
      zIndex: 4,
      position: "absolute",
      left: 0,
      top: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      width: "100vw"
    },
    luckyDipPrizePick: {
      animation: "fadeInAnimation ease 3s",
      animationIterationCount: 1,
      animationFillMode: "forwards"
    },
    luckyDipSkipSign: {
      paddingTop: theme.spacing(1) / 2.67
    },
    "@keyframes fadeInAnimation": {
      "0%": {
        opacity: 0
      },
      "100%": {
        opacity: 1
      }
    },

    luckyDipPrizeButtonContainer: {
      zIndex: 5,
      position: "absolute",
      top: "45vh",
      left: 0,
      right: 0,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column"
    },
    luckyDiphorizontalBarContainer: {
      width: "100%",
      zIndex: 5,
      background: theme.palette.secondary.main
    },
    luckyDiphorizontalBarText: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "min(4vh,7.5vw)",
      fontWeight: "bold",
      fontFamily: "VodafoneRgBold",
      textAlign: "center",
      padding: "0 8px"
    },
    luckyDipContentFirst: {
      fontSize: "min(110px, 20vw)",
      color: theme.palette.text.secondary,
      fontWeight: "bolder",
      lineHeight: "0.76 !important",
      fontFamily: "VodafoneRgBold"
    },
    prizeheading: {
      textAlign: "center",
      fontSize: theme.typography.h3.fontSize,
      margin: "5px auto",
      fontFamily: "VodafoneRgBold",
      fontWeight: 700,
      lineHeight: 1
    },
    prizeSubHeading: {
      textAlign: "center",
      fontSize: theme.typography.h5.fontSize,
      marginBottom: "30px"
    },
    visitCountContainer: {
      // width: "56%",
      // alignItems: "center",
      // margin: "0 auto",
      display: "flex",
      justifyContent: "center",
      position: "relative"
    },
    visitToWinImg: {
      width: "70%",
      height: "100%"
    },
    visitToCountCount: {
      position: "absolute",
      height: "100%",
      margin: "0 auto",
      top: "40.5%",
      fontSize: theme.typography.h2.fontSize,
      color: theme.palette.primary.main,
      fontFamily: "VodafoneRgBold"
    },

    visitToWinBtn: {
      "@media screen and (max-width: 412px)": {
        bottom: "240px"
      },
      "@media screen and (max-width: 415px)": {
        bottom: "147px"
      },
      "@media screen and (max-width: 640px) and (min-width: 360px)": {
        bottom: "110px"
      },
      "@media screen and (max-width: 376px)": {
        bottom: "110px"
      },
      "@media screen and (max-width: 345px)": {
        bottom: "43px"
      }
    },
    additionalDescription: {
      borderTop: `1px solid ${theme.palette.primary.main}`,
      paddingTop: "20px"
    },
    fallback_bg: {
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      justifyContent: "center"
    },
    backBtn_Style: {
      backgroundColor: "#333",
      color: "#ffff"
    },
    offerRatingSection: {
      height: "fit-content",
      margin: "18px 5vw",
      paddingBottom: "25px"
    },
    divLike: {
      background: "#fff",
      borderRadius: 10,
      paddingTop: "1px",
      paddingBottom: "25px",
    },
    offerRateText: {
      padding: "0px 18px",
      fontSize: "18px",
      marginTop: "-1.5vh"
    },
    textRate: {
      padding: "0px 18px",
      margin: "18px 0px",
      fontWeight: "bold",
      color: theme.palette.primary.main,
      fontSize: "20px"
    },
    rewardButtonContainer: {
      display: "flex",
      flexDirection: "row",
      padding: "18px 18px 20px 18px"
    },
    likeButtonContainer: {
      width: "48%",
      flexDirection: "row",
      backgroundColor: "#fff",
      fontWeight: "bolder",
      borderRadius: 10,
      paddingLeft: "17px",
      marginRight: 5,
      height: "6vh",
      justifyContent: "flex-start",
      alignItems: "center",
      display: "flex"
    },
    dislikeButtonContainer: {
      width: "48%",
      paddingLeft: "17px",
      flexDirection: "row",
      backgroundColor: "#fff",
      fontWeight: "bolder",
      borderRadius: 10,
      marginLeft: 5,
      height: "6vh",
      justifyContent: "flex-start",
      alignItems: "center",
      display: "flex"
    },
    responseButtonContainer : {
      width: "inherit",
      flexDirection: "column",
      backgroundColor: "#fff",
      fontWeight: "500",
      borderRadius: 10,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      gap: "10px"
    },
    responseButton: {
      width: "87%",
      flexDirection: "row",
      backgroundColor: "#fff",
      borderRadius: 10, 
      height: "6vh",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      fontSize: "16px",
    },
    thumbIconStyle: {
      width: "20px",
      objectFit: "contain"
    },
    ratingButtonTextStyle: {
      fontSize: "15px",
      fontFamily: "VodafoneRgBold",
      textAlign: "center",
      marginLeft: "12px"
    },
    rateOfferButtonOnCard:{
      display: "flex",
      justifyContent: "end",
      marginRight: "10px",
      marginBottom: "-1%",
      width: "100%"
    },
    rateOfferCardButton:{
      border: `1px solid ${theme.palette.primary.main}`,
      textTransform: "none",
      fontSize: "1rem",
      fontFamily: "VodafoneRg, VodafoneRgBold",
      padding: "0 1rem"
    }
  })
);
