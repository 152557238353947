import React, { useEffect } from "react";
import { useStyles } from "../styles";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import defaultImg from "Configurations/Clients/vodafone/Assets/Membership/membershipstartImg.png";
import {
  EVENT_TYPE,
  logEvents,
  pushAllEventsToServer
} from "Services/EventService";
import { restCardsUseStyles } from "./styles";

interface IProps {
  restaurantImage: string;
  restaurantName: string;
  cuisines: { id: string; name: string }[];
  infoLabelOffer: object | {};
  id: string;
  offerDetail: object | {};
  parentOffer: any;
}

const SearchResultGridCard: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();
  const classes = useStyles();
  const restCardsClasses = restCardsUseStyles();
  const {
    id,
    restaurantImage,
    restaurantName,
    cuisines,
    infoLabelOffer,
    offerDetail,
    parentOffer
  } = props;

  const handleCardClick = () => {
    setTimeout(() => {
      if (parentOffer?.addType === "Eat Local") {
        logEvents({
          eventType: EVENT_TYPE.EAT_LOCAL_RESTAURANT_CLICK,
          offerId: parentOffer?.offerId,
          restaurantId: id
        });
      } else {
        logEvents({
          eventType: EVENT_TYPE.COFFEE_CLUB_SHOP_CLICK,
          offerId: parentOffer?.offerId,
          restaurantId: id
        });
      }
      pushAllEventsToServer();
    }, 200);
    history.push(`/restaurant-details/${id}`, { restaurantProps: props });
  };

  return (
    <div className={restCardsClasses.gridCard} onClick={handleCardClick}>
      <div className={restCardsClasses.gridCardImage}>
        <img src={restaurantImage ? restaurantImage : defaultImg} />
      </div>
      <div className={classes.cardContent}>
        <p className={restCardsClasses.gridInfoLabel}>
          {infoLabelOffer[0]?.name}
          <span className={classes.infoLabelDays}></span>
        </p>
        <h4 className={restCardsClasses.cardTitle}>{restaurantName}</h4>
        <h4 className={restCardsClasses.cardText}>{cuisines[0]?.name}</h4>
      </div>
    </div>
  );
};

export default SearchResultGridCard;
