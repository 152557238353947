import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";

import img1 from "Configurations/Clients/vodafone/Assets/Images/landing-screen/1.jpg";
import img2 from "Configurations/Clients/vodafone/Assets/Images/landing-screen/2.jpg";
import img3 from "Configurations/Clients/vodafone/Assets/Images/landing-screen/3.jpg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useStyles } from "./styles";
import { connect } from "react-redux";
import { ClientModel } from "../../../Models/ReduxModels/ClientDetails";
import UserProfile from "../../../Models/ReduxModels/UserProfileModel";
import { onBoardingScreens } from "./OnboardingEvents";
import { getNextNewOnBoardingRoute } from "Utils/GeneralUtils";
interface IProps {
  history: History;
  clientData: any;
  userProfileData: any;
  currentOnboardingPage: number;
  totalOnboardingPages: Array<string>;
}
const CommonJourneyComponent: React.FC<IProps> = props => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const {
    totalOnboardingPages,
    currentOnboardingPage
  } = props;
  const sliderRef: any = useRef();

  useEffect(() => {

    sliderRef && sliderRef?.current?.slickGoTo(currentOnboardingPage);
    setActiveStep(currentOnboardingPage);
  }, [currentOnboardingPage]);

  const renderSlides = () => {
    return totalOnboardingPages.map((item, index) => {
      const Page = onBoardingScreens[item];
      return (
        <div
          style={{
            height: "100vh"
          }}
          key={item}
        >
          <Page />
        </div>
      );
    });
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const settings = {
    dots: false,
    infinite: false,
    swipe: false,
    autoplay: false,
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 1,
    slidesPerRow: 1,
    slidesToScroll: 1,
    centerMode: false,
    afterChange: handleStepChange,
    focusOnSelect: false,
    nextArrow: null,
    prevArrow: null
  };

  return (
    <div className={classes.mainContainer} role="none">
      <Slider ref={sliderRef} {...settings}>
        {renderSlides()}
      </Slider>
      <div className={classes.slideIndicator}>
        {totalOnboardingPages.map((s, index) => {
          const slideIndex = activeStep;
          return index === slideIndex ? (
            <span key={s} className={classes.activeSlide} role="none" />
          ) : (
            <span key={s} />
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const clientData = ClientModel.getInsatnce("", state)?.props;

  const userProfileData = UserProfile.getInsatnce("local", state)?.props;
  const totalOnboardingPages = userProfileData?.totalOnboardingPages;
  const currentOnboardingPage = userProfileData?.currentOnboardingPage;
  return {
    clientData,
    userProfileData,
    totalOnboardingPages,
    currentOnboardingPage
  };
};
export default connect(mapStateToProps)(CommonJourneyComponent);
