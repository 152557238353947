import RestaurantsList, {
  IRestaurant
} from "Models/ReduxModels/RestaurantCoffeeModel";
import { spring } from "react-motion";

export const setRestaurantListInitial = list => {
  let newObj = {};
  if (list.length) {
    list.map((item: any) => {
      let restaurant: IRestaurant = {
        id: item.id,
        type: item.type,
        name: item.name,
        cuisines: item.cuisines,
        chain: item.chain,
        independent: item.independent,
        brand: item.brand,
        images: item.images,
        website: item.website,
        description: item.description,
        notice: item.notice,
        bookingTelephone: item.bookingTelephone,
        bookingEmail: item.bookingEmail,
        bookingUrl: item.bookingUrl,
        isBookingRequired: item.isBookingRequired,
        offers: item.offers,
        address: item.address,
        geo: item.geo,
        updatedAt: item.updatedAt,
        deletedAt: item.deletedAt,
        newUntil: item.newUntil,
        ctas: item.ctas,
        primaryImage: item.primaryImage
      };
      newObj[item?.id] = restaurant;
    });
  }

  new RestaurantsList({
    id: "",
    ...newObj
  }).$save();
};

export const titleDefaultStyles = {
  opacity: 0,
  marginTop: -40
};

export const titleEnterStyles = {
  marginTop: spring(0),
  opacity: spring(1)
};

export const titleLeaveStyles = {
  marginTop: spring(-40),
  opacity: spring(0)
};

export const getNotAvailableDays = infoLabelOffer => {
  const daysOfWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday"
  ];
  const availableDays: string[] = [];
  const notAvailableDays: string[] = [];

  for (const day of daysOfWeek) {
    const apiDay = Object.keys(infoLabelOffer[0].availability).find(
      d => d.toLowerCase() === day.toLowerCase()
    );
    if (apiDay && !infoLabelOffer[0].availability[apiDay]?.day) {
      notAvailableDays.push(apiDay);
    } else if (apiDay && infoLabelOffer[0].availability[apiDay]?.day) {
      availableDays.push(apiDay);
    }
  }
  const availabilityOfDays = {
    available: availableDays,
    notAvailable: notAvailableDays
  };

  return availabilityOfDays;
};

export const getAvailableDays = infoLabelOffer => {
  const daysOfWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday"
  ];
  const availableDays: string[] = [];
  let startDay: any = null;
  let prevDay: string | null = null;

  for (const day of daysOfWeek) {
    const apiDay = Object.keys(infoLabelOffer[0].availability).find(
      d => d.toLowerCase() === day.toLowerCase()
    );
    if (apiDay && infoLabelOffer[0].availability[apiDay]?.day) {
      if (prevDay === null) {
        // startDay = day;
        startDay = day.charAt(0).toUpperCase() + day.slice(1).toLowerCase(); // Capitalize first letter, lowercase rest
      }
      // prevDay = day;
      prevDay = day.charAt(0).toUpperCase() + day.slice(1).toLowerCase(); // Capitalize first letter, lowercase rest
    } else {
      if (prevDay !== null) {
        if (prevDay === startDay) {
          availableDays.push(startDay.substring(0, 3));
        } else {
          availableDays.push(
            `${startDay.substring(0, 3)} - ${prevDay.substring(0, 3)}`
          );
        }
        startDay = null;
        prevDay = null;
      }
    }
  }

  // Check if there's a remaining range after iterating through all days
  if (prevDay !== null) {
    if (prevDay === startDay) {
      availableDays.push(startDay.substring(0, 3)!);
    } else {
      availableDays.push(
        `${startDay?.substring(0, 3)!} - ${prevDay.substring(0, 3)}`
      );
    }
  }

  return availableDays;
};
