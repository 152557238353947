import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Header } from "gce-cxinteracts-ui-components-library";
import { useStyles } from "./styles";
import {
  clientLogoPrimary,
  clientErrorPageLogo
} from "../../../Constants/Require/Require";
import { ConstantMapping } from "../../../Configurations/ConstantsMapping";
import ApplicationFlag, {
  IApplication
} from "../../../Models/ReduxModels/ApplicationFlag";
import UserDetails from "Models/ReduxModels/UserDetails";
import { getImageDetails, getClientName } from "../../../Utils/GeneralUtils";
import { ImagesMapping } from "Configurations/ImagesMapping";
const ZENDESK_KEY = "5eb14305-919e-4957-b573-955b28075bab";
import Zendesk, { ZendeskAPI } from "./FAQSection/zendeskConfig";
import loaderGif from "../../../Configurations/Clients/vodafone/Assets/Images/loaderImg.gif";

interface IProps {
  applicationFlags?: IApplication;
  userDetails: UserDetails;
}

interface MyWindow extends Window {
  zE: any;
  zESettings: any;
}

declare var window: MyWindow;

const Chat: React.FC<IProps> = props => {
  const { applicationFlags } = props;
  const clientName = getClientName();
  let classes = useStyles();
  const history = useHistory();

  const userDetails = props.userDetails;

  const goToBackPage = () => {
    if (applicationFlags?.isRedirectedFromGiftingSuccessPage) {
      history.push(`/offer-details/${applicationFlags?.offerId}`);
    } else {
      window.history.back();
    }
  };

  const redirectToHome = () => {
    new ApplicationFlag({
      id: "",
      isShowFooter: true
    }).$save();
    history.push("/home");
  };

  const handleLoaded = () => {
    let path = history?.location?.state;
    if (window.zE) {
      ZendeskAPI(userDetails,path, "messenger", "open");
    }
  };

  useEffect(() => { 
    let path = history?.location?.state;
    ZendeskAPI(userDetails,path,"messenger", "open");
  }, []);

  return (
    <Fragment>
      <div className={classes.chatContainer}>
        <div className={classes.chatHeaderContainer}>
          <Header
            headerText=""
            className={classes.headerBackground}
            svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
            clientLogoPrimary={getImageDetails(
              clientLogoPrimary,
              ConstantMapping[clientName].CLIENT_ICON_TEXT
            )}
            clientLogoSecondary={getImageDetails(
              clientErrorPageLogo,
              ConstantMapping[clientName].CLIENT_ICON_TEXT
            )}
            onSvgSelection={goToBackPage}
            onLogoSelection={redirectToHome}
            // isScrollHeader={false}
          />
        </div>
        <div className={classes.chatBodyContent}>
          <div className={classes.chatZendeskContainer}>
            <Zendesk zendeskKey={ZENDESK_KEY} onLoaded={handleLoaded} />
          </div>
          <div className={classes.loader}>
            <img src={loaderGif} alt="Loading..." />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  let applicationFlags = ApplicationFlag.getInsatnce("", state)?.props;
  let userDetails = UserDetails.getInsatnce("", state)?.props;
  return {
    userDetails,
    applicationFlags
  };
};

export default connect(mapStateToProps)(Chat);