import { Button, Typography } from "@material-ui/core";
import { ImagesMapping } from "Configurations/ImagesMapping";
import React from "react";
import { getClientName } from "Utils/GeneralUtils";
import { useStyles } from "./styles";

interface IProps {
  heading: string;
  subHeading: string;
  handlePermissionClick: any;
  buttonOkText: string;
  buttonCancelText?: string;
  imageAltText?: string;
  onboardingImage: any;
  showVodafoneIcon?: boolean;
}

const NewJourneyFramework: React.FC<IProps> = props => {
  const {
    heading,
    subHeading,
    handlePermissionClick,
    buttonOkText,
    buttonCancelText,
    imageAltText,
    onboardingImage,
    showVodafoneIcon
  } = props;
  const classes = useStyles();
  const clientName = getClientName();

  return (
    <div className={classes.mainContainer}>
      {showVodafoneIcon && (
        <div className={classes.vodafoneIconWrapper}>
          <img
            style={{
              height: "50px"
            }}
            alt="vodafoneIcon"
            src={ImagesMapping[clientName].newOnboardingVodafoneIcon}
          />
        </div>
      )}
      <div className={classes.heroImageWrapper}>
        <img
          style={{
            width: "100%"
          }}
          alt={imageAltText || "heroImage"}
          src={onboardingImage}
        />
      </div>

      <div className={classes.content}>
        <h4 className={classes.heading}>{heading}</h4>
        <Typography
          className={classes.subHeading}
          dangerouslySetInnerHTML={{
            __html: subHeading
          }}
        />
        <div />
        <div
          className={classes.buttonContainer}
          style={{ paddingBottom: buttonCancelText ? "2rem" : "3rem" }}
        >
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonOk}
            onClick={e => handlePermissionClick(e, "ok")}
          >
            <Typography>{buttonOkText}</Typography>
          </Button>
          {buttonCancelText ? (
            <div
              className={classes.cancelPermissionText}
              onClick={e => handlePermissionClick(e, "cancel")}
            >
              <Typography>{buttonCancelText}</Typography>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default NewJourneyFramework;
