import { BaseModel } from "ReduxStore/index";

export interface SnackBarConfig {
  message: string;
  openSnackBar: boolean;
  autoHideDuration?: number;
}

export default class SnackBarModel extends BaseModel<SnackBarConfig> {
  static resource = "SnackBarModel";
}
