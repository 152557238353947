import React from "react";
import { graphQlCall, GQLConstants } from "../../Utils/GraphQLUtils";
import AddFavouriteOffersModel from "../../Models/ReduxModels/AddFavouriteOffersModel";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { GQLConstantMapping } from "Configurations/ConstantsMapping";
import { getClientName } from "Utils/GeneralUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    offersContainer: {
      margin: theme.spacing(6)
    },
    offersButtonContainer: {
      display: "flex",
      marginTop: theme.spacing(2)
    },
    addButton: {
      padding: theme.spacing(1),
      marginRight: theme.spacing(2)
    },
    removeButton: {
      padding: theme.spacing(1)
    }
  })
);

export const FavouriteOffers: React.FC<any> = props => {
  const classes = useStyles();
  const callAddFavouriteOffersApi = () => {
    let variable = { offerId: 801453 };
    AddFavouriteOffers(variable);
  };
  const clientName = getClientName();
  const AddFavouriteOffers = async (variable: any) => {
    try {
      let resp: any;
      resp = await graphQlCall(
        GQLConstants.MUTATION,
        GQLConstantMapping[clientName].mutations.ADD_FAVORITE,
        variable
      );
      let favOffers = resp?.data;
      new AddFavouriteOffersModel({
        id: "1",
        favouriteOffers: favOffers || {}
      }).$save();
    } catch (error) {
      console.log(error);
    }
  };
  const callRemoveFavouriteOffersApi = () => {
    let variable = { offerId: 801453 };
    RemoveFavouriteOffers(variable);
  };
  const RemoveFavouriteOffers = async (variable: any) => {
    try {
      let resp = await graphQlCall(
        GQLConstants.MUTATION,
        GQLConstantMapping[clientName].mutations.REMOVE_FAVORITE,
        variable
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.offersContainer}>
      Favourite Offers
      <div className={classes.offersButtonContainer}>
        <button
          className={classes.addButton}
          onClick={callAddFavouriteOffersApi}
        >
          Add Favourite Offers
        </button>
        <button
          className={classes.removeButton}
          onClick={callRemoveFavouriteOffersApi}
        >
          Remove Favourite Offers
        </button>
      </div>
    </div>
  );
};
