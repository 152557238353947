import { BaseModel } from "ReduxStore/index";

interface IAddFavouriteOffersModel {
  id: string;
  favouriteOffers: {};
}
export default class AddFavouriteOffersModel extends BaseModel<
  IAddFavouriteOffersModel
> {
  static resource = "AddFavouriteOffers";
}
