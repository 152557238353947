import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const restCardsUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    //Start of SearchResultGridCard file css
    gridCard: {
      backgroundColor: "#f6f6f6",
      borderRadius: "10px",
      overflow: "hidden",
      width: "100%",
      height: "100%"
    },
    gridCardImage: {
      width: "100%",
      height: "auto",
      overflow: "hidden",
      position: "relative",
      "& img": {
        width: "100%",
        height: "115px",
        objectFit: "cover"
      }
    },
    gridInfoLabel: {
      color: "#fff",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: 14,
      marginTop: "-5px",
      marginBottom: "10px",
      backgroundColor: "#000",
      borderRadius: "5px",
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "6px",
      paddingBottom: "6px"
    },
    cardDistance: {
      fontSize: 14,
      fontWeight: "normal",
      marginBottom: 0,
      marginTop: "5px",
      textAlign: "left"
    },
    //End of SearchResultGridCard file css

    //Start of SearchResultListCard file css
    listCardContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "15px"
    },
    listCard: {
      flex: "1",
      backgroundColor: "#F6F6F6",
      borderRadius: "10px",
      overflow: "hidden",
      width: "100%"
    },
    listCardImage: {
      width: "100%",
      height: "auto",
      overflow: "hidden",
      position: "relative",
      "& img": {
        width: "100%",
        height: "178px",
        objectFit: "cover"
      }
    },
    strip: {
      position: "absolute",
      top: "10px",
      margin: "0px 10px"
    },
    stripLabel: {
      background: "black",
      color: "#fff",
      padding: "7px 16px 4px 16px",
      fontSize: "14px",
      borderRadius: "6px",
      margin: "0px !important",
      fontWeight: "bold"
    },
    infoIcons: {
      margin: "8px 0px",
      width: "max-content",
      backgroundColor: "black",
      borderRadius: "6px",
      padding: "8px 14px 4px 14px",
      display: "flex",
      alignItems: "baseline"
    },
    maxDinerIcon: {
      height: "20px !important",
      width: "18px !important",
      margin: "0px 13px 0px 0px!important"
    },
    maxDinerIconX: {
      color: "rgb(255, 255, 255)",
      fontSize: "17px",
      alignContent: "flex-end"
    },
    maxDiner: {
      color: " #fff",
      fontSize: " 18px",
      alignContent: "center",
      textAlign: "center",
      alignItems: "center",
      marginLeft: "3px"
    },
    //End of SearchResultListCard file css

    //Start of both Grid and List file css
    cardTitle: {
      fontSize: 16,
      fontWeight: "bold",
      marginBottom: "-15px",
      marginTop: "0px",
      textAlign: "left"
    },
    cardText: {
      fontSize: 14,
      fontWeight: "normal",
      marginBottom: 0,
      textAlign: "left"
    }
    //End of both Grid and List file css
  })
);
