import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Header } from "gce-cxinteracts-ui-components-library";
import QRCode from "qrcode.react";

import { computeTicks, getClientName, getImageDetails } from "Utils/GeneralUtils";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import ApplicationFlag from "Models/ReduxModels/ApplicationFlag";
import { useStyles } from "../styles";
import { showSnackBar } from "Utils/SnackBarUtils";
import RestoLocalBFilterModal from "Models/ReduxModels/RestoLocalBlendFilters";
import FilteredRestaurants from "Models/ReduxModels/FilteredRestaurants";
import {
  EVENT_TYPE,
  logEvents,
  pushAllEventsToServer
} from "Services/EventService";
import { connect } from "react-redux";
import { membShipUseStyles } from "../Membership/styles";
import Timer from "../Membership/Timer";
import { restDetailsUseStyles } from "./styles";

interface IProps {
  applicationFlags: any;
  subHeading: string;
  location: any;
}

const QrCode: React.FC<IProps> = (props: IProps) => {
  const { applicationFlags } = props;
  const history = useHistory();
  const classes = useStyles();
  let qrCodeClasses = restDetailsUseStyles();
  let clientName = getClientName();
  const restoInfoLabel = props?.location?.state?.offerDetail?.offers[0]?.name;
  const restaurantName = props?.location?.state?.offerDetail?.name;
  const claimedDetails =
    props?.location?.state?.claimedOfferDetails?.expiryDateTime;
  let dateObject = claimedDetails ? new Date(claimedDetails) : new Date();
  const redemptionCode =
    props?.location?.state?.claimedOfferDetails?.redemptionCode ||
    "qwecowef";

  useEffect(() => {
    let parentOffer = applicationFlags?.eatLocalCoffeClubOffer;
    if (parentOffer?.addType === "Eat Local") {
      logEvents({
        eventType: EVENT_TYPE.EAT_LOCAL_QRCODE_DISPLAY,
        offerId: parentOffer?.id,
        QRtype: "SingleUseQR"
      });
    } else {
      logEvents({
        eventType: EVENT_TYPE.COFFEE_CLUB_QRCODE_DISPLAY,
        offerId: parentOffer?.id,
        QRtype: "SingleUseQR"
      });
    }
    pushAllEventsToServer();
  }, []);

  if (!claimedDetails) {
    dateObject.setHours(23, 59, 59, 999);
  }

  const goBack = () => {
    setTimeout(() => window.history.back(), 500);
  };

  const redirectToHome = () => {
    new RestoLocalBFilterModal({
      id: "",
      offerType: [],
      cuisine: "",
      availability: ""
    }).$save();
    let list = {};
    new FilteredRestaurants({
      id: "",
      ...list
    }).$save();
    new ApplicationFlag({
      id: "",
      ...applicationFlags,
      isShowFooter: true,
      isRedirectedFromGiftingSuccessPage: false,
      eatLocalCoffeClubID: 0,
      eatLocalCoffeClubOffer: {},
      selectedShopOrResto: {}
    }).$save();
    setTimeout(() => history && history.push("/home"), 500);
  };

  const copyToClipboard = text => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showSnackBar("Code copied to clipboard");
      })
      .catch(error => {
        showSnackBar("Error copying text to clipboard:", error);
      });
  };

  const renderTimer = () => {
    return (
      <Timer
        remainingTicks={computeTicks(dateObject)}
        style={{
          margin: "-21px 0px 0px 65px"
        }}
        styleOptions={{
          timerNumbersStyle: {
            display: "flex",
            flexDirection: "row",
          },
          timerLabelsStyle: {
            fontSize: "16px"
          }
        }}
      ></Timer>
    );
  };

  return (
    <div className={qrCodeClasses.mainContainerQrcode}>
      <Header
        headerText=""
        isHeaderInverted={true}
        svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
        clientLogoPrimary={getImageDetails(
          ImagesMapping[clientName].clientLogoPrimary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        clientLogoSecondary={getImageDetails(
          ImagesMapping[clientName].clientLogoSecondary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        onSvgSelection={goBack}
        onLogoSelection={redirectToHome}
        isScrollHeader={false}
      />
      <div className={classes.useCardcontent}>
        <QRCode
          value={redemptionCode}
          className={qrCodeClasses.svgQrCode}
          renderAs={"svg"}
        />
      </div>
      <div style={{ margin: "-50px 20px" }}>
        <Button
          className={qrCodeClasses.showQRCodeButton}
          style={{ textTransform: "uppercase" }}
          onClick={() => {
            if (redemptionCode) {
              copyToClipboard(redemptionCode);
            }
          }}
        >
          {redemptionCode}
        </Button>
      </div>
      <div style={{ margin: "50px 30px" }}>
        <p className={classes.infoLabelRestoDetailsPage}>
          {restoInfoLabel}
          <span className={classes.infoLabelDays}></span>
        </p>
        <h2 className={classes.cardName}>{restaurantName}</h2>
        <h4 className={classes.addressText}>Expires in {renderTimer()}</h4>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  let applicationFlags = ApplicationFlag.getInsatnce("", state).props;
  return {
    applicationFlags
  };
};

export default connect(mapStateToProps)(QrCode);
