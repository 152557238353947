import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      height: "45px",
      padding: "0vw 4vw",
      "& .MuiToolbar-regular": {
        padding: 0
      }
    },
    mainContainer: {
      overflow: "scroll",
      height: "100%",
      //marginTop: theme.spacing(-13)
    },
    cardHeroImage: {
      height: "55vw",
      marginBottom: "10vw",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      backgroundSize: "100%",
      justifyContent: "center",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundPositionX: "0%",
      backgroundPositionY: "0%"
    },
    title: { fontSize: "24px" },
    offerContainer: {
      display: "grid",
      position: "relative",
      gridTemplateColumns: "1fr 1fr",
      gridAutoFlow: "dense"
    },
    collectionCardOffer: {
      margin: theme.spacing(3)
    }
  })
);
