import React, { Fragment, useEffect, useState } from "react";
import { useStyles } from "./styles";
import { Header } from "gce-cxinteracts-ui-components-library";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import CollectionCardDetailsModel, {
  CollectionCardItem
} from "Models/ReduxModels/CollectionCardDetailsModel";
// import { getOfferImage } from "Utils/offerUtils";
import Offers, {
  ImageClassifier,
  IOffers
} from "Models/ReduxModels/OffersModel";
import Merchants, { IMerchant } from "Models/ReduxModels/Merchants";
import FavouriteOffers, {
  IFavouriteOffers
} from "Models/ReduxModels/FavouriteOffersModel";
import {
  getOfferImage,
  addRemoveFavouriteOffers,
  getLatestClaimDetailsByClaimId
} from "Utils/offerUtils";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import { getImageDetails } from "Utils/GeneralUtils";
import ClaimedOffers, {
  IClaimedOffers
} from "Models/ReduxModels/ClaimedOffersModel";
import { useTheme } from "@material-ui/core";
import GridOffer from "Components/GridOffer/GridOffer";
import OfferIdsSortedByWeight from "Models/ReduxModels/OfferIdsSortedByWeight";
import SortedFavouriteOfferIds, {
  OfferIds
} from "Models/ReduxModels/SortedFavouriteOfferIds";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { getClientName } from "Utils/GeneralUtils";
import UserDetails, { IUserDetails } from "Models/ReduxModels/UserDetails";
interface IProps {
  collectionCardDetails: CollectionCardItem;
  offers: IOffers;
  merchants: IMerchant;
  favorites: IFavouriteOffers;
  claimedOffers: IClaimedOffers;
  offerIdsSortedByWeight: number[];
  match: any;
  favouriteOfferIds: OfferIds;
  userDetails: IUserDetails;
}

const CollectionCardDetails: React.FC<IProps> = (props: IProps) => {
  let classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const {
    collectionCardDetails,
    offers,
    merchants,
    favorites,
    claimedOffers,
    offerIdsSortedByWeight,
    favouriteOfferIds,
    userDetails,
  } = props;
  const { id } = props.match.params;
  const clientName = getClientName();
  const [gridOffers, setGridOffers] = useState<number[]>([]);
  const [collectionCard] = useState<CollectionCardItem>(
    collectionCardDetails[id]
  );
  let isFavoriteCallIP = false;
  const isConvergedUser = userDetails?.isConvergedUser;

  useEffect(() => {
    if (collectionCard?.offerIds?.length) {
      // This code sorts offers based in their weight
      const totalOffersInGrid = collectionCard.offerIds.length;
      const gridOfferIdList: number[] = [];
      for (let index = 0; index < offerIdsSortedByWeight.length; index++) {
        if (
          collectionCard.offerIds.indexOf(offerIdsSortedByWeight[index]) > -1
        ) {
          gridOfferIdList.push(offerIdsSortedByWeight[index]);
        }
        if (totalOffersInGrid === gridOfferIdList.length) {
          break;
        }
      }
      setGridOffers(gridOfferIdList);
    }
  }, [collectionCard, offerIdsSortedByWeight]);

  const renderOfferById = (offerId: any, index: number) => {
    const longClaimedOfferIds = Object.values(claimedOffers)
      .filter((claimedOffer) => {
        const claimDate = new Date(claimedOffer.claimDateTime);
        const currentDate = new Date();
        const daysDifference = Math.floor(
          (currentDate.getTime() - claimDate.getTime()) / (1000 * 60 * 60 * 24)
        );
        return daysDifference > 30;
      })
      .map((claimedOffer) => claimedOffer.offerId);

    if (longClaimedOfferIds.includes(offerId)) {
      return false;
    }
    let offer = offers[offerId];
    if (!offer) {
      return null;
    }
    if (isConvergedUser === false) {
      if (offer?.isConvergedOffer) {
        return false;
      }
    };
    let claimedDetails = getLatestClaimDetailsByClaimId(
      offer.claimedIds,
      claimedOffers
    );
    return (
      <GridOffer
        key={`${offerId}${index}`}
        addRemoveFavorites={(
          offerId: number,
          isStoreGiftingOffer: boolean | null
        ) => {
          if (!isFavoriteCallIP && !isStoreGiftingOffer) {
            isFavoriteCallIP = true;
            addRemoveFavouriteOffers(offerId, favorites, favouriteOfferIds)
              .then(() => {
                isFavoriteCallIP = false;
              })
              .catch(() => {
                isFavoriteCallIP = false;
              });
          }
        }}
        claimedDetails={claimedDetails || undefined}
        clientName={clientName}
        merchant={merchants[offer?.merchantId]}
        offer={offer}
        style={{
          margin: theme.spacing(3)
        }}
      ></GridOffer>
    );
  };
  const renderCollectionCards = (card: CollectionCardItem) => {
    let heroImage = getOfferImage(
      ImageClassifier.OFFER_IMAGE_LARGE,
      card?.images
    );
    return (
      <div id="collectionCardContainer">
        <div
          className={classes.cardHeroImage}
          style={{
            backgroundImage: "url(" + heroImage?.url + ")"
          }}
        >
          <h2 style={{ color: card?.textColor }} className={classes.title}>
            {card?.showOverlayText ? card?.title : ""}
          </h2>
        </div>
        <div className={classes.offerContainer}>
          {gridOffers &&
            gridOffers.map((offerId: any, index: number) => {
              return renderOfferById(offerId, index);
            })}
        </div>
      </div>
    );
  };
  const redirectToHome = () => {
    history.push("/home");
  };
  return (
    <Fragment>
      <Header
        className={classes.header}
        headerText=""
        isHeaderInverted={true}
        svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
        clientLogoPrimary={getImageDetails(
          ImagesMapping[clientName].clientLogoPrimary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        clientLogoSecondary={getImageDetails(
          ImagesMapping[clientName].clientLogoSecondary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        onSvgSelection={redirectToHome}
        onLogoSelection={redirectToHome}
        isScrollHeader={false}
      />
      <div className={classes.mainContainer}>
        {/* Collection Cards Details*/}
        {renderCollectionCards(collectionCard)}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  let collectionCardDetails = CollectionCardDetailsModel.getInsatnce("", state)
    ?.props;
  const offers = Offers.getInsatnce("", state)?.props;
  const merchants = Merchants.getInsatnce("", state)?.props;
  const favorites = FavouriteOffers.getInsatnce("", state)?.props;
  const claimedOffers = ClaimedOffers.getInsatnce("", state)?.props;
  const offerIdsSortedByWeight =
    OfferIdsSortedByWeight.getInsatnce("", state)?.props?.offerIds || [];
  const favouriteOfferIds = SortedFavouriteOfferIds.getInsatnce("", state)
    ?.props;
  const userDetails = UserDetails.getInsatnce("", state).props;
  return {
    collectionCardDetails,
    offers,
    merchants,
    favorites,
    claimedOffers,
    offerIdsSortedByWeight,
    favouriteOfferIds,
    userDetails,
  };
};
export default connect(mapStateToProps)(CollectionCardDetails);
