import React, { useEffect, useState } from "react";
import { ClientModel } from "../../../Models/ReduxModels/ClientDetails";
import UserProfile from "../../../Models/ReduxModels/UserProfileModel";
import { connect } from "react-redux";
import NewJourneyFramework from "../newJourneyFramework";
import { ImagesMapping } from "../../../Configurations/ImagesMapping";
import { getClientName } from "Utils/GeneralUtils";
import { OnBoardingConstants } from "../constants";
import { FunctionMapper } from "Configurations/FunctionMapping";
import { logEvents } from "../../../Services/EventService";
import { EVENT_TYPE } from "Services/EventService";
import { pushAllEventsToServer } from "Services/EventService";
import { History } from "history";
import { useHistory } from "react-router-dom";

interface IProps {
  history: History;
  clientData: any;
  userProfileData: any;
  currentOnboardingPage: number;
  totalOnboardingPages: Array<String>;
}
const Summary: React.FC<IProps> = props => {
  let clientName = getClientName();
  let page_name, accepted;
  const history = useHistory();
  const { userProfileData, currentOnboardingPage, totalOnboardingPages } =
    props;
  const { permissions } = userProfileData;
  const [update, setUpdate] = useState(true);
  let onLoadParams: any = {
    event_name: "onboarding_complete"
  };
  useEffect(() => {
    const nextPageNo = totalOnboardingPages.length - 1;
    if (update && currentOnboardingPage === nextPageNo) {
      if (checkPermission()) {
        page_name = "uk>app>veryme rewards>permissions>accepted";
        accepted = true;
      } else {
        page_name = "uk>app>veryme rewards>permissions>not accepted";
        accepted = false;
      }
      onLoadParams = { ...onLoadParams, page_name, accepted };
      FunctionMapper[getClientName()].pageLoadHook({
        tealium: {
          previous_page_name: `${window.previousPage}`,
          page_subsection: "permissions",
          page_name
        }
      });
      setUpdate(false);
    }
  }, [currentOnboardingPage]);

  const checkPermission = () => {
    if (
      permissions &&
      permissions.profiling === true &&
      permissions.location === true &&
      permissions.traffic === true &&
      permissions.sms === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handlePermissionClick = () => {
    history.push("/home", { onLoadParams });
    logEvents({ eventType: EVENT_TYPE.NEXT_PAGE_ONBOARDING });
    logEvents({ eventType: EVENT_TYPE.ONBOARDING_SUMMARY_EXITED });
    pushAllEventsToServer();
    nextPage();
  };
  const nextPage = () => {

    new UserProfile({
      id: "local",
      ...userProfileData,
      currentOnboardingPage: currentOnboardingPage + 1
    }).$save();

  };
  return (
    <>
      <NewJourneyFramework
        heading={OnBoardingConstants.SUMMARY_HEADING}
        subHeading={OnBoardingConstants.SUMMARY_SUBHEADING}
        handlePermissionClick={handlePermissionClick}
        buttonOkText="Show me my rewards"
        onboardingImage={ImagesMapping[clientName].newOnboardingEndImage}
        imageAltText="summaryHeroImg"
        showVodafoneIcon={true}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  const clientData = ClientModel.getInsatnce("", state)?.props;
  const userProfileData = UserProfile.getInsatnce("local", state)?.props;
  const currentOnboardingPage = userProfileData.currentOnboardingPage;
  const totalOnboardingPages = userProfileData?.totalOnboardingPages;

  return {
    clientData,
    userProfileData,
    currentOnboardingPage,
    totalOnboardingPages
  };
};

export default connect(mapStateToProps)(Summary);
