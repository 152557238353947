import { VodafoneTheme } from "../Theme";

export const VodafoneConstants = Object.freeze({
  SPLASH_SCREEN_TEXT: "Welcome!",
  INTEREST_HEADING: "Ready for a change?",
  INTEREST_PARAGRAPH_TEXT:
    "Tap your favourites (at least 5) to tell us what you love. Your current selection is shown in red.",
  RESET_BUTTON_TEXT: "Reset",
  SAVE_BUTTON_TEXT: "Save",
  MY_INTEREST_TEXT: "My interests",
  MY_INTEREST_LINK_TEXT: "See all",
  REWARDS_USED_TEXT: "Reward codes used",
  MY_BIRTHDAY_TEXT: "My birthday",
  BIRTHDAY_VALIDATION_TEXT: "You must be 18 years or older to use this app",
  EDIT_TEXT: "Edit",
  DATA_PERMISSION_TEXT: "My data permissions",
  DATA_PERMISSION_DESC:
    "You can review and update your Vodafone data permission settings online at any time.",
  REVIEW_PERMISSION_TEXT: "Review permissions",
  REVIEW_PERMISSION_URL:
    "https://www.vodafone.co.uk/webcenter/portal/myvodafone/app/appprivacysettings",
  ONBOARDING_INTEREST_HEADING: "Tell us more about what you love",
  ONBOARDING_INTEREST_VALIDATION_TEXT:
    "Please select at least 5 interests to help us understand what you love. Swipe to see more interests.",
  NEXT_BUTTON_TEXT: "Next",
  PROFILE_PERMISSION_HEADING: "Rewards you love",
  PROFILE_PERMISSION_DESC:
    "To make sure you get the very best that VeryMe Rewards has to offer, we need to know what you love. So, turn on profiling to make sure you only get the rewards you really want.",
  TURN_ON_PROFILING_BUTTON_TEXT: "Turn on profiling",
  TURN_OFF_PROFILING_BUTTON_TEXT: "Don't turn on profiling",
  USE_LOCATION_BUTTON_TEXT: "Use my location",
  DONT_USE_LOCATION_TEXT: "Don't use my location",
  ONBOARDING_TRAFFIC_HEADING: "Want us to tailor your experience?",
  ONBOARDING_TRAFFIC_CONTENT:
    "By allowing us to use information about how you use our network – for example the types of numbers you call and text and how you use data, we can tell you about the offers and products that suit you – which may even save you money.",
  TRAFFIC_BUTTON_TEXT: "Allow network data",
  TRAFFIC_LINK: "Don't use network data",
  SMS_PERMISSION_HEADING: "Don't miss out",
  SMS_PERMISSION_CONTENT:
    "Get text notifications from VeryMe Rewards to stay up to date.",
  SMS_PERMISSION_BUTTON_TEXT: "Allow text notifications",
  SMS_PERMISSION_BOTTOM_LINK: "Don't send me text notifications",
  ONBOARDING_SUMMARY_HEADING: "You're all set",
  ONBOARDING_SUMMARY_CONTENT:
    "Your privacy settings mean you're all set to get the best rewards. But if you want to, you can change your settings at any point",
  CHANGE_SETTING_LINK: "here",
  SUMMARY_BUTTON_TEXT: "Let's start",
  LANDING_BUTTON_TEXT: "Let's go",
  SAVED_OFFER_CONTENT:
    "You'll find your saved rewards here. Click on the heart next to a reward to get started...",
  KEY_TERMS_HEADING_TEXT: "What you need to know",
  ENTRY_MECHANICS_HEADING_TEXT: "How to redeem",
  TERMS_AND_CONDITIONS_TEXT: "Terms and Conditions",
  RECOMMENDED_OFFERS_TEXT: "Offers you might like",
  TALK_TO_US_TEXT: "Talk to us",
  TALK_TO_US_LINE_1:
    "Can’t find the answer you’re looking for? We’re here to help with all things",
  TALK_TO_US_LINE_2: " VeryMe Rewards",
  TALK_TO_US_LINE_3: ", from 8am-9pm every day.",
  TALK_TO_US_SHORT_CONTENT: "Did you find what you were looking for?",
  START_WEB_CHAT_TEXT: "Start a web chat",
  SEE_MORE_TEXT: "See more FAQs",
  CLIENT_ICON_TEXT: "VeryMe Rewards logo",
  LOCATION_PERMISSION_HEADING: "Rewards near you",
  LOCATION_PERMISSION_DESC:
    "Wherever you are, make sure you can find rewards near you. Let us use your location data and we'll send you nearby treats you'll love.",
  EXPAND_TERMS_ALT_TEXT: "Expand terms",
  SAVE_ALT_TEXT: "Save",
  UNSAVE_ALT_TEXT: "Unsave",
  PRIMER_WELCOME_TEXT_ONE: "Your rewards in one place",
  PRIMER_CHRISTMAS_TEXT_ONE: "Your rewards,\n now with added magic",
  PRIMER_WELCOME_TEXT_TWO:
    "We've made it easier for you to see all the rewards on offer and sort them based on what you love the most",
  PRIMER_CHRISTMAS_TEXT_TWO:
    "From festive food to glorious giveaways, connect to the magic of Christmas with our seasonal rewards.",
  PRIMER_BUTTON_TEXT: "Let's start",
  PRIMER_CLOSE_IMAGE_ALT_TEXT: "Close",
  PRIMER_LOCAL_STORAGE_KEY: "home-screen-filter-primer-last-shown",
  XMAS_PRIMER_LOCAL_STORAGE_KEY: "xmas-home-screen-filter-primer-last-shown",
  VMRLITE_PRIMER_LOCAL_STORAGE_KEY:
    "vmrlite-home-screen-filter-primer-last-shown",
  APP_UPDATE_DIALOG_LOCAL_STORAGE_KEY: "app-update-primer-last-shown",
  ARTICLE_URL_REGEX:
    "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?",
  PRIZE_DRAW_URL_REGEX:
    "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?",
  TERMS_AND_CONDITIONS_URL_TEXT: "For partner-specific terms click",

  BADGE_HAPPENSIN_MOMENT: "in a moment",
  BADGE_HAPPENSIN_MIN: "in a min",
  BADGE_HAPPENSIN_HOUR: "in an hour",
  BADGE_HAPPENSIN_TOMORROW: "tomorrow",
  BADGE_REWARD_CLAIMED: "Reward already claimed",
  BADGE_NO_CODES: "No codes left",
  BADGE_UNAVAILABLE: "Currently unavailable",
  BADGE_GIFT: "Give a gift",
  BADGE_GIFTED: "You've gifted this reward",
  GIFT_HELP_ADVICE:
    "If you had trouble gifting your reward, please contact the VeryMe Rewards team on live chat.",
  IW_INTRO_TEXT: "to see if you're a winner",
  IW_LOOSE_TEXT: "Better luck next time",
  IW_CLAIM_PRIZE_TEXT: "Claim my prize",
  IW_BACK_TEXT: "Back to the rewards",
  IW_SKIP_TEXT: "Swipe to skip",
  ERROR_PAGE_BACK_URL: "myvodafone://HOME",
  ERROR_PAGE_SUPPORT_URL: "/chat",
  PRIVACY_URL: "https://m.vodafone.co.uk/vmobile/faces/privacysettings.jspx",
  ERROR_PAGE_CLICK_HERE: "talk to our team",
  PRIZE_DRAW_ENTERED_HEADING:
    "Congratulations! <br/> You have entered this prize draw.",
  OFFER_SHARED_TEXT: "You've gifted this reward",
  ERROR_PAGE_MESSAGE: {
    LINE1:
      "Sorry, we’re having difficulty getting you through to VeryMe Rewards – there are a few reasons why this can happen.",
    LINE2:
      "If you’re a new Vodafone customer, or recently changed your plan, please be aware that it can take a few days to access VeryMe Rewards.",
    LINE3: "You can also",
    LINE4:
      "if it’s been more than 48 hours and you still can’t access VeryMe Rewards."
  },
  MAINTENANCE_PAGE_MESSAGE: {
    LINE1: "We’ll be back soon!",
    LINE2: "VeryMe Rewards is currently undergoing maintenance.",
    LINE3: "We’re sorry for any inconvenience and aim to be back online soon."
  },
  CHECK_APP_VERSION: true,
  MINIMUM_APP_VERSION: { major: 10, minor: 5 },
  MINIMUM_APP_VERSION_GIFTING: { major: 10, minor: 5 },
  APP_VERSION_FEATURE_ERROR_MESSAGE:
    "Make sure that you've updated your My Vodafone app to use this feature.",
  APP_VERSION_DIALOG_MESSAGE:
    "Make sure that you have updated your My Vodafone app to get the most out of Veryme Rewards.",
  APP_VERSION_DIALOG_HEADING: "Stay up to date with the latest rewards",
  APP_VERSION_DIALOG_CLOSE_TEXT: "Close",
  PREPAID_ERROR_PAGE_BACK_URL: "myvodafone://HOME",
  PREPAID_ERROR_PAGE_MESSAGE: {
    LINE1:
      "Pay as you go mobile customers must have topped up with at least £10 in the last 6 weeks.",
    LINE2:
      "If you have just topped up, please wait a few days to access VeryMe Rewards"
  },
  NO_REWARDS_HEADING: "No rewards at the moment",
  NO_REWARDS_TEXT: "Keep coming back to check, we add new rewards every week.",
  ENTER_BIRTHDATE_TEXT:
    "Tell us when your birthday is, and we might surprise you!",
  ADD_DOB_TEXT: "Add",
  // SVG Files
  SVG: {
    headerSvgStyle: {
      svgStyleProps: {
        width: "22px",
        height: "22px",
        viewBox: "0 0 50.000000 46.000000"
      },
      transform: "translate(0.000000,46.000000) scale(0.100000,-0.100000)",
      path: "M107 352 c-59 -59 -107 -114 -107 -122 0 -18 210 -230 228 -230 7 0 15 7 19 16 4 11 -21 43 -83 105 l-89 89 213 0 c205 0 212 1 212 20 0 19 -7 20 -212 20 l-213 0 89 89 c62 62 87 94 83 105 -4 9 -12 16 -19 16 -7 0 -62 -48 -121 -108z",
      isSvg: true,
      ariaLabel: "Back"
    },
    footerIcons: {
      0: {
        id: 0,
        width: "21px",
        height: "23px",
        viewBox: "0 0 44 48",
        fill: "currentcolor",
        stroke: VodafoneTheme.palette.secondary.light,
        strokeWidth: VodafoneTheme.spacing(1),
        style: {
          display: "inline-block",
          transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          flexShrink: 0,
          marginBottom: "5px"
        },
        transform: "translate(0 48) scale(0.1 -0.1)",
        path: "M 10 240 l 0 -230 l 210 0 l 210 0 l 0 230 l 0 230 l -210 0 l -210 0 l 0 -230 Z m 400 65 l 0 -145 l -190 0 l -190 0 l 0 145 l 0 145 l 190 0 l 190 0 l 0 -145 Z m 0 -220 l 0 -55 l -190 0 l -190 0 l 0 55 l 0 55 l 190 0 l 190 0 l 0 -55 Z",
        label: "Home",
        ariaLabel: "Home",
        selected: {
          path: "M10 240 l0 -230 210 0 210 0 0 230 0 230 -210 0 -210 0 0 -230z m400 -155 l0 -55 -190 0 -190 0 0 55 0 55 190 0 190 0 0 -55z",
          fill: "currentcolor"
        }
      },
      1: {
        id: 1,
        width: "23px",
        height: "23px",
        viewBox: "0 0 52.000000 52.000000",
        fill: "currentcolor",
        stroke: VodafoneTheme.palette.secondary.light,
        strokeWidth: VodafoneTheme.spacing(1),
        style: {
          display: "inline-block",
          transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          flexShrink: 0,
          marginBottom: "5px"
        },
        transform: "translate(0.000000,52.000000) scale(0.100000,-0.100000)",
        path: "M94 481 c-85 -52 -96 -123 -38 -241 26 -51 62 -98 118 -157 45 -45 87 -83 95 -83 8 0 53 39 101 87 95 97 140 174 147 251 10 120 -115 203 -214 142 -33 -20 -33 -20 -65 0 -42 25 -104 26 -144 1z m119 -12 c12 -6 32 -21 45 -32 12 -11 22 -15 22 -9 0 19 61 52 95 52 71 0 133 -76 121 -149 -3 -19 -17 -58 -31 -87 -29 -58 -154 -199 -187 -210 -25 -7 -97 59 -164 152 -78 109 -94 189 -47 244 40 48 98 64 146 39z",
        label: "My rewards",
        ariaLabel: "My rewards",
        selected: {
          path: "M89 479 c-95 -55 -92 -164 6 -304 47 -66 156 -175 176 -175 7 0 51 39 99 87 95 97 140 174 147 251 10 120 -115 203 -214 142 -33 -20 -33 -20 -65 0 -43 26 -103 25 -149 -1z",
          fill: "currentcolor"
        }
      },
      2: {
        id: 2,
        width: "24px",
        height: "23px",
        viewBox: "0 0 24 25",
        fill: "currentcolor",
        stroke: "",
        strokeWidth: 0,
        style: {
          display: "inline-block",
          transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          flexShrink: 0,
          marginBottom: "5px"
        },
        fillRule: "evenodd",
        transform: "",
        path: "M3.33184 1.14547C3.96299 0.433863 4.94295 0 6.22094 0C8.48649 0 9.91981 1.80125 10.7511 3.39606C10.9897 3.85385 11.1873 4.31017 11.348 4.72933C11.5088 4.31017 11.7064 3.85385 11.945 3.39606C12.7763 1.80125 14.2096 0 16.4751 0C17.7531 0 18.7331 0.433863 19.3642 1.14547C19.9906 1.85167 20.2279 2.78092 20.1203 3.68618C20.0062 4.64571 19.5086 5.58184 18.669 6.25433H23V11.805H21.9377V25H0.863036V11.805H0V6.25433H4.02705C3.18749 5.58184 2.68985 4.64571 2.5758 3.68618C2.4682 2.78092 2.70548 1.85167 3.33184 1.14547ZM7.1093 6.25433H10.8032C10.7748 6.1544 10.7422 6.04434 10.7053 5.9259C10.5282 5.35778 10.2539 4.60569 9.8643 3.85827C9.0656 2.32592 7.88625 1 6.22094 1C5.1762 1 4.48893 1.34793 4.07997 1.80901C3.66623 2.27549 3.49056 2.90983 3.56881 3.56815C3.72341 4.86886 4.88334 6.25433 7.1093 6.25433ZM9.21309 7.25433V10.805H13.3913V7.25433H9.21309ZM11.8929 6.25433H15.5868C17.8127 6.25433 18.9727 4.86886 19.1273 3.56815C19.2055 2.90983 19.0298 2.27549 18.6161 1.80901C18.2071 1.34793 17.5199 1 16.4751 1C14.8098 1 13.6305 2.32592 12.8318 3.85827C12.4422 4.60569 12.1679 5.35778 11.9908 5.9259C11.9539 6.04434 11.9213 6.1544 11.8929 6.25433ZM14.3913 7.25433H22V10.805H14.3913V7.25433ZM8.21309 10.805V7.25433H1V10.805H8.21309ZM1.86304 11.8679V24H8.64426V11.8679H1.86304ZM9.64426 11.8679V24H12.9225V11.8679H9.64426ZM13.9225 11.8679V24H20.9377V11.8679H13.9225Z",
        label: "Refer a friend",
        ariaLabel: "Refer a friend",
        selected: {
          path: "M3.47199 1.25728C4.21798 0.451239 5.33995 0 6.72094 0C9.26489 0 10.8288 1.94264 11.6862 3.51908C11.7423 3.62231 11.7962 3.72539 11.848 3.82793C11.8998 3.72539 11.9538 3.62231 12.0099 3.51908C12.8673 1.94264 14.4312 0 16.9751 0C18.3561 0 19.4781 0.451239 20.2241 1.25728C20.9649 2.05775 21.2415 3.10978 21.1162 4.11995C21.0334 4.78736 20.7812 5.43011 20.3783 5.99374H24V12.3548H22.9377V25H0.863036V12.3548H0V5.99374H3.31778C2.91491 5.43011 2.66268 4.78736 2.57991 4.11995C2.45462 3.10978 2.73116 2.05775 3.47199 1.25728ZM7.6093 5.99374H10.6038C10.4389 5.53615 10.2164 5.00265 9.92921 4.47466C9.15665 3.05423 8.10784 2 6.72094 2C5.7792 2 5.23393 2.29798 4.93982 2.61576C4.64056 2.93912 4.50414 3.38552 4.5647 3.87378C4.68169 4.81703 5.58956 5.99374 7.6093 5.99374ZM10.2131 7.99374V10.3548H13.3913V7.99374H10.2131ZM13.0922 5.99374H16.0868C18.1065 5.99374 19.0144 4.81703 19.1314 3.87378C19.1919 3.38552 19.0555 2.93912 18.7562 2.61576C18.4621 2.29798 17.9169 2 16.9751 2C15.5882 2 14.5394 3.05423 13.7669 4.47466C13.4797 5.00265 13.2572 5.53615 13.0922 5.99374ZM15.3913 7.99374H22V10.3548H15.3913V7.99374ZM8.21309 10.3548V7.99374H2V10.3548H8.21309ZM2.86304 12.4151V23H8.64426V12.4151H2.86304ZM10.6443 12.4151V23H12.9225V12.4151H10.6443ZM14.9225 12.4151V23H20.9377V12.4151H14.9225Z",
          fill: "currentcolor"
        }
      },
      3: {
        id: 3,
        width: "23px",
        height: "23px",
        viewBox: "0 0 50.000000 50.000000",
        fill: "currentcolor",
        stroke: VodafoneTheme.palette.secondary.light,
        strokeWidth: VodafoneTheme.spacing(1),
        style: {
          display: "inline-block",
          transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          flexShrink: 0,
          marginBottom: "5px"
        },
        transform: "translate(0.000000,50.000000) scale(0.100000,-0.100000)",
        path: "M180 455 c0 -28 -5 -38 -26 -47 -20 -9 -30 -9 -45 0 -29 18 -37 15 -64 -29 -39 -60 -39 -65 -7 -84 40 -24 40 -66 0 -90 -32 -19 -32 -24 7 -84 27 -44 35 -47 64 -29 15 9 25 9 45 0 21 -9 26 -19 26 -47 l0 -35 70 0 70 0 0 33 c0 27 6 36 26 45 17 8 31 9 44 2 30 -16 38 -12 65 31 39 61 40 66 6 84 -24 12 -31 22 -31 45 0 19 6 31 19 35 39 12 43 29 15 79 -32 57 -37 60 -72 42 -25 -13 -30 -13 -49 2 -12 10 -23 31 -25 47 -3 30 -4 30 -70 33 l-68 3 0 -36z m118 -20 c2 -23 11 -36 37 -49 32 -17 36 -18 59 -3 24 16 25 16 45 -19 27 -45 26 -48 -4 -62 -21 -10 -25 -19 -25 -51 0 -31 6 -43 26 -58 l26 -19 -22 -37 c-19 -32 -24 -35 -42 -26 -16 8 -31 8 -59 -1 -32 -11 -38 -18 -41 -44 -3 -29 -5 -31 -50 -34 l-48 -3 0 33 c0 28 -6 37 -34 52 -31 17 -35 17 -59 2 -24 -16 -25 -16 -46 19 -27 46 -26 49 4 63 21 10 25 19 25 52 0 33 -4 42 -25 52 -30 14 -31 17 -4 63 21 35 22 35 46 19 24 -15 28 -15 59 2 28 15 34 24 34 52 l0 33 48 -3 c44 -3 47 -5 50 -33z M185 315 c-35 -34 -35 -96 0 -130 18 -19 35 -25 65 -25 56 0 90 34 90 90 0 56 -34 90 -90 90 -30 0 -47 -6 -65 -25z m115 -15 c43 -43 11 -120 -50 -120 -38 0 -70 32 -70 70 0 17 9 39 20 50 11 11 33 20 50 20 17 0 39 -9 50 -20z",
        label: "My profile",
        ariaLabel: "My profile",
        selected: {
          path: "M180 455 c0 -28 -5 -38 -26 -47 -20 -9 -30 -9 -45 0 -29 18 -37 15 -64 -29 -39 -60 -39 -65 -7 -84 34 -20 41 -71 11 -80 -39 -13 -42 -31 -14 -79 33 -57 41 -62 71 -46 39 21 74 0 74 -45 l0 -35 70 0 70 0 0 33 c0 27 6 36 26 45 17 8 31 9 44 2 30 -16 38 -12 65 31 39 61 40 66 6 84 -24 12 -31 22 -31 45 0 19 6 31 19 35 39 12 43 29 15 79 -32 57 -37 60 -72 42 -25 -13 -30 -13 -49 2 -12 10 -23 31 -25 47 -3 30 -4 30 -70 33 l-68 3 0 -36z m120 -155 c43 -43 11 -120 -50 -120 -38 0 -70 32 -70 70 0 17 9 39 20 50 11 11 33 20 50 20 17 0 39 -9 50 -20z",
          fill: "currentcolor"
        }
      },
      4: {
        id: 4,
        width: "23px",
        height: "23px",
        viewBox: "0 0 54.000000 54.000000",
        fill: "currentcolor",
        stroke: VodafoneTheme.palette.secondary.light,
        strokeWidth: VodafoneTheme.spacing(1),
        style: {
          display: "inline-block",
          transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          flexShrink: 0,
          marginBottom: "5px"
        },
        transform: "translate(0.000000,54.000000) scale(0.100000,-0.100000)",
        path: "M181 503 c-24 -9 -63 -35 -86 -58 -116 -115 -91 -308 50 -390 118 -69 271 -28 342 92 24 41 28 59 28 123 0 64 -4 82 -28 122 -63 108 -187 153 -306 111z m208 -40 c27 -17 58 -48 76 -77 25 -42 30 -59 30 -116 0 -57 -5 -74 -30 -116 -19 -30 -49 -60 -79 -79 -71 -43 -161 -43 -232 0 -30 19 -60 49 -79 79 -25 42 -30 59 -30 115 0 54 5 75 28 113 66 113 205 149 316 81z M222 384 c-12 -8 -22 -22 -22 -30 0 -20 13 -17 28 6 14 22 51 27 70 8 19 -19 14 -48 -13 -80 -25 -29 -34 -78 -15 -78 6 0 10 8 10 19 0 10 14 35 30 56 36 45 37 54 9 89 -24 30 -63 34 -97 10z M254 159 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5 -16 -11z",
        label: "Help",
        ariaLabel: "Help",
        selected: {
          path: "M181 503 c-24 -9 -63 -35 -86 -58 -116 -115 -91 -308 50 -390 118 -69 271 -28 342 92 24 41 28 59 28 123 0 64 -4 82 -28 122 -63 108 -187 153 -306 111z m139 -123 c28 -28 25 -64 -10 -103 -16 -19 -30 -42 -30 -51 0 -9 -4 -16 -10 -16 -20 0 -10 57 15 80 14 13 25 33 25 45 0 45 -47 60 -77 25 -20 -22 -33 -26 -33 -8 0 45 84 64 120 28z m-34 -221 c10 -17 -13 -36 -27 -22 -12 12 -4 33 11 33 5 0 12 -5 16 -11z",
          fill: "currentcolor"
        }
      }
    }
  },
  FADE_IN_ANIMATION_DURATION: 400,

  //Preclaim PopUp
  PRECLAIM_SHAREABLE: `You're about to use this code.`,
  PRECLAIM_TIMER: `You'll only have a limited time to use this code.`,
  PRECLAIM_PRIZEDRAW: `You are about to enter this prize draw.`,
  PRECLAIM_OTHER: `You're about to get this code.`,
  PRECLAIM_GIFTING_HEADING: `Did something go wrong?`,
  PRECLAIM_GIFTING_OK: `Open live chat`,
  PRECLAIM_EAT_LOCAL_HEADING: `Ready to explore Eat Local?`,
  PRECLAIM_COFFEE_CLUB_HEADING: `Ready to explore Local Blend?`,
  PRECLAIM_RENEW_COFFEE_CLUB_HEADING: `Reactivate your Local Blend membership`,
  BADGE_IW: "Try your luck",
  STRIP_INFO_IW_WIN: "Congratulations, winner!",
  STRIP_INFO_IW_LOOSE: "Better luck next time!",
  BARCODE_FAILED:
    "Barcode generation failed. You may be able to present the code below for use or please contact us via the help chat",
  ONBOARDING_CROSSCARD_SUMMARY_HEADING:
    "We want you to get rewards you'll love",
  ONBOARDING_CROSSCARD_SUMMARY_CONTENT1:
    "Your privacy settings mean we won't always be able to give you the best rewards and offers. Change your settings",
  ONBOARDING_CROSSCARD_SUMMARY_CONTENT2:
    " and we'll be able to give you a more tailored experience.",
  ONBOARDING_CHANGE_SETTING_LINK: "online",
  EXTERNAL_API_ERROR_MESSAGE: {
    PAYG_ERROR_MESSAGE: {
      LINE1:
        "Sorry, we’re having difficulty getting you through to your Top up Rewards.",
      LINE2: "Try again or come back later to check your status."
    },
    EATLOCAL_ERROR_MESSAGE: {
      LINE1:
        "Sorry, we're having difficulty getting you through to search restaurant.",
      LINE2: "Try again or come back later to check your status."
    }
  },
  PAYG_DIALOG_MESSAGE:
    "To say thank you for being a Pay as you go customer, we're giving you the chance to earn more rewards every time you top up.",
  PAYG_DIALOG_HEADING: "Welcome to Top up Rewards!",
  PAYG_DIALOG_LOCAL_STORAGE_KEY: "payg-dialog-last-shown",
  // Christmas Properties
  IS_SHOW_CHRISTMAS: false, // To Show Christmas Screen
  CHRISTMAS_START_DATE: "2020-11-20", // Christmas Start Date
  CHRISTMAS_END_DATE: "2020-12-28", // Christmas End Date
  SOCIAL_PLATFORM_TEXT: "Text",
  //VMR Lite
  VMRLite_WELCOME_TEXT_ONE: "Welcome to VeryMe Rewards",
  VMRLite_WELCOME_TEXT_TWO:
    "Discover a selection of rewards at your fingertips and top up to unlock loads more",
  VMRLite_TOPUP_WELCOME_TEXT_ONE: "Top up to unlock",
  VMRLite_TOPUP_WELCOME_TEXT_TWO:
    "Look out for the banner to get a sneak peek of what's on offer when you top up at least £10",
  VMRLite_RESTRICTED_OFFER_HEADING: "Like what you’ve read so far?",
  VMRLite_RESTRICTED_OFFER_TEXT_ONE:
    "Top up at least £10 to unlock more rewards like this.",
  VMRLite_RESTRICTED_OFFER_TEXT_TWO: "You’ll get access after 48 hours.",
  VMRLite_TopUp_URL: "https://www.vodafone.co.uk/myvf/apptopup",
  VMRLite_TopUp_Button: "Top up now",
  VMRLite_DIALOG_MESSAGE:
    "Make sure to top up £10 every 6 weeks to enjoy the full selection.",
  VMRLite_DIALOG_HEADING:
    "Congrats! You've now got access to all of our rewards.",
  VMRLite_TOPUP_RECHARGE: "Top up £10 to unlock",
  //payg+
  PAYGPLUS_DIALOG_HEADING:
    "Congratulations! You now have access to all our rewards",
  PAYGPLUS_DIALOG_MESSAGE:
    "Stay on your Pay as you go Plus plan to enjoy the full selection.",
  PAYGPLUS_UPGRADE_TOPUP: "Upgrade to unlock",
  PAYGPLUS_UPGRADE_BUTTON: "Upgrade now",
  PAYGPLUS_RESTRICTED_OFFER_TEXT_ONE:
    "Stay on your Pay as you go Plus plan to enjoy the full selection of rewards.",

  //Tealium
  TEALIUM_PAGE_PREFIX: "uk>app>veryme rewards>",
  TEALIUM_PAGE_PRIZE_DRAW: "prize draw confirmation",
  TEALIUM_PAGE_CLAIM: "claim screen",
  TEALIUM_PAGE_SAVED_OFFER: "saved",
  TEALIUM_PAGE_MYCODES: "my codes",
  TEALIUM_PAGE_HELP: "help",
  TEALIUM_PAGE_FAQ: "faq>",
  TEALIUM_PAGE_LIMITED_TIME_OVERLAY: ">limited time overlay",
  TEALIUM_PAGE_GIFTING_OVERLAY: ">gift offer overlay",
  TEALIUM_PAGE_OFFER_SAVE_UNSAVE: "offer_saved OR offer_unsaved",
  TEALIUM_OFFER_SAVE: "offer_saved",
  TEALIUM_OFFER_UNSAVE: "offer_unsaved",
  TEALIUM_MANUAL_ADD_HOME_PAGE: "uk>app>veryme rewards>home",
  TEALIUM_MANUAL_ADD_PROFILE_PAGE: "uk>app>veryme rewards>my profile",
  TEALIUM_MANUAL_ADD_SAVE_PAGE: "uk>app>veryme rewards>my rewards>saved",
  TEALIUM_MANUAL_ADD_MYCODES_PAGE: "uk>app>veryme rewards>my rewards>my codes",
  /**
   * Tealium Analytics
   * Don't change the below object
   * As it taken accordingly by dom url
   * */
  SET_TEALIUM_TAGGING: true,
  SET_TEALIUM_MAPPER: {
    ONBOARDINGTEALIUM_MAPPER: {
      landing: "uk>app>veryme rewards>landing",
      interest: "uk>app>veryme rewards>my interests",
      "traffic-permission": "uk>app>veryme rewards>permissions>network data",
      "profile-permission": "uk>app>veryme rewards>permissions>profile",
      "sms-permission": "uk>app>veryme rewards>permissions>sms notifications",
      "location-permission": "uk>app>veryme rewards>permissions>location"
    },
    TEALIUM_MAPPER: {
      home: "uk>app>veryme rewards>home",
      interest: "uk>app>veryme rewards>my interests",
      myProfile: "uk>app>veryme rewards>my profile",
      // myRewards: "uk>app>veryme rewards>my rewards",
      help: "uk>app>veryme rewards>help",
      membership: "uk>app>veryme rewards>membership"
    }
  },

  LD_INTRO_TEXT: "and see what you've won!",
  LD_CONGRATS_BADGE: "Congratulations, winner!",
  LD_Prize: "You've grabbed ",
  LD_TYPE: "LUCKY_DIP",
  STW_TYPE: "SPIN_TO_WIN",
  LD_PRIZE_TEXT: "Claim now",
  LD_PICK_PRIZE: "Pick up",
  LD_PRIZE: "a prize",
  LOZENGE_MAGIC_MOMENTS: "Magical moment",
  VISIT_TO_WIN_TEXT_ONE: "Extra entries for excellent prizes",
  VISIT_TO_WIN_TEXT_TWO:
    "Visit each day to boost your chances of winning in our brilliant prize draw ",
  VISIT_TO_WIN_LOGO: "V2W Logo",
  VISIT_TO_WIN_MOCK_ENTRIES_IMG: "V2W Entries",
  VISIT_TO_WIN_DIALOG_HEADING: "You've earned your entry for today!",
  VISIT_TO_WIN_DIALOG_MESSAGE:
    "Keep coming back to get an extra entry each day",
  VISIT_TO_WIN_PRIZE_HEADING: "Extra entries for excellent prizes",
  VISIT_TO_WIN_PRIZE_SUBHEADING: "Bag one extra entry a day",
  XMAS_IW_WIN_TEXT: "Congrats! You're a winner.",
  XMAS_IW_LOOSE_TEXT: "Sorry, you're not a winner this time",
  XMAS_IW_START_DATE: "2021-11-02",
  XMAS_IW_END_DATE: "2021-12-30",
  LD_INTRO_SOLO_ALT_TEXT: "Lucky dip",
  LD_INTRO_SCREEN_ALT_TEXT: "Lucky dip intro",
  LD_INTRO_CLAW_SCREEN_ALT_TEXT: "Lucky dip intro claw",
  LD_TAP_TO_PLAY: "Tap to play",
  LD_CONVEYORBELT_INTRO_SCREEN_ALT_TEXT: "Lucky dip conveyorbelt",
  LD_CONGRATS_ALT_TEXT: "Lucky dip congrats",
  LD_PRIZE_PICK_UP_ALT_TEXT: "Lucky dip prize pick up",
  IMAGES_DERIVE_FROM_CMS: {
    logo: "loading-screen-logo",
    background: "loading-screen-background",
    iwWinImage: "iw_winner_screen",
    iwLooseImage: "iw_loser_screen",
    iwIntroImage: "iw_play_animation"
  },
  IWTEXT_DRIVEN_FROM_CMS: {
    iw_intro_text: "intro_text",
    iw_winner_text: "winner_text",
    iw_loser_text: "loser_text"
  },
  CON_PROFILE_EXTERNAL_LINK:
    "https://www.vodafone.co.uk/broadband-sim-only-deals",
  CONVERGED_PRIMER_LOCAL_STORAGE_KEY:
    "converged-home-screen-filter-primer-last-shown",
  CONVERGED_HEADER_TITLE: "Vodafone Together",
  CONVERGED_HEADER_TITLE_SUBHEADER1: "powered by",
  CONVERGED_HEADER_TITLE_SUBHEADER2: "VeryMeRewards",
  CONVERGED_HEADER_TITLE__SUBHEADER3: "from Vodafone",
  EDIT_DOB_EXTERNAL_LINK: "https://www.vodafone.co.uk/login",
  BADGE_EATLOCAL_EXPIRED_CODE: "Membership expired",
  //offer rate texts
  OFFER_RATED_HEADING: "Thanks for your feedback!",
  OFFER_RATE_HEADING: "Did you like this reward?",
  OFFER_RATED_DESCRIPTION:
    "It'll help us improve your VeryMe Rewards experience.",
  OFFER_RATE_DESCRIPTION:
    "Thumbs up or thumbs down - no matter your rating, you'll be helping us improve your VeryMe Rewards experience.",
  OFFER_LIKED_OPTIONS_HEADING:
    "Thats great! What did you like best about this reward?",
  OFFER_DISLIKED_OPTIONS_HEADING: "What did you dislike about this reward?",
  OFFER_LIKED_OPTION_ONE: "It's good value",
  OFFER_DISLIKED_OPTION_ONE: "It's not a good deal",
  OFFER_LIKED_OPTION_TWO: "I like the brand/product",
  OFFER_DISLIKED_OPTION_TWO: "I don't like the brand/product",
  OFFER_LIKED_OPTION_THREE: "It's easy to claim",
  OFFER_DISLIKED_OPTION_THREE: "It's difficult to claim"
});
