import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Header } from "gce-cxinteracts-ui-components-library";
import { connect } from "react-redux";
import { useStyles } from "./styles";
import TalkToUs from "../../../../Components/TalkToUs/TalkToUs";
import Faq, { Faqdetails } from "../../../../Models/ReduxModels/FaqModel";
import { Typography } from "@material-ui/core";
import { ConstantMapping } from "../../../../Configurations/ConstantsMapping";
import { getClientName, getImageDetails } from "Utils/GeneralUtils";
import { useHistory } from "react-router-dom";
import ApplicationFlag from "Models/ReduxModels/ApplicationFlag";
import { ImagesMapping } from "Configurations/ImagesMapping";

interface IProps {
  questionList: Faqdetails[];
}

const FaqSection: React.FC<IProps> = props => {
  const { id } = useParams<{ id: string }>();
  let classes = useStyles();
  const history = useHistory();
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const { questionList } = props;
  const clientName = getClientName();

  useEffect(() => {
    const question = questionList.filter((ques: Faqdetails) => ques.id == id);
    const name = question[0].name;

    const value = question[0].value;
    setName(name);
    setValue(value);
  }, []);
  const redirectToHome = () => {
    history.push("/home");
    new ApplicationFlag({
      id: "",
      isShowFooter: true
    }).$save();
  };
  const goBack = () => {
    window.history.back();
  };
  return (
    <>
      <div className={classes.mainContainer}>
        <Header
          headerText=""
          isHeaderInverted={false}
          svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
          clientLogoPrimary={getImageDetails(
            ImagesMapping[clientName].clientLogoPrimary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          clientLogoSecondary={getImageDetails(
            ImagesMapping[clientName].clientLogoSecondary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          onSvgSelection={goBack}
          onLogoSelection={redirectToHome}
          isScrollHeader={false}
        />
        <div>
          <div className={classes.content}>
            {/* Question Set */}
            <div>
              <div className={classes.question}>
                <Typography variant="h5" className={classes.questionTitle}>
                  {name}
                </Typography>
              </div>
              <Typography
                variant="h6"
                className={classes.questionBody}
                dangerouslySetInnerHTML={{
                  __html: value
                }}
              />
            </div>
            {/* Talk TO US */}
            <TalkToUs
              showOnFaq={true}
              tealiumPageName={`${ConstantMapping[clientName].TEALIUM_PAGE_PREFIX}${ConstantMapping[clientName].TEALIUM_PAGE_FAQ}${name}`}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const faqs = Faq.getInsatnce("", state).props;
  const questionList = faqs?.configurationDataList;
  return {
    questionList
  };
};

export default connect(mapStateToProps)(FaqSection);
