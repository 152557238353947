import { BaseModel } from "ReduxStore/index";

export interface ImageDetails {
  url: string;
  altText: string; // alt text will be used as aria-label when we are using background image and as alt-text when we use image tag
  dimension: string;
}
export interface Image {
  [key: string]: ImageDetails;
}
export interface CollectionCardItem {
  id: number;
  images: Image;
  offerIds: number[];
  showOverlayText: string | null;
  subTitle: string;
  textColor: string;
  title: string;
}
export interface ICollectionCardDetails {
  [key: number]: CollectionCardItem;
}
export default class CollectionCardDetailsModel extends BaseModel<
  ICollectionCardDetails
> {
  static resource = "CollectionCardDetails";
}
