import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    claimedOffersMainContainer: {
      display: "flex",
      flexDirection: "column",
      // marginBottom: "56vw",
      alignItems: "center"
      // marginTop: "24VW"
    },
    sharedOnText: {
      color: theme.palette.text.secondary,
      margin: theme.spacing(1, 1, 1, 5),
      fontSize: theme.typography.h6.fontSize,
      marginTop: theme.spacing(2.25)
    },
    sharedBackgroundImage: {
      flex: 1,
      zIndex: 0,
      display: "flex",
      flexDirection: "column",
      backgroundSize: "100%",
      backgroundRepeat: "no-repeat"
    },
    sharedOverlayImage: { height: "28vw", margin: "10px auto" },
    overlayStripText: {
      position: "absolute",
      display: "flex",
      bottom: 0,
      width: "100%",
      height: theme.spacing(10),
      alignItems: "center",
      paddingLeft: theme.spacing(5),
      color: theme.palette.text.secondary,
      fontSize: "20px",
      lineHeight: 1,
      fontWeight: "normal"
    }
  })
);
