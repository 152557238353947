//@ts-nocheck

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { getClientName } from "Utils/GeneralUtils";

const infoLabelCommonStyles = {
  top: 10,
  left: 10,
  color: "white",
  fontSize: 16,
  fontWeight: "bold",
  backgroundColor: "black",
  borderRadius: "5px",
  paddingLeft: "15px",
  paddingRight: "15px",
  paddingTop: "6px",
  paddingBottom: "6px"
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchResultsContainer: {
      marginBottom: "80px"
    },
    searchScreenContainer: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      paddingBottom: "55px",
      position: "fixed"
    },
    searchScreenText: {
      display: "flex",
      justifyContent: "center",
      margin: "10px 0px",
      "& p": {
        fontSize: "20px",
        fontWeight: "bold",
        margin: "10px"
      }
    },
    searchButtonContainer: {
      flexDirection: "column",
      justifyContent: "flex-end",
      display: "flex",
      flex: 1
    },
    formAapplyButton: {
      color: "#ffffff",
      backgroundColor: "#e60000",
      borderRadius: "4px",
      margin: "0px 12px",
      textTransform: "none",
      fontWeight: "bold",
      fontSize: "18px",
      height: "43.5px",
      border: "none",
      fontFamily: "VodafoneRg, sans-serif"
    },

    searchTextField: {
      marginBottom: "0px !important",

      " & .MuiFilledInput-root": {
        backgroundColor: "#fff",
        border: "2px solid #dadcdc",
        borderRadius: "3px",
        // borderBottom: "none",
        color: "#000"
      },
      "& .MuiFilledInput-input": {
        padding: "12px 14px",
        fontFamily: "VodafoneRg, VodafoneRgBold",
        fontWeight: "bold",
        fontSize: "18px"
      },
      "& .MuiFilledInput-underline:after": {
        borderBottom: "none"
      },
      "& .MuiFilledInput-underline:before": {
        borderBottom: "none"
      },
      "& .MuiFilledInput-underline:hover:before": {
        borderBottom: "none"
      }
    },
    infoOnSerchScreen: {
      margin: "0vw 5vw",
      fontSize: "18px"
    },
    gridCardContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      gridAutoRows: "minmax(0, 1fr)",
      alignItems: "center",
      gap: "18px",
      margin: "15px"
    },
    mapFilterButtonWrapper: {
      display: "flex",
      flexDirection: "row",
      margin: "4vw 0vw"
    },
    mapSearchButton: {
      textTransform: "none",
      borderRadius: "20px",
      marginRight: "16px",
      cursor: "pointer"
    },
    mapFilterButtonText: {
      fontSize: "18px"
    },
    mapFilterIcon: {
      marginRight: "12px"
    },
    filterButton: {
      textTransform: "none",
      borderRadius: "20px",
      border: "2px solid #dadcdc"
    },
    useCardcontent: {
      display: "flex",
      flexDirection: "column",
      margin: "5vw",
      marginTop: "30px",
      fontFamily: "VodafoneRg"
    },
    card: {
      width: "90%",
      backgroundColor: "#f6f6f6",
      borderRadius: "10px",
      overflow: "hidden"
    },
    cardContent: {
      margin: "10px"
    },
    cardName: {
      fontSize: 25,
      fontWeight: "bold",
      marginBottom: "-15px",
      marginTop: "0px",
      textAlign: "left"
    },
    addressText: {
      fontSize: 16,
      fontWeight: "normal",
      marginTop: 20,
      marginBottom: 0,
      marginRight: 0,
      textAlign: "left"
    },
    subHeader: {
      fontSize: 20,
      fontWeight: "bold",
      marginBottom: "-15px",
      marginTop: "0px",
      textAlign: "left"
    },
    infoLabelRestoDetailsPage: {
      ...infoLabelCommonStyles,
      width: "fit-content"
    },
    imageCountRestoDetailsPage: {
      ...infoLabelCommonStyles,
      position: "relative",
      width: "fit-content",
      display: "inline-block"
    },
    infoLabel: {
      position: "absolute",
      top: "0px !important",
      ...infoLabelCommonStyles
    },
    infoLabelDays: {
      fontWeight: "lighter",
      marginLeft: "4px"
    },
    gridInfoLabelContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%"
    },
    searchViewButton: {
      textTransform: "none",
      paddingRight: "5px",
      fontWeight: "700"
    },
    searchResultTopContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginLeft: "18px",
      marginRight: "18px"
    },
    totalSearchResults: {
      flex: "start",
      fontWeight: "bold"
    },
    viewSelection: {
      flex: "end"
    },
    mainWrapper: {
      color: theme.palette.common.black
    },
    eatLocalContentWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    contentHeading: {
      fontSize: theme.typography.subtitle1.fontSize,
      margin: theme.spacing(0),
      fontFamily: theme.typography.fontFamily
    },
    footerContainer: {
      zIndex: 999,
      position: "fixed",
      width: "100%",
      left: 0,
      bottom: 0,
      "& .MuiBottomNavigation-root": {
        borderTop: `1px solid ${theme.palette.divider}`,
        paddingTop: "1px",
        height: "58px",
        "& .MuiBottomNavigationAction-root": {
          paddingBottom: theme.spacing(1)
        }
      }
    },
    mainContainerWl: {
      backgroundSize: "cover",
      backgroundPosition: "center",
      // height: "100vh",
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
      backgroundRepeat: "no-repeat",
      backgroundImage: `url(${ImagesMapping[getClientName()].membershipWlBgImage
        })`
    },
    heading: {
      fontSize: "32px",
      textAlign: "center",
      margin: "0px",
      fontWeight: 100,
      "@media (min-width:320px) and (min-height:568px)": {
        fontSize: "24px",
        margin: "10px 0px"
      },
      "@media (min-width:360px) and (min-height:740px) ": {
        fontSize: "32px",
        margin: "10px 0px"
      },
      "@media (min-width:375px)": {
        fontSize: "28px",
        margin: "10px 0px"
      },
      "@media (min-width:375px) and (min-height:810px)": {
        fontSize: "38px",
        margin: "10px 0px"
      },
      "@media (min-width:390px)": {
        fontSize: "40px",
        margin: "10px 0px"
      },
      "@media (min-width:414px) and (min-height:890px)": {
        fontSize: "42px",
        margin: "18px 0px"
      },
      "@media (min-width:414px) and (min-height:736px)": {
        fontSize: "32px",
        margin: "8px 0px"
      },
      "@media (min-width:760px) and (min-height:1020px)": {
        fontSize: "42px",
        margin: "10px 0px"
      }
    },
  })
);
