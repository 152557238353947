import { BaseModel } from "ReduxStore/index";

export interface FavouriteOffer {
  offerId: number;
  createdOn: Date;
}
export interface IFavouriteOffers {
  [key: number]: FavouriteOffer;
}

export default class FavouriteOffers extends BaseModel<IFavouriteOffers> {
  static resource = "FavouriteOffers";
}
