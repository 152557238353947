import { BaseModel } from "ReduxStore";

export interface IRestaurant {
  id: string;
  type: string;
  name: string;
  cuisines: any;
  chain: any;
  independent: boolean;
  brand: any;
  images: any;
  website: string;
  description: string;
  notice: string;
  bookingTelephone: string;
  bookingEmail: string;
  bookingUrl: string;
  isBookingRequired: boolean;
  offers: any;
  address: any;
  geo: any;
  updatedAt: string;
  deletedAt: string;
  newUntil: string;
  ctas: any;
  primaryImage?: any;
}

export interface IList {
  [key: number]: IRestaurant;
}

export default class RestaurantsList extends BaseModel<IList> {
  static resource = "RestaurantList";
}
