import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const restDetailsUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: "100%",
      fontFamily: theme.typography.fontFamily
    },
    cardImage: {
      width: "100%",
      height: "auto",
      overflow: "hidden",
      position: "relative",
      "& img": {
        width: "100%",
        height: "220px",
        objectFit: "cover"
      }
    },
    innerDiv: {
      position: "absolute",
      bottom: 10,
      right: 30,
      color: "white",
      padding: "5px"
    },
    cuisineText: {
      fontSize: 14,
      fontWeight: "normal",
      marginTop: "5px",
      marginBottom: 0,
      marginRight: 0,
      textAlign: "left"
    },
    restoDetailsNoteBox: {
      border: `1px solid #f9f8f2`,
      position: "relative",
      alignItems: "center",
      margin: "20px 10px",
      padding: "10px 10px 10px 25px",
      borderRadius: "10px",
      backgroundColor: "#f9f8f2"
    },
    strap: {
      position: "absolute",
      left: 0,
      top: 0,
      bottom: 0,
      width: "10px",
      backgroundColor: "#d6c499",
      borderRadius: "10px 0 0px 10px"
    },
    noticeText: {
      fontSize: 16,
      fontWeight: "normal",
      marginTop: "5px",
      marginBottom: 0,
      marginRight: 0,
      textAlign: "left"
    },
    cardBottomContent: {
      textAlign: "left",
      margin: "25px 10px"
    },
    impStuffHeader: {
      fontSize: 21,
      fontWeight: "bold",
      marginBottom: "20px",
      marginTop: "20px",
      textAlign: "left"
    },
    svgIcons: {
      margin: "8px 10px 0px 0px",
      height: "26px"
    },
    impStuffText: {
      fontSize: 18,
      fontWeight: "normal",
      textAlign: "left",
      margin: "10px"
    },
    footer: {
      padding: "20px",
      display: "flex",
      backgroundColor: "#fff",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "-8px"
    },
    redButton: {
      height: theme.spacing(12),
      minWidth: "149px",
      color: theme.palette.primary.contrastText,
      fontSize: theme.typography.h5.fontSize,
      fontWeight: theme.typography.fontWeightBold as number,
      fontFamily: "VodafoneRgBold",
      textTransform: "inherit",
      padding: theme.spacing(4),
      "&.Mui-disabled": {
        color: theme.palette.primary.contrastText
      },
      "& .MuiButton-label": {
        padding: theme.spacing(0, 2.5)
      }
    },
    //Start of QRCode page css 
    mainContainerQrcode: {
      height: "100vh",
      display: "flex",
      overflow: "auto",
      flexDirection: "column"
    },
    svgQrCode: {
      width: "100%",
      margin: "20px 0px 50px 0px",
      textAlign: "center",
      height: "calc(100vh - 80vh)"
    },
    showQRCodeButton: {
      height: theme.spacing(13),
      minWidth: "149px",
      width: "100%",
      fontSize: theme.typography.h5.fontSize,
      fontWeight: theme.typography.fontWeightBold as number,
      fontFamily: "VodafoneRgBold",
      textTransform: "inherit",
      padding: theme.spacing(3),
      boxShadow: theme.shadows[4],
      marginTop: "8px",
      "&.Mui-disabled": {
        color: theme.palette.primary.contrastText
      },
      "& .MuiButton-label": {
        padding: theme.spacing(2),
        background: "#ededed",
        color: theme.palette.common.black,
        borderRadius: "10px"
      }
    },
    //End of QRCode page css 
  })
);