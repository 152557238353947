import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    favOffersMainContainer: {
      display: "flex",
      flexDirection: "column",
      // marginBottom: "56vw",
      alignItems: "center",
      // marginTop: "24VW"
    },
    content: {
      fontSize: "20px",
      color: "#AAAAAA",
      lineHeight: "28px"
    }
  })
);
