import { BaseModel } from "ReduxStore/index";

export interface ClientSessionDetails {
  token: string | null;
  sessionId: string | null;
}

export class ClientModel extends BaseModel<ClientSessionDetails> {
  static resource = "ClientDetails";
}
