import React, { useEffect } from "react";
import PropTypes from "prop-types";



const ZendeskAPI= (userDetails,path , ...args) => {
  if (canUseDOM() && window?.zE) {
    window.zE.apply(null, args);
    if(path ==='/help'){
      window.zE("messenger", "loginUser", callback => {
        callback(userDetails?.chatToken);
      });
    }    
  } else {
    console.warn("Zendesk is not initialized yet");
  }
};

const canUseDOM = () =>
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement;

const authenticateLogin = token => {
  window.zE("messenger", "loginUser", callback => {
    callback(token);
  });
};

const Zendesk = props => {
  const { defer = false, zendeskKey, onLoaded = () => {}, ...other } = props;
  const onScriptLoaded = () => {
    onLoaded();
    window.zE("messenger:on", "close", () => {
      window.history.back();
    });
  };

  const insertScript = () => {
    const script = document.createElement("script");
    script[defer ? "defer" : "async"] = true;
    script.id = "ze-snippet";
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    script.addEventListener("load", onScriptLoaded);
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (canUseDOM() && !window.zE) {
      insertScript();
      window.zESettings = other;
    } else {
      console.warn("Zendesk or DOM is not available");
    }
    return () => {
      if (canUseDOM() && window.zE) {
        // Clean up tasks (if any) related to Zendesk
        // window.zE("messenger", "logoutUser");
      }
    };
  }, [defer, zendeskKey, onLoaded, other]);
  return null;
};

Zendesk.propTypes = {
  zendeskKey: PropTypes.string.isRequired,
  defer: PropTypes.bool,
  onLoaded: PropTypes.func
};

export { authenticateLogin, ZendeskAPI };
export default Zendesk;