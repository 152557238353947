//@ts-nocheck
import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      marginRight: theme.spacing(-5),
      height: "100vh",
      "@media (max-width : 960px)": {
        marginRight: theme.spacing(0)
      }
    },
    header: {
      "& .makeStyles-toolbar-19": {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
      }
    },
    contentWrapper: {
      marginTop: "-45px",
      minHeight: "100vh"
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      background: `linear-gradient(rgb(216, 216, 216), rgb(255, 255, 255))`
      // marginBottom: theme.spacing(-5),
      // marginTop: theme.spacing(-13)
    },
    content: {
      display: "flex",
      // justifyContent: "space-between",
      minHeight: "100vh",
      flexDirection: "column",
      height: "100%"
    },
    interest: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      overflowX: "hidden",
      overflowY: "hidden",
      height: "calc(100vh - 200px)"
    },
    mainContent: {
      position: "relative",
      boxSizing: "border-box",
      overflowX: "scroll",
      overflowY: "scroll",
      "@media (max-height: 520px)": {
        marginTop: theme.spacing(0)
      }
    },
    textWrapper: {
      display: "flex",
      height: "128px",
      alignItems: "flex-start",
      flexDirection: "column",
      color: theme.palette.secondary.light,
      margin: "0px 5vw"
    },
    resetButton: {
      color: theme.palette.primary.main,
      flex: 1,
      height: theme.spacing(12),
      textTransform: "capitalize",
      fontSize: theme.typography.h6.fontSize,
      paddingLeft: theme.spacing(0),
      "& .MuiButton-label": {
        display: "flex",
        justifyContent: "flex-start"
      },
      "& .MuiTouchRipple-root": {
        color: theme.palette.secondary.main
      }
    },
    saveButton: {
      flex: 1,
      height: theme.spacing(12),
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontSize: theme.typography.h6.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: "capitalize",
      padding: theme.spacing(0),
      boxShadow: theme.shadows[4],
      "&.Mui-disabled": {
        color: theme.palette.primary.contrastText,
        background: "rgb(170, 170, 170)",
        boxShadow: theme.shadows[1]
      }
    },

    heading: {
      margin: "9px 0",
      fontWeight: theme.typography.fontWeightBold,
      fontSize: "24px",
      fontFamily: "VodafoneRgBold",
      lineHeight: 1.2
    },
    descriptionText: {
      marginTop: theme.spacing(0),
      fontSize: theme.typography.h6.fontSize,
      lineHeight: "22px"
    },
    buttonWrapper: {
      display: "flex",
      height: "72px",
      margin: "0px 5vw",
      paddingTop: theme.spacing(3.5)
    },
    onboardingButtonWrapper: {
      display: "flex",
      height: "72px",
      margin: "0vh 5vh"
    },
    nextButton: {
      color: theme.palette.primary.main,
      flex: 1,
      height: theme.spacing(12),
      textTransform: "capitalize",
      fontSize: theme.typography.h6.fontSize,
      textAlign: "center",
      padding: 0,
      margin: 0,
      "& .MuiButton-label": {
        display: "flex",
        justifyContent: "center",
        paddingTop: "6px"
      }
    },
    nextButtonDisabled: {
      color: "rgb(119, 119, 119) !important"
    },
    divdislikeButton:{
      
    }
  })
);
