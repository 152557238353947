//@ts-nocheck

import { makeStyles, Theme, createStyles } from "@material-ui/core";
import vmrBg from "Configurations/Clients/vodafone/Assets/Images/VMR_Onboarding_blank_background_myprofile.png";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      overflow: "scroll",
      marginRight: theme.spacing(-5),
      height: "100vh",
      "@media (max-width : 960px)": {
        marginRight: theme.spacing(0)
      }
    },
    contentWrapper: {
      //marginTop: theme.spacing(-13),
      overflowY: "scroll",
      paddingBottom: "60px"
    },
    content: {
      width: "100%"
    },
    divider: {
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    sectionWrapper: {
      padding: "16px 5vw 0px",
      boxSizing: "border-box",
      marginBottom: theme.spacing(3)
    },
    heading: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: "24px",
      color: theme.palette.secondary.light,
      lineHeight: "24px"
    },
    rewardsWrapper: {
      color: theme.palette.primary.contrastText,
      display: "flex",
      height: "59.3vw",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100%",
      backgroundPositionX: "0%",
      backgroundPositionY: "0%",
      "& p": {
        margin: "0",
        fontSize: theme.typography.h5.fontSize
      },
      "& h2": {
        margin: "-20px",
        fontSize: "100px",
        fontWeight: 400
      }
    },
    rewardsCount: {
      fontSize: "100px",
      fontWeight: theme.typography.fontWeightRegular,
      margin: 0
    },
    interestContent: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer"
    },

    interestLinkText: {
      marginRight: theme.spacing(2.25),
      color: theme.palette.primary.main,
      fontSize: theme.typography.h6.fontSize
    },
    interestsLinkArrow: {
      height: "18px"
    },
    interestChips: {
      marginTop: theme.spacing(1.8),
      marginBottom: theme.spacing(2),
      display: "flex",
      flexWrap: "wrap"
    },
    chip: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontWeight: theme.typography.fontWeightBold,
      height: "32px",
      boxShadow: theme.shadows[7],
      display: "flex",
      margin: theme.spacing(0.5, 1.25, 1.25, 0),
      "& .MuiChip-label": {
        fontSize: theme.typography.h6.fontSize,
        fontFamily: "VodafoneRgBold",
        fontWeight: "bold"
      }
    },
    pixelText: {
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    birthdayContent: {
      display: "flex",
      flexDirection: "column",
      paddingTop: "3px",
      marginBottom: theme.spacing(1)
    },
    birthdayValidation: {
      flex: 1
    },
    birthdateText: {
      margin: theme.spacing(0),
      fontSize: "14px"
    },
    birthDate: {
      "& .react-datepicker-wrapper": {
        width: "100%"
      },
      "& .react-datepicker__input-container": {
        width: "100%",
        " & input": {
          width: "100%",
          borderColor: theme.palette.primary.contrastText,
          borderWidth: 0
        },
        " & input:focus": {
          outline: "none"
        }
      }
    },
    enterDobContainer: {
      display: "flex"
    },
    editDob: {
      color: theme.palette.primary.main,
      fontSize: theme.typography.h6.fontSize
    },
    customDateInput: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: theme.spacing(2.5),
      lineHeight: "26px"
    },
    dateInput: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    linkText: {
      color: theme.palette.primary.main,
      fontSize: theme.typography.h6.fontSize,
      textDecoration: "underline"
    },
    dataPermissionDesc: {
      fontSize: theme.typography.h6.fontSize,
      color: theme.palette.secondary.light,
      lineHeight: "22px",
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(4.5)
    },
    buttonDefault: {
      background: "none",
      border: "none",
      padding: "0px",
      textAlign: "left"
    },
    seeAllButton: {
      background: "none",
      border: "none",
      padding: "0px",
      textAlign: "left",
      display: "flex"
    },
    convergedContainer: {
      display: "flex",
      flexDirection: "row",
      padding: "16px 4vw 16px",
      margin: "16px 8px",
      backgroundImage: `url(${vmrBg})`,
      backgroundRepeat: "no-repeat",
      WebkitBackgroundSize: "cover",
      borderRadius: theme.spacing(4.3)
    },
    convergedLeftSection: {
      flex: 1
    },
    convergedRightSection: {
      height: "88px",
      paddingTop: "8px"
    },
    convergedText: {
      fontSize: "21px",
      marginBottom: "16px",
      color: theme.palette.primary.contrastText
    },
    convergedButtonContainer: {
      display: "flex",
      flexDirection: "row",
      flex: "1",
      marginBottom: "8px",
      marginTop: "12px"
    },
    findOutMore: {
      borderRadius: theme.spacing(3.3),
      padding: "6px",
      fontWeight: theme.typography.fontWeightBold,
      display: "flex",
      fontSize: "19px",
      minHeight: theme.spacing(12),
      alignItems: "center",
      outline: "none",
      border: "none",
      textTransform: "none",
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      boxShadow: "rgb(0, 0, 0 , 0.20) 0px 2px 10px 0px",
      fontFamily: "VodafoneRgBold",
      "& .MuiButton-label": {
        margin: "0px 16px"
      }
    }
  })
);
