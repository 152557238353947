import React from "react";
import { useStyles } from "../styles";
import { useHistory } from "react-router-dom";
import defaultImg from "Configurations/Clients/vodafone/Assets/Membership/membershipstartImg.png";
import {
  EVENT_TYPE,
  logEvents,
  pushAllEventsToServer
} from "Services/EventService";
import { getClientName } from "Utils/GeneralUtils";
import { getAvailableDays } from "../RestaurantUtil";
import { restCardsUseStyles } from "./styles";
import {
  dinerGuestIconOne,
  dinerGuestIconTwo,
  dinerGuestIconThree,
  dinerGuestIconFour,
  dinerGuestIconFive,
  dinerGuestIconSix,
  dinerGuestIconSeven,
  dinerGuestIconEight,
  dinerGuestIconNine,
  dinerGuestIconTen,
  dinerGuestIconEleven,
  dinerGuestIconTwelve,
  dinerGuestIconThirteen,
  dinerGuestIconFourteen,
  dinerGuestIconFifteen,
  dinerGuestDefault
} from "Constants/Require/Require";

interface IProps {
  restaurantImage: string;
  restaurantName: string;
  cuisines: { id: string; name: string }[];
  infoLabelOffer: any;
  id: string;
  offerDetail: object | {};
  parentOffer: any;
}

const SearchResultListCard: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();
  const classes = useStyles();
  const restCardsClasses = restCardsUseStyles();
  const {
    id,
    restaurantImage,
    restaurantName,
    cuisines,
    infoLabelOffer,
    offerDetail,
    parentOffer
  } = props;

  const maxDiners = {
    1: dinerGuestIconOne,
    2: dinerGuestIconTwo,
    3: dinerGuestIconThree,
    4: dinerGuestIconFour,
    5: dinerGuestIconFive,
    6: dinerGuestIconSix,
    7: dinerGuestIconSeven,
    8: dinerGuestIconEight,
    9: dinerGuestIconNine,
    10: dinerGuestIconTen,
    11: dinerGuestIconEleven,
    12: dinerGuestIconTwelve,
    13: dinerGuestIconThirteen,
    14: dinerGuestIconFourteen,
    15: dinerGuestIconFifteen
  };

  const handleCardClick = () => {
    setTimeout(() => {
      if (parentOffer?.addType === "Eat Local") {
        logEvents({
          eventType: EVENT_TYPE.EAT_LOCAL_RESTAURANT_CLICK,
          offerId: parentOffer?.offerId,
          restaurantId: id
        });
      } else {
        logEvents({
          eventType: EVENT_TYPE.COFFEE_CLUB_SHOP_CLICK,
          offerId: parentOffer?.offerId,
          restaurantId: id
        });
      }
      pushAllEventsToServer();
    }, 200);

    history.push(`/restaurant-details/${id}`, { restaurantProps: props });
  };

  const renderMaxDiner = () => {
    return (
      <p className={restCardsClasses.infoIcons}>
        <img
          src={
            infoLabelOffer[0]?.maxDiners > 15
              ? dinerGuestDefault
              : maxDiners[infoLabelOffer[0]?.maxDiners]
          }
          style={{ height: "22px", minWidth: "30px" }}
        />
      </p>
    );
  };

  return (
    <div
      className={restCardsClasses.listCardContainer}
      onClick={handleCardClick}
    >
      <div className={restCardsClasses.listCard}>
        <div className={restCardsClasses.listCardImage}>
          <img src={restaurantImage ? restaurantImage : defaultImg} />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className={restCardsClasses.strip}>
              <p className={restCardsClasses.stripLabel}>
                {props.infoLabelOffer[0]?.name}
                <br />
                <span style={{ fontWeight: "400" }}>
                  {getAvailableDays(infoLabelOffer)}
                </span>
              </p>
              {parentOffer?.addType === "Eat Local"
                ? infoLabelOffer[0]?.maxDiners > 0
                  ? renderMaxDiner()
                  : ""
                : ""}
            </div>
          </div>
        </div>
        <div className={classes.cardContent}>
          <h2 className={restCardsClasses.cardTitle}>{restaurantName}</h2>
          <h4 className={restCardsClasses.cardText}>{cuisines[0]?.name}</h4>
        </div>
      </div>
    </div>
  );
};

export default SearchResultListCard;
