import React, { Fragment } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Button,
  Typography,
  Card,
  CardActions,
  CardContent
} from "@material-ui/core";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { Header } from "gce-cxinteracts-ui-components-library";
import { getClientName, getImageDetails } from "Utils/GeneralUtils";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import ApplicationFlag from "Models/ReduxModels/ApplicationFlag";
import { getClaimedDate, getExpiryDate } from "./MembershipUtils";
import gourmetlogo from "Configurations/Clients/vodafone/Assets/RestoLocalBlendImages/gourmetLogo.png";
import coffeeclublogo from "Configurations/Clients/vodafone/Assets/RestoLocalBlendImages/coffeeclub.png";
import { connect } from "react-redux";
import FilteredRestaurants from "Models/ReduxModels/FilteredRestaurants";
import RestoLocalBFilterModal from "Models/ReduxModels/RestoLocalBlendFilters";
import { membShipUseStyles } from "./styles";
import Clock from "./Clock";
import { useStyles } from "../styles";
import UserDetails from "Models/ReduxModels/UserDetails";

interface IProps {
  applicationFlags: any;
  location: any;
  userDetails?: any;
}

const eatLocal = "Eat Local";

const UseCard: React.FC<IProps> = (props: IProps) => {
  const { applicationFlags, userDetails } = props;
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const classes = useStyles();
  const memberShipClasses = membShipUseStyles();
  let clientName = getClientName();
  let claimedDetails: any = searchParams.get("claimedOfferDetails");
  let claimOfferDetails = JSON.parse(claimedDetails);

  const previousRoute: any = searchParams.get("previousRoute") || "";
  const mainRouteFrom: any = searchParams.get("mainRouteFrom") || "";
  const offerAddType: any = searchParams.get("offerAddType") || "";

  const goBack = () => {
    setTimeout(() => {
      if (
        previousRoute === "welcome-membership" &&
        applicationFlags?.selectedShopOrResto &&
        Object.keys(applicationFlags.selectedShopOrResto).length > 0
      ) {
        history.push("/search-custom-list");
      } else if (
        previousRoute === "welcome-membership" &&
        mainRouteFrom == "home"
      ) {
        history.push("/home");
      } else if (
        previousRoute === "welcome-membership" &&
        mainRouteFrom == "rewards"
      ) {
        history.push("/myRewards");
      } else if (previousRoute === "welcome-membership") {
        history.push("/search-screen");
      } else {
        window.history.back();
      }
    }, 500);
  };

  const redirectToHome = () => {
    setTimeout(() => {
      history.push("/home");
      new RestoLocalBFilterModal({
        id: "",
        offerType: [],
        cuisine: "",
        availability: ""
      }).$save();
      let list = {};
      new FilteredRestaurants({
        id: "",
        ...list
      }).$save();
      new ApplicationFlag({
        id: "",
        ...applicationFlags,
        isShowFooter: true,
        isRedirectedFromGiftingSuccessPage: false,
        eatLocalCoffeClubID: 0,
        eatLocalCoffeClubOffer: {},
        selectedShopOrResto: {}
      }).$save();
    }, 500);
  };

  const getUserName = () => {
    const str = `${userDetails?.firstName ? userDetails?.firstName : ""} ${
      userDetails?.lastName ? userDetails?.lastName : ""
    }`;
    let updatedStr: any = str
      .toLowerCase()
      .replace(/\b\w/g, char => char.toUpperCase());
    return updatedStr;
  };

  const type = offerAddType
    ? offerAddType
    : applicationFlags?.eatLocalCoffeClubOffer?.addType;

  return (
    <div className={memberShipClasses.mainContainerUseCard}>
      <Header
        headerText=""
        isHeaderInverted={true}
        svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
        clientLogoPrimary={getImageDetails(
          ImagesMapping[clientName].clientLogoPrimary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        clientLogoSecondary={getImageDetails(
          ImagesMapping[clientName].clientLogoSecondary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        onSvgSelection={goBack}
        onLogoSelection={redirectToHome}
        isScrollHeader={false}
      />
      <div className={classes.useCardcontent}>
        <Card
          style={{
            backgroundColor: "#ededed",
            boxShadow: "none",
            padding: "12px"
          }}
        >
          <CardActions
            style={{ justifyContent: "space-between", paddingBottom: "10px" }}
          >
            <Typography style={{ fontFamily: "sans-serif", fontSize: "14px" }}>
              <Clock />
            </Typography>
          </CardActions>
          <CardContent style={{ textAlign: "center", padding: "5px" }}>
            <img
              src={type === eatLocal ? gourmetlogo : coffeeclublogo}
              alt="gourmet-image"
              style={{ height: "46px" }}
            />
            {userDetails?.firstName || userDetails?.lastName ? (
              <h1
                style={{
                  fontSize: "15px",
                  fontFamily: "sans-serif",
                  fontWeight: "500"
                }}
              >
                {getUserName()}
              </h1>
            ) : (
              ""
            )}
          </CardContent>
          <CardActions
            style={{ justifyContent: "space-between", padding: "0px" }}
          >
            <Typography
              style={{
                fontWeight: "bold",
                marginBottom: "-20px",
                textTransform: "uppercase",
                fontFamily: "sans-serif"
              }}
            >
              {claimOfferDetails?.redemptionCode}
            </Typography>
            <Typography>
              <p
                style={{
                  margin: "0px",
                  textAlign: "end",
                  fontFamily: "sans-serif"
                }}
              >
                Expires:
              </p>
              <span style={{ fontWeight: "bold", fontFamily: "sans-serif" }}>
                {claimOfferDetails ? getExpiryDate(claimOfferDetails) : ""}
              </span>
            </Typography>
          </CardActions>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  let applicationFlags = ApplicationFlag.getInsatnce("", state).props;
  const userDetails = UserDetails.getInsatnce("", state)?.props;

  return {
    applicationFlags,
    userDetails
  };
};

export default connect(mapStateToProps)(UseCard);
