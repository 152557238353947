import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Header } from "gce-cxinteracts-ui-components-library";
import TalkToUs from "../../../Components/TalkToUs/TalkToUs";
import Faq, { IFaq, Faqdetails } from "../../../Models/ReduxModels/FaqModel";
import { useStyles } from "./styles";
import { ConstantMapping } from "../../../Configurations/ConstantsMapping";
import ApplicationFlag, {
  IApplication
} from "../../../Models/ReduxModels/ApplicationFlag";
import { Typography } from "@material-ui/core";
import { getClientName, getImageDetails } from "../../../Utils/GeneralUtils";
import { ImagesMapping } from "Configurations/ImagesMapping";
interface IProps {
  faqs: any;
  applicatinFlags: IApplication;
}

const Help: React.FC<IProps> = props => {
  const { faqs, applicatinFlags } = props;
  const clientName = getClientName();
  let question = faqs?.configurationDataList || [];
  let classes = useStyles();
  const history = useHistory();
  const [showTalkToUs, setShowTalkToUs] = useState(false);
  const [category, setCategory] = useState("");
  const [questions, setQuestions] = useState<Faqdetails[]>([...question]);
  const root = document.getElementById("root");
  const fixedMainElement: any = document.getElementById("main-container");

  useEffect(() => {
    resetScroll();
  }, [category]);

  const resetScroll = () => {
    if (root) {
      root.scrollTop = 0;
    }
    if (fixedMainElement) {
      fixedMainElement.scrollTop = 0;
    }
  };

  const goToBackPage = () => {
    if (!showTalkToUs && applicatinFlags?.isShowFooter) {
      window.history.back();
    } else {
      setShowTalkToUs(false);
      new ApplicationFlag({
        id: "",
        isShowFooter: true
      }).$save();
    }
  };

  const redirectToHome = () => {
    history.push("/home");
    new ApplicationFlag({
      id: "",
      isShowFooter: true
    }).$save();
  };

  const handleFAQSelection = (categoryName: string) => {
    setCategory(categoryName);
    const selectedCategory = faqs.configurationDataList.find(
      faq => faq.name === categoryName
    );
    if (selectedCategory) {
      const sortedQuestions = selectedCategory.configurationDataList.sort(
        (a, b) => {
          const priorityA = parseInt(a.name.split('_')[0], 10);
          const priorityB = parseInt(b.name.split('_')[0], 10);
          return priorityA - priorityB;
        }
      );
      setQuestions([...sortedQuestions]);
    }
  };

  return (
    <Fragment>
      <div className={classes.mainContainer}>
        <Header
          headerText=""
          isHeaderInverted={false}
          svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
          clientLogoPrimary={getImageDetails(
            ImagesMapping[clientName].clientLogoPrimary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          clientLogoSecondary={getImageDetails(
            ImagesMapping[clientName].clientLogoSecondary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          onSvgSelection={
            category !== "" ? () => setCategory("") : goToBackPage
          }
          onLogoSelection={redirectToHome}
          isScrollHeader={false}
        />
        <div className={classes.contentWrapper}>
          <div
            className={`${classes.content} ${applicatinFlags?.isShowFooter
              ? classes.showFooter
              : classes.hideFooter
              }`}
          >
            <div className={classes.faqContainer}>
              {category === "" && (
                <div>
                  <Typography variant="h4" className={classes.Mainheading}>
                    How can we help you?
                  </Typography>
                  <Typography variant="h6" className={classes.subHeading}>
                    Choose a category to find the help you need
                  </Typography>
                  <div>
                    <div
                      className={classes.faqOption}
                      onClick={() => handleFAQSelection("Rewards and Codes")}
                    >
                      <img
                        src={ImagesMapping[clientName].rewardsImg}
                        className={classes.faqImage}
                      ></img>
                      <Typography variant="h4" className={classes.faqTitle}>
                        Rewards and codes
                      </Typography>
                    </div>
                    <div
                      className={classes.faqOption}
                      onClick={() => handleFAQSelection("Vodafone Together")}
                    >
                      <img
                        src={ImagesMapping[clientName].vfTogetherImg}
                        className={classes.faqImage}
                      ></img>
                      <Typography variant="h4" className={classes.faqTitle}>
                        Vodafone Together
                      </Typography>
                    </div>
                    <div
                      className={classes.faqOption}
                      onClick={() =>
                        handleFAQSelection("Eat Local/Local Blend")
                      }
                    >
                      <img
                        src={ImagesMapping[clientName].eatLocalImg}
                        className={classes.faqImage}
                      ></img>
                      <Typography variant="h4" className={classes.faqTitle}>
                        Eat Local / Local Blend
                      </Typography>
                    </div>
                    <div
                      className={classes.faqOption}
                      onClick={() => handleFAQSelection("Subscriptions")}
                    >
                      <img
                        src={ImagesMapping[clientName].subscriptionsImg}
                        className={classes.faqImage}
                      ></img>
                      <Typography variant="h4" className={classes.faqTitle}>
                        Subscriptions
                      </Typography>
                    </div>
                    <div
                      className={classes.faqOption}
                      onClick={() => handleFAQSelection("PAYG")}
                    >
                      <img
                        src={ImagesMapping[clientName].paygImg}
                        className={classes.faqImage}
                      ></img>
                      <Typography variant="h4" className={classes.faqTitle}>
                        Pay as you go
                      </Typography>
                    </div>
                    <div
                      className={classes.faqOption}
                      onClick={() => handleFAQSelection("Personal details")}
                    >
                      <img
                        src={ImagesMapping[clientName].personalDetailsImg}
                        className={classes.faqImage}
                      ></img>
                      <Typography variant="h4" className={classes.faqTitle}>
                        Personal details
                      </Typography>
                    </div>
                    <div
                      className={classes.faqOption}
                      onClick={() => handleFAQSelection("General")}
                    >
                      <img
                        src={ImagesMapping[clientName].generalImg}
                        className={classes.faqImage}
                      ></img>
                      <Typography variant="h4" className={classes.faqTitle}>
                        General
                      </Typography>
                    </div>
                  </div>
                </div>
              )}
              {category !== "" && (
                <>
                  <Typography variant="h4" className={classes.Mainheading}>
                    {" "}
                    {category === "PAYG"
                      ? "Pay as you go"
                      : category === "Rewards and Codes"
                        ? "Rewards and codes"
                        : category}
                  </Typography>
                  {questions.map(item => (
                    < div className={classes.faqQuestionContainer} key={item.id} >
                      <Typography variant="h5" className={classes.faqQuestion}>
                        {item.name.indexOf('_') !== -1 ? item.name.substring(item.name.indexOf('_') + 1) : item.name}
                      </Typography>
                      {item.value && (
                        <Typography
                          variant="h6"
                          className={classes.faqAnswer}
                          dangerouslySetInnerHTML={{ __html: item.value }}
                        />
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
            {/* Talk TO US */}
            <TalkToUs
              showOnFaq={!applicatinFlags?.isShowFooter}
              tealiumPageName={`${ConstantMapping[clientName].TEALIUM_PAGE_PREFIX}${ConstantMapping[clientName].TEALIUM_PAGE_HELP}`}
            />
          </div>
        </div>
      </div>
    </Fragment >
  );
};

const mapStateToProps = (state: any) => {
  const faqs = Faq.getInsatnce("", state)?.props;
  let applicatinFlags = ApplicationFlag.getInsatnce("", state).props;
  return {
    faqs,
    applicatinFlags
  };
};

export default connect(mapStateToProps)(Help);
