import React, { useState } from "react";
import { useStyles } from "./styles";
import { Dialog, Typography, Button } from "@material-ui/core";
import { shareOfferGraphQLCall } from "../../Services/OfferSerivice";
import { iOSDevice, loadEvents, previousPages } from "Utils/GeneralUtils";
import moment from "moment";
import { isObjectEmpty } from "Utils/offerUtils";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import { ImagesMapping } from "Configurations/ImagesMapping";

interface IProps {
  claimId: any;
  title: string;
  openDialog: boolean;
  selctedDialogOption: string | null;
  handleCloseDialog(): void;
  setSelctedDialogOption(text: string | null): void;
  successCallback(res: any): void;
  callToActionDetails?: any;
  clientName?: any;
}

const dialogOptions = {
  textMessage: "Sms",
  email: "Email",
  whatsapp: "WhatsApp"
};

const messageMapping = {
  withExpiryTime:
    "Hi, here’s something I thought you might like. {TITLE} with VeryMe Rewards from Vodafone. Your gift will expire on {DATE}. Follow the link to claim yours now and view Terms and Conditions: {URL}",
  withoutExpiryTime:
    "Hi, here’s something I thought you might like. {TITLE} with VeryMe Rewards from Vodafone. Follow the link to claim yours now and view Terms and Conditions: {URL}"
};

const GiftingDialog: React.FC<IProps> = ({
  claimId,
  title,
  openDialog,
  selctedDialogOption,
  handleCloseDialog,
  setSelctedDialogOption,
  successCallback,
  callToActionDetails,
  clientName
}) => {
  const [dialogError, setDialogErrorMessage] = React.useState<string | null>(
    null
  );
  const classes = useStyles();
  let isShareCallIP = false;

  const formatDate = (date: any) => {
    let convertedDate = moment
      .parseZone(date)
      .local()
      .format("Do MMMM YYYY HH:mm");
    let newDate = convertedDate.split(" ");
    let d =
      newDate[0] + " " + newDate[1] + " " + newDate[2] + " at " + newDate[3];
    return d;
  };

  const generateUrl = (encodedBody: any) => {
    switch (selctedDialogOption) {
      case dialogOptions.email:
        encodedBody = encodeURIComponent(encodedBody);
        return (
          "mailto:?subject=You've been sent a gift from VeryMe Rewards&body=" +
          encodedBody
        );

      case dialogOptions.textMessage:
        let separator = iOSDevice() ? "&" : "?";
        encodedBody = encodeURIComponent(encodedBody);
        return "sms:" + separator + "body=" + encodedBody;

      case dialogOptions.whatsapp:
        encodedBody = encodeURIComponent(encodedBody);
        return "whatsapp://send?text=" + encodedBody;
    }
  };

  const generateRedirectionUrl = (res: any) => {
    let selectedOption: any = [];
    let encodedData: any = "";
    if (callToActionDetails) {
      selectedOption = callToActionDetails.find(item => {
        if (item.key === selctedDialogOption) {
          return item;
        }
      });
    }
    if (!isObjectEmpty(selectedOption)) {
      encodedData = selectedOption?.value + " " + res?.sharingUrl;
    } else {
      encodedData = generateMockUrl(res);
    }
    return generateUrl(encodedData);
  };

  const generateMockUrl = (res: any) => {
    let encodedBody = res?.expiryDateTime
      ? messageMapping.withExpiryTime
      : messageMapping.withoutExpiryTime;

    encodedBody = encodedBody.replace("{TITLE}", title);

    if (res?.expiryDateTime) {
      encodedBody = encodedBody.replace(
        "{DATE}",
        formatDate(res.expiryDateTime)
      );
    }
    encodedBody = encodedBody.replace("{URL}", res?.sharingUrl);
    return encodedBody;
  };

  const redirectShare = (res: any) => {
    const url: any = generateRedirectionUrl(res);
    let linkElement = document.createElement("a");
    linkElement.setAttribute("href", url);

    linkElement.click();
  };

  const getPlateform = () => {
    switch (selctedDialogOption) {
      case dialogOptions.email:
        return "EMAIL";
      case dialogOptions.textMessage:
        return "SMS";
      case dialogOptions.whatsapp:
        return "WHATSAPP";
    }
  };

  const loadTealiumEvents = platform => {
    const state = {
      onLoadParams: {
        show_view: false,
        event_name: "PageInteraction",
        event_action: "link",
        event_label: platform,
        page_name: `${ConstantMapping[clientName].TEALIUM_PAGE_PREFIX}${title}${ConstantMapping[clientName].TEALIUM_PAGE_GIFTING_OVERLAY}`,
        same_page_event: true,
        page_subsection: "offers"
      }
    };
    loadEvents(previousPages, { state });
  };

  const shareOffer = async () => {
    const platform = getPlateform();
    if (!isShareCallIP) {
      isShareCallIP = true;
      try {
        let res = await shareOfferGraphQLCall(claimId, platform);
        if (res?.data?.shareOffer) {
          setDialogErrorMessage(null);
          redirectShare(res?.data?.shareOffer);
          successCallback(res?.data?.shareOffer);
          handleCloseDialog();
          isShareCallIP = false;
        } else {
          throw "API response invalid";
          isShareCallIP = false;
        }
      } catch (error) {
        console.log(error);
        setSelctedDialogOption(null);
        setDialogErrorMessage(
          "Something went wrong. please try again after sometime."
        );
        isShareCallIP = false;
      }
    }
  };

  const choosePlatform = platform => {
    setSelctedDialogOption(platform);
    loadTealiumEvents(platform);
  };

  const closeGiftByDialog = () => {
    const state = {
      onLoadParams: {
        page_name: `${ConstantMapping[clientName].TEALIUM_PAGE_PREFIX}${title}>${ConstantMapping[clientName].TEALIUM_PAGE_CLAIM}`,
        page_subsection: "offers"
      }
    };
    loadEvents(previousPages, { state });
    handleCloseDialog();
  };

  const renderDialogOptions = (icon: any, text: string) => {
    let selectedPlatform: string =
      text === ConstantMapping[clientName].SOCIAL_PLATFORM_TEXT
        ? dialogOptions.textMessage
        : text;
    return (
      <Button
        className={classes.dialogButton}
        disableRipple
        disableFocusRipple
        startIcon={
          <div className={classes.dialogButtonIconContainer}>
            <img src={icon} className={classes.dialogButtonIcon} alt={text} />
          </div>
        }
        onClick={() => choosePlatform(selectedPlatform)}
      >
        <Typography className={classes.dialogOptionText}>{text}</Typography>
      </Button>
    );
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      PaperProps={{
        classes: { root: classes.dialogOpenAimation },
        style: {
          backgroundColor: "#FFFFFF",
          width: "100%",
          padding: "0px",
          color: "#000000",
          margin: "20px"
        }
      }}
      TransitionProps={{
        style: {
          opacity: 1,
          transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          background: "rgba(0, 0, 0, 0.4)"
        }
      }}
      disableBackdropClick={true}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          padding: "5vw"
        }}
      >
        {!selctedDialogOption ? (
          <>
            <h3 className={classes.giftTitle}>Give your gift by:</h3>
            <div className={classes.sendGiftIcons}>
              {renderDialogOptions(
                ImagesMapping[clientName].textMessageIcon,
                ConstantMapping[clientName].SOCIAL_PLATFORM_TEXT
              )}

              {renderDialogOptions(
                ImagesMapping[clientName].emailIcon,
                dialogOptions.email
              )}
              {renderDialogOptions(
                ImagesMapping[clientName].whatsappIcon,
                dialogOptions.whatsapp
              )}
            </div>
            <p className={classes.dialogError}>{dialogError}</p>
            <div className={classes.backDialogActionContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.dialogActionButton}
                onClick={closeGiftByDialog}
              >
                <Typography className={classes.buttonText}>Back</Typography>
              </Button>
            </div>
          </>
        ) : (
          <>
            <img
              alt=""
              src={ImagesMapping[clientName].giftRedIcon}
              className={classes.dialogTitleIcon}
            />
            <h3 className={classes.giftDialogHeading}>
              You’re about to send this gift to someone.
            </h3>
            <p className={classes.giftDialogText}>
              Make sure you only gift using an app you already have installed.
              Once it’s sent, you won’t be able to use it yourself.
            </p>
            <div
              style={{ margin: "0" }}
              className={classes.dialogActionButtonContainer}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.dialogActionButton}
                onClick={() => setSelctedDialogOption(null)}
              >
                <Typography className={classes.buttonText}>Back</Typography>
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.dialogActionButton}
                onClick={shareOffer}
              >
                <Typography className={classes.buttonText}>
                  Give gift
                </Typography>
              </Button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default GiftingDialog;
