import { VodafoneConstants } from "./Clients/vodafone/Constants/Constants";
import {
  VodafoneMutations,
  VodafoneQueries
} from "./Clients/vodafone/Constants/GQLConstants";
import vfMockData from "./Clients/vodafone/Constants/response.json";

export const ConstantMapping: Record<string, any> = {
  vodafone: VodafoneConstants
};

export const GQLConstantMapping: Record<string, any> = {
  vodafone: {
    queries: VodafoneQueries,
    mutations: VodafoneMutations
  }
};

export const MockDataMapping: Record<string, any> = {
  vodafone: {
    "fetch-all": vfMockData
  }
};
