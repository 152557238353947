import { Settings } from "Models/ReduxModels/Settings";

export const getRestaurantsList = async (searchText, restaurantName, token) => {
  let state: any;
  const settings = Settings.getInsatnce("", state).props;
  const endPoint = settings["clientDetails"]?.restaurantService;
  const serviceOrigin = settings["clientDetails"]?.restaurantServiceOrigin;

  let headers = {
    Origin: serviceOrigin,
    "Content-type": "application/json",
    "X-Consumer-Token": `${token}`
  };

  const url = new URL(endPoint);
  url.searchParams.append("searchString", searchText);
  url.searchParams.append("restaurantName", restaurantName);

  try {
    const resp = await fetch(url.toString(), {
      method: "GET",
      headers: headers
    });
    const data = await resp.json();
    if (data) {
      return data;
    }
  } catch (e) {
    console.error(e);
    return e;
  }
};
