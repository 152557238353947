import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const membShipUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    //Start of MemberShip & WelcomeMembership page css 
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      background: "linear-gradient(180deg, #E60000 0%, #820000 100%)",
      height: "100vh"
    },
    imageContainer: {
      height: "42vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      clipPath: "ellipse(115% 100% at 50% 0%)"
    },
    content: {
      display: "flex",
      flexDirection: "column",
      margin: "5vw 5vw 1vw 5vw",
      flex: 1,
      color: "#fff",
      fontFamily: "VodafoneRg"
    },
    subHeading: {
      fontSize: "24px",
      margin: "10px 0px",
      fontWeight: 600,
      letterSpacing: "2px",
      fontFamily: "VodafoneRg",
      lineHeight: "26px",
      textAlign: "center",
      "@media (min-width:320px) and (min-height:568px)": {
        fontSize: "16px"
      },
      "@media (min-width:360px) and (min-height:740px) ": {
        fontSize: "22px"
      },
      "@media (min-width:375px)": {
        fontSize: "22px"
      },
      "@media (min-width:375px) and (min-height:810px)": {
        fontSize: "20px"
      },
      "@media (min-width:390px)": {
        fontSize: "22px"
      },
      "@media (min-width:414px) and (min-height:890px)": {
        fontSize: "22px !important"
      },
      "@media (min-width:414px) and (min-height:736px)": {
        fontSize: "22px  !important"
      },
      "@media (min-width:760px) and (min-height:1020px)": {
        fontSize: "22px"
      }
    },
    subTitle: {
      fontSize: "18px",
      fontWeight: 400,
      letterSpacing: "1px",
      fontFamily: "VodafoneRg",
      lineHeight: "26px",
      textAlign: "center",
      marginTop: "10px",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      marginTop: "20px",
      flex: "1"
    },
    buttonOk: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      height: "47px",
      textTransform: "none",
      backgroundColor: "#fff",
      color: "#000",
      fontFamily: "VodafoneRg",
      borderRadius: "8px",
      "@media (min-width:320px) and (min-height:568px)": {
        height: "30px",
        "& p": {
          fontSize: "14px !important",
          fontWeight: "bold",
        }
      },
      "@media (min-width:360px) and (min-height:740px) ": {
        height: "48px",
        "& p": {
          fontSize: "18px !important",
          fontWeight: "bold",
        }
      },
      "@media (min-width:375px)": {
        height: "34px",
        "& p": {
          fontSize: "18px !important",
          fontWeight: "bold",
        }
      },
      "@media (min-width:375px) and (min-height:810px)": {
        height: "47px",
        "& p": {
          fontSize: "18px !important",
          fontWeight: "bold",
        }
      },
      "@media (min-width:390px)": {
        fontSize: "20px"
      },
      "@media (min-width:414px)": {
        height: "46px",
        "& p": {
          fontSize: "20px !important",
          fontWeight: "bold",
        }
      },
      "@media (min-width:414px) and (min-height:736px)": {
        height: "36px",
        "& p": {
          fontSize: "18px !important",
          fontWeight: "bold",
        }
      },
      "@media (min-width:760px) and (min-height:1020px)": {
        height: "46px",
        "& p": {
          fontSize: "20px !important",
          fontWeight: "bold",
        }
      },
      "& p": {
        fontSize: "20px",
        fontWeight: "bold",
      }
    },
    cancelPermissionText: {
      "@media (min-width:320px) and (min-height:568px)": {
        "& p": {
          fontSize: "14px !important"
        }
      },
      "@media (min-width:360px) and (min-height:740px) ": {
        "& p": {
          fontSize: "18px !important"
        }
      },
      "@media (min-width:375px)": {
        "& p": {
          fontSize: "18px !important"
        }
      },
      "@media (min-width:375px) and (min-height:810px)": {
        "& p": {
          fontSize: "18px !important"
        }
      },
      "@media (min-width:390px)": {
        "& p": {
          fontSize: "20px !important"
        }
      },
      "@media (min-width:414px)": {
        "& p": {
          fontSize: "20px !important"
        }
      },
      "@media (min-width:414px) and (min-height:736px)": {
        "& p": {
          fontSize: "18px !important"
        }
      },
      "@media (min-width:760px) and (min-height:1020px)": {
        "& p": {
          fontSize: "20px !important"
        }
      },
      "& p": {
        fontSize: "20px",
        textAlign: "center",
        padding: "10px 0px "
      }
    },
    //End of MemberShip & WelcomeMembership page css
    //Start of UseCard page css 
    mainContainerUseCard: {
      height: "100vh",
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
      background: "#fff !important"
    },
    //End of UseCard page css 

    //  Start of CountdownTimer classes
    timerText: {
      fontFamily: "VodafoneRgBold",
      fontSize: "30px"
    },
    timerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    delimiter: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5)
    },
    //  End of CountdownTimer classes
  })
);