import { GQLConstants, graphQlCall } from "../Utils/GraphQLUtils";
import { GQLConstantMapping } from "Configurations/ConstantsMapping";
import { getClientName } from 'Utils/GeneralUtils';

export const getClaimedOffers = async (
  pageSize: number = 100,
  pageNumber: number = 0
) => {
  const variables = {
    pageSize: pageSize,
    pageNumber: pageNumber
  };

  try {
    const resp: any = await graphQlCall(
      GQLConstants.QUERY,
      GQLConstantMapping[getClientName()].queries.GET_CLAIMED_OFFERS,
      variables
    );
    return resp;
  } catch (e) {
    console.error(e);
    return e;
  }
};
