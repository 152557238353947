import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaperRoot: {
      width: "68.02vw",
      maxWidth: "68.02vw",
      height: "fit-content",
      maxHeight: "75.939vh",
      backgroundColor: theme.palette.background.default,
      borderRadius: "10px",
      boxShadow: "0px 4px 4px 0px #00000040",
      margin: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "16px 0",
      gap: "12px"
    },
    dialogWinnerHeading: {
      fontSize: "40px",
      color: theme.palette.secondary.dark,
      textAlign: "center",
      margin: 0
    },
    dialogWinnerLabelDesc: {
      fontSize: "24px",
      color: theme.palette.secondary.dark,
      fontWeight: 700,
      textAlign: "center",
      width: "74.254%",
      margin: 0
    },
    dialogWinnerDisaclaimer: {
      fontSize: "20px",
      color: theme.palette.secondary.dark,
      textAlign: "center",
      margin: 0,
      padding: "0 16px"
    },
    cliamNowButton: {
      borderRadius: "12px",
      padding: "16px 40px",
      "& span": {
        textTransform: "none",
        fontSize: "18px",
        fontWeight: "bold"
      }
    },
    dialogMidContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
      alignItems: "center"
    },
    titleText: {
      fontSize: "30px",
      fontWeight: 700,
      lineHeight: "30px",
      letterSpacing: "-0.063px",
      textAlign: "center",
      color: theme.palette.common.white
    },
    shortTitleText: {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "24px",
      letterSpacing: "-0.063px",
      textAlign: "center",
      color: theme.palette.common.white
    },
    textWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      width: "88.331vw",
      "& p": {
        margin: 0
      }
    },
    spinWinMainContainer: {
      height: "calc(100vh - 45px)",
      width: "100vw",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      gap: "6vh",
      padding: "20px",
      overflowX: "hidden"
    },
    wheelWrapper: {
      display: "flex",
      alignItems: "center",
      width: "100vw",
      height: "calc(100% - 20vh - 100px)"
    }
  })
);
