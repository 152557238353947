//@ts-nocheck
import React, { useRef, useLayoutEffect, useState, Fragment } from "react";
import JsBarcode from "jsbarcode";
import QRCode from "qrcode.react";
import { Typography } from "@material-ui/core";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    QrCode: {
      width: `45% !important`,
      height: `100% !important`,
      maxWidth: "50%",
      padding: theme.spacing(0.5),
      backgroundColor: "#fff"
    },
    barCode: {
      margin: theme.spacing(1, 0, 1),
      "& $g": {
        fill: `${theme.palette.secondary.light} !important`
      }
    },
    svgQrCode: {
      width: "100%",
      height: "calc(100vh - 80vh)"
    }
  })
);
interface IProps {
  type: "QR" | "Barcode";
  value: string;
  config?: any;
  clientName: any;
}
const QRBarcode: React.FC<IProps> = props => {
  const { type, value, config, clientName } = props;
  const [isError, setError] = useState<boolean>(false);
  const barcodeRef = useRef<SVGSVGElement>(null);
  const classes = useStyles();

  useLayoutEffect(() => {
    if (barcodeRef.current && value) {
      try {
        JsBarcode(barcodeRef.current, value, config);
        setError(false);
      } catch (error) {
        setError(true);
        console.log(error);
      }
    }
  }, [value, config]);

  if (!value) {
    return <Fragment></Fragment>;
  }
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center"
      }}
    >
      {type === "QR" ? (
        <div
          style={{
            margin: "5vh 0px 9px",
            display: "flex",
            justifyContent: "center"
          }}
        >
          {/* Change in QRCode, as it is coming in shrink form  */}

          {/* <QRCode
            value={value}
            size={window.innerWidth * 0.7}
            className={classes.QrCode}
          /> */}

          {/* Revert above code if this will not work then */}
          <QRCode
            value={value}
            className={classes.svgQrCode}
            renderAs={"svg"}
          />
        </div>
      ) : isError ? (
        <Typography style={{ margin: "0 10vw", lineHeight: 1 }}>
          {ConstantMapping[clientName].BARCODE_FAILED}
        </Typography>
      ) : (
        <svg ref={barcodeRef} className={classes.barCode} />
      )}
    </div>
  );
};

export default QRBarcode;
