import { BaseModel } from "ReduxStore/index";

export enum OfferDisplayCategory {
  RECOMMENDED = "Recommended",
  NEWEST = "Newest",
  POPULAR = "Popular"
}
export interface DisplayCategory {
  id: number;
  displayPosition: number;
  imageUrl: string;
  name: string;
  offerIds: number[];
}

export interface IDisplayCategory {
  [key: number]: DisplayCategory;
}

export default class DisplayCategories extends BaseModel<IDisplayCategory> {
  static resource = "DisplayCategories";
}

interface CategoryIds {
  ids: number[];
}

export class DisplayCategoryIds extends BaseModel<CategoryIds> {
  static resource = "DisplayCategoryIds";
}
