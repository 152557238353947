import { BaseModel } from "ReduxStore/index";

export interface Filter {
  isSelected: boolean;
  label: string;
  name: string;
  position: number;
  selectOrder: number;
}

export interface IFilter {
  [key: number]: Filter;
}

export default class Filters extends BaseModel<IFilter> {
  static resource = "Filters";
}
