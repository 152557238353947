import React, { useEffect } from "react";
import { Header } from "gce-cxinteracts-ui-components-library";
import { connect } from "react-redux";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import { pushAllEventsToServer } from "Services/EventService";
import { useStyles } from "./styles";
import { getClientName } from "Utils/GeneralUtils";

const PrepaidErrorPage: React.FC = () => {
  const classes = useStyles();
  const clientName = getClientName();

  useEffect(() => {
    pushAllEventsToServer();
  }, []);

  return (
    <div className={classes.container}>
      <div style={{ zIndex: 5, top: 0, position: "sticky" }}>
        <Header
          headerText="My Vodafone"
          svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
          onSvgSelection={() =>
            window.open(ConstantMapping[clientName].PREPAID_ERROR_PAGE_BACK_URL)
          }
          className={classes.header}
        />
      </div>
      <div className={classes.errorMessage}>
        <p>{ConstantMapping[clientName].PREPAID_ERROR_PAGE_MESSAGE.LINE1}</p>
        <p>{ConstantMapping[clientName].PREPAID_ERROR_PAGE_MESSAGE.LINE2}</p>
      </div>
    </div>
  );
};

export default PrepaidErrorPage;
