import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: `${theme.palette.secondary.dark} !important`,
      minHeight: "100vh",
      minWidth: "100vw",
      fontSize: 24,
      height: "max-content",
      overflowX: "auto",
      overflowY: "auto"
    },
    header: {
      "& $span": {
        color: `${theme.palette.text.secondary} !important`
      }
    },
    errorMessage: {
      padding: "5vh 8vw",
      color: `#AAAAAA !important`,
      "& $p:first-child": {
        margin: "0px"
      },
    },
    
  })
);
