//@ts-nocheck

import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      marginRight: theme.spacing(-5),
      height: "100vh",
      "@media (max-width : 960px)": {
        marginRight: theme.spacing(0)
      }
    },
    header: {
      "& .makeStyles-toolbar-21": {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
      }
    },
    contentWrapper: {
      marginTop: theme.spacing(-11.25),
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column"
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      background: `linear-gradient(rgb(216, 216, 216), rgb(255, 255, 255))`
    },
    mainContent: {
      position: "relative",
      boxSizing: "border-box",
      marginTop: "8vh",
      marginBottom: theme.spacing(-6),
      overflowX: "scroll",
      overflowY: "scroll",
      marginLeft: theme.spacing(3),
      "@media (max-height: 320px)": {
        marginTop: theme.spacing(0)
      }
    },
    imageWrapper: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      flex: "2 1 50%"
    },
    image: {
      height: "100%",
      display: "flex",
      width: "100%"
    },
    content: {
      display: "flex",
      height: "100vh",
      background: `linear-gradient(rgb(216, 216, 216), rgb(255, 255, 255))`,
      justifyContent: "center",
      minHeight: "100vh",
      flexDirection: "column",
      flex: "1 1 100%"
    },
    textWrapper: {
      display: "flex",
      fontSize: "16px",
      alignItems: "flex-start",
      flexDirection: "column",
      color: theme.palette.secondary.light,
      margin: "0vh 5vw",
      flex: "1 0 auto"
    },
    buttonWrapper: {
      flex: "0 0 17%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      height: "100px",
      alignItems: "center",
      "@media (min-width: 960px)": {
        margin: "3vh 8vw"
      }
    },
    locationButtonOff: {
      color: theme.palette.primary.main,
      textTransform: "none",
      padding: theme.spacing(0),
      fontSize: theme.typography.h6.fontSize,
      marginTop: theme.spacing(4.5),
      lineHeight: "1",
      "& .MuiButton-label": {
        display: "flex",
        justifyContent: "center"
      }
    },
    locationUseButton: {
      width: theme.spacing(50),
      height: theme.spacing(12),
      fontSize: theme.typography.h6.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: "none",
      fontFamily: theme.typography.h3.fontFamily,
      boxShadow: theme.shadows[4],
      "&.Mui-disabled": {
        color: theme.palette.primary.contrastText
      }
    },

    heading: {
      margin: theme.spacing(1, 0, 1.5),
      fontFamily: theme.typography.h3.fontFamily
    },
    descriptionText: {
      marginTop: theme.spacing(0),
      fontSize: theme.typography.h6.fontSize,
      lineHeight: "22px"
    },

    onb2_redpin: {
      position: "absolute",
      zIndex: 1,
      margin: "75% 0 0 calc(50% - 15px)",
      width: "8%",
      transform: "translate3d(0,-30px,0)",
      webkitTransform: "translate3d(0,-30px,0)",
      animation: "$easing_in 650ms linear 0s 1"
    },
    pin: {
      position: "absolute",
      width: "4%",
      zIndex: 1,
      transform: "translate3d(0,-10px,0)",
      webkitTransform: "translate3d(0,-10px,0)"
    },

    pin_animate1: {
      animation: "$pin_in 120ms linear 0s 1"
    },

    pin_animate2: {
      animation: "$pin_in 240ms linear 0s 1"
    },

    pin_animate3: {
      animation: "$pin_in 360ms linear 0s 1"
    },

    pin_animate4: {
      animation: "$pin_in 480ms linear 0s 1"
    },

    onb2_backphone: {
      maxWidth: "90%",
      margin: "5% 0 0 5%",
      position: "relative"
    },

    onb2_popup: {
      position: "absolute",
      width: "60%",
      margin: "15% 0 0 20%",
      zIndex: 2,
      transform: "translate3d(0,-30px,0)",
      webkitTransform: "translate3d(0,-30px,0)",
      animation: "$easing_in 979ms linear 0s 1"
    },

    "@keyframes easing_in": {
      "0%": {
        opacity: 0,
        transform: "translate3d(0,0,0)",
        webkitTransform: "translate3d(0,0,0)"
      },
      "80%": {
        opacity: 0,
        transform: "translate3d(0,-15px,0)",
        webkitTransform: "translate3d(0,-15px,0)"
      },
      "100%": {
        opacity: 1,
        transform: "translate3d(0,-30px,0)",
        webkitTransform: "translate3d(0,-30px,0)"
      }
    },

    "@keyframes pin_in": {
      "0%": {
        opacity: 0,
        transform: "translate3d(0,0,0)",
        webkitTransform: "translate3d(0,0,0)"
      },
      "80%": {
        opacity: 0,
        transform: "translate3d(0,-5px,0)",
        webkitTransform: "translate3d(0,-5px,0)"
      },
      "100%": {
        opacity: 1,
        transform: "translate3d(0,-10px,0)",
        webkitTransform: "translate3d(0,-10px,0)"
      }
    }
  })
);
