const loadTealiumUtags = (
  utag: any,
  tealium: Object,
  showView: Boolean = true
) => {
  if (utag && Object.keys(tealium).length > 0) {
    // window.utag.data = {};
    window.utag.vfuk_data = {};
    let tag = window.utag.vfuk_data || {};
    tag.site = "mva";
    tag.page_channel = "mva";
    tag.page_section = "veryme rewards";
    tag.page_country = "uk";
    tag.page_locale = "en";
    tag.page_platform = "affinion";
    tag.tealium_event = "view";
    Object.assign(tag, tealium);

    if (showView) {
      utag.view(tag);
    } else {
      utag.link(tag);
    }
  }
};

export const pageLoadHook = (params: any) => {
  const { tealium, showView } = params;
  if (tealium) {
    let utag = window?.utag;
    if (utag?.view) {
      // If Tealium script loaded then it will trigger the Tealium
      loadTealiumUtags(utag, tealium, showView);
    } else {
      // Added this setTimout as if Tealium script takes time to load
      // Then event will raise after 500 ms
      // This will happen at the time of page load only
      setTimeout(() => loadTealiumUtags(window?.utag, tealium, showView), 1000);
    }
  }
};
