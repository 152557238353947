import React, { Fragment, useEffect } from "react";
import { useStyles } from "./styles";
import { getOfferImage } from "../../Utils/offerUtils";
import {
  ImageClassifier,
  RedemptionDetail,
  Image
} from "../../Models/ReduxModels/OffersModel";
import RedemptionGuidelines from "./RedemptionGuidelines";
import { Button, Typography } from "@material-ui/core";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { getClientName } from "Utils/GeneralUtils";
import { OfferType } from "Models/Interfaces/OfferType";

interface IProps {
  images: Image[];
  redemptionDetails: any;
  onClose(): void;
  offerData: any;
}
const PrizeDrawRedemption: React.FC<IProps> = ({
  images,
  redemptionDetails,
  onClose,
  offerData
}) => {
  const classes = useStyles();
  const clientName = getClientName();
  const img = getOfferImage(ImageClassifier.OFFER_IMAGE_LARGE, images)?.url;
  const isVisitToWin = offerData?.offerType === OfferType.VISIT_TO_WIN;

  return (
    <div className={classes.prizeDrawMainContentContainer}>
      <div
        className={classes.prizeDrawImageContainer}
        style={{
          backgroundImage: `url(${img})`
        }}
      >
        {!isVisitToWin && (
          <img
            alt=""
            src={ImagesMapping[clientName].giftIconAll}
            style={{ height: "100px" }}
          />
        )}
      </div>
      <div className={classes.prizeDrawContent}>
        <div className={classes.prizeDrawContentContainer}>
          <h2 className={classes.prizeDrawText}>
            {!isVisitToWin ? "Congratulations!" : "Great!"}
          </h2>
          <h2 className={classes.prizeDrawText}>
            You've entered this prize draw.
          </h2>
          <div
            className={classes.prizeDrawRedemptionContainer}
            style={{ marginTop: "25px" }}
          >
            {redemptionDetails &&
              redemptionDetails.map(
                (details: RedemptionDetail, index: number) => (
                  <Fragment key={index.toString()}>
                    <RedemptionGuidelines
                      guidelines={details.guidelines}
                      isLightTheme={true}
                    />
                  </Fragment>
                )
              )}
          </div>
        </div>
        <div className={classes.prizeDrawButton}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.buttonClass}
            onClick={onClose}
          >
            <Typography color="primary" className={classes.buttonText}>
              Close
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PrizeDrawRedemption;
