import { graphQlCall, GQLConstants } from "../Utils/GraphQLUtils";
import { GQLConstantMapping } from "Configurations/ConstantsMapping";
import { getClientName } from "Utils/GeneralUtils";
import FavouriteOffers, {
  IFavouriteOffers,
  FavouriteOffer
} from "../Models/ReduxModels/FavouriteOffersModel";
import { EVENT_TYPE, logEvents } from "./EventService";
import SortedFavouriteOfferIds, {
  OfferIds
} from "Models/ReduxModels/SortedFavouriteOfferIds";

export const addFavouriteOffers = async (
  variable: any,
  favorites: IFavouriteOffers,
  favouriteOfferIds: OfferIds
) => {
  return new Promise((resolve, reject) => {
    graphQlCall(
      GQLConstants.MUTATION,
      GQLConstantMapping[getClientName()].mutations.ADD_FAVORITE,
      variable
    )
      ?.then((response: any) => {
        let offerDetails = response?.data?.addFavourite;
        resolve({
          add: response?.data?.addFavourite?.favouriteOfferId && true
        });
        if (offerDetails) {
          let favouriteOffers: IFavouriteOffers = {};
          let sortedFavouriteOfferIds: number[] = favouriteOfferIds?.offerIds;
          let favouriteOffer: FavouriteOffer = {
            offerId: offerDetails.favouriteOfferId.offerId,
            createdOn: offerDetails?.createdOn
          };
          favouriteOffers[offerDetails?.favouriteOfferId?.offerId] =
            favouriteOffer;

          if (sortedFavouriteOfferIds) {
            sortedFavouriteOfferIds.splice(
              0,
              0,
              offerDetails.favouriteOfferId.offerId
            );
          }
          let favOffers: IFavouriteOffers = {};
          if (favorites && favouriteOffers) {
            favOffers = Object.assign(favorites, favouriteOffers);
          }
          new FavouriteOffers({
            id: "",
            ...favOffers
          }).$save();
          new SortedFavouriteOfferIds({
            id: "",
            offerIds: sortedFavouriteOfferIds
          }).$save();
          logEvents({
            eventType: EVENT_TYPE.ADD_FAVOURITE,
            offerId: offerDetails.favouriteOfferId.offerId
          });
        }
      })
      .catch(error => {
        console.log("error==>", error);
        reject(false);
      });
  });
};
