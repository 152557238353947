import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "../Routes/Routes";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import CustomSnackBar from "./SnackBar/CustomSnackBar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageGrid: {
      [theme.breakpoints.down("sm")]: {
        display: "none"
      },
      [theme.breakpoints.down("xs")]: {
        display: "none"
      },
      height: "100vh"
    }
  })
);

interface IProps {
  clientName: string;
}

const Layout: React.FC<IProps> = props => {
  const classes = useStyles();
  return (
    <div>
      <BrowserRouter>
        <Routes clientName={props.clientName} />
      </BrowserRouter>
      {/* <Grid
          item
          xs={12}
          sm={12}
          xl={12}
          lg={12}
          md={4}
          style={{ overflow: "hidden" }}
        >
        </Grid> */}
      {/* <Grid className={classes.imageGrid} item xl={8} lg={8} md={8}>
          <img
            alt="layout_bg_img"
            width="100%"
            height="100%"
            src={layoutBackgroundImg}
          />
        </Grid> */}
      <CustomSnackBar />
    </div>
  );
};

export default Layout;
