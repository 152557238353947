import { BaseModel } from "ReduxStore/index";

export interface CarouselItem {
  label: string;
  offerId: number | null;
  collectionCardId: number | null;
}
export interface ICarousel {
  carousel: CarouselItem[];
}
export default class Carousel extends BaseModel<ICarousel> {
  static resource = "Carousel";
}
