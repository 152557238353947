import React, { useEffect } from "react";
import { ClientModel } from "../../../Models/ReduxModels/ClientDetails";
import UserProfile from "../../../Models/ReduxModels/UserProfileModel";
import { connect } from "react-redux";
import NewJourneyFramework from "../newJourneyFramework";
import { ImagesMapping } from "../../../Configurations/ImagesMapping";
import { getClientName } from "Utils/GeneralUtils";
import { OnBoardingConstants } from "../constants";
import { logEvents } from "../../../Services/EventService";
import { EVENT_TYPE } from "Services/EventService";
import { pushAllEventsToServer } from "Services/EventService";
import { getNextStartBoardingEvent } from "../../../Utils/GeneralUtils";

//Network
interface IProps {
  history: History;
  clientData: any;
  userProfileData: any;
  currentOnboardingPage: number;
  totalOnboardingPages: Array<String>;
}
const Traffic: React.FC<IProps> = props => {
  let clientName = getClientName();

  const { userProfileData, currentOnboardingPage, totalOnboardingPages } =
    props;

  const handlePermissionClick = (e, type) => {
    let permissionconsent = false;
    if (type === "ok") {
      permissionconsent = true;
    }
    new UserProfile({
      id: "local",
      ...userProfileData,
      isFirstUse: userProfileData?.isFirstUse,
      dob: userProfileData?.dob,
      interests: userProfileData?.interests,
      permissions: {
        ...userProfileData?.permissions,
        traffic: permissionconsent
      },
      currentOnboardingPage: currentOnboardingPage + 1
    }).$save();

    nextPage(permissionconsent);
  };
  const nextPage = permissionOpted => {
    logEvents({ eventType: EVENT_TYPE.NEXT_PAGE_ONBOARDING });
    logEvents({
      eventType: EVENT_TYPE.ONBOARDING_TRAFFIC_PERMISSION_EXITED,
      traffic: permissionOpted
    });
    const nextStartEvent = getNextStartBoardingEvent(
      currentOnboardingPage,
      totalOnboardingPages
    );
    if (nextStartEvent) {
      logEvents({ eventType: nextStartEvent });
    }

    pushAllEventsToServer();
  };
  return (
    <>
      <NewJourneyFramework
        heading={OnBoardingConstants.NETWORK_HEADING}
        subHeading={OnBoardingConstants.NETWORK_SUBHEADING}
        handlePermissionClick={handlePermissionClick}
        buttonOkText="Allow"
        onboardingImage={ImagesMapping[clientName].newOnboardingNetworkImage}
        imageAltText="networkHeroImg"
        buttonCancelText="Not now"
        showVodafoneIcon={true}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  const clientData = ClientModel.getInsatnce("", state)?.props;
  const userProfileData = UserProfile.getInsatnce("local", state)?.props;
  const currentOnboardingPage = userProfileData.currentOnboardingPage;
  const totalOnboardingPages = userProfileData?.totalOnboardingPages;

  return {
    clientData,
    userProfileData,
    currentOnboardingPage,
    totalOnboardingPages
  };
};

export default connect(mapStateToProps)(Traffic);
