import { BaseModel } from "ReduxStore/index";

export interface ISettings {
  clientDetails: IClientConfig;
}

export interface IClientConfig {
  apiEndPoint: string;
  eventServiceEndPoint: string;
  tenantId: string;
  rulaiBotId: string | null;
  payG: string | null;
  eatLocal: string | null;
  coffeeClub: string | null;
  apiTenantName: string;
  zendeskKey: string | null;
  setTealiumTagging: boolean | null;
  tealiumUrl: string | null;
  utagUrl: string | null;
}

export class Settings extends BaseModel<ISettings> {
  static resource = "Settings";
}
