//@ts-nocheck
import { ImagesMapping } from "Configurations/ImagesMapping";
import { getClientName } from "Utils/GeneralUtils";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      height: "100vh",
      display: "flex",
      overflow:'auto',
      flexDirection: "column",
      backgroundImage: `url(${
        ImagesMapping[getClientName()].newOnboardingGradientBg
      })`
    },
    heroImageWrapper: {
      width: "100%"
    },
    vodafoneIconWrapper: {
      position: "absolute",
      top: 50,
      paddingLeft:"20px"
    },
    content: {
      display: "flex",
      flexDirection: "column",
      margin: "10px 24px",
      flex: 1,
      color: "#fff",
      fontFamily: "VodafoneRg"
    },
    heading: {
      fontSize: "32px",
      textAlign: "center",
      margin: "0px",
      fontWeight: 100,
      "@media (min-width:320px) and (min-height:568px)": {
        fontSize: "24px",
        margin: "10px 0px"
      },
      "@media (min-width:360px) and (min-height:740px) ": {
        fontSize: "32px",
        margin: "10px 0px"
      },
      "@media (min-width:375px)": {
        fontSize: "28px",
        margin: "10px 0px"
      },
      "@media (min-width:375px) and (min-height:810px)": {
        fontSize: "38px",
        margin: "10px 0px"
      },
      "@media (min-width:390px)": {
        fontSize: "40px",
        margin: "10px 0px"
      },
      "@media (min-width:414px) and (min-height:890px)": {
        fontSize: "42px",
        margin: "18px 0px"
      },
      "@media (min-width:414px) and (min-height:736px)": {
        fontSize: "32px",
        margin: "8px 0px"
      },
      "@media (min-width:760px) and (min-height:1020px)": {
        fontSize: "42px",
        margin: "10px 0px"
      }
    },
    subHeading: {
      fontSize: "18px",
      textAlign: "center",
      "@media (min-width:320px) and (min-height:568px)": {
        fontSize: "14px"
      },
      "@media (min-width:360px) and (min-height:740px) ": {
        fontSize: "18px"
      },
      "@media (min-width:375px)": {
        fontSize: "16px"
      },
      "@media (min-width:375px) and (min-height:810px)": {
        fontSize: "20px"
      },
      "@media (min-width:390px)": {
        fontSize: "22px"
      },
      "@media (min-width:414px) and (min-height:890px)": {
        fontSize: "22px"
      },
      "@media (min-width:414px) and (min-height:736px)": {
        fontSize: "18px"
      },
      "@media (min-width:760px) and (min-height:1020px)": {
        fontSize: "22px"
      }
    },
    buttonContainer: {
      flex: "1",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      paddingBottom: "8px"
    },
    buttonOk: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      height: "42px",
      textTransform: "none",
      backgroundColor: "#fff",
      color: "#000",
      fontFamily: "VodafoneRg",
      "@media (min-width:320px) and (min-height:568px)": {
        height: "30px",
        "& p": {
          fontSize: "14px !important"
        }
      },
      "@media (min-width:360px) and (min-height:740px) ": {
        height: "48px",
        "& p": {
          fontSize: "18px !important"
        }
      },
      "@media (min-width:375px)": {
        height: "34px",
        "& p": {
          fontSize: "18px !important"
        }
      },
      "@media (min-width:375px) and (min-height:810px)": {
        height: "38px",
        "& p": {
          fontSize: "18px !important"
        }
      },
      "@media (min-width:390px)": {
        fontSize: "20px"
      },
      "@media (min-width:414px)": {
        height: "46px",
        "& p": {
          fontSize: "20px !important"
        }
      },
      "@media (min-width:414px) and (min-height:736px)": {
        height: "36px",
        "& p": {
          fontSize: "18px !important"
        }
      },
      "@media (min-width:760px) and (min-height:1020px)": {
        height: "46px",
        "& p": {
          fontSize: "20px !important"
        }
      },
      "& p": {
        fontSize: "20px"
      }
    },
    cancelPermissionText: {
      "@media (min-width:320px) and (min-height:568px)": {
        "& p": {
          fontSize: "14px !important"
        }
      },
      "@media (min-width:360px) and (min-height:740px) ": {
        "& p": {
          fontSize: "18px !important"
        }
      },
      "@media (min-width:375px)": {
        "& p": {
          fontSize: "18px !important"
        }
      },
      "@media (min-width:375px) and (min-height:810px)": {
        "& p": {
          fontSize: "18px !important"
        }
      },
      "@media (min-width:390px)": {
        "& p": {
          fontSize: "20px !important"
        }
      },
      "@media (min-width:414px)": {
        "& p": {
          fontSize: "20px !important"
        }
      },
      "@media (min-width:414px) and (min-height:736px)": {
        "& p": {
          fontSize: "18px !important"
        }
      },
      "@media (min-width:760px) and (min-height:1020px)": {
        "& p": {
          fontSize: "20px !important"
        }
      },
      "& p": {
        fontSize: "20px",
        textAlign: "center",
        padding: "10px 0px "
      }
    }
  })
);
