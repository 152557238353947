import React, { Fragment, useEffect, useState } from "react";
import {
  getClientName,
  getImageDetails,
  isEmptyObject,
  loadEvents,
  previousPages
} from "Utils/GeneralUtils";
import { Header } from "gce-cxinteracts-ui-components-library";
import ApplicationFlag from "Models/ReduxModels/ApplicationFlag";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useStyles } from "./styles";
import discountIcon from "Configurations/Clients/vodafone/Assets/RestoLocalBlendImages/icon-discount-grey.png";
import availabilityIcon from "Configurations/Clients/vodafone/Assets/RestoLocalBlendImages/icon-availability-grey.png";
import cuisineIcon from "Configurations/Clients/vodafone/Assets/RestoLocalBlendImages/icon-cuisine-grey.png";
import { Button, Divider } from "@material-ui/core";
import RestoLocalBFilterModal, {
  IRestoLocalBlendFilter
} from "Models/ReduxModels/RestoLocalBlendFilters";
import RestaurantsList, {
  IList
} from "Models/ReduxModels/RestaurantCoffeeModel";
import FilteredRestaurants from "Models/ReduxModels/FilteredRestaurants";
import {
  EVENT_TYPE,
  logEvents,
  pushAllEventsToServer
} from "Services/EventService";

interface IProps {
  applicationFlags: any;
  filterDetails: IRestoLocalBlendFilter;
  restaurantDtails: IList;
}

const eatLocal = "Eat Local";

const CustomFilters: React.FC<IProps> = props => {
  const { applicationFlags, filterDetails, restaurantDtails } = props;
  const clientName = getClientName();
  const history = useHistory();
  let classes = useStyles();
  let updatedList: any = [];

  if (!isEmptyObject(restaurantDtails)) {
    let newList = Object.assign(restaurantDtails);
    Object.entries(newList).map(([key, value]) => {
      let val = value;
      if (val !== "") {
        updatedList.push(val);
      }
    });
  }
  const [restoList, setRestoList] = useState(updatedList);
  const [clearAllButton, setClearAllButton] = useState(false);
  const [restoCount, setRestoCount] = useState(0);

  useEffect(() => {
    if (!filterDetails) {
      setClearAllButton(false);
    } else if (
      filterDetails?.availability !== "" ||
      filterDetails?.offerType?.length ||
      filterDetails?.cuisine !== ""
    ) {
      setClearAllButton(true);
    } else {
      setClearAllButton(false);
    }
  }, [filterDetails]);

  useEffect(() => {
    let offers = filterDetails?.offerType || [];
    let availabilityDay = filterDetails?.availability?.split(",") || "";
    let cuisines = filterDetails?.cuisine?.split(",") || "";
    // for counting the no. of restaurants seperately fetch each filter results then combine them
    let offerFilter: any = [];
    restoList.forEach((item: any) => {
      let data = item["offers"][0];
      if (offers.includes(data.name)) {
        offerFilter.push(item.id);
      }
    });

    let availFilter: any = [];
    restoList.forEach((item: any) => {
      let data = item["offers"][0];
      if (availabilityDay?.length) {
        let keys = Object.keys(data?.availability);
        keys.forEach(ele => {
          let cap = ele[0].toUpperCase() + ele.slice(1).toLowerCase();
          if (
            availabilityDay.includes(cap) &&
            data.availability[ele].day === true
          ) {
            availFilter.push(item.id);
          }
        });
      }
    });

    let cuisineFilter: any = [];
    restoList.forEach((item: any) => {
      if (cuisines?.length) {
        item.cuisines.forEach(cuisine => {
          if (cuisines.includes(cuisine.name)) {
            cuisineFilter.push(item.id);
          }
        });
      }
    });

    const combinedArray = offerFilter.concat(availFilter, cuisineFilter);
    let finalRestaurants = [];
    //remove repeated occurences of restaurants
    if (combinedArray.length) {
      finalRestaurants = combinedArray.filter(
        (item, index) => combinedArray.indexOf(item) === index
      );
    }
    if (finalRestaurants.length) {
      setRestoCount(finalRestaurants.length);
    }
  }, []);

  const redirectToHome = () => {
    setTimeout(
      () => {
        new RestoLocalBFilterModal({
          id: "",
          offerType: [],
          cuisine: "",
          availability: "",
          previousFilters: {}
        }).$save();
        let list = {};
        new FilteredRestaurants({
          id: "",
          ...list
        }).$save();
        new ApplicationFlag({
          id: "",
          ...applicationFlags,
          isShowFooter: true,
          isRedirectedFromGiftingSuccessPage: false,
          eatLocalCoffeClubID: 0,
          eatLocalCoffeClubOffer: {},
          selectedShopOrResto: {}
        }).$save();
        history && history.push("/home");
      },

      500
    );
  };

  const updateRestoList = (filters) => {
    let newRestoList = {};
    let offers: any = filters?.offerType || [];
    let availabilityDay = filters?.availability?.split(",") || "";
    let cuisines = filters?.cuisine?.split(",") || "";
    restoList.map((item: any) => {
      let data = item["offers"][0];
      if (offers.includes(data.name)) {
        newRestoList[item.id] = item;
      }
      if (availabilityDay.length) {
        let keys = Object.keys(data.availability);
        keys.forEach(ele => {
          let cap = ele[0].toUpperCase() + ele.slice(1).toLowerCase();
          if (
            availabilityDay.includes(cap) &&
            data.availability[ele].day === true
          ) {
            newRestoList[item.id] = item;
          }
        });
      }

      if (cuisines.length) {
        item.cuisines.forEach(cuisine => {
          if (cuisines.includes(cuisine.name)) {
            newRestoList[item.id] = item;
          }
        });
      }
    });

    logEvents({
      ...{
        offerTypeFilters: offers?.join(",") || "",
        availabilityFilters: filters?.availability,
        cuisineFilters: filters?.cuisine
      },
      eventType: EVENT_TYPE.APPLIED_FILTERS,
      offerId: applicationFlags?.eatLocalCoffeClubID
    });

    loadAdobeTealiumEvent({
      offerTypeFilters: offers?.join(",") || "",
      availabilityFilters: filters?.availability,
      cuisineFilters: filters?.cuisine
    });

    pushAllEventsToServer();

    new FilteredRestaurants({
      id: "",
      ...newRestoList
    }).$save();
  }

  const goBack = () => {
    setTimeout(() => {
      updateRestoList(filterDetails?.previousFilters)
      new RestoLocalBFilterModal({
        id: "",
        ...filterDetails,
        offerType: filterDetails?.previousFilters?.offerType,
        cuisine: filterDetails?.previousFilters?.cuisine,
        availability: filterDetails?.previousFilters?.availability,
      }).$save();
      history.push("/search-custom-list");
    }, 500);
  };

  let data = {
    discount: {
      text: "Offer type",
      icon: discountIcon
    },
    availability: {
      text: "Availability",
      icon: availabilityIcon
    },
    cuisine: {
      text: "Cuisine",
      icon: cuisineIcon
    }
  };

  const handleFilterClick = (e, route) => {
    history.push(route);
  };

  const handleClearButton = e => {
    new RestoLocalBFilterModal({
      id: "",
      offerType: [],
      cuisine: "",
      availability: "",
      previousFilters: {}
    }).$save();
    let list = {};
    new FilteredRestaurants({
      id: "",
      ...list
    }).$save();
    setClearAllButton(false);
    setRestoCount(0);
  };

  const loadAdobeTealiumEvent = data => {
    let selectedFilters = "";
    if (data?.offerTypeFilters) {
      selectedFilters += " OfferType";
    }
    if (data?.availabilityFilters) {
      selectedFilters += " Availability";
    }

    if (data?.cuisineFilters) {
      selectedFilters += " Cuisines";
    }

    let type =
      applicationFlags?.eatLocalCoffeClubOffer?.addType === eatLocal
        ? "eat local"
        : "coffee club";

    const state = {
      onLoadParams: {
        show_view: false,
        event_name: "veryme_restaurants_filter_interaction",
        event_action: null,
        event_label: "selected filter -" + selectedFilters,
        page_name: `uk>app>veryme rewards>home>${type} search>filters`,
        same_page_event: false
      }
    };
    loadEvents(previousPages, { state });
  };

  const handleResultButton = e => {
    new RestoLocalBFilterModal({
      id: "",
      ...filterDetails,
      previousFilters: {
        availability: filterDetails?.availability,
        cuisine: filterDetails?.cuisine,
        offerType: filterDetails?.offerType
      }
    }).$save();
    updateRestoList(filterDetails)
    history.push("/search-custom-list");
  };

  const getFilter = (filter, item, index, filterType) => {
    let n: any = filterType == "offerType" ? filter : filter.split(",");
    let text = "";
    if (n.length - 1 === index) {
      text = item;
    } else {
      text = `${item}, `;
    }
    return text;
  };

  return (
    <Fragment>
      <Header
        headerText=""
        isHeaderInverted={true}
        svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
        clientLogoPrimary={getImageDetails(
          ImagesMapping[clientName].clientLogoPrimary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        clientLogoSecondary={getImageDetails(
          ImagesMapping[clientName].clientLogoSecondary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        onSvgSelection={goBack}
        onLogoSelection={redirectToHome}
        isScrollHeader={false}
      />
      <div className={classes.filterContainer}>
        <div className={classes.filterMainText}>
          <p>Filters</p>
          {/* <img src={} alt="close"/> */}
        </div>
        <Divider />
        <div
          className={classes.filterWwrapper}
          onClick={e => {
            handleFilterClick(e, "/offertype-filter");
          }}
        >
          <img
            style={{ height: "22px", width: "22px" }}
            src={data["discount"].icon}
            alt="discountImg"
          />
          <div className={classes.filtersContent}>
            <p>{data["discount"].text}</p>
            {filterDetails?.offerType?.length ? (
              <span>
                {filterDetails?.offerType?.map((item, index) => {
                  return getFilter(
                    filterDetails?.offerType,
                    item,
                    index,
                    "offerType"
                  );
                })}
              </span>
            ) : (
              ""
            )}
          </div>
          <img
            src={ImagesMapping[clientName].rightArrow}
            alt="right-arrow"
            className={classes.filterArrow}
          />
        </div>

        <Divider />
        <div
          className={classes.filterWwrapper}
          onClick={e => {
            handleFilterClick(e, "/avail-filter");
          }}
        >
          <img src={data["availability"].icon} alt="availabilityImg" />
          <div className={classes.filtersContent}>
            <p>{data["availability"].text}</p>
            {filterDetails?.availability !== "" ? (
              <span>
                {filterDetails?.availability?.split(",").map((item, index) => {
                  return getFilter(
                    filterDetails?.availability,
                    item,
                    index,
                    "availability"
                  );
                })}
              </span>
            ) : (
              ""
            )}
          </div>
          <img
            src={ImagesMapping[clientName].rightArrow}
            alt="right-arrow"
            className={classes.filterArrow}
          />
        </div>
        <Divider />
        {applicationFlags?.eatLocalCoffeClubOffer?.addType === eatLocal ? (
          <>
            <div
              className={classes.filterWwrapper}
              onClick={e => {
                handleFilterClick(e, "/cuisine-filter");
              }}
            >
              <img src={data["cuisine"].icon} alt="cuisineImg" />
              <div className={classes.filtersContent}>
                <p>{data["cuisine"].text}</p>
                {filterDetails?.cuisine !== "" ? (
                  <span>
                    {filterDetails?.cuisine?.split(",").map((item, index) => {
                      return getFilter(
                        filterDetails?.cuisine,
                        item,
                        index,
                        "cuisine"
                      );
                    })}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <img
                src={ImagesMapping[clientName].rightArrow}
                alt="right-arrow"
                className={classes.filterArrow}
              />
            </div>
            <Divider />{" "}
          </>
        ) : (
          ""
        )}
        <div className={classes.buttonContainer}>
          {clearAllButton && (
            <Button
              variant="outlined"
              color="primary"
              className={classes.clearButtonStyle}
              onClick={handleClearButton}
            >
              Clear all
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonStyle}
            onClick={handleResultButton}
          >
            {`View ${restoCount > 0 ? restoCount : ""} results`}
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  let applicationFlags = ApplicationFlag.getInsatnce("", state).props;
  let filterDetails = RestoLocalBFilterModal.getInsatnce("", state)?.props;
  let restaurantDtails = RestaurantsList.getInsatnce("", state).props;

  return {
    applicationFlags,
    filterDetails,
    restaurantDtails
  };
};

export default connect(mapStateToProps)(CustomFilters);
