import React, { useState, useEffect } from "react";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import { Provider, connect } from "react-redux";
import { ThemeMapping } from "./Configurations/ThemeMapping";
import Layout from "./Components/Layout";
import { updateInitialState } from "Services/FetchAllService";
import { store } from "ReduxStore/index";
import  SplashScreen  from "Components/SplashScreen/SplashScreen";
import "./App.css";
import { EVENT_TYPE, pushEventsToServer } from "Services/EventService";
import {
  convertLocalDateToISO,
  getCurrentTime,
  setRulAIOps
} from "Utils/GeneralUtils";
import ErrorBoundary from "./ErrorBoundary";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { getClientName } from "Utils/GeneralUtils";
import ScriptTag from "react-script-tag";
import { Settings, ISettings } from "Models/ReduxModels/Settings";

declare global {
  interface Window {
    utag: any;
    utag_data: any;
  }
}

const App: React.FC<any> = props => { 
  const path = window.location.pathname.split("/")[1]; 

  const [config, setConfig] = useState<any>({
    loading: true,
    isError: false,
    clientName: getClientName()
  });

  const settings = Settings.getInsatnce("")?.props;
  const { loading, clientName } = config;

  const customStore: any = store;
  const defaultRulaiUrl = "https://demo.rul.ai/wd.js";

  // get client details and fetch-all call before loading app
  useEffect(() => {
    //setting clientName initially to know which loader component to load
    let clientName = getClientName();
    const favicon: any = document.getElementById("favicon");
    favicon.href = ImagesMapping[clientName].clientFavicon;
    setConfig({ loading: true, isError: false, clientName: clientName });
    getConfigAsync();
  }, []);

  async function getConfigAsync() {
    try {
      const response: any = await updateInitialState();
      setConfig({ loading: false, isError: false, clientName: clientName });
      setRulAIOps();
      if (response?.data?.errors) {
        throw new Error(response?.data?.errors.toString());
      }
    } catch (err:any) {
      console.error(err, "APP_ERROR");
      setRulAIOps();
      let eventsArray: any[] = [];
      let payload = {};
      if (err && err.length && err[0].extensions) {
        payload = {
          error: err[0].extensions.error,
          errorCode: err[0].extensions.errorCode.toString(),
          timestamp: err[0].extensions.timestamp
        };
      }
      const eventMetaData = {
        eventType:
          err && err.length && err[0].extensions?.errorCode === 403
            ? EVENT_TYPE.REJECTED
            : EVENT_TYPE.API_ERROR,
        timestamp: convertLocalDateToISO(getCurrentTime()),
        session_id: "",
        offerId: "",
        eventPayload: payload
      };
      eventsArray.push({ ...eventMetaData });
      if (err && err.length && err[0].message.includes("payg-not-topped-up")) {
        window.history.pushState("", "Error", "/prepaid-error");
        pushEventsToServer(eventsArray);
      } 
      else {
        window.history.replaceState("", "Error", "/error-500");
        pushEventsToServer(eventsArray);
      }
      setConfig({ loading: false, isError: true, clientName: clientName });
    }
  }

  const renderApp = () => {
    if (!loading) {
      return (
       <>
          <Layout clientName={clientName} />
          {path != "mantainence-page" && 
          <ScriptTag
            async={true}
            type="text/javascript"
            src={settings?.clientDetails?.rulaiScriptUrl || defaultRulaiUrl}
          />
          }
      </>
      );
    } else {
      return <SplashScreen />;
    }
  };

  return (
    <MuiThemeProvider theme={ThemeMapping[clientName]}>
      <CssBaseline />
      <Provider store={customStore}>
        {renderApp()}
      </Provider>
    </MuiThemeProvider>
  );
};

export default App;
