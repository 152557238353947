import { BaseModel } from "ReduxStore/index";

export interface Faqdetails {
  id: string;
  name: string;
  value: string;
  type: string | any;
  lastUpdated: string | any;
}

export interface IFaq {
  name: string;
  description: string | null;
  configurationDataList: Faqdetails[];
}

export default class Faq extends BaseModel<IFaq> {
  static resource = "Faq";
}
