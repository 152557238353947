import React, { useEffect } from "react";
import { Header } from "gce-cxinteracts-ui-components-library";
import { useHistory } from "react-router-dom";

import { ConstantMapping } from "Configurations/ConstantsMapping";
import { getClientName, getImageDetails } from "Utils/GeneralUtils";
import { pushAllEventsToServer } from "Services/EventService";
import { useStyles } from "../styles";
import { ImagesMapping } from "Configurations/ImagesMapping";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";

const MaintenanceErrorPage: React.FC = () => {
  const classes = useStyles(); 
  const clientName = getClientName(); 

  useEffect(() => {
    pushAllEventsToServer();
  }, []);

  return (
    <div className={classes.container}>
      <div style={{ zIndex: 5, top: "6px", position: "sticky" }}>
        <Header
          headerText="My Vodafone"
          svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
          clientLogoPrimary={getImageDetails(
            ImagesMapping[clientName].clientLogoPrimary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          clientLogoSecondary={getImageDetails(
            ImagesMapping[clientName].clientErrorPageLogo,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          onSvgSelection={() =>
            window.open(ConstantMapping[clientName].ERROR_PAGE_BACK_URL)
          }
          onLogoSelection={() =>
            window.open(ConstantMapping[clientName].ERROR_PAGE_BACK_URL)
          }
          className={classes.header}
        />
      </div>
      <div className={classes.errorMessage}>
        <div>
          <h3>{ConstantMapping[clientName].MAINTENANCE_PAGE_MESSAGE.LINE1}</h3>
          <p>{ConstantMapping[clientName].MAINTENANCE_PAGE_MESSAGE.LINE2}</p>
          <span>{ConstantMapping[clientName].MAINTENANCE_PAGE_MESSAGE.LINE3}</span>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceErrorPage;
