import Axios from "axios";
import { ClientModel } from "Models/ReduxModels/ClientDetails";
import { Settings } from "Models/ReduxModels/Settings";

class HttpClient {
  fetchHeaders() {
    const clientDetails = ClientModel.getInsatnce("")?.props;
    const settings = Settings.getInsatnce("")?.props;
    const headers = {
      Authorization: `Bearer ${clientDetails?.token}`,
      tenantId: settings?.clientDetails?.tenantId,
      sessionId: localStorage
        ? localStorage.getItem("session-id")
        : clientDetails.sessionId
    };
    return { headers: headers };
  }

  get(url, config = {}) {
    return Axios.get(url, { ...config, ...this.fetchHeaders() });
  }

  getWithoutHeaders(url, config = {}) {
    return Axios.get(url, { ...config });
  }

  post(url, data, config = {}) {
    return Axios.post(url, data, {
      ...config,
      ...this.fetchHeaders()
    });
  }
}

export default HttpClient;
