//@ts-nocheck
import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minWidth: "100vw",
      fontSize: 24,
      height: "100vh",
      overflowX: "auto",
      overflowY: "auto"
    },
    header: {
      "& $span": {
        color: `${theme.palette.text.secondary} !important`
      },
      position: "fixed"
    },
    errorMessage: {
      padding: "5vh 10vw",
      "& $p:first-child": {
        margin: "0px"
      }
    },
    apiErrorMsg: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "calc(100vh - 45px)",
      fontSize: 24
    },
    buttonWrapper: {
      display: "flex",
      flexDirection: "column",
      flex: "0 0 17%"
    },
    retry: {
      borderRadius: theme.spacing(2.3),
      padding: theme.spacing(0, 4.5),
      fontWeight: theme.typography.fontWeightBold,
      display: "flex",
      fontSize: theme.typography.h6.fontSize,
      minHeight: theme.spacing(12),
      alignItems: "center",
      outline: "none",
      border: "none",
      textTransform: "none",
      width: "100%",
      boxShadow: "0px 5px 20px 0px rgba(0, 176, 202, 0.3)",
      "& .MuiButton-label": {
        margin: "0px 16px"
      }
    },
    textButtonOff: {
      color: theme.palette.primary.main,
      textTransform: "none",
      padding: theme.spacing(0),
      fontSize: theme.typography.h6.fontSize,
      marginTop: theme.spacing(4.5),
      lineHeight: "1",
      "& .MuiButton-label": {
        display: "flex",
        justifyContent: "center"
      }
    },
    iframeContainer: {
      overflow: "auto",
      height: "calc(100vh - 45px)",
      position: "relative",
      width: "100%"
    },
    iframe: {
      width: "100%",
      border: "none",
      margin: "0",
      padding: "0",
      overflow: "hidden",
      zIndex: 99999,
      height: "100%",
      position: "absolute"
    },
    waitLoader: {
      marginTop: theme.spacing(-11.25),
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex : 9999
    }
  })
);
