import { ConstantMapping } from "Configurations/ConstantsMapping";
import { ImagesMapping } from "Configurations/ImagesMapping";
import ApplicationFlag from "Models/ReduxModels/ApplicationFlag";
import { TextField, Typography } from "@material-ui/core";
import {
  getClientName,
  getImageDetails,
  isEmptyObject,
  loadEvents,
  previousPages
} from "Utils/GeneralUtils";
import { AnimationHelper, Header } from "gce-cxinteracts-ui-components-library";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SearchResultListCard from "./Cards/SearchResultListCard";
import SearchResultGridCard from "./Cards/SearchResultGridCard";
import { Button } from "@material-ui/core";
import ListIcon from "Configurations/Clients/vodafone/Assets/Images/icon-list-grey.png";
import GridIcon from "Configurations/Clients/vodafone/Assets/Images/icon-grid-grey.png";
import filterIcon from "Configurations/Clients/vodafone/Assets/Images/icon-filter-grey.png";
import { useStyles } from "./styles";
import EatLocalFooter from "./Footer/EatLocalFooter";
import searchIcon from "Configurations/Clients/vodafone/Assets/RestoLocalBlendImages/icon-search-grey.png";
import RestaurantsList, {
  IList
} from "Models/ReduxModels/RestaurantCoffeeModel";
import { connect } from "react-redux";
import RestoLocalBFilterModal, {
  IRestoLocalBlendFilter
} from "Models/ReduxModels/RestoLocalBlendFilters";
import FilteredRestaurants, {
  IFilteredList
} from "Models/ReduxModels/FilteredRestaurants";
import {
  EVENT_TYPE,
  logEvents,
  pushAllEventsToServer
} from "Services/EventService";
import {
  setRestaurantListInitial,
  titleDefaultStyles,
  titleEnterStyles,
  titleLeaveStyles
} from "./RestaurantUtil";

interface IProps {
  applicationFlags: any;
  restaurantList: IList;
  filterDetails: IRestoLocalBlendFilter;
  filteredRestaurants: IFilteredList;
  location: any;
}
const eatLocal = "Eat Local";

const SearchRestaurant: React.FC<IProps> = (props: IProps) => {
  const {
    applicationFlags,
    restaurantList,
    filterDetails,
    filteredRestaurants
  } = props;
  const history = useHistory();
  let classes = useStyles();
  const clientName = getClientName();
  const [viewType, setViewType] = useState(
    localStorage.getItem("viewType") || "list"
  );
  const [restoList, setRestaurantList] = useState([]);
  const [filtersLength, setFiltersLength] = useState(0);
  const [showEnterAnime, setShowEnterAnime] = useState(false);
  const [showLeaveAnime, setShowLeaveAnime] = useState(false);

  useEffect(() => {
    let state = props?.location?.state?.onLoadParam;
    if (state) {
      loadEvents(previousPages, { state });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("viewType", viewType); // Update local storage
  }, [viewType]);

  useEffect(() => {
    setShowEnterAnime(true);
    let offer = applicationFlags?.eatLocalCoffeClubOffer;

    if (offer?.addType === eatLocal) {
      logEvents({
        eventType: EVENT_TYPE.EAT_LOCAL_RESTAURANT_LIST,
        offerId: offer?.offerId
      });
    } else {
      logEvents({
        eventType: EVENT_TYPE.LOCAL_BLEND_RESTAURANT_LIST,
        offerId: offer?.offerId
      });
    }
    pushAllEventsToServer();
  }, []);

  useEffect(() => {
    let count;
    if (!filterDetails) {
      count = 0;
    } else {
      count =
        (filterDetails?.availability ? 1 : 0) +
        (filterDetails?.offerType?.length ? 1 : 0) +
        (filterDetails?.cuisine ? 1 : 0);
    }
    setFiltersLength(count);
  }, [filterDetails]);

  const isNullObject = obj => {
    return Object.keys(obj).length === 1 && obj.id === "";
  };

  useEffect(() => {
    if (!isEmptyObject(filteredRestaurants)) {
      let checkFilteredObj = Object.keys(filteredRestaurants);
      if (checkFilteredObj.length > 1) {
        let modifiedList = getRestoList(filteredRestaurants);
        setRestaurantList(modifiedList);
      } else {
        if (!isEmptyObject(restaurantList)) {
          if (isNullObject(filteredRestaurants) && filtersLength > 0) {
            setRestaurantList([]);
          } else {
            let modifiedList = getRestoList(restaurantList);
            setRestaurantList(modifiedList);
          }
        }
      }
    } else {
      if (!isEmptyObject(restaurantList)) {
        let modifiedList = getRestoList(restaurantList);
        setRestaurantList(modifiedList);
      }
    }
  }, [filteredRestaurants, filtersLength]);

  const getRestoList = list => {
    let newList = Object.assign(list);
    let updatedList: any = [];
    Object.entries(newList).map(([key, value]) => {
      let val = value;
      if (val !== "") {
        updatedList.push(val);
      }
    });
    return updatedList;
  };

  const redirectToHome = () => {
    setTimeout(() => history && history.push("/home"), 100);
    setTimeout(() => {
      new RestoLocalBFilterModal({
        id: "",
        offerType: [],
        cuisine: "",
        availability: ""
      }).$save();
      new ApplicationFlag({
        id: "",
        ...applicationFlags,
        isShowFooter: true,
        eatLocalCoffeClubID: 0,
        eatLocalCoffeClubOffer: {},
        selectedShopOrResto: {}
      }).$save();
      let list = {};
      new FilteredRestaurants({
        id: "",
        ...list
      }).$save();
      setRestaurantListInitial([]);
    }, 250);
  };

  const goBack = () => {
    setShowLeaveAnime(true);
    setShowEnterAnime(false);
    setTimeout(() => history.push("/search-screen"), 100);

    setTimeout(() => {
      new RestoLocalBFilterModal({
        id: "",
        offerType: [],
        cuisine: "",
        availability: ""
      }).$save();
      let list = {};
      new FilteredRestaurants({
        id: "",
        ...list
      }).$save();
      new ApplicationFlag({
        id: "",
        ...applicationFlags,
        isShowFooter: true,
        isRedirectedFromGiftingSuccessPage: false,
        selectedShopOrResto: {},
        offerId: 0
      }).$save();
      setRestaurantListInitial([]);
    }, 250);
  };

  const handleFilterClick = () => {
    logEvents({
      eventType: EVENT_TYPE.NAVIGATE_FILTERS,
      offerId: applicationFlags?.eatLocalCoffeClubID
    });

    pushAllEventsToServer();

    history.push("/custom-filters");
  };

  const handleSearchClick = () => {
    history.push("/search-screen", { pathFrom: "search-page" });
    setTimeout(() => {
      new RestoLocalBFilterModal({
        id: "",
        offerType: [],
        cuisine: "",
        availability: ""
      }).$save();
      let list = {};
      new FilteredRestaurants({
        id: "",
        ...list
      }).$save();
    }, 250);
  };

  const renderSearch = () => {
    let val = "";
    if (
      !isEmptyObject(
        applicationFlags?.selectedShopOrResto ||
          applicationFlags?.selectedShopOrResto?.locationText
      )
    ) {
      let text = "";
      if (applicationFlags?.selectedShopOrResto?.name) {
        text = applicationFlags?.selectedShopOrResto?.name;
      } else {
        text = applicationFlags?.selectedShopOrResto?.locationText;
      }
      val = `Search  \u2022  ${text}` || "";
    }

    return (
      <div style={{ margin: "0px 5vw" }}>
        <div>
          <TextField
            id={"search"}
            margin="normal"
            placeholder="Search"
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  <img src={searchIcon} alt="search" />
                </React.Fragment>
              )
            }}
            variant="filled"
            fullWidth
            name={applicationFlags?.selectedShopOrResto?.value}
            onClick={handleSearchClick}
            value={val}
            className={classes.searchTextField}
          />
        </div>
        <div className={classes.mapFilterButtonWrapper}>
          <Button
            variant="outlined"
            className={classes.filterButton}
            onClick={handleFilterClick}
          >
            <img
              src={filterIcon}
              alt="filter icon"
              className={classes.mapFilterIcon}
            />
            <strong className={classes.mapFilterButtonText}>
              Filters{" "}
              {filtersLength > 0 ? (
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    paddingLeft: "4px"
                  }}
                >
                  ({filtersLength})
                </span>
              ) : (
                ""
              )}
            </strong>
          </Button>
        </div>
      </div>
    );
  };

  const getListCards = () => {
    if (restoList?.length) {
      return restoList.map((data: any) => (
        <SearchResultListCard
          key={data.brand.name}
          restaurantImage={data?.primaryImage?.href}
          restaurantName={data.name}
          cuisines={data.cuisines}
          infoLabelOffer={data.offers}
          id={data.id}
          offerDetail={data}
          parentOffer={applicationFlags?.eatLocalCoffeClubOffer}
        />
      ));
    }
  };

  const getGridCards = () => {
    if (restoList?.length) {
      return restoList.map((data: any) => (
        <SearchResultGridCard
          key={data?.brand?.name}
          restaurantImage={data?.primaryImage?.href}
          restaurantName={data?.name}
          cuisines={data?.cuisines}
          infoLabelOffer={data?.offers}
          id={data?.id}
          offerDetail={data}
          parentOffer={applicationFlags?.eatLocalCoffeClubOffer}
        />
      ));
    }
  };

  const renderListGridCards = () => {
    return (
      <div className={classes.searchResultsContainer}>
        <div className={classes.searchResultTopContainer}>
          <div>
            <Typography className={classes.totalSearchResults}>
              {restoList?.length ? restoList?.length : 0} results
            </Typography>
          </div>
          <div className={classes.viewSelection}>
            <Button onClick={() => setViewType("list")}>
              <Typography
                className={classes.searchViewButton}
                style={{ opacity: viewType === "list" ? 1 : 0.5 }}
              >
                List
              </Typography>{" "}
              <img
                src={ListIcon}
                style={{ opacity: viewType === "list" ? 1 : 0.5 }}
              />
            </Button>
            <Button onClick={() => setViewType("grid")}>
              <Typography
                className={classes.searchViewButton}
                style={{ opacity: viewType === "grid" ? 1 : 0.5 }}
              >
                Grid
              </Typography>{" "}
              <img
                src={GridIcon}
                style={{ opacity: viewType === "grid" ? 1 : 0.5 }}
              />
            </Button>
          </div>
        </div>
        {viewType === "list" ? (
          getListCards()
        ) : (
          <div className={classes.gridCardContainer}>{getGridCards()}</div>
        )}
      </div>
    );
  };

  return (
    <Fragment>
      <AnimationHelper
        defaultStyles={titleDefaultStyles}
        enterStyles={titleEnterStyles}
        exitStyles={titleLeaveStyles}
        showEnterAnimation={showEnterAnime}
        showExitAnimation={showLeaveAnime}
        enterAnimationDelay={650}
        exitAnimationDelay={100}
        units={{
          marginTop: "px"
        }}
      >
        <Header
          headerText=""
          isHeaderInverted={true}
          svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
          clientLogoPrimary={getImageDetails(
            ImagesMapping[clientName].clientLogoPrimary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          clientLogoSecondary={getImageDetails(
            ImagesMapping[clientName].clientLogoSecondary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          onSvgSelection={goBack}
          onLogoSelection={redirectToHome}
          isScrollHeader={false}
        />
        {renderSearch()}
        {renderListGridCards()}
        <div className={classes.footerContainer}>
          <EatLocalFooter offerId={applicationFlags?.eatLocalCoffeClubID} />
        </div>
      </AnimationHelper>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  let applicationFlags = ApplicationFlag.getInsatnce("", state).props;
  let restaurantList = RestaurantsList.getInsatnce("", state)?.props;
  let filterDetails = RestoLocalBFilterModal.getInsatnce("", state)?.props;
  let filteredRestaurants = FilteredRestaurants.getInsatnce("", state)?.props;

  return {
    applicationFlags,
    restaurantList,
    filterDetails,
    filteredRestaurants
  };
};

export default connect(mapStateToProps)(SearchRestaurant);
