import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      marginRight: theme.spacing(-5),
      // height: "100vh",
      backgroundColor: theme.palette.common.white,
      "@media (max-width : 960px)": {
        marginRight: theme.spacing(0)
      }
    },
    faqContainer:{
      margin: "20px 20px 40px 20px",
      minHeight: "55vh"
    },
    contentWrapper: {
      top: "0",
      width: "100%",
      // height: "100vh",
      backgroundColor: theme.palette.common.white
    },
    content: {
      width: "100%",
      display: "flex",
      flexDirection: "column"
    },
    showFooter: {
      // height: "calc(100vh - 99px)"
    },
    hideFooter: {
      height: "calc(100vh - 45px)"
    },
    divider: {
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    headingWrapper: {
      display: "block",
      flexDirection: "column",
      boxSizing: "border-box",
      margin: `${theme.spacing(9)}px 5vw ${theme.spacing(4.85)}px`
    },
    heading: {
      marginTop: theme.spacing(0),
      flex: 1,
      fontSize: "24px",
      color: theme.palette.secondary.light
    },
    questionWrapper: {
      borderTop: "1px solid rgb(208, 208, 208)",
      flex: 1
    },
    question: {
      color: theme.palette.primary.main,
      borderBottom: "1px solid rgb(208, 208, 208)",
      padding: "12px 5vw",
      cursor: "pointer"
    },
    questionTitle: {
      margin: theme.spacing(0),
      lineHeight: "19px"
    },
    footer: {
      position: "fixed",
      height: "inherit",
      "& .MuiBottomNavigation-root": {
        borderTop: `1px solid ${theme.palette.divider}`
      }
    },
    seeMoreWrapper: {
      display: "flex",
      justifyContent: "space-between",
      padding: "19px 5vw",
      alignItems: "center",
      margin: theme.spacing(2.12, 0),
      color: theme.palette.secondary.light,
      "@media (min-width : 375px) and (min-height:812px)": {
        padding: "28px 5vw",
        marginBottom: "18vw",
        marginTop: "0px",
        lineHeight: "23px"
      }
    },
    seeMore: {
      margin: theme.spacing(0),
      fontSize: "24px"
    },
    arrowLeft: {
      width: "10px"
    },
    chatContainer: {
      marginRight: theme.spacing(-5),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100vh",
      backgroundColor: theme.palette.common.white,
      "@media (max-width : 960px)": {
        marginRight: theme.spacing(0)
      }
    },
    chatHeaderContainer: {
      position: "fixed",
      width: "100%",
      background: "rgb(51,51,51)",
      "& $span": {
        color: `${theme.palette.text.secondary} !important`
      },
      zIndex: 1000000 ,
    },
    chatBodyContent:{
      height: "50px",
    },
    chatZendeskContainer: {
      width: '100%', // or any desired width
      position: 'absolute',
      top: '70px', // adjust this value based on the height of your header
    },
    headerBackground: {
      backgroundColor:"rgb(51,51,51) !important" 
    },
    chatInputContainer: {
      position: "fixed",
      bottom: 0,
      margin: "20px",
      background: "rgb(255, 255, 255)",
      width: "calc(100% - 40px)"
    },
    chatInput: {
      height: "48px",
      display: "flex !important",
      alignItems: "center",
      padding: "0px 9px",
      borderRadius: "12px",
      border: "1px solid rgb(208, 208, 208)",
      color: "rgb(68, 68, 68)",
      lineHeight: "22px",
      fontSize: "18px"
    },
    chatOfflineContainer: {
      display: "flex",
      flex: 1,
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "95px"
    },
    buttonContainer: {
      margin: "18px 5vw",
      display: "flex"
    },
    closeButton: {
      color: "rgb(255, 255, 255)",
      borderRadius: "9px",
      width: "100%",
      background: "rgb(51, 51, 51)",
      display: "flex",
      fontSize: "18px",
      height: "48px",
      alignItems: "center",
      textTransform: "none",
      fontFamily: theme.typography.h3.fontFamily,
      margin: "18px 0"
    },
    offlineText: {
      fontSize: "18px",
      color: "rgb(68, 68, 68)",
      lineHeight: "22px",
      margin: "0px 0px 18px",
      textAlign: "center"
    },
    offlineTextContainer: {
      display: "flex",
      flexDirection: "column",
      margin: "18px 5vw",
      alignItems: "center",
      justifyContent: "center"
    },
    supportContainer: {
      position: "fixed",
      width: "100%",
      top: "45px",
      padding: "2vw 5vw",
      boxShadow: theme.shadows[2],
      boxSizing: "border-box",
      background: "#FFFFFF",
      zIndex: 5
    },
    supportText: {
      fontFamily: theme.typography.h3.fontFamily,
      fontWeight: "bold",
      color: "rgb(51,51,51)",
      lineHeight: "22px",
      fontSize: "18px"
    },
    chatComponentContainer: {
      display: "flex",
      flex: 1,
      width: "100%",
      flexDirection: "column",
      position: "absolute",
      top: "80px",
      bottom: "88px",
      padding: "18px 5vw",
      overflow: "hidden",
      overflowY: "scroll"
      // background:"yellow"
    },
    chatStartText: {
      fontSize: "18px",
      color: "rgb(68, 68, 68)",
      lineHeight: "22px",
      margin: "0px 0px 18px",
      "& a": {
        color: "rgb(68, 68, 68)"
      }
    },
    chatInfoText: {
      fontSize: "12px",
      color: "rgb(170, 170, 170)",
      textAlign: "center"
    },
    messageSender: {
      fontFamily: theme.typography.h3.fontFamily,
      fontSize: "18px",
      color: "rgb(68, 68, 68)",
      lineHeight: 1.2
    },
    messageText: {
      fontSize: "18px",
      color: "rgb(68, 68, 68)",
      lineHeight: 1.1
    },
    messageContainer: {
      margin: "9px 0"
    },
    specialMessageContainer: {
      marginTop: "9px",
      height: "69px",
      display: "flex",
      flexDirection: "column",
      borderRadius: "12px",
      boxShadow: theme.shadows[3],
      justifyContent: "center"
    },
    spMsgContentContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    chatMessageLeftIcon: {
      marginLeft: "8px",
      marginRight: "16px",
      height: "59px"
    },
    chatRightArrowIcon: {
      margin: "0 16px",
      height: "17px"
    },
    buttonDefault: {
      padding: "0px",
      border: "none",
      background: "none",
      width: "100%",
      textAlign: "left"
    },
    loader: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      '& img': {
        width: '75px', 
        height: '75px', 
      },
    },
    faqOption: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "left",
      background: "#e6e5e5",
      padding: "10px 20px 10px 20px",
      borderRadius: "10px",
      marginBottom: "10px"
  },
  faqImage: {
      width: 50, 
      height: 50, 
      marginRight: theme.spacing(6), 
  },
  Mainheading:{
      fontSize: "26px",
      fontWeight: 400,
  },
  subHeading:{
      fontSize: "14px",
      fontWeight: 400,
      marginBottom: "20px",
  },
  faqTitle: {
      fontSize: "20px",    
  },
  faqQuestion:{
      fontSize: "16px",
      fontWeight: 600,
      marginBottom: "6px",
      color: "#ff0000"
  },
  faqAnswer:{
      fontSize: "16px",
      fontWeight: 400,
      marginBottom: "20px",
      lineHeight: 1.2
  },
  FaqOptionheading:{
      fontSize: "26px",
      fontWeight: 400,
      marginBottom: "30px"
  },
  faqQuestionContainer: {
    margin: "20px 0px 20px 0px"
  }
  })
);