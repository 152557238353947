//@ts-nocheck

import React, { Component, ComponentClass, FC } from "react";
import { pick } from "lodash";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  RouteComponentProps
} from "react-router-dom";
import ScrollableWrapper from "Components/ScrollableWrapper/ScrollableWrapper";
import { RoutesMapper } from "../Configurations/RoutesMapper";
import { logEvents, EVENT_TYPE } from "../Services/EventService";
import { getClientName, getQueryParameterValue } from "Utils/GeneralUtils";
import { FunctionMapper } from "Configurations/FunctionMapping";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import ErrorBoundary from "ErrorBoundary";
import {
  previousPages,
  loadEvents,
  manualParameterPass
} from "../Utils/GeneralUtils";

interface IRoute {
  path: string;
  component: ComponentClass | FC;
  exact: boolean;
}

interface IProps extends RouteComponentProps {
  clientName: string;
}

interface State {
  routes: IRoute[];
}

declare global {
  interface Window {
    previousPage: any;
  }
}

class Routes extends Component<IProps, State> {
  constructor(props: IProps) {
    super(props);
    let routesConfig = RoutesMapper[props.clientName];

    let routesArray: any = [];
    Object.keys(routesConfig).forEach(route => {
      let routeDetails = routesConfig[route];
      routesArray.push({
        path: routeDetails.path,
        component: routeDetails.component,
        exact: false
      });
    });

    this.state = {
      routes: routesArray
    };
    this.renderRoutes.bind(this);
  }

  componentDidMount() {
    // let previousPages: any = [];
    window.previousPage = "";
    const currentPath = window.location.pathname.split("/")[1];
    if (ConstantMapping[getClientName()].SET_TEALIUM_TAGGING) {
      // Passing Event Name for LandingRoute

      if (currentPath === "landing") {
        manualParameterPass(previousPages, {
          event_name: "onboarding_start"
        });
      } else {
        loadEvents(previousPages);
      }
      // if (!previousPages.length && currentPath == "home") {
      //   previousPages.push(
      //     ConstantMapping[getClientName()].SET_TEALIUM_MAPPER.TEALIUM_MAPPER[
      //       "home"
      //     ]
      //   );
      // }
      // else if (!previousPages.length) {
      //   previousPages.push(
      //     ConstantMapping[getClientName()].SET_TEALIUM_MAPPER
      //       .ONBOARDINGTEALIUM_MAPPER["landing"]
      //   );
      // }
    }

    try {
      // @ts-ignore
      const _paq = window._paq || [];
      _paq.push(["setCustomUrl", this.props.history.location.pathname]);
      _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
      _paq.push(["trackPageView"]);
      _paq.push(["enableLinkTracking"]);
    } catch (er) {
      console.log(er);
    }
    this.props.history.listen((location: any) => {
      // Tealium Event Raise on Routes
      if (
        location.pathname !== "/summary" &&
        location.pathname !== "/myRewards"
      ) {
        loadEvents(previousPages, location);
      }

      let canGo = true;
      if (canGo) {
        canGo = false;
        // @ts-ignore
        let _paq = window._paq || [];
        _paq.push(["setCustomUrl", location.pathname]);
        _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
        _paq.push(["trackPageView"]);
        _paq.push(["enableLinkTracking"]);
        setTimeout(() => {
          canGo = true;
        }, 2000);
      }

      logEvents({
        eventType: EVENT_TYPE.NAVIGATE,
        ...pick(location, "pathname")
      });
    });
  }

  renderRoutes() {
    let { routes } = this.state;
    return (routes || []).map((route: any, i) => {
      if (route.path !== "/") {
        return (
          <Route
            path={route.path}
            key={i}
            exact={route.exact}
            render={props => (
              //@ts-ignore
              <ErrorBoundary>
                <ScrollableWrapper>
                  <route.component {...props} />
                </ScrollableWrapper>
              </ErrorBoundary>
            )}
          />
        );
      }
      return (
        <Route
          path={route.path}
          key={i}
          exact={route.exact}
          render={props => (
            //@ts-ignore
            <ErrorBoundary>
              <route.component {...props} />
            </ErrorBoundary>
          )}
        />
      );
    });
  }

  render() {
    return (
      <Switch>
        {this.renderRoutes()}
        <Redirect to="/error-500" />
      </Switch>
    );
  }
}

export default withRouter(Routes);
