import React, { Fragment, useEffect } from "react";
import { Header } from "gce-cxinteracts-ui-components-library";
import Button from "@material-ui/core/Button";
import { useStyles } from "./styles";
import { connect } from "react-redux";
import { History } from "history";
import { ConstantMapping } from "../../../Configurations/ConstantsMapping";
import { ClientModel } from "../../../Models/ReduxModels/ClientDetails";
import UserProfile, {
  UserProfileDetails
} from "../../../Models/ReduxModels/UserProfileModel";
import { userOnboardingRoutes } from "../../../Configurations/Clients/vodafone/Routes";
import {
  iterateHierarchy,
  getImageDetails,
  getClientName,
  redirectToNextRoute
} from "../../../Utils/GeneralUtils";
import {
  logEvents,
  EVENT_TYPE,
  pushAllEventsToServer
} from "Services/EventService";
import { ImagesMapping } from "Configurations/ImagesMapping";

interface IProps {
  history: History;
  clientData: any;
  userProfileData: UserProfileDetails;
}

const LocationPermissionPage: React.FC<IProps> = props => {
  let classes = useStyles();
  const clientName = getClientName();

  const { history, clientData, userProfileData } = props;
  let accessToken = clientData?.token;

  const onLoadParams = {
    page_subsection: "permissions"
  };

  useEffect(() => {
    logEvents({ eventType: EVENT_TYPE.ONBOARDING_LOCATION_PERMISSION_LANDED });
    pushAllEventsToServer();
  }, []);

  const onClickStartButton = () => {
    new UserProfile({
      id: "local",
      isFirstUse: userProfileData?.isFirstUse,
      dob: userProfileData?.dob,
      interests: userProfileData?.interests,
      permissions: {
        ...userProfileData?.permissions,
        location: true
      }
    }).$save();
    nextPage(true);
  };

  const onClickDontAllowLink = () => {
    new UserProfile({
      id: "local",
      isFirstUse: userProfileData?.isFirstUse,
      dob: userProfileData?.dob,
      interests: userProfileData?.interests,
      permissions: {
        ...userProfileData?.permissions,
        location: false
      }
    }).$save();
    nextPage(false);
  };

  const nextPage = (permissionOpted: boolean) => {
    const nextRoute = redirectToNextRoute("location");
    logEvents({ eventType: EVENT_TYPE.NEXT_PAGE_ONBOARDING });
    logEvents({
      eventType: EVENT_TYPE.ONBOARDING_LOCATION_PERMISSION_EXITED,
      location: permissionOpted
    });
    pushAllEventsToServer();
    history.push(nextRoute, { onLoadParams });
  };

  return (
    <Fragment>
      <div className={classes.mainContainer}>
        <Header
          className={classes.header}
          isHeaderInverted={false}
          clientLogoPrimary={getImageDetails(
            ImagesMapping[clientName].clientLogoPrimary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          clientLogoSecondary={getImageDetails(
            ImagesMapping[clientName].clientLogoSecondary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          isScrollHeader={false}
          primaryLogoTabIndex={-1}
          secondaryLogoTabIndex={-1}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.content}>
            <div
              className={classes.imageWrapper}
              style={{ position: "relative" }}
            >
              <div style={{ display: "block" }}>
                <div id="onb2">
                  <img
                    alt=""
                    id="data"
                    className={`${classes.onb2_popup}`}
                    src={ImagesMapping[clientName].popupIcon}
                  />
                  <img
                    alt=""
                    className={classes.onb2_redpin}
                    src={ImagesMapping[clientName].redpinIcon}
                  />
                  <img
                    alt=""
                    src={ImagesMapping[clientName].pinIcon}
                    className={`${classes.pin} ${classes.pin_animate1}`}
                    style={{
                      margin: "80% 0px 0px 60%"
                    }}
                  />
                  <img
                    alt=""
                    src={ImagesMapping[clientName].pinIcon}
                    className={`${classes.pin} ${classes.pin_animate2}`}
                    style={{
                      margin: "85% 0px 0px 35%"
                    }}
                  />
                  <img
                    alt=""
                    src={ImagesMapping[clientName].pinIcon}
                    className={`${classes.pin} ${classes.pin_animate3}`}
                    style={{
                      margin: "70% 0px 0px 30%"
                    }}
                  />
                  <img
                    alt=""
                    src={ImagesMapping[clientName].pinIcon}
                    className={`${classes.pin} ${classes.pin_animate4}`}
                    style={{ margin: "60% 0px 0px 70%" }}
                  />
                  <img
                    alt=""
                    className={classes.onb2_backphone}
                    src={ImagesMapping[clientName].locationPermissionHeroImg}
                  />
                </div>
              </div>
            </div>
            <div className={classes.textWrapper}>
              <h2 className={classes.heading}>
                {ConstantMapping[clientName].LOCATION_PERMISSION_HEADING}
              </h2>
              <p className={classes.descriptionText}>
                {ConstantMapping[clientName].LOCATION_PERMISSION_DESC}
              </p>
            </div>
            <div className={classes.buttonWrapper}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.locationUseButton}
                  onClick={onClickStartButton}
                >
                  {ConstantMapping[clientName].USE_LOCATION_BUTTON_TEXT}
                </Button>
                <Button
                  className={classes.locationButtonOff}
                  onClick={onClickDontAllowLink}
                >
                  {ConstantMapping[clientName].DONT_USE_LOCATION_TEXT}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  let clientData = ClientModel.getInsatnce("", state)?.props;
  let userProfileData = UserProfile.getInsatnce("local", state)?.props;
  return {
    clientData,
    userProfileData
  };
};

export default connect(mapStateToProps)(LocationPermissionPage);
