import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink
} from "apollo-boost";
import { ClientModel } from "Models/ReduxModels/ClientDetails";
import { Settings } from "Models/ReduxModels/Settings";

/**
 * Function to call graphQL queries
 * @param headersToPass {object} pass the headers in the query
 */
export const configApolloClient = (
  headersToPass: any,
  url: string | import("apollo-link-http-common").UriFunction | undefined,
  tokenType = "Bearer"
) => {
  const settings = getDefaultSettings();
  const httpLink = new HttpLink({
    uri: url ? url : settings.url
  });
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: headersToPass ? headersToPass : settings.headers
    });
    return forward(operation);
  });
  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      addTypename: false
    })
  });
};

const getDefaultSettings = () => {
  const clientDetails = ClientModel.getInsatnce("")?.props;
  const settings = Settings.getInsatnce("")?.props;
  return {
    url: settings.clientDetails.apiEndPoint,
    headers: {
      Authorization: `Bearer ${clientDetails.token}`,
      tenantId: settings.clientDetails.tenantId,
      sessionId: localStorage
        ? localStorage.getItem("session-id")
        : clientDetails.sessionId
    }
  };
};

/**
 * @summary The below function is just to make graphql Query and mutation
 * @param Type: graphql operation (query or mutation)
 * @param Query:actual gql Query consisting response field
 * @param variable: the variable is object i.e passed to query
 * @param header: Used to pass headers like access_token and x visibility scope.
 */
export const graphQlCall = (
  type: string,
  query: any,
  variable?: {},
  headers?: {},
  url?: string,
  tokenType?: string
) => {
  let client;
  headers
    ? (client = configApolloClient(headers, url, tokenType))
    : (client = configApolloClient(null, url, tokenType));
  switch (type) {
    case "query":
      let queryPromise = client
        ? client.query({
            query: query,
            variables: { ...variable }
          })
        : new Promise(reject => {
            reject("client is null");
          });
      return queryPromise;
    case "mutation":
      let mutationPromise = client
        ? client.mutate({
            mutation: query,
            variables: { ...variable }
          })
        : new Promise(reject => {
            reject("client is null");
          });
      return mutationPromise;
  }
};

/*Below function processes the error occured  during Graphql calls */
export const onGqlError = (err: any) => {
  let snackBarMessage = "";
  if (err && err.graphQLErrors && err.graphQLErrors.length) {
    err.graphQLErrors.forEach((error: { message: string }, index: number) => {
      if (error.message) {
        let errorMessage = error.message.split(":");
        snackBarMessage = snackBarMessage
          ? `${snackBarMessage}, ${index + 1}. ${
              errorMessage.length > 1 ? errorMessage[1] : errorMessage[0]
            }`
          : `${index + 1}. ${
              errorMessage.length > 1 ? errorMessage[1] : errorMessage[0]
            }`;
      }
    });
  } else {
    snackBarMessage = "Please try again after some time.";
  }
  return snackBarMessage;
};

export const GQLConstants = {
  QUERY: "query",
  MUTATION: "mutation"
}
