import { EVENT_TYPE } from "Services/EventService";
import { pushAllEventsToServer } from "Services/EventService";
import { logEvents } from "Services/EventService";
import WelcomeScreen from "../../NewUserOnboarding/WelcomeScreen/WelcomeScreen";
import Sms from "../../NewUserOnboarding/SmsScreen/Sms";
import Location from "../../NewUserOnboarding/LocationScreen/Location";
import Traffic from "../../NewUserOnboarding/TrafficScreen/Traffic";
import Profile from "../../NewUserOnboarding/ProfileScreen/Profile";
import Summary from "../../NewUserOnboarding/SummaryScreen/Summary";

const sendOnboardingEvent = {
  onboardingEvent: async eventName => {
    logEvents({ eventType: eventName });
    pushAllEventsToServer();
  },
  onboardingPermissionNext: async (permissionOpted, eventName) => {
    logEvents({ eventType: EVENT_TYPE.NEXT_PAGE_ONBOARDING });
    logEvents({
      eventType: eventName,
      permissionOpted
    });
    pushAllEventsToServer();
  }
};

const onBoardingScreens = {
  landing: WelcomeScreen,
  sms: Sms,
  location: Location,
  traffic: Traffic,
  profiling: Profile,
  summary: Summary
};

export { sendOnboardingEvent,onBoardingScreens };
