import { BaseModel } from "ReduxStore/index";

export interface AppSectionConfig {
  name: string;
  type: string;
  value: string;
}
export interface IAppSectionConfig {
  key: string;
  value: AppSectionConfig[];
}

export default class AppConfig extends BaseModel<IAppSectionConfig> {
  static resource = "AppConfig";
}
