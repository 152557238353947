//@ts-nocheck

import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { getClientName } from "Utils/GeneralUtils";

const clientName = getClientName();

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      width: "100%",
      height: "100vh",
      position: "fixed",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    },
    header: {},
    heroImageAndButtonContainer: {
      minHeight: "calc(100vh - 45px)",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "space-between"
    },
    heroImage: {
      width: "70%",
      margin: "5.034vh auto 8.86vh",
      "@media (min-width: 411px)": {
        margin: "8.034vh auto 8.86vh"
      },
      "@media (min-width: 411px) and (min-height:823px)": {
        margin: "12.034vh auto 8.86vh"
      },
      "@media (min-width: 320px)": {
        margin: "3.4vh auto 7vh"
      }
    },
    buttonContainer: {
      backgroundColor: theme.palette.primary.main
    },
    buttonWrapper: {
      flex: "0 0 110px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      marginTop: "24px"
    },
    landingButtonOn: {
      height: "48px",
      width: "200px",
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontSize: theme.typography.h6.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: "none",
      margin: theme.spacing(0, "auto"),
      fontFamily: "VodafoneRgBold",
      boxShadow: theme.shadows[4],
      "&.Mui-disabled": {
        color: theme.palette.primary.contrastText
      }
    },

    landingSlides: {
      textAlign: "center",
      display: "flex !important",
      flexDirection: "column",
      alignItems: "center",
      "& img": {
        width: "100%"
      },
      "& div": {
        textAlign: "center",
        fontSize: "22px",
        color: theme.palette.secondary.dark,
        marginTop: "20px",
        marginLeft: "12px",
        maxWidth: "60%"
      }
    },
    headingSection: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    topHeading: {
      color: theme.palette.secondary.dark,
      fontSize: "60px",
      fontWeight: "bold"
    },
    subHeading: {
      color: theme.palette.primary.main,
      maxWidth: "80%",
      textAlign: "center",
      marginTop: "0px",
      fontWeight: "normal",
      fontSize: "16px"
    },
    slideIndicator: {
      textAlign: "center",
      position: "fixed",
      left: 0,
      right: 0,
      bottom: "1rem",
      marginTop: "12px",
      "& span": {
        marginLeft: "12px",
        height: "10px",
        width: "10px",
        borderRadius: "50%",
        border: "1px solid " + theme.palette.common.white,
        backgroundColor: "transparent", //theme.palette.secondary.light,
        display: "inline-block"
      }
    },
    activeSlide: {
      marginLeft: "12px",
      height: "12px !important",
      width: "12px  !important",
      borderRadius: "50%",
      border: "1px solid " + theme.palette.common.white,
      display: "inline-block",
      backgroundColor: theme.palette.common.white + " !important"
    }
  })
);
