//@ts-nocheck
import React, { useEffect, useState, useLayoutEffect } from "react";
import { useStyles } from "./styles";
import { Typography, Button, Slide, useTheme } from "@material-ui/core";
import { LuckyDipTypes } from "../../Models/Interfaces/PreClaimDialogTypes";
import { Swipeable } from "react-swipeable";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import { Header } from "gce-cxinteracts-ui-components-library";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { checkChristmasMonth } from "Utils/GeneralUtils";
import { setTimeout } from "timers-browserify";
import GenericDialog from "Components/Dialog/GenericDialog";

interface IProps {
  type: LuckyDipTypes;
  successCallback(): void;
  handleCloseDialog(): void;
  winCallback(): void;
  clientName: string;
  ldClawIntroScreenImage: any;
  ldIntroScreenImage: any;
  ldConveyorbeltIntroScreenImage: any;
  ldPickToWinBgImage: any;
  ldCongratsImage: any;
  prizeName: string;
  ldStaticPrizeInterimImage: any;
  LdNewIntroSolo: any;
  CMS_lucky_dip_intro_background: string;
  CMS_lucky_dip_intro_animation: string;
  CMS_lucky_dip_play_animation: string;
  CMS_lucky_dip_congrats_animation: string;
  isEmailCapture?: boolean;
  showEmail?: string | null,
  goBack(): void,
  onEmailSubmitCallBack: (data?) => void
}
const LuckyDipJourney: React.FC<IProps> = ({
  type,
  successCallback,
  handleCloseDialog,
  winCallback,
  ldIntroScreenImage,
  ldClawIntroScreenImage,
  ldConveyorbeltIntroScreenImage,
  clientName,
  ldPickToWinBgImage,
  ldCongratsImage,
  prizeName,
  ldStaticPrizeInterimImage,
  LdNewIntroSolo,
  CMS_lucky_dip_intro_background,
  CMS_lucky_dip_intro_animation,
  CMS_lucky_dip_play_animation,
  CMS_lucky_dip_congrats_animation,
  goBack,
  isEmailCapture,
  showEmail,
  onEmailSubmitCallBack
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [pickPrize, setPickPrize] = useState(true);
  const [congratScreen, setCongatsScreen] = useState(false);
  const constants = ConstantMapping[clientName];
  const [animate, setAnimate] = useState(false);
  const [showWinningPrizeName, setShowWiningPrizeName] = useState(false);
  const [xmasPickPrizeOne, setXmasPickPrizeOne] = useState(true);
  const [xmasPickPrizeTwo, setXmasPickPrizeTwo] = useState(false);
  const [showEmailPopUp, setShowEmailPopUp] = useState(false);
  const isChristmas = checkChristmasMonth(
    constants.XMAS_IW_START_DATE,
    constants.XMAS_IW_END_DATE
  );

  const horizontalBar = (style: Object, text: string) => {
    return (
      <div className={classes.luckyDiphorizontalBarContainer} style={style}>
        <Typography className={classes.luckyDiphorizontalBarText}>
          {text}
        </Typography>
      </div>
    );
  };

  const SwipableView = () => {
    return (
      <Swipeable
        preventDefaultTouchmoveEvent={true}
        trackTouch={true}
        onSwiped={successCallback}
        className={classes.contentBottomButton}
        style={{ bottom: "3.5vh" }}
      >
        <Typography variant="h4" className={classes.skipContentText}>
          {constants.IW_SKIP_TEXT}&nbsp;
        </Typography>
        <Typography
          variant="h4"
          className={`${classes.skipTextSign} ${classes.luckyDipSkipSign}`}
        >
          {">"}
        </Typography>
      </Swipeable>
    );
  };

  
  const hideEmailPopupAfterSeconds = () => {
    setTimeout(()=>{
      setShowEmailPopUp(false);
    },1000)
  }
  
 const closeEmailPopup = () => {
  goBack();
  hideEmailPopupAfterSeconds()
}

const submitButton = (data) => {
  // Need to add some function here which will redirect to offer details
  onEmailSubmitCallBack(data);
  hideEmailPopupAfterSeconds()
}

const renderEmailPopup = () => {
  return (
    <>
      {" "}

      <GenericDialog
        openDialog={showEmailPopUp}
        content={"EMAILTYPE"}
        clientName={clientName}
        handleCloseDialog={closeEmailPopup}
        showCloseIcon={true}
        heading="Tell us where to send your prize"
        paperStyle={{ backgroundColor: "transparent", padding: 0 }}
        closeIconBtnStyle={ConstantMapping[clientName].SVG.headerSvgStyle}
        showFullScreenDialog={true}
        showEmailPopUp={true}
        confirmCallback={submitButton}
        isHeaderTransparent={true}
      ></GenericDialog>
    </>
  );
};
  const winCallBackHandler = () => {
    if(isEmailCapture === true){
      setShowEmailPopUp(true)
      renderEmailPopup()
    } else{
      winCallback();
    }
  }

  const renderIntroScreen = () => {
    let svgProps = Object.assign(
      {},
      ConstantMapping[clientName].SVG.headerSvgStyle
    );
    svgProps.fill = theme.palette.text.secondary;
    return (
      <>
        <div className={classes.luckyDipHeader} >
          <Header className={classes.transparentBackground} svgProps={svgProps} onSvgSelection={handleCloseDialog} />
        </div>
        {CMS_lucky_dip_intro_background !== "" && (
          <img
            src={CMS_lucky_dip_intro_background}
            className={classes.iwGifIntro}
            alt={constants.LD_INTRO_SCREEN_ALT_TEXT}
          />
        )}
        <img
              src={LdNewIntroSolo.src}
              className={classes.luckydipIntro}
              alt={constants.LD_INTRO_SOLO_ALT_TEXT}
            />
        {CMS_lucky_dip_intro_background === "" && (
          
            
            <img
              src={ldIntroScreenImage.src}
              className={classes.iwGifIntro}
              alt={constants.LD_INTRO_SCREEN_ALT_TEXT}
            />
        
        )}

        <div className={classes.luckyDipContainer}>
          {CMS_lucky_dip_intro_animation !== "" && (
            <img
              src={CMS_lucky_dip_intro_animation}
              className={classes.luckyDipIntroGif}
              alt={constants.LD_INTRO_CLAW_SCREEN_ALT_TEXT}
            />
          )}
          {CMS_lucky_dip_intro_animation === "" && (
            <>
              <img
                src={ldClawIntroScreenImage.src}
                className={classes.luckyDipIntroGif}
                alt={constants.LD_INTRO_CLAW_SCREEN_ALT_TEXT}
              />
              <img
                src={ldConveyorbeltIntroScreenImage.src}
                className={classes.luckyDipIntroGif}
                alt={constants.LD_CONVEYORBELT_INTRO_SCREEN_ALT_TEXT}
              />
            </>
          )}
          <div
            className={classes.luckyDipTap}
            onClick={() => successCallback()}
          >
            {constants.LD_TAP_TO_PLAY}
          </div>
        </div>
        {isChristmas &&
          horizontalBar(
            { bottom: "13.5vh", position: "absolute" },
            constants.LD_INTRO_TEXT
          )}
        <SwipableView />
      </>
    );
  };

  useEffect(()=>{
    if(type === LuckyDipTypes.WIN && showEmail === 'SHOW_POPUP'){
      setShowEmailPopUp(true)
    }
  },[])

  useEffect(() => {
    if (LuckyDipTypes.WIN === type) {
      if (!isChristmas) {
        setTimeout(() => {
          setPickPrize(false);
          setCongatsScreen(true);
        }, 2300);
      } else {
        setTimeout(() => {
          setXmasPickPrizeTwo(false);
          setCongatsScreen(true);
        }, 7200);
      }
    }
  }, [type]);

  useLayoutEffect(() => {
    const pickGiftOne = (document.getElementById(
      "pickGift"
    ) as HTMLElement) || { clientHeight: 0 };
    if (pickGiftOne?.clientHeight > 500) {
      setTimeout(() => {
        setXmasPickPrizeOne(false);
        setXmasPickPrizeTwo(true);
      }, 3500);
    }
  });

  const updateWiningPrizeText = () => {
    setTimeout(() => {
      setShowWiningPrizeName(true);
    }, 2100);
  };

  const winingPrizeScreen = () => {
    return (
      <>
        <img
          src={
            CMS_lucky_dip_congrats_animation !== ""
              ? CMS_lucky_dip_congrats_animation
              : ldCongratsImage.src
          }
          className={classes.iwGifIntro}
          onLoad={animateButton}
          alt={constants.LD_CONGRATS_ALT_TEXT}
        />
        {updateWiningPrizeText()}
        <div className={classes.luckyDipPrizeButtonContainer}>
          {showWinningPrizeName &&
            horizontalBar({}, `${constants.LD_Prize}${prizeName}!`)}
          <Slide
            direction="up"
            in={animate}
            mountOnEnter
            unmountOnExit
            timeout={200}
          >
            <Button
              variant="contained"
              color="secondary"
              className={classes.claimPrizeButton}
              onClick={winCallBackHandler}
              style={{ marginTop: "6vh" }}
            >
              <Typography variant={"h4"} className={classes.claimRewardsText}>
                {constants.LD_PRIZE_TEXT}
              </Typography>
            </Button>
          </Slide>
        </div>
      </>
    );
  };

  const prizePickUp = () => {
    return (
      <div className={classes.luckyDipPrizePick}>
        <img
          src={
            CMS_lucky_dip_play_animation !== ""
              ? CMS_lucky_dip_play_animation
              : ldPickToWinBgImage.src
          }
          className={classes.iwGifIntro}
          alt={constants.LD_PRIZE_PICK_UP_ALT_TEXT}
        />
      </div>
    );
  };

  const renderPrizeScreen = () => {
    return (
      <div
        style={{
          backgroundImage:
            CMS_lucky_dip_play_animation !== ""
              ? undefined
              : `url(${ldStaticPrizeInterimImage.src})`,
          height: "100vh",
          backgroundRepeat: "no-repeat"
        }}
      >
        {/* Pick & Release Prize */}
        {pickPrize && prizePickUp()}
        {/* Congrats Screen */}
        {congratScreen && winingPrizeScreen()}
      </div>
    );
  };

  const xmasPrizePickUp = () => {
    return (
      <div className={classes.luckyDipPrizePick}>
        {xmasPickPrizeOne && (
          <img
            src={ldPickToWinBgImage.src}
            className={classes.iwGifIntro}
            id="pickGift"
            alt={constants.LD_PRIZE_PICK_UP_ALT_TEXT}
          />
        )}
        {xmasPickPrizeTwo && (
          <img
            src={ImagesMapping[clientName].iwPicktoWin_backgroundP2}
            className={classes.iwGifIntro}
            alt={constants.LD_PRIZE_PICK_UP_ALT_TEXT}
          />
        )}
      </div>
    );
  };

  const renderXmasPrizeScreen = () => {
    return (
      <div
        style={{
          backgroundImage: `url(${ldStaticPrizeInterimImage.src})`,
          height: "100vh",
          backgroundRepeat: "no-repeat"
        }}
      >
        {/* Pick & Release Prize */}
        {xmasPrizePickUp()}
        {/* Congrats Screen */}
        {congratScreen && winingPrizeScreen()}
      </div>
    );
  };

  const animateButton = () => {
    setTimeout(() => {
      setAnimate(true);
    }, 2600);
  };

  const renderContent = () => {
    if (LuckyDipTypes.INTRO === type) {

      return renderIntroScreen();
    }

    if (LuckyDipTypes.WIN === type) {
      return isChristmas ? renderXmasPrizeScreen() : renderPrizeScreen();
      // return renderPrizeScreen();
    }
  };

  const renderGamificationEmail = () => {
    return <>{renderEmailPopup()}
    </>
  }

  return (
    //Generic Journey
    <div className={`${classes.iwGifContainer} ${classes.fallback_bg}` }>
      {(type === LuckyDipTypes.WIN && isEmailCapture && showEmailPopUp && showEmail === "SHOW_POPUP") &&  renderGamificationEmail()}
      { renderContent()}
      </div>
  );
};
export default LuckyDipJourney;
