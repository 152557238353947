//@ts-nocheck
import React, { Fragment } from "react";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { Typography } from "@material-ui/core";
import { useStyles } from "../RestaurantDetails/styles";
import { getCurrentTime } from "Utils/GeneralUtils";
import moment from "moment";
import { membShipUseStyles } from "./styles";

interface StyleOptions {
  timerNumbersStyle?: CSSProperties;
  timerLabelsStyle?: CSSProperties;
  timerDelimitersStyle?: CSSProperties;
}

export interface IProps {
  remainingTicks: number | string;
  styleOptions?: StyleOptions;
  style?: CSSProperties;
}

const Timer: React.FC<IProps> = props => {
  const { remainingTicks, styleOptions, style } = props;
  const classes = membShipUseStyles();

  const timerTextStyle: React.CSSProperties = styleOptions.timerLabelsStyle || {};
  const timerTextClass = styleOptions.timerLabelsStyle ? '' : classes.timerText;

  const renderer = (props: CountdownRenderProps) => {
    let date = moment(getCurrentTime());
    const { hours, minutes, seconds } = props;


    return (
      <div style={style}>
        <div style={styleOptions.timerNumbersStyle || classes.timerContainer}>
          <Typography variant="h1" className={timerTextClass} style={timerTextStyle}>
            {hours < 10 ? `0${hours}` : hours}
            <span className={classes.delimiter}>:</span>
          </Typography>
          <Typography variant="h1" className={timerTextClass} style={timerTextStyle}>
            {minutes < 10 ? `0${minutes}` : minutes}
            <span className={classes.delimiter}>:</span>
          </Typography>
          <Typography variant="h1" className={timerTextClass} style={timerTextStyle}>
            {seconds < 10 ? `0${seconds}` : seconds}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <Countdown date={remainingTicks} renderer={renderer} />
    </Fragment>
  );
};

export default Timer;
