import React, { Fragment, useEffect } from "react";
import {
  makeStyles,
  FormControl,
  Typography,
  TextField
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useDebounce } from "use-debounce";
import searchIcon from "Configurations/Clients/vodafone/Assets/RestoLocalBlendImages/icon-search-grey.png";

const styles = makeStyles(theme => ({
  option: {
    textAlign: "left",
    whiteSpace: "normal",
    wordWrap: "break-word"
  },
  columns: {
    display: "flex",
    "flex-direction": "row",
    justifyContent: "space-between",
    width: "100%"
  },
  formControl: {
    width: "100%",
    margin: "10px 0"
  },
  formControlLimit: {
    color: "#0b64f3"
  },
  input: {
    textAlign: "left",
    flex: 1,
    color: theme.palette.text.primary,
    "& label.Mui-focused": {
      color: theme.palette.text.primary
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent"
    },
    "& .MuiFilledInput-root": {
      borderRadius: "3px",
      height: "50px",
      padding: "0",
      border: `2px solid #dadcdc !important`,
      backgroundColor: "#fff",
      paddingRight: "14px !important",
      fontFamily: "VodafoneRg, VodafoneRgBold",
      fontWeight: "bold",
      fontSize: "18px",
      "& fieldset": {
        borderColor: "#dadcdc"
      },
      "&:hover fieldset": {
        borderColor: "#dadcdc"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#dadcdc"
      },
      "&:before, &:after": {
        borderBottom: "none"
      },
      "& .MuiFilledInput-input": {
        padding: "10px 14px"
      }
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px 0"
    }
  },
  error: {
    textAlign: "left",
    flex: 1,
    color: theme.palette.error.main,
    "& label.Mui-focused": {
      color: theme.palette.error.main
    },
    // "& .MuiInput-underline:after": {
    //   borderBottomColor: "transparent"
    // },
    "& .MuiFilledInput-root": {
      borderRadius: "10px",
      //   padding: "0",
      height: "50px",
      paddingRight: "20px !important",
      border: `1px solid ${theme.palette.error.main} !important`,
      "& fieldset": {
        borderColor: theme.palette.error.main
      },
      "&:hover fieldset": {
        borderColor: theme.palette.error.main
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.error.main
      },
      "&:before, &:after": {
        borderBottom: "none"
      },
      "& .MuiFilledInput-input": {
        padding: "10px 20px"
      }
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px 0"
    }
  },
  paper: {
    backgroundColor: "#dadcdc",
    color: "#000"
  },
  loadingText: {
    color: "#000"
  },
  autoComplete: {
    flex: 1,
    marginTop: "-17px",
    textAlign: "left",
    whiteSpace: "normal",
    wordWrap: "break-word",
    "& .MuiAutocomplete-root": {
      width: "100%"
    }
  }
}));

interface IProps {
  label?: string;
  typographyVariant: any;
  id?: string;
  value?: any;
  disableClearable?: boolean | true;
  autoCompleteOptions: any[];
  customInputRef?: React.Ref<any> | any;
  name?: string;
  errors?: object | {};
  subLabel?: string | "";
  errorMessage?: string;
  type?: string;
  watch?: (string) => string | string[] | { [key: string]: any } | undefined;
  disabled?: boolean | false;
  style?: any;
  freeSolo?: boolean | true;
  autoSuggestTextChanged?: (string) => void;
  autoCompleteValueChanged?: (any) => void;
  loading?: boolean;
  defaultValue?: any;
  multiple?: any;
  debounceInterval?: number;
  externalApiCall?: boolean;
  showName?: boolean;
  fieldPlaceholder?: string;
  textFieldStyle?: any;
}

const AutoComplete: React.FC<IProps> = props => {
  const [loading, setLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState(undefined);
  const [debouncedText] = useDebounce(
    searchText,
    props.debounceInterval ? props.debounceInterval : 600
  );
  const classes = styles();

  useEffect(() => {
    if (props.autoSuggestTextChanged && debouncedText !== undefined) {
      props.autoSuggestTextChanged(debouncedText);
    }
  }, [debouncedText]);

  useEffect(() => {
    if (props.externalApiCall) setLoading(false);
  }, [props.autoCompleteOptions]);

  const autocompleteTextChanged = e => {
    if (props.externalApiCall) setLoading(true);
    setSearchText(e.target.value);
  };

  const autoCompleteValueChanged = (event, value) => {
    if (props.autoCompleteValueChanged && value) {
      props.autoCompleteValueChanged(value);
    }
  };

  const getOptionsText = option => {
    let text = "";
    if (option.name) {
      text = option.name;
    }
    return text;
  };

  return (
    <Fragment>
      <FormControl
        variant="filled"
        className={classes.formControl}
        style={props.style}
      >
        <div className={classes.columns}>
          <Typography
            className={
              props.errors &&
              Object.keys(props.errors).length &&
              props.name &&
              props.errors[props.name]
                ? classes.error
                : classes.input
            }
            variant={props.typographyVariant}
          >
            {props.label}
            {props?.subLabel}
          </Typography>
        </div>
        <Autocomplete
          loading={loading}
          clearOnEscape
          filterSelectedOptions
          noOptionsText={<div style={{ color: "#000" }}>No options</div>}
          loadingText={<span className={classes.loadingText}>Loading...</span>}
          onChange={autoCompleteValueChanged}
          multiple={props.multiple}
          disabled={props.disabled}
          className={classes.autoComplete}
          defaultValue={props.defaultValue}
          id={props.id}
          disableClearable={props.disableClearable}
          options={props.autoCompleteOptions}
          classes={{ paper: classes.paper }}
          renderOption={option => (
            <div className={classes.option}>
              {props.showName ? option.name : getOptionsText(option)}
            </div>
          )}
          getOptionLabel={option =>
            props.showName ? option.name : getOptionsText(option)
          }
          freeSolo={props.freeSolo}
          renderInput={params => (
            <TextField
              id={`text${props.id}`}
              {...(params as {})}
              margin="normal"
              placeholder={
                props.fieldPlaceholder && props.fieldPlaceholder !== ""
                  ? props.fieldPlaceholder
                  : ""
              }
              disabled={props.disabled}
              className={
                props.errors &&
                props.name &&
                Object.keys(props.errors).length &&
                props.errors[props.name]
                  ? classes.error
                  : classes.input
              }
              style={props?.textFieldStyle}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    <img src={searchIcon} alt="search" />
                  </React.Fragment>
                )
              }}
              variant="filled"
              fullWidth
              name={props.name}
              inputRef={props.customInputRef}
              onChange={autocompleteTextChanged}
            />
          )}
        />
        <Typography className={classes.error} variant={props.typographyVariant}>
          {props.errors &&
          Object.keys(props.errors).length &&
          props.name &&
          props.errors[props.name] ? (
            <span>
              {props.errorMessage
                ? props.errorMessage
                : "Please select appropriate value"}
            </span>
          ) : (
            ""
          )}
        </Typography>
      </FormControl>
    </Fragment>
  );
};

export default AutoComplete;
