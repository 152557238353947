//@ts-nocheck
import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import { Dialog, Typography, Button, Slide, useTheme } from "@material-ui/core";
import { IWTypes } from "../../Models/Interfaces/PreClaimDialogTypes";
import { Swipeable } from "react-swipeable";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import { Header } from "gce-cxinteracts-ui-components-library";
import { useHistory } from "react-router-dom";
import { checkChristmasMonth } from "Utils/GeneralUtils";
import GenericDialog from "Components/Dialog/GenericDialog";

interface IProps {
  type: IWTypes;
  successCallback(): void;
  handleCloseDialog(): void;
  winCallback(): void;
  onEmailSubmitCallBack: (data?) => void;
  iwWinImage: any;
  iwLooseImage: any;
  iwIntroImage: any;
  iwPopBalloonImage: any;
  clientName: string;
  CMS_iwWinImage?: any;
  CMS_iwLooseImage?: any;
  CMS_iwIntroImage?: any;
  showEmail?: string | null;
  goBack(): void;
  isEmailCapture?: boolean;
  iw_error_message: string | undefined;
  iw_intro_message: string | undefined;
  iw_winner_message: string | undefined;
  iw_loser_message: string | undefined;
  std_intro_text: string | undefined;
  std_winner_text: string | undefined;
  std_loser_text: string | undefined;
}
const InstantWinJourney: React.FC<IProps> = ({
  type,
  successCallback,
  handleCloseDialog,
  winCallback,
  iwWinImage,
  iwLooseImage,
  iwIntroImage,
  iwPopBalloonImage,
  clientName,
  CMS_iwWinImage,
  CMS_iwLooseImage,
  CMS_iwIntroImage,
  onEmailSubmitCallBack,
  goBack,
  isEmailCapture,
  showEmail,
  iw_error_message,
  iw_intro_message,
  iw_winner_message,
  iw_loser_message,
  std_intro_text,
  std_loser_text,
  std_winner_text
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [animate, setAnimate] = useState(false);
  const [showText, setShowText] = useState(false);
  const [showEmailPopUp, setShowEmailPopUp] = useState(false);
  const constants = ConstantMapping[clientName];
  const iw_error_message_text = iw_error_message
    ? iw_error_message
    : constants.IW_LOOSE_TEXT;

  const history = useHistory();
  const isChristmas = checkChristmasMonth(
    ConstantMapping[clientName].XMAS_IW_START_DATE,
    ConstantMapping[clientName].XMAS_IW_END_DATE
  );

  const get_element_with_delay = (bottom: string, text: string) => {
    // console.log("Delaying element loading .....");
    let comp = <></>;
    setTimeout(() => {
      comp = (
        <div
          className={classes.horizontalBarContainer}
          style={{ bottom: bottom }}
        >
          <Typography className={classes.horizontalBarText}>{text}</Typography>
        </div>
      );
    }, 1000);

    return comp;
  };
  const horizontalBar = (bottom: string, text: string) => {
    // setTimeout(() =>{},1000);

    return (
      <div
        className={classes.horizontalBarContainer}
        style={{ bottom: bottom }}
      >
        <Typography className={classes.horizontalBarText}>{text}</Typography>
      </div>
    );
  };

  useEffect(() => {
    if (type === IWTypes.WIN && showEmail === "SHOW_POPUP") {
      setShowEmailPopUp(true);
    }
  }, []);

  const getText = key => {
    let value = "";

    switch (key) {
      case "intro_text":
        if (iw_intro_message) {
          value = iw_intro_message;
        } else if (std_intro_text) {
          value = std_intro_text;
        } else {
          value = constants.IW_INTRO_TEXT;
        }
        break;
      case "winner_text":
        if (iw_winner_message) {
          value = iw_winner_message;
        } else if (std_winner_text) {
          value = std_winner_text;
        } else {
          value = "";
        }
        break;
      case "loser_text":
        if (iw_loser_message) {
          value = iw_loser_message;
        } else if (std_loser_text) {
          value = std_loser_text;
        } else {
          value = constants.IW_LOOSE_TEXT;
        }
        break;
      default:
        break;
    }

    return value;
  };

  const renderIntroScreen = () => {
    let svgProps = Object.assign(
      {},
      ConstantMapping[clientName].SVG.headerSvgStyle
    );
    svgProps.fill = theme.palette.text.secondary;
    return (
      <>
        <div className={classes.iwHeader}>
          <Header
            className={classes.transparentBackground}
            svgProps={svgProps}
            onSvgSelection={handleCloseDialog}
          />
        </div>
        {/* <div className={classes.overlayContent}>
          <Typography className={classes.contentFirst}>POP</Typography>
          <Typography className={classes.contentSecond}>the balloon</Typography>
        </div> */}
        {CMS_iwIntroImage ? (
          <img
            src={CMS_iwIntroImage}
            className={classes.iwGifIntro}
            alt=""
            onClick={successCallback}
          />
        ) : (
          <>
            <img
              src={iwIntroImage?.src}
              className={classes.iwGifIntro}
              onClick={successCallback}
              alt=""
            />

            {/* <div className={classes.iwGifPopBalloonContainer}>
              <img
                src={iwPopBalloonImage?.src}
                className={classes.iwGifPopBalloon}
                onClick={successCallback}
                alt=""
              />
            </div> */}
          </>
        )}
        {horizontalBar("18vh", getText("intro_text"))}
        <Swipeable
          preventDefaultTouchmoveEvent={true}
          trackTouch={true}
          onSwiped={successCallback}
          className={classes.contentBottomButton}
          style={{ bottom: "2.5vh" }}
        >
          <Typography variant="h4" className={classes.skipContentText}>
            {constants.IW_SKIP_TEXT}&nbsp;
          </Typography>
          <Typography variant="h4" className={classes.skipTextSign}>
            {">"}
          </Typography>
        </Swipeable>
      </>
    );
  };

  const animateButton = () => {
    // console.log("Loading 2 iamage...")
    setTimeout(() => {
      setAnimate(true);
    }, 1000);
  };

  const winCallBackHandler = () => {
    if (isEmailCapture === true) {
      setShowEmailPopUp(true);
      renderEmailPopup();
    } else {
      winCallback();
    }
  };

  const renderWinScreen = () => {
    return (
      <>
        {CMS_iwWinImage ? (
          <img
            src={CMS_iwWinImage}
            className={classes.iwGifIntro}
            onLoad={e => {
              loadScreen(100);
            }}
            alt=""
          />
        ) : (
          <img
            src={iwWinImage.src}
            className={classes.iwGifIntro}
            onLoad={e => {
              loadScreen(100);
            }}
            alt=""
          />
        )}
        {isChristmas && horizontalBar("25vh", constants.XMAS_IW_WIN_TEXT)}
        <div className={classes.claimPrizeButtonContainer}>
          <Slide
            direction="up"
            in={animate}
            mountOnEnter
            unmountOnExit
            timeout={100}
          >
            <Button
              variant="contained"
              color="secondary"
              className={classes.claimPrizeButton}
              onClick={winCallBackHandler}
            >
              <Typography variant={"h4"} className={classes.claimRewardsText}>
                {constants.IW_CLAIM_PRIZE_TEXT}
              </Typography>
            </Button>
          </Slide>
        </div>
      </>
    );
  };

  const hideEmailPopupAfterSeconds = () => {
    setTimeout(() => {
      setShowEmailPopUp(false);
    }, 1000);
  };

  const closeEmailPopup = () => {
    goBack();
    hideEmailPopupAfterSeconds();
  };

  const submitButton = data => {
    onEmailSubmitCallBack(data);
    hideEmailPopupAfterSeconds();
  };

  const renderEmailPopup = () => {
    return (
      <>
        {" "}
        <GenericDialog
          openDialog={showEmailPopUp}
          content={"EMAILTYPE"}
          clientName={clientName}
          handleCloseDialog={closeEmailPopup}
          showCloseIcon={true}
          heading="Tell us where to send your prize"
          paperStyle={{ backgroundColor: "transparent", padding: 0 }}
          closeIconBtnStyle={ConstantMapping[clientName].SVG.headerSvgStyle}
          showFullScreenDialog={true}
          showEmailPopUp={true}
          confirmCallback={submitButton}
          isHeaderTransparent={true}
        ></GenericDialog>
      </>
    );
  };

  const loadScreen = seconds => {
    //console.log("Loading screen....");
    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      //   console.log("Loading screen...11.");
      setShowText(true);
      setAnimate(true);
    }, seconds);
  };
  const showHorizentalBarOnLoading = () => {
    // console.log("Showing Text ", showText);
    return showText ? horizontalBar("25vh", getText("loser_text")) : null;
  };

  const renderLooseScreen = () => {
    return (
      <>
        {CMS_iwLooseImage ? (
          <img
            src={CMS_iwLooseImage}
            className={classes.iwGifIntro}
            onLoad={e => {
              loadScreen(100);
            }}
            alt=""
          />
        ) : (
          <img
            src={iwLooseImage.src}
            className={classes.iwGifIntro}
            onLoad={e => {
              loadScreen(100);
            }}
            alt=""
          />
        )}
        {showHorizentalBarOnLoading()}
        <div className={classes.claimPrizeButtonContainer}>
          <Slide
            direction="up"
            in={animate}
            mountOnEnter
            unmountOnExit
            timeout={100}
          >
            <Button
              variant="contained"
              color="secondary"
              className={classes.claimPrizeButton}
              onClick={() => {
                handleCloseDialog();
                history.push(`/home`);
              }}
            >
              <Typography variant="h4" className={classes.claimRewardsText}>
                {constants.IW_BACK_TEXT}
              </Typography>
            </Button>
          </Slide>
        </div>
      </>
    );
  };

  const renderContent = () => {
    switch (type) {
      case IWTypes.INTRO:
        return renderIntroScreen();
      case IWTypes.WIN:
        return renderWinScreen();
      case IWTypes.LOOSE:
        return renderLooseScreen();
    }
  };

  const renderChristmasIntroScreen = () => {
    let svgProps = Object.assign(
      {},
      ConstantMapping[clientName].SVG.headerSvgStyle
    );
    svgProps.fill = theme.palette.text.secondary;
    return (
      <div className={classes.fallback_bg}>
        <div className={classes.iwHeader}>
          <Header
            className={classes.transparentBackground}
            svgProps={svgProps}
            onSvgSelection={handleCloseDialog}
          />
        </div>
        <div
          className={classes.overlayContent}
          style={{ bottom: "14vh", padding: 0 }}
        >
          <Typography
            className={classes.contentFirst}
            style={{ fontSize: "60px" }}
          >
            Catch a
          </Typography>
          <Typography
            className={classes.contentSecond}
            style={{ fontSize: "60px" }}
          >
            snowflake
          </Typography>
        </div>
        {CMS_iwIntroImage ? (
          <img src={CMS_iwIntroImage} className={classes.iwGifIntro} alt="" />
        ) : (
          <>
            <img
              src={iwIntroImage?.src}
              className={classes.iwGifIntro}
              onClick={successCallback}
              alt=""
            />
            {/* <div className={classes.iwGifPopBalloonContainer}>
              <img
                src={iwPopBalloonImage?.src}
                className={classes.iwGifPopBalloon}
                onClick={successCallback}
                alt=""
              />
            </div> */}
          </>
        )}
        {horizontalBar("8.5vh", constants.IW_INTRO_TEXT)}
        <Swipeable
          preventDefaultTouchmoveEvent={true}
          trackTouch={true}
          onSwiped={successCallback}
          className={classes.contentBottomButton}
        >
          <Typography variant="h4" className={classes.skipContentText}>
            {constants.IW_SKIP_TEXT}&nbsp;
          </Typography>
          <Typography variant="h4" className={classes.skipTextSign}>
            {">"}
          </Typography>
        </Swipeable>
      </div>
    );
  };

  const renderChristmasInstantWinScreen = () => {
    switch (type) {
      case IWTypes.INTRO:
        return renderChristmasIntroScreen();
      case IWTypes.WIN:
        return renderWinScreen();
      case IWTypes.LOOSE:
        return renderLooseScreen();
    }
  };

  return (
    //Generic Journey
    <div className={classes.iwGifContainer}>
      {type === IWTypes.WIN &&
        isEmailCapture &&
        showEmail === "SHOW_POPUP" &&
        renderEmailPopup()}
      {isChristmas ? renderChristmasInstantWinScreen() : renderContent()}
      {/* { renderEmailPopup()}  */}
    </div>
  );
};
export default InstantWinJourney;
