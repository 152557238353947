import React from "react";
import {
  createStyles,
  makeStyles,
  Slide,
  Snackbar,
  Theme,
  Typography
} from "@material-ui/core";
import SnackBarModel, {
  SnackBarConfig
} from "Models/ReduxModels/SnackBarModel";
import { connect } from "react-redux";
import { manuallyHideSnackBar } from "Utils/SnackBarUtils";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { TransitionProps } from "@material-ui/core/transitions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close: {
      padding: theme.spacing(0.5)
    },
    container: {
      backgroundColor: theme.palette.secondary.dark,
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      borderRadius: 8,
      display: "flex"
    },
    message: {
      flex: 1,
      lineHeight: 1.1,
      alignSelf: "center",
      padding: theme.spacing(2)
    }
  })
);

export interface IProps {
  snackBarConfig: SnackBarConfig | undefined;
}

function SlideTransition(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

const CustomSnackBar: React.FC<IProps> = ({ snackBarConfig }) => {
  const classes = useStyles();

  const handleClose = () => {
    manuallyHideSnackBar();
  };
  if (snackBarConfig)
    return (
      <div>
        <Snackbar
          key={snackBarConfig.message ? snackBarConfig.message : undefined}
          open={snackBarConfig.openSnackBar}
          autoHideDuration={snackBarConfig.autoHideDuration || 5000}
          onClose={handleClose}
          TransitionComponent={SlideTransition}
        >
          <div className={classes.container}>
            <Typography variant={"h6"} className={classes.message}>
              {snackBarConfig.message}
            </Typography>
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.close}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Snackbar>
      </div>
    );
  else return null;
};

const mapStateToProps = (state: any) => {
  let snackBarConfig = SnackBarModel.getInsatnce("", state)?.props;
  return {
    snackBarConfig
  };
};

export default connect(mapStateToProps)(CustomSnackBar);
