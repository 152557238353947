import React, { Fragment, useEffect, useState } from "react";
import { getClientName, getImageDetails } from "Utils/GeneralUtils";
import { AnimationHelper, Header } from "gce-cxinteracts-ui-components-library";
import ApplicationFlag from "Models/ReduxModels/ApplicationFlag";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useStyles } from "./styles";
import { Divider, TextField } from "@material-ui/core";
import mockData from "./restaurant.json";
import AutoComplete from "Components/AutoComplete/AutoComplete";
import {
  setRestaurantListInitial,
  titleDefaultStyles,
  titleEnterStyles,
  titleLeaveStyles
} from "./RestaurantUtil";
import searchIcon from "Configurations/Clients/vodafone/Assets/RestoLocalBlendImages/icon-search-grey.png";
import EatLocalFooter from "./Footer/EatLocalFooter";
import { getRestaurantsList } from "Services/RestaurantService";
import UserDetails from "Models/ReduxModels/UserDetails";
import { useForm } from "react-hook-form";
import {
  EVENT_TYPE,
  logEvents,
  pushAllEventsToServer
} from "Services/EventService";
import { showSnackBar } from "Utils/SnackBarUtils";
import PreClaimDialog from "Routes/OfferDetails/PreClaimDialog";
import { PreClaimDialogTypes } from "Models/Interfaces/PreClaimDialogTypes";

interface IProps {
  applicationFlags: any;
  userData: any;
}

const eatLocal = "Eat Local";

const SearchScreen: React.FC<IProps> = props => {
  const { applicationFlags, userData } = props;
  const clientName = getClientName();
  const history = useHistory();
  let classes = useStyles();
  const historyState = history?.location?.state;
  let initialState = {
    name: "",
    value: ""
  };
  const [selectedResto, setSelectedResto] = useState(initialState);
  const [buttonState, setButtonState] = useState(true);
  const [restaurantList, setRestaurantList] = useState([]);
  const [typedText, setRestoSerchText] = useState("");
  const [typedLocationText, setLocationText] = useState("");
  const [options, setOptions] = useState<any[]>([]);
  const { setValue, watch } = useForm({
    mode: "onChange"
  });
  const [showEnterAnime, setShowEnterAnime] = useState(false);
  const [showLeaveAnime, setShowLeaveAnime] = useState(false);
  const [showLoader, setLoaderState] = useState(false);

  localStorage.setItem("viewType", "list"); // Update local storage

  useEffect(() => {
    setShowEnterAnime(true);
    if (applicationFlags?.eatLocalCoffeClubOffer) {
      logEvents({
        eventType: EVENT_TYPE.SEARCH_SCREEN_DISPLAYED,
        offerId: applicationFlags?.eatLocalCoffeClubID
      });
    }
    pushAllEventsToServer();
  }, []);

  const redirectToHome = () => {
    history.push("/home");
    setTimeout(() => {
      new ApplicationFlag({
        id: "",
        ...applicationFlags,
        isShowFooter: true,
        isRedirectedFromGiftingSuccessPage: false,
        eatLocalCoffeClubID: 0,
        eatLocalCoffeClubOffer: {},
        selectedShopOrResto: {}
      }).$save();
    }, 100);
  };

  const goBack = () => {
    setShowLeaveAnime(true);
    setShowEnterAnime(false);
    setTimeout(() => {
      if (historyState?.pathFrom === "search-page") {
        history.push(`/search-custom-list`);
      } else if (applicationFlags?.eatLocalCoffeClubID) {
        history.push(`/custom-vendor/${applicationFlags?.eatLocalCoffeClubID}`);
        new ApplicationFlag({
          id: "",
          ...applicationFlags,
          isShowFooter: true,
          isRedirectedFromGiftingSuccessPage: false,
          eatLocalCoffeClubID: 0,
          eatLocalCoffeClubOffer: {},
          selectedShopOrResto: {}
        }).$save();
      } else {
        new ApplicationFlag({
          id: "",
          ...applicationFlags,
          isShowFooter: true,
          isRedirectedFromGiftingSuccessPage: false,
          eatLocalCoffeClubID: 0,
          eatLocalCoffeClubOffer: {},
          selectedShopOrResto: {}
        }).$save();
        history.push(`/home`);
      }
    }, 500);
  };

  const setAutoSuggestRestaurants = async text => {
    let mock = false;
    setRestoSerchText(text);
    if (text === "") {
      setSelectedResto(initialState);
    }
    if (text) {
      setButtonState(false);
      if (mock) {
        let list: any = mockData.data.restaurantlist;
        setRestaurantList(list);
      } else {
        try {
          let isOfferEatLocal =
            applicationFlags?.eatLocalCoffeClubOffer?.addType === "Eat Local"
              ? true
              : false;
          let token = "";
          if (isOfferEatLocal) {
            token = userData?.eatLocalToken;
          } else {
            token = userData?.coffeeClubToken;
          }
          let response = await getRestaurantsList(text, "", token);

          if (response?.length) {
            setRestaurantList(response);
            setOptions(getOptions(response));
          }
        } catch (e) {
          console.log(e);
        }
      }
    } else if (!typedLocationText) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  };

  const getOptions = list => {
    return (
      list &&
      list?.map((restaurant: any) => {
        return {
          name: restaurant?.name,
          value: restaurant?.id
        };
      })
    );
  };

  const autoCompleteOptionsWithText = [
    { name: typedText, value: typedText }, // Add searched text as an option
    ...options
  ];

  const autoCompleteValueChanged = e => {
    if (e.value === typedText) {
      // If searched text is selected, handle it accordingly
      setSelectedResto({ name: typedText, value: typedText });
    } else {
      // If an option is selected, update state accordingly
      setValue("selectedResto", e.value, { shouldValidate: false });
      let updatedList = restaurantList?.filter((item: any) => {
        return item?.id === e.value;
      });
      setRestaurantList(updatedList);
      setSelectedResto(e);
    }
    setButtonState(false);
  };

  const renderSearch = () => {
    return (
      <div style={{ margin: "5vw" }}>
        <AutoComplete
          disabled={false}
          freeSolo={false}
          defaultValue={selectedResto}
          typographyVariant="subtitle2"
          value={selectedResto.value}
          name={"restaurant"}
          watch={watch}
          fieldPlaceholder={
            applicationFlags?.eatLocalCoffeClubOffer?.addType === eatLocal
              ? "Restaurants"
              : "Coffee shops"
          }
          key={
            selectedResto ? selectedResto.name : `defaultKey-${Math.random()}`
          }
          externalApiCall={true}
          textFieldStyle={{ height: "40px" }}
          autoCompleteOptions={typedText ? autoCompleteOptionsWithText : []}
          autoSuggestTextChanged={setAutoSuggestRestaurants}
          autoCompleteValueChanged={autoCompleteValueChanged}
        />

        <div>
          <TextField
            autoComplete="off"
            id={"place"}
            margin="normal"
            placeholder="Location"
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  <img src={searchIcon} alt="search" />
                </React.Fragment>
              )
            }}
            variant="filled"
            fullWidth
            onChange={e => {
              setLocationText(e.target.value);
              setButtonState(false);
            }}
            className={classes.searchTextField}
          />
        </div>
      </div>
    );
  };

  const handleClick = async e => {
    e.preventDefault();
    let offer = applicationFlags?.eatLocalCoffeClubOffer;
    if (typedText === "" && selectedResto?.name) {
      setSelectedResto(initialState);
    }
    if (selectedResto?.name || typedLocationText) {
      setLoaderState(true);

      try {
        let isOfferEatLocal =
          applicationFlags?.eatLocalCoffeClubOffer?.addType === "Eat Local"
            ? true
            : false;
        let token = "";
        if (isOfferEatLocal) {
          token = userData?.eatLocalToken;
        } else {
          token = userData?.coffeeClubToken;
        }
        let restoName = "";

        if (typedText === "") {
          restoName = "";
        } else if (selectedResto?.name) {
          restoName = selectedResto?.name;
        } else {
          restoName = typedText;
        }

        let onLoadParams = {
          page_name: `${ConstantMapping[clientName].TEALIUM_PAGE_PREFIX}Searchd ${restoName}`,
          page_subsection:
            offer?.addType === eatLocal ? "restaurants" : "coffeeShops"
        };

        let response = await getRestaurantsList(
          typedLocationText,
          restoName,
          token
        );
        if (response?.length) {
          setRestaurantList(response);
          setOptions(getOptions(response));
          new ApplicationFlag({
            id: "",
            ...applicationFlags,
            selectedShopOrResto: {
              ...selectedResto,
              locationText: typedLocationText
            }
          }).$save();
          if (offer?.addType === eatLocal) {
            logEvents({
              ...{
                restaurant: selectedResto?.name,
                location: typedLocationText
              },
              eventType: EVENT_TYPE.EAT_LOCAL_SEARCH_RESTAURANT,
              offerId: offer?.offerId
            });
          } else {
            logEvents({
              ...{
                restaurant: selectedResto?.name,
                location: typedLocationText
              },
              eventType: EVENT_TYPE.LOCAL_BLEND_SEARCH_COFFEE_SHOPS,
              offerId: offer?.offerId
            });
          }

          setRestaurantListInitial(response);

          setTimeout(() => {
            setLoaderState(false);
            history.push("/search-custom-list", {
              prevRoute: "search screen",
              offerId: history?.location?.state?.offerId,
              onloadParam: onLoadParams
            });
          }, 500);
          pushAllEventsToServer();
        } else {
          setLoaderState(false);
          showSnackBar("No results found");
        }
      } catch (e) {
        setLoaderState(false);
        console.log(e);
        // setRestaurantList([]);
      }
    } else {
      showSnackBar("Please select a restaurant or type location");
    }
  };

  return (
    <Fragment>
      <AnimationHelper
        defaultStyles={titleDefaultStyles}
        enterStyles={titleEnterStyles}
        exitStyles={titleLeaveStyles}
        showEnterAnimation={showEnterAnime}
        showExitAnimation={showLeaveAnime}
        enterAnimationDelay={650}
        exitAnimationDelay={100}
        units={{
          marginTop: "px"
        }}
      >
        <Header
          headerText=""
          isHeaderInverted={true}
          svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
          clientLogoPrimary={getImageDetails(
            ImagesMapping[clientName].clientLogoPrimary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          clientLogoSecondary={getImageDetails(
            ImagesMapping[clientName].clientLogoSecondary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          onSvgSelection={goBack}
          onLogoSelection={redirectToHome}
          isScrollHeader={false}
        />
        <form>
          <div className={classes.searchScreenContainer}>
            <div className={classes.searchScreenText}>
              <p>Search</p>
            </div>
            <Divider />
            {renderSearch()}
            {applicationFlags?.eatLocalCoffeClubOffer?.addType === eatLocal ? (
              <div className={classes.infoOnSerchScreen}>
                Use the <strong>Restaurants</strong> search box if you’re
                looking for a particular dining spot.
                <br />
                <br />
                Or use the <strong>Location</strong> search box to explore the
                full list of local gems.
              </div>
            ) : (
              <div className={classes.infoOnSerchScreen}>
                Use the <strong>Coffee shops</strong> search box to see if your
                favourite coffee spot is part of the offer.
                <br />
                <br />
                Or search by <strong>Location</strong> to explore the full list
                of local gems.
              </div>
            )}
            <div
              className={classes.searchButtonContainer}
              style={{
                marginBottom:
                  historyState?.pathFrom === "search-page" ? "0px" : "66px"
              }}
            >
              <input
                className={classes.formAapplyButton}
                type="submit"
                value={
                  applicationFlags?.eatLocalCoffeClubOffer?.addType === eatLocal
                    ? "Search restaurants"
                    : "Search Coffee shops"
                }
                onClick={handleClick}
                disabled={buttonState}
              />
            </div>
          </div>
        </form>
        {historyState?.pathFrom === "search-page" ? (
          ""
        ) : (
          <div className={classes.footerContainer}>
            <EatLocalFooter offerId={applicationFlags?.eatLocalCoffeClubID} />
          </div>
        )}
        {showLoader && (
          <PreClaimDialog
            openDialog={showLoader}
            type={PreClaimDialogTypes.LOADER}
            clientName={clientName}
            confirmCallback={() => {}}
            handleCloseDialog={() => {}}
            claimAdvice={""}
          />
        )}
      </AnimationHelper>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  let applicationFlags = ApplicationFlag.getInsatnce("", state).props;
  let userData = UserDetails.getInsatnce("", state)?.props;
  return {
    applicationFlags,
    userData
  };
};

export default connect(mapStateToProps)(SearchScreen);
