import React, { Fragment, useEffect, useState } from "react";
import OfferIdsSortedByWeight from "Models/ReduxModels/OfferIdsSortedByWeight";
import Merchants, { IMerchant } from "Models/ReduxModels/Merchants";
import Offers, { IOffers } from "Models/ReduxModels/OffersModel";
import { connect } from "react-redux";
import GridOffer from "Components/GridOffer/GridOffer";
import { useTheme } from "@material-ui/core";
import FavouriteOffers, {
  IFavouriteOffers
} from "Models/ReduxModels/FavouriteOffersModel";
import { addRemoveFavouriteOffers } from "Utils/offerUtils";
import { isEmptyObject } from "Utils/GeneralUtils";
import SortedFavouriteOfferIds, {
  OfferIds
} from "Models/ReduxModels/SortedFavouriteOfferIds";
import { getClientName } from "Utils/GeneralUtils";
import { Offer } from "Models/ReduxModels/OffersModel";
interface IProps {
  filterOfferIds: number[];
  favorites: IFavouriteOffers;
  offers: IOffers;
  merchants: IMerchant;
  offerIdsSortedByWeight: number[];
  favouriteOfferIds: OfferIds;
  onOfferClick?: () => void;
}

const RecommendedOffers: React.FC<IProps> = (props: IProps) => {
  const {
    filterOfferIds,
    merchants,
    offerIdsSortedByWeight,
    offers,
    favorites,
    favouriteOfferIds,
    onOfferClick
  } = props;
  const theme = useTheme();
  const [renderOfferIds, setRenderOfferIds] = useState<number[]>([]);
  let isFavoriteCallIP = false;

  useEffect(() => {
    if (!isEmptyObject(offers) && offerIdsSortedByWeight.length) {
      let renderIds: number[] = [];
      for (let index = 0; index < offerIdsSortedByWeight.length; index++) {
        const offerId = offerIdsSortedByWeight[index];
        if (!filterOfferIds.length || filterOfferIds.indexOf(offerId) === -1) {
          if (!offers[offerId]?.claimedIds?.length&&!offers[offerId]?.isQRActive) {
            renderIds.push(offerId);
          }
        }
        //only show two offer in recommended section, hence number 2
        if (renderIds.length === 2) {
          break;
        }
      }
      setRenderOfferIds(renderIds);
    }
  }, [offers, filterOfferIds, offerIdsSortedByWeight]);

  const renderOfferById = (offerId: number) => {
    let offer = offers[offerId];
    if (!offer) return null;
    return (
      <GridOffer
        key={offerId}
        addRemoveFavorites={(
          offerId: number,
          isStoreGiftingOffer: boolean | null
        ) => {
          if (!isFavoriteCallIP && !isStoreGiftingOffer) {
            isFavoriteCallIP = true;
            addRemoveFavouriteOffers(offerId, favorites, favouriteOfferIds)
              .then(() => {
                isFavoriteCallIP = false;
              })
              .catch(() => {
                isFavoriteCallIP = false;
              });
          }
        }}
        claimedDetails={undefined}
        clientName={getClientName()}
        merchant={merchants[offer?.merchantId]}
        offer={offer}
        style={{ margin: theme.spacing(1.25) }}
        onOfferClick={onOfferClick}
      ></GridOffer>
    );
  };
  return (
    <Fragment>
      {renderOfferIds.map((id: number) => {
        return renderOfferById(id);
      })}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const offers = Offers.getInsatnce("", state)?.props;
  const merchants = Merchants.getInsatnce("", state)?.props;
  const offerIdsSortedByWeight =
    OfferIdsSortedByWeight.getInsatnce("", state)?.props?.offerIds || [];
  const favorites = FavouriteOffers.getInsatnce("", state)?.props;
  const favouriteOfferIds = SortedFavouriteOfferIds.getInsatnce("", state)
    ?.props;
  return {
    offers,
    merchants,
    offerIdsSortedByWeight,
    favorites,
    favouriteOfferIds
  };
};
export default connect(mapStateToProps)(RecommendedOffers);
