import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const footerUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomNav: {
      backgroundColor: "#fff"
    },
    bottomNavAction: {
      color: "black"
    }
  })
);