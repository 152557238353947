import React from "react";
import { useStyles } from "./styles";

const ScrollableWrapper = props => {
  let classes = useStyles();

  return (
    <div className={classes.wrapper} id="main-container">
      {props.children}
    </div>
  );
};
export default ScrollableWrapper;
