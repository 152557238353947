import React, { useState } from "react";
import { connect } from "react-redux";
import { useStyles } from "./styles";
import { ConstantMapping } from "../../Configurations/ConstantsMapping";
import { useHistory } from "react-router-dom";
import { Typography, Button } from "@material-ui/core";
import { getClientName } from "Utils/GeneralUtils";

interface IProps {
  showOnFaq: Boolean;
  tealiumPageName?: string;
}

const TalkToUs: React.FC<IProps> = props => {
  let classes = useStyles();
  const history = useHistory();

  const { showOnFaq = false, tealiumPageName = "" } = props;
  const clientName = getClientName();


  const goToChat = () => { 
    history.push("/chat" ,"/help");
  };

  return (
    <div className={classes.bottomWrapper}>
      <div className={classes.info}>
        {!showOnFaq ? (
          <div className={classes.helpBottomContainer}>
            <Typography variant="h3" className={classes.infoHeading}>
              {ConstantMapping[clientName].TALK_TO_US_TEXT}
            </Typography>
            <Typography variant="h6" className={classes.infoText}>
              {ConstantMapping[clientName].TALK_TO_US_LINE_1}
              <span className={classes.infoBoldText}>
                {ConstantMapping[clientName].TALK_TO_US_LINE_2}
              </span>
              {ConstantMapping[clientName].TALK_TO_US_LINE_3}
            </Typography>
            <Button
              className={classes.startChat}
              variant="contained"
              color="primary"
              onClick={goToChat}
            >
              Start a web chat
            </Button>
          </div>
        ) : (
          <>
            <Typography variant="h6" className={classes.infoText}>
              {ConstantMapping[clientName].TALK_TO_US_SHORT_CONTENT}
            </Typography>
            <Button
              className={classes.webchat_button_center}
              variant="contained"
              color="primary"
              onClick={goToChat}
            >
              Start a web chat
            </Button>
          </>
        )}
      </div>
    </div>
    
  );
};

export default TalkToUs;