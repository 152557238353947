import { BaseModel } from "ReduxStore/index";

export interface Merchant {
  merchantId: number;
  name: string;
  imageUrl: string;
}

export interface IMerchant {
  [key: number]: Merchant;
}

export default class Merchants extends BaseModel<IMerchant> {
  static resource = "Merchants";
}
