import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      height: "calc(100% - 45px)",
      overflow: "scroll",
      flexDirection: "column"
    },
    containerPrizeDraw: {
      display: "flex",
      flexDirection: "column",
      overflow: "scroll",
      height: "100vh",
      background: theme.palette.primary.main,
      // height: "100%",
      marginTop: "-45px"
    },
    headerContainer: { width: "100%" },
    headerContainerPrizeDraw: {
      width: "100%",
      position: "fixed"
    },
    prizeDrawMainContentContainer: {
      // overflow: "scroll"
    },
    prizeDrawImageContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: theme.spacing(54),
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundColor: "rgba(230, 0, 0, 0.5)",
      backgroundBlendMode: "overlay"
    },
    prizeDrawContent: {
      height: "calc(100vh - 216px)",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column"
    },
    prizeDrawContentContainer: {
      margin: theme.spacing(5)
    },
    prizeDrawButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5)
    },
    prizeDrawText: {
      color: theme.palette.text.secondary,
      margin: 0,
      fontSize: "24px",
      lineHeight: 1.1
    },
    redemptionContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "column",
      flex: 1,
      background: "#F2F2F2",
      animation: "$easing_in 1s linear 0s 1"
    },
    prizeDrawRedemptionContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "column",
      flex: 1
    },
    noCode: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      flex: 1,
      background: "#F2F2F2"
    },
    countdownContainer: {
      // minHeight: theme.spacing(35),
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      paddingTop: theme.spacing(6.5)
    },
    countdownWrapper: {
      // minHeight: theme.spacing(35),
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end"
    },
    coundownTitle: {
      margin: "9px 5vw",
      animation: "$easing_in .4s linear 0s 1",
      lineHeight: "26px"
    },
    guidelinesContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignSelf: "flex-start"
    },
    guidelines: {
      padding: "5vw 5vw 0vw",
      color: theme.palette.text.primary,
      fontSize: theme.typography.h5.fontSize,
      "& p": {
        lineHeight: 1,
        margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(
          4.5
        )}px`
      },
      "& ul": {
        paddingLeft: "4vw",
        margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(
          4.5
        )}px`,
        lineHeight: 1,
        "& li": {
          margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(
            3
          )}px`
        }
      },
      "& ol": {
        paddingLeft: "4vw",
        margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(
          4.5
        )}px`,
        lineHeight: 1,
        "& li": {
          margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(
            3
          )}px`
        }
      }
    },
    guidelinesLight: {
      marginTop: theme.spacing(5),
      color: theme.palette.text.secondary,
      fontSize: "24px",
      "& p": {
        lineHeight: 1,
        margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(
          4.5
        )}px`
      },
      "& ul": {
        paddingLeft: "4vw",
        margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(
          4.5
        )}px`,
        lineHeight: 1,
        "& li": {
          margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(
            3
          )}px`
        }
      },
      "& ol": {
        paddingLeft: "4vw",
        margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(
          4.5
        )}px`,
        lineHeight: 1,
        "& li": {
          margin: `${theme.spacing(0)}px ${theme.spacing(0)}px ${theme.spacing(
            3
          )}px`
        }
      }
    },
    codeContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    codeWrapper: {
      textAlign: "center",
      fontFamily: "VodafoneRgBold",
      margin: theme.spacing(15, 0, 4)
    },
    buttonClass: {
      minHeight: theme.spacing(12),
      width: theme.spacing(38),
      textTransform: "none",
      textAlign: "center",
      overflow: "visible",
      padding: theme.spacing(0),
      fontFamily: "VodafoneRgBold",
      fontSize: theme.typography.h6.fontSize,
      margin: theme.spacing(0, 1, 3.75),
      boxShadow: theme.shadows[0],
      "@media (min-width: 375px) and (min-height:667px)": {
        margin: theme.spacing(0, 1.5, 3.75)
      },
      "@media (min-width: 414px) and (min-height:736px)": {
        margin: theme.spacing(0, 3, 3.75)
      }
    },
    giveAGiftButton: {
      minHeight: theme.spacing(12),
      width: theme.spacing(38),
      textTransform: "none",
      textAlign: "center",
      overflow: "visible",
      padding: theme.spacing(0),
      fontFamily: "VodafoneRgBold",
      fontSize: theme.typography.h6.fontSize,
      margin: theme.spacing(0, 1, 3.75),
      boxShadow: theme.shadows[4],
      "@media (min-width: 375px) and (min-height:667px)": {
        margin: theme.spacing(0, 1.5, 3.75)
      },
      "@media (min-width: 414px) and (min-height:736px)": {
        margin: theme.spacing(0, 3, 3.75)
      }
    },
    closeButton: {
      minHeight: theme.spacing(12),
      width: theme.spacing(38),
      textTransform: "none",
      textAlign: "center",
      overflow: "visible",
      padding: theme.spacing(0),
      fontFamily: "VodafoneRgBold",
      fontSize: theme.typography.h6.fontSize,
      margin: "40vh 4px 20px",
      boxShadow: theme.shadows[0]
    },
    buttonText: {
      fontWeight: "bold",
      fontSize: theme.typography.h6.fontSize,
      fontFamily: "inherit"
    },
    buttonIcon: {
      height: theme.spacing(4.5)
    },
    dialogButtonIconContainer: {
      height: theme.spacing(6),
      width: theme.spacing(10),
      alignItems: "center",
      justifyContent: "center",
      display: "inline-flex"
    },
    dialogError: { color: theme.palette.error.main },
    title: {
      marginBottom: theme.spacing(7.5)
    },
    dialogButtonIcon: {
      height: theme.spacing(5)
    },
    dialogButton: {
      padding: theme.spacing(2.5, 0),
      display: "flex",
      flex: 1,
      textTransform: "none",
      textAlign: "center"
    },
    dialogOptionText: {
      color: theme.palette.text.primary,
      marginLeft: theme.spacing(1.75),
      fontSize: "24px"
    },
    dialogActionButton: {
      margin: theme.spacing(0, 1),
      textTransform: "none",
      textAlign: "center",
      flex: 1,
      fontFamily: "VodafoneRgBold",
      height: "48px",
      boxShadow: theme.shadows[4]
    },
    dialogActionButtonContainer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      margin: theme.spacing(7.5, 0)
    },
    dialogTitleIcon: {
      margin: "25px auto"
    },
    giftedContainer: {
      padding: "0 0 5vw",
      height: "100vh",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around"
    },
    giftedHeading: {
      fontSize: theme.typography.h1.fontSize,
      color: theme.palette.text.secondary
    },
    giftedContent: {
      color: theme.palette.text.secondary,
      fontSize: "24px",
      lineHeight: "28px"
    },
    giftedButton: {
      margin: "0 auto",
      textTransform: "none",
      padding: theme.spacing(2, 15),
      fontSize: theme.typography.h5.fontSize,
      fontFamily: "VodafoneRgBold",
      color: theme.palette.primary.main
    },

    redemptiondata: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
      marginTop: theme.spacing(5.25),
      width: "100%"
    },

    clipBoardButton: {
      // minHeight: theme.spacing(12),
      marginBottom: theme.spacing(3.75),
      textTransform: "none",
      textAlign: "center",
      overflow: "visible",
      padding: theme.spacing(0, 5),
      fontFamily: "Arial",
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: "bold",
      "& .MuiButton-endIcon": {
        paddingBottom: theme.spacing(1.75),
        marginLeft: theme.spacing(1.5)
      },
      "& .MuiButton-label": {
        letterSpacing: "-1px"
      }
    },

    giftTitle: {
      fontSize: "24px",
      color: "rgb(68, 68, 68)",
      fontWeight: "bold",
      fontFamily: "VodafoneRgBold",
      margin: "0 0 15px",
      animation: "$easing_in .4s linear 0s 1"
    },

    sendGiftIcons: {
      marginTop: "4%"
    },

    backDialogActionContainer: {
      width: "50%",
      margin: "5% auto 0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      animation: "$easing_in .4s linear 0s 1"
    },

    giftDialogHeading: {
      fontSize: "24px",
      color: "rgb(68, 68, 68)",
      margin: "0px",
      fontFamily: "VodafoneRgBold",
      lineHeight: theme.typography.h3.lineHeight
    },

    giftDialogText: {
      fontSize: theme.typography.h6.fontSize,
      color: "rgb(68, 68, 68)",
      lineHeight: "22px",
      margin: "5vw 0px"
    },

    sendGiftContainer: {
      display: "flex",
      flexDirection: "column",
      margin: "18px 5vw",
      boxSizing: "border-box"
    },

    sendGiftHeaderText: {
      fontSize: "24px",
      color: theme.palette.common.white,
      fontFamily: "VodafoneRgBold"
    },

    sendGiftContent: {
      margin: theme.spacing(17, 0, 0)
    },

    giftedProblemText: {
      fontSize: theme.typography.h6.fontSize,
      lineHeight: "22px",
      color: theme.palette.common.white
    },

    sendGiftWrapper: {
      height: "100vh",
      paddingTop: "50px"
    },

    giftIconBoxImage: {
      display: "flex",
      margin: "4% auto 19%",
      height: "28vw"
    },

    countdownTimer: {
      borderBottom: "3px solid",
      borderBottomColor: theme.palette.primary.main,
      animation: "$easing_in .6s linear 0s 1"
    },
    dialogOpenAimation: {
      animation: "$openDialogTransition .2s linear 0s 1"
    },
    "@keyframes openDialogTransition": {
      from: {
        height: 0
      },
      to: {
        height: "40%"
      }
    },
    "@keyframes easing_in": {
      "0%": {
        opacity: 0
      },
      "80%": {
        opacity: 0
      },
      "100%": {
        opacity: 1
      }
    },
    barcodeGiftDetails: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around"
    },
    barcodeGift: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    giftHeader: {
      fontFamily: "VodafoneRgBold",
      fontSize: theme.typography.h6.fontSize
    }
  })
);
