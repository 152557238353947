//@ts-nocheck

import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      background: `linear-gradient(rgb(216, 216, 216), rgb(255, 255, 255))`
    },
    header: {},
    imageContainer: {},
    heroImage: {
      width: "242px",
      position: "relative"
    },
    card4: {
      position: "absolute",
      zIndex: 1,
      width: "128px",
      margin: theme.spacing(19.5, 0, 0, 18.75),
      animation: "$easing_in 0ms ease-in-out 0s 1",
      transform: "scale3d(1,1,1)"
    },
    card3: {
      position: "absolute",
      zIndex: 2,
      width: "107px",
      margin: theme.spacing(21, 0, 0, 18.75),
      animation: "$easing_in 250ms linear 0s 1",
      transform: "scale3d(1,1,1)"
    },
    card2: {
      position: "absolute",
      zIndex: 3,
      width: "126px",
      margin: theme.spacing(20.25, 0, 0, 13.25),
      animation: "$easing_in 500ms linear 0s 1",
      transform: "scale3d(1,1,1)"
    },
    card1: {
      position: "absolute",
      zIndex: 4,
      width: "143px",
      margin: theme.spacing(20, 0, 0, 8.75),
      animation: "$easing_in 750ms linear 0s 1",
      transform: "scale3d(1,1,1)"
    },
    contentWrapper: {
      marginTop: theme.spacing(-11.25),
      minHeight: "100vh"
    },
    bottomLink: {
      color: theme.palette.primary.main,
      paddingLeft: "4px"
    },
    buttonWrapper: {
      flex: "0 0 17%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      height: "100px",
      // margin: "1vh 0",
      alignItems: "center",
      "@media (min-width: 960px)": {
        margin: "3vh 8vw"
      }
    },

    summaryButtonOn: {
      width: theme.spacing(50),
      height: theme.spacing(12),
      padding: 0,
      fontSize: theme.typography.h6.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: "none",
      fontFamily: theme.typography.h3.fontFamily,
      boxShadow: theme.shadows[4],
      "&.Mui-disabled": {
        color: theme.palette.primary.contrastText
      }
    },

    "@keyframes easing_in": {
      "0%": {
        opacity: 0,
        transform: "scale3d(1.3,1.3,1.3)",
        webkitTransform: "scale3d(1.3,1.3,1.3)"
      },
      "80%": {
        opacity: 0,
        transform: "scale3d(1.15,1.15,1.15)",
        webkitTransform: "scale3d(1.15,1.15,1.15)"
      },
      "100%": {
        opacity: 1,
        transform: "scale3d(1,1,1)",
        webkitTransform: "scale3d(1,1,1)"
      }
    },

    wrapper: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      background: `linear-gradient(rgb(216, 216, 216), rgb(255, 255, 255))`,
      marginBottom: theme.spacing(-5),
      marginTop: theme.spacing(-13)
    },

    content: {
      display: "flex",
      height: "100vh",
      background: `linear-gradient(rgb(216, 216, 216), rgb(255, 255, 255))`,
      justifyContent: "center",
      minHeight: "100vh",
      flexDirection: "column"
    },

    imageWrapper: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      flex: "2 1 50%"
    },

    textWrapper: {
      display: "flex",
      fontSize: "16px",
      alignItems: "flex-start",
      flexDirection: "column",
      color: theme.palette.secondary.light,
      margin: "0vh 5vw",
      flex: "1 0 auto"
    },

    heading: {
      margin: theme.spacing(1, 0, 2),
      fontFamily: theme.typography.h3.fontFamily,
      lineHeight: "28px"
    },

    descriptionText: {
      marginTop: theme.spacing(0),
      fontSize: theme.typography.h6.fontSize,
      lineHeight: "22px"
    }
  })
);
