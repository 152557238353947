import { graphQlCall, GQLConstants } from "../Utils/GraphQLUtils";
import { GQLConstantMapping } from "Configurations/ConstantsMapping";
import { getClientName } from "Utils/GeneralUtils";

export const claimOfferGraphQLCall = (offerId: any, membershipId?: string, ratePlanId?: string, restaurantId?: string) => {
  const variables = {
    claimRequest: {
      offerId: offerId,
      redeemEcode: restaurantId ? {
        membershipId: membershipId || "",
        ratePlanId: ratePlanId || "",
        restaurantId: restaurantId || ""
      } : null
    }
  }
  const resp: any = graphQlCall(
    GQLConstants.MUTATION,
    GQLConstantMapping[getClientName()].mutations.CLAIM_OFFER,
    variables
  );
  return resp;
};

export const shareOfferGraphQLCall = (claimId: any, platform: any) => {
  const variables = {
    claimId: claimId,
    platform: platform
  };
  const resp: any = graphQlCall(
    GQLConstants.MUTATION,
    GQLConstantMapping[getClientName()].mutations.SHARE_OFFER,
    variables
  );
  return resp;
};

export const gamifiactionEmailGraphQLCall = (
  claimId: any,
  firstName: any,
  lastName: any,
  email: any,
  memberRegId: any,
  msisdn: any,
  offerPrizeName: any,
  offerId: any
) => {
  const variables = {
    claimId: claimId,
    firstName: firstName,
    lastName: lastName,
    email: email,
    memberRegId: memberRegId,
    msisdn: msisdn,
    offerPrizeName: offerPrizeName,
    offerId: offerId
  };
  const resp: any = graphQlCall(
    GQLConstants.MUTATION,
    GQLConstantMapping[getClientName()].mutations
      .SAVE_GAMIFICATION_MAILS_DETAILS,
    variables
  );
  return resp;
};

export const offerRatingGraphqlCall = (
  id: any,
  customerId: any,
  offerRating: any,
  offerId: any,
  memberRegId: any,
  feedback: any,
) => {
  const variables = {
    id: id ? id : null,
    customerId: customerId,
    offerRating: offerRating,
    offerId: offerId,
    memberRegId: memberRegId,
    feedback: feedback ? feedback : null,
  };

  const resp: any = graphQlCall(
    GQLConstants.MUTATION,
    GQLConstantMapping[getClientName()].mutations.SAVE_OFFER_RATE_DETAILS,
    variables
  );

  return resp;
};
