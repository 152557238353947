import { BaseModel } from "ReduxStore";
import { IRestaurant } from "./RestaurantCoffeeModel";

export interface IFilteredList {
  [key: number]: IRestaurant;
}

export default class FilteredRestaurants extends BaseModel<IFilteredList> {
  static resource = "FilteredRestaurantList";
}
