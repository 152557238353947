import { get } from "lodash";
import Interests from "../Models/ReduxModels/Interests";

export const sanitizeInterestData = (interests: any) => {
  return interests.map((interest: any) => {
    return get(interest, ["id"]);
  });
};

export const getInterestsData = (interestIds: number[]) => {
  const interestDetails = Interests.getInsatnce("")?.props;
  return interestIds.map((id) => {
    return interestDetails[id];
  });
};
