import { BaseModel } from "ReduxStore";

export interface IRestoLocalBlendFilter {
  offerType?: string[];
  availability?: string;
  cuisine?: string;
  previousFilters?: {
    offerType?: string[];
    availability?: string;
    cuisine?: string;
  };
}

export default class RestoLocalBFilterModal extends BaseModel<IRestoLocalBlendFilter> {
  static resource = "RestoLocalBFilterModal";
}
