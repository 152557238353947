import { graphQlCall, GQLConstants } from "../Utils/GraphQLUtils";
import { GQLConstantMapping } from "Configurations/ConstantsMapping";
import { getClientName } from 'Utils/GeneralUtils';
import FavouriteOffers, {
  IFavouriteOffers,
  FavouriteOffer
} from "../Models/ReduxModels/FavouriteOffersModel";
import { logEvents, EVENT_TYPE } from "./EventService";
import SortedFavouriteOfferIds, {
  OfferIds
} from "Models/ReduxModels/SortedFavouriteOfferIds";

export const removeFavouriteOffers = async (
  variable: any,
  favorites: IFavouriteOffers,
  favouriteOfferIds: OfferIds
) => {
  return new Promise((resolve, reject) => {
    graphQlCall(GQLConstants.MUTATION, 
      GQLConstantMapping[getClientName()].mutations.REMOVE_FAVORITE, variable)
      ?.then((response: any) => {
        if (response?.data?.removeFavourite) {
          let favoriteOffers = Object.values(favorites || {});
          resolve({ remove: response?.data?.removeFavourite });
          const favouriteOffersData = favoriteOffers.filter((favObj: any) => {
            if (parseInt(variable?.offerId) !== favObj.offerId) {
              return favObj;
            }
          });

          let favouriteOffers: IFavouriteOffers = {};
          let sortedFavouriteOfferIds: number[] = favouriteOfferIds?.offerIds;
          let index: number = sortedFavouriteOfferIds.indexOf(
            variable?.offerId
          );
          sortedFavouriteOfferIds.splice(index, 1);
          if (favouriteOffersData && favouriteOffersData.length) {
            favouriteOffersData.forEach((savedOffer: any) => {
              let favouriteOffer: FavouriteOffer = {
                offerId: savedOffer.offerId,
                createdOn: savedOffer.createdOn
              };
              favouriteOffers[savedOffer.offerId] = favouriteOffer;
            });
          }
          new FavouriteOffers({
            id: "",
            ...favouriteOffers
          }).$save();
          new SortedFavouriteOfferIds({
            id: "",
            offerIds: sortedFavouriteOfferIds
          }).$save();
          logEvents({
            eventType: EVENT_TYPE.REMOVE_FAVOURITE,
            offerId: variable?.offerId
          });
        }
      })
      .catch(error => {
        console.log(error);
        reject(false);
      });
  });
};
