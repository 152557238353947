import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import { Header } from "gce-cxinteracts-ui-components-library";
import { membershipConstants } from "../constants";
import { getClientName, getImageDetails } from "Utils/GeneralUtils";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import ApplicationFlag from "Models/ReduxModels/ApplicationFlag";
import eatLocalMembership from "Configurations/Clients/vodafone/Assets/RestoLocalBlendImages/welcomeEatLocal.png";
import localBlendMembership from "Configurations/Clients/vodafone/Assets/RestoLocalBlendImages/coffeeClubWelcome.png";
import { connect } from "react-redux";
import FilteredRestaurants from "Models/ReduxModels/FilteredRestaurants";
import RestoLocalBFilterModal from "Models/ReduxModels/RestoLocalBlendFilters";
import {
  EVENT_TYPE,
  logEvents,
  pushAllEventsToServer
} from "Services/EventService";
import { membShipUseStyles } from "./styles";

interface IProps {
  applicationFlags: any;
  subHeading: string;
  location: any;
}

const eatLocal = "Eat Local";

const WelcomeMembership: React.FC<IProps> = (props: IProps) => {
  const { applicationFlags } = props;
  let subHeading = membershipConstants.WELCOME_SUBHEADING;
  let subTitle =
    applicationFlags?.eatLocalCoffeClubOffer?.addType === eatLocal
      ? membershipConstants.WELCOME_SUBTITLE
      : membershipConstants.LOCALBLEND_WELCOME_SUBTITLE;
  const history = useHistory();
  let clientName = getClientName();
  const memberShipClasses = membShipUseStyles();
  const claimedOfferDetails = props?.location?.state?.claimedOfferDetails;
  const offerId = applicationFlags?.eatLocalCoffeClubID;
  const previousRoute = history?.location?.state?.path;
  const mainRouteFrom = history?.location?.state?.mainRouteFrom;
  const restId = JSON.parse(history?.location?.state?.offerDetail)?.id;
  const restaurantProps = history?.location?.state?.restaurantProps;
  const goBack = () => {
    setTimeout(() => {
      if (previousRoute === "membership") {
        if (applicationFlags?.selectedShopOrResto && !restId) {
          history.push("/search-custom-list");
        } else if (mainRouteFrom === "home") {
          history.push("/home");
        } else if (mainRouteFrom == "rewards") {
          history.push("/myRewards");
        } else {
          history.push("/search-screen");
        }
      } else if (previousRoute === "restaurant-details") {
        history.push(`/restaurant-details/${restId}`, {
          restaurantProps: restaurantProps,
          previousRoute: "welcome-membership"
        });
      } else {
        window.history.back();
      }
    }, 500);
  };

  const redirectToHome = () => {
    setTimeout(() => {
      history.push("/home");
      new RestoLocalBFilterModal({
        id: "",
        offerType: [],
        cuisine: "",
        availability: ""
      }).$save();
      let list = {};
      new FilteredRestaurants({
        id: "",
        ...list
      }).$save();
      new ApplicationFlag({
        id: "",
        ...applicationFlags,
        isShowFooter: true,
        isRedirectedFromGiftingSuccessPage: false,
        eatLocalCoffeClubID: 0,
        eatLocalCoffeClubOffer: {},
        selectedShopOrResto: {}
      }).$save();
    }, 500);
  };

  const getImage = () => {
    let img;
    if (applicationFlags?.eatLocalCoffeClubOffer?.addType === eatLocal) {
      img = eatLocalMembership;
    } else {
      img = localBlendMembership;
    }
    return img;
  };

  return (
    <div className={memberShipClasses.mainContainer}>
      <Header
        headerText=""
        isHeaderInverted={true}
        svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
        clientLogoPrimary={getImageDetails(
          ImagesMapping[clientName].clientLogoPrimary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        clientLogoSecondary={getImageDetails(
          ImagesMapping[clientName].clientLogoSecondary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        onSvgSelection={goBack}
        onLogoSelection={redirectToHome}
        isScrollHeader={false}
      />
      <div
        className={memberShipClasses.imageContainer}
        style={{ backgroundImage: `url(${getImage()})` }}
      ></div>
      <div className={memberShipClasses.content}>
        <Typography
          className={memberShipClasses.subHeading}
          dangerouslySetInnerHTML={{
            __html: subHeading
          }}
        />
        <Typography
          className={memberShipClasses.subTitle}
          dangerouslySetInnerHTML={{
            __html: subTitle
          }}
        />
        <div />
        <div className={memberShipClasses.buttonContainer}>
          <Button
            variant="contained"
            // color="primary"
            className={memberShipClasses.buttonOk}
            onClick={() => {
              let offer = applicationFlags?.eatLocalCoffeClubOffer;
              logEvents({
                eventType: EVENT_TYPE.USE_CARD_CLICK,
                offerId: offer?.offerId
              });

              if (offer?.addType === eatLocal) {
                logEvents({
                  eventType: EVENT_TYPE.SHOW_EAT_LOCAL_CARD,
                  offerId: offer?.offerId
                });
              } else {
                logEvents({
                  eventType: EVENT_TYPE.SHOW_LOCAL_BLEND_COFFEE_CLUB_CARD,
                  offerId: offer?.offerId
                });
              }

              pushAllEventsToServer();

              // creating anchor and clicking for go to that page.

              const claimedOfferDetailsObj =
                JSON.stringify(claimedOfferDetails);

              const path = `${window?.location?.origin}/use-card?offerId=${offerId}&claimedOfferDetails=${claimedOfferDetailsObj}&previousRoute=welcome-membership&mainRouteFrom=${mainRouteFrom}`;

              window.history.pushState({}, "", path);
              window.dispatchEvent(new PopStateEvent("popstate"));

              // history.push({
              //   pathname: "/use-card",
              //   state: {
              //     offerId: offerId,
              //     claimedOfferDetails: claimedOfferDetails,
              //     path: "welcome-membership",
              //     mainRouteFrom: mainRouteFrom
              //   }
              // });
            }}
          >
            <Typography>{"Use card"}</Typography>
          </Button>
          <div
            className={memberShipClasses.cancelPermissionText}
            onClick={e => goBack()}
          >
            <Typography>{"Back"}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  let applicationFlags = ApplicationFlag.getInsatnce("", state).props;
  return {
    applicationFlags
  };
};

export default connect(mapStateToProps)(WelcomeMembership);
