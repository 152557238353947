import React, { Fragment, useEffect, useState } from "react";
import {
  getClientName,
  getImageDetails,
  isEmptyObject
} from "Utils/GeneralUtils";
import { Header } from "gce-cxinteracts-ui-components-library";
import ApplicationFlag from "Models/ReduxModels/ApplicationFlag";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useStyles } from "./styles";
import { Divider } from "@material-ui/core";
import RestaurantsList, {
  IList
} from "Models/ReduxModels/RestaurantCoffeeModel";
import RestoLocalBFilterModal, {
  IRestoLocalBlendFilter
} from "Models/ReduxModels/RestoLocalBlendFilters";
import FilteredRestaurants from "Models/ReduxModels/FilteredRestaurants";

interface IProps {
  applicationFlags: any;
  restaurantDetails: IList;
  filterDetails: IRestoLocalBlendFilter;
}

const CuisineFilter: React.FC<IProps> = props => {
  const { applicationFlags, restaurantDetails, filterDetails } = props;
  const clientName = getClientName();
  const history = useHistory();
  let classes = useStyles();
  const [cuisineList, setCuisineList] = useState([]);
  const [selectedCuisines, setSelectedCuisines] = useState<string[]>([]);
  const [clear, setClear] = useState(false);
  const renderedNames: any = [];

  useEffect(() => {
    if (!isEmptyObject(restaurantDetails)) {
      let newList = Object.assign(restaurantDetails);
      let updatedList: any = [];
      Object.entries(newList).map(([key, value]) => {
        let val = value;
        if (val !== "") {
          updatedList.push(val);
        }
      });

      setCuisineList(updatedList);
    }
  }, [restaurantDetails]);

  useEffect(() => {
    setTimeout(() => {
      if (filterDetails?.cuisine !== "") {
        let selectedCuisines = filterDetails?.cuisine?.split(",");

        if (selectedCuisines?.length) {
          setClear(true);
          selectedCuisines?.forEach(offer => {
            const stringWithoutWhitespace = offer.trimStart();
            const ele: any = document.getElementById(stringWithoutWhitespace);
            if (ele) {
              ele.checked = true;
            }
          });
        } else {
          setClear(false);
        }
      }
    }, 100);
  }, [filterDetails]);

  useEffect(() => {
    if (filterDetails?.cuisine) {
      let allSelectedCuisines = filterDetails.cuisine
        .split(",")
        .map(cuisine => cuisine.trim());

      setSelectedCuisines(allSelectedCuisines);
    }
  }, []);

  const handleClear = e => {
    e.preventDefault();
    const selectedOffer: any = document.querySelectorAll(
      'input[name="cuisine"]'
    );

    if (selectedOffer) {
      selectedOffer.forEach(checkbox => {
        checkbox.checked = false;
      });
    }
    setSelectedCuisines([]);
    setClear(false);
  };

  const redirectToHome = () => {
    new RestoLocalBFilterModal({
      id: "",
      offerType: [],
      cuisine: "",
      availability: "",
      previousFilters: {}
    }).$save();
    let list = {};
    new FilteredRestaurants({
      id: "",
      ...list
    }).$save();
    new ApplicationFlag({
      id: "",
      ...applicationFlags,
      isShowFooter: true,
      isRedirectedFromGiftingSuccessPage: false,
      eatLocalCoffeClubID: 0,
      eatLocalCoffeClubOffer: {},
      selectedShopOrResto: {}
    }).$save();
    setTimeout(() => history && history.push("/home"), 500);
  };

  const goBack = () => {
    setTimeout(() => window.history.back(), 500);
  };

  const handleCuisine = (e, item) => {
    e.preventDefault();
    const ele: any = document.getElementById(item);
    if (ele) {
      ele.checked = true;
    }
  };

  let count = 1;

  const handleCardClick = (item: string) => {
    if (selectedCuisines.includes(item)) {
      setSelectedCuisines(prev => prev.filter(cuisine => cuisine !== item));
    } else {
      setSelectedCuisines(prev => [...prev, item]);
    }
  };

  const renderCard = item => {
    const isSelected = selectedCuisines.includes(item);
    return (
      <div
        className={classes.cuisienCard}
        id={`{item}${count++}`}
        style={{
          backgroundColor: isSelected ? "#E60000" : "#f6f6f6",
          color: isSelected ? "#ffffff" : "#000000"
        }}
        onClick={e => {
          handleCardClick(item);
          handleCuisine(e, item);
          const selectedOffer: any = document.querySelectorAll(
            'input[name="cuisine"]:checked'
          );
          if (selectedOffer?.length) {
            setClear(true);
          } else {
            setClear(false);
          }
        }}
      >
        {item}
      </div>
    );
  };

  const data = [
    "Italian",
    "British",
    "Indian",
    "International",
    "American",
    "Asian"
  ];

  const renderSuggestions = (element: any) => {
    const cuisineName = element?.cuisines[0]?.name;
    if (renderedNames.includes(cuisineName)) {
      return null;
    } else renderedNames.push(cuisineName);
    return (
      <>
        <div className={classes.availOptions} key={element}>
          <label htmlFor={element?.cuisines[0]?.name}>
            {element?.cuisines[0]?.name}
          </label>
          <input
            type="checkBox"
            name="cuisine"
            id={element?.cuisines[0]?.name}
            value={element?.cuisines[0]?.name}
            checked={selectedCuisines.includes(element?.cuisines[0]?.name)}
            onChange={() => handleCardClick(element?.cuisines[0]?.name)}
            onClick={e => {
              const selectedOffer: any = document.querySelectorAll(
                'input[name="cuisine"]:checked'
              );
              if (selectedOffer?.length) {
                setClear(true);
              } else {
                setClear(false);
              }
            }}
          />
        </div>
        <Divider />
      </>
    );
    // });
  };

  const handleClick = e => {
    e.preventDefault();
    const selectedDay: any = document.querySelectorAll('input[name="cuisine"]');

    if (selectedDay) {
      const checkedValues: any = [];
      selectedDay.forEach(checkbox => {
        if (checkbox.checked) {
          checkedValues.push(checkbox.value);
        }
      });

      new RestoLocalBFilterModal({
        id: "",
        ...filterDetails,
        offerType: filterDetails?.offerType || [],
        cuisine: selectedCuisines.join(","),
        availability: filterDetails?.availability || ""
      }).$save();
    }
    history.push("/custom-filters");
  };

  return (
    <Fragment>
      <Header
        headerText=""
        isHeaderInverted={true}
        svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
        clientLogoPrimary={getImageDetails(
          ImagesMapping[clientName].clientLogoPrimary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        clientLogoSecondary={getImageDetails(
          ImagesMapping[clientName].clientLogoSecondary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        onSvgSelection={goBack}
        onLogoSelection={redirectToHome}
        isScrollHeader={false}
      />
      <div className={classes.cuisineFilterContainer}>
        <div className={classes.cuisineFilterText}>
          <p className={classes.filterName}>Cuisine</p>
          {
            <span className={classes.cuisineClearText} onClick={handleClear}>
              {clear ? "Clear all" : ""}
            </span>
          }
        </div>
        <Divider />

        <div className={classes.cuisineContent}>
          <p>Popular categories</p>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              gridGap: "14px"
            }}
          >
            {data.map(item => {
              return renderCard(item);
            })}
          </div>
          <div style={{ marginTop: "40px" }}>
            {cuisineList?.map(element => {
              return renderSuggestions(element);
            })}
          </div>
        </div>

        <div className={classes.cuisineButtonContainer}>
          <input
            className={classes.formAapplyButton}
            type="submit"
            value="Apply"
            onClick={handleClick}
          />
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  let applicationFlags = ApplicationFlag.getInsatnce("", state).props;
  let restaurantDetails = RestaurantsList.getInsatnce("", state).props;
  let filterDetails = RestoLocalBFilterModal.getInsatnce("", state)?.props;

  return {
    applicationFlags,
    restaurantDetails,
    filterDetails
  };
};

export default connect(mapStateToProps)(CuisineFilter);
