export enum PreClaimDialogTypes {
  "LOADER",
  "SHAREABLE",
  "PRIZEDRAW",
  "TIMER",
  "OTHER",
  "GIFTHELP",
  "EATLOCAL"
}

export enum IWTypes {
  "INTRO",
  "WIN",
  "LOOSE"
}

export enum LuckyDipTypes {
  "INTRO",
  "WIN"
}

export enum SpinToWinTypes {
  "INTRO",
  "WIN"
}

export enum EmailPopupTypes {
  // to show the email popup for offer set from CMS
  "SHOW_POPUP",
  // to hide the email popup for offer which is not set from CMS
  "DETAILS_SUBMITTED",
  // collected - email popup is set from CMS - user fills that and show screen like prizedraw
  "EXPIRED",
  "NONE"
}
