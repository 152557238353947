import React, { useEffect } from "react";
import { Button } from "@material-ui/core";
import { Header } from "gce-cxinteracts-ui-components-library";
import { useStyles } from "./styles";
import { ConstantMapping } from "../../../Configurations/ConstantsMapping";
import { History } from "history";
import { connect } from "react-redux";
import { getImageDetails } from "Utils/GeneralUtils";
import {
  EVENT_TYPE,
  logEvents,
  pushAllEventsToServer
} from "Services/EventService";
import UserProfile, {
  UserProfileDetails
} from "../../../Models/ReduxModels/UserProfileModel";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { getClientName, redirectToNextRoute } from "Utils/GeneralUtils";
import { FunctionMapper } from "Configurations/FunctionMapping";

interface IProps {
  history: History;
  userProfileData: UserProfileDetails;
}

const OnboardingSummary: React.FC<IProps> = props => {
  const classes = useStyles();
  const clientName = getClientName();
  const { history, userProfileData } = props;
  const { permissions } = userProfileData;
  let onLoadParams: any = {
    event_name: "onboarding_complete"
  };
  let page_name, accepted;

  useEffect(() => {
    if (checkPermission()) {
      page_name = "uk>app>veryme rewards>permissions>accepted";
      accepted = true;
    } else {
      page_name = "uk>app>veryme rewards>permissions>not accepted";
      accepted = false;
    }
    onLoadParams = { ...onLoadParams, page_name, accepted };
    FunctionMapper[getClientName()].pageLoadHook({
      tealium: {
        previous_page_name: `${window.previousPage}`,
        page_subsection: "permissions",
        page_name
      }
    });
    logEvents({ eventType: EVENT_TYPE.ONBOARDING_SUMMARY_LANDED });
    pushAllEventsToServer();
  }, []);

  const onClickStartButton = () => {
    history.push("/home", { onLoadParams });
    logEvents({ eventType: EVENT_TYPE.NEXT_PAGE_ONBOARDING });
    logEvents({ eventType: EVENT_TYPE.ONBOARDING_SUMMARY_EXITED });
    pushAllEventsToServer();
  };

  const setCardImage = (value: Boolean, card: string, crossCard: string) => {
    if (value) {
      return card;
    } else {
      return crossCard;
    }
  };

  const checkPermission = () => {
    if (
      permissions &&
      permissions.profiling === true &&
      permissions.location === true &&
      permissions.traffic === true &&
      permissions.sms === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getSummaryHeading = () => {
    if (checkPermission()) {
      return (
        <h2 className={classes.heading}>
          {ConstantMapping[clientName].ONBOARDING_SUMMARY_HEADING}
        </h2>
      );
    } else {
      return (
        <h2 className={classes.heading}>
          {ConstantMapping[clientName].ONBOARDING_CROSSCARD_SUMMARY_HEADING}
        </h2>
      );
    }
  };

  const getSummaryContent = () => {
    if (checkPermission()) {
      return (
        <p className={classes.descriptionText}>
          {ConstantMapping[clientName].ONBOARDING_SUMMARY_CONTENT}
          <a
            className={classes.bottomLink}
            href={ConstantMapping[clientName].PRIVACY_URL}
            rel="external"
            target="_blank"
          >
            {ConstantMapping[clientName].CHANGE_SETTING_LINK}
          </a>
        </p>
      );
    } else {
      return (
        <p className={classes.descriptionText}>
          {ConstantMapping[clientName].ONBOARDING_CROSSCARD_SUMMARY_CONTENT1}
          <a
            className={classes.bottomLink}
            href={ConstantMapping[clientName].PRIVACY_URL}
            rel="external"
            target="_blank"
          >
            {ConstantMapping[clientName].ONBOARDING_CHANGE_SETTING_LINK}
          </a>
          {ConstantMapping[clientName].ONBOARDING_CROSSCARD_SUMMARY_CONTENT2}
        </p>
      );
    }
  };

  return (
    <div className={classes.mainContainer}>
      <Header
        className={classes.header}
        isHeaderInverted={false}
        clientLogoPrimary={getImageDetails(
          ImagesMapping[clientName].clientLogoPrimary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        clientLogoSecondary={getImageDetails(
          ImagesMapping[clientName].clientLogoSecondary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        isScrollHeader={false}
        primaryLogoTabIndex={-1}
        secondaryLogoTabIndex={-1}
      />

      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          <div
            className={classes.imageWrapper}
            style={{ position: "relative" }}
          >
            <div style={{ display: "block" }}>
              <div id="onb2">
                <img
                  className={classes.card1}
                  alt="alt_img"
                  src={setCardImage(
                    permissions.sms,
                    ImagesMapping[clientName].summaryCard1,
                    ImagesMapping[clientName].noSummaryCard1
                  )}
                />
                <img
                  className={classes.card2}
                  alt="alt_img"
                  src={setCardImage(
                    permissions.traffic,
                    ImagesMapping[clientName].summaryCard2,
                    ImagesMapping[clientName].noSummaryCard2
                  )}
                />
                <img
                  className={classes.card3}
                  alt="alt_img"
                  src={setCardImage(
                    permissions.location,
                    ImagesMapping[clientName].summaryCard3,
                    ImagesMapping[clientName].noSummaryCard3
                  )}
                />
                <img
                  className={classes.card4}
                  alt="traffic_img"
                  src={setCardImage(
                    permissions.profiling,
                    ImagesMapping[clientName].summaryCard4,
                    ImagesMapping[clientName].noSummaryCard4
                  )}
                />
                <img
                  className={classes.heroImage}
                  alt="Traffic_Screen"
                  src={ImagesMapping[clientName].SummaryHeroImg}
                />
              </div>
            </div>
          </div>
          <div className={classes.textWrapper}>
            {getSummaryHeading()}
            {getSummaryContent()}
          </div>
          <div className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="primary"
              className={classes.summaryButtonOn}
              onClick={onClickStartButton}
            >
              {ConstantMapping[clientName].SUMMARY_BUTTON_TEXT}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  let userProfileData = UserProfile.getInsatnce("local", state)?.props;
  return {
    userProfileData
  };
};

export default connect(mapStateToProps)(OnboardingSummary);
