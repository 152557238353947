import React, { useEffect, useState, useMemo } from "react";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { ConstantMapping } from "../../../Configurations/ConstantsMapping";
import { useStyles } from "./styles";
import { checkChristmasMonth, getCurrentTime } from "Utils/GeneralUtils";
import moment from "moment";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { getClientName, getCMSDrivenImageURL } from "Utils/GeneralUtils";
import ApplicationFlag, {
  IApplication
} from "Models/ReduxModels/ApplicationFlag";
import { useHistory } from "react-router-dom";
import UserDetails, { IUserDetails } from "Models/ReduxModels/UserDetails";
import AppConfig from "Models/ReduxModels/AppConfig";
import CMSImages, {
  IVisitoWinCongigurations
} from "Models/ReduxModels/CMSImages";

interface IProps {
  updateShowPrimer: () => void;
  applicationFlags: IApplication;
  userData: IUserDetails;
  cshowPrimerType: string;
  c_CMSDrivenImage?: string;
  cms_v2w_images: IVisitoWinCongigurations;
}

const HomeScreenPrimer: React.FC<IProps> = props => {
  const classes = useStyles();
  const {
    updateShowPrimer,
    applicationFlags,
    userData,
    cshowPrimerType,
    c_CMSDrivenImage,
    cms_v2w_images
  } = props;
  const clientName = getClientName();
  const showPrimerType = cshowPrimerType;
  const _CMSDrivenImage = c_CMSDrivenImage;
  const [showVMRFirstScreen, setShowVMRFirstScreen] = useState(false);
  const [showVMRSecondScreen, setShowVMRSecondScreen] = useState(false);
  const { fullVisitToWinPopup, isConvergedUser, appAccessLevel } =
    props?.userData;

  //  let showPrimerType=getIntroScreenToShow(appAccessLevel,isVisitToWin,_CMSDrivenImage,isConvergedUser,CMS_introScreenConfigurations);

  const history = useHistory();
  const isChristmas = null;
  const checkChristmas = checkChristmasMonth(
    ConstantMapping[clientName].XMAS_IW_START_DATE,
    ConstantMapping[clientName].XMAS_IW_END_DATE
  );

  useEffect(() => {
    if (showPrimerType === "VMRLITE") {
      setShowVMRFirstScreen(true);
    }
  }, []);

  const close = () => {
    // localStorage.setItem(
    //   ConstantMapping[clientName].PRIMER_LOCAL_STORAGE_KEY,
    //   getCurrentTime().toString()
    // );
    if (isChristmas) {
      localStorage.setItem(
        ConstantMapping[clientName].XMAS_PRIMER_LOCAL_STORAGE_KEY,
        getCurrentTime().toString()
      );
    }
    // if (showPrimerType==="VMRLITE") {
    localStorage.setItem(showPrimerType, getCurrentTime().toString());

    if (isConvergedUser) {
      localStorage.setItem(
        ConstantMapping[clientName].CONVERGED_PRIMER_LOCAL_STORAGE_KEY,
        getCurrentTime().toString()
      );
    }
    //   }
    // if (showPrimerType==="VMRLITE") {
    localStorage.setItem(showPrimerType, getCurrentTime().toString());
    //   }
    updateShowPrimer();
  };

  const closeVMRLite = () => {
    if (showVMRSecondScreen) {
      close();
    }
    if (showVMRFirstScreen) {
      setTimeout(() => {
        setShowVMRFirstScreen(false);
        setShowVMRSecondScreen(true);
      }, 700);
    }
  };

  const closeVisitToWin = () => {
    new UserDetails({
      id: "",
      ...userData,
      fullVisitToWinPopup: false
    }).$save();
    updateShowPrimer();
  };

  const enterVisitToWinOfferDetailsPage = () => {
    closeVisitToWin();
    history.push(`/offer-details/${applicationFlags?.visitToWinId}`);
  };

  const setTitleText = () => {
    if (isChristmas) {
      return ConstantMapping[clientName].PRIMER_CHRISTMAS_TEXT_ONE.replace(
        /\\n/g,
        "\n"
      );
    } else if (showPrimerType === "VMRLITE" && showVMRFirstScreen) {
      return ConstantMapping[clientName].VMRLite_WELCOME_TEXT_ONE;
    } else if (showPrimerType === "VMRLITE" && showVMRSecondScreen) {
      return ConstantMapping[clientName].VMRLite_TOPUP_WELCOME_TEXT_ONE;
    } else if (showPrimerType === "VISITTOWIN") {
      return ConstantMapping[clientName].VISIT_TO_WIN_TEXT_ONE;
    } else {
      return ConstantMapping[clientName].PRIMER_WELCOME_TEXT_ONE;
    }
  };

  const setDescriptionText = () => {
    if (isChristmas) {
      return ConstantMapping[clientName].PRIMER_CHRISTMAS_TEXT_TWO;
    } else if (showPrimerType === "VMRLITE" && showVMRFirstScreen) {
      return ConstantMapping[clientName].VMRLite_WELCOME_TEXT_TWO;
    } else if (showPrimerType === "VMRLITE" && showVMRSecondScreen) {
      return ConstantMapping[clientName].VMRLite_TOPUP_WELCOME_TEXT_TWO;
    } else if (showPrimerType === "VISITTOWIN") {
      return ConstantMapping[clientName].VISIT_TO_WIN_TEXT_TWO;
    } else {
      return ConstantMapping[clientName].PRIMER_WELCOME_TEXT_TWO;
    }
  };

  const textOne = setTitleText();
  const textTwo = setDescriptionText();

  const setImage = () => {
    if (isChristmas) {
      return `url("${ImagesMapping[clientName].homeScreenChristmas}")`;
    } else if (showPrimerType === "VMRLITE" && showVMRFirstScreen) {
      return `url("${ImagesMapping[clientName].vmrLite_AppIntro_First}")`;
    } else if (showPrimerType === "VMRLITE" && showVMRSecondScreen) {
      return `url("${ImagesMapping[clientName].vmrLite_AppIntro_Second}")`;
    } else if (showPrimerType === "VISITTOWIN") {
      return `url("${ImagesMapping[clientName].visit_To_Win_Bg}")`;
    } else if (showPrimerType === "CMSDRIVEN") {
      return `url("${_CMSDrivenImage}")`;
    }
    return ` url("${ImagesMapping[clientName].homeScreenPrimerImage}")`;
  };

  const buttonText = () => {
    if (isChristmas) {
      return ConstantMapping[clientName].LANDING_BUTTON_TEXT;
    } else if (showPrimerType === "VMRLITE" && showVMRFirstScreen) {
      return "Next";
    } else if (showPrimerType === "VMRLITE" && showVMRSecondScreen) {
      return "Let's go";
    } else if (showPrimerType === "VISITTOWIN") {
      return "Enter now";
    }
    return ConstantMapping[clientName].LANDING_BUTTON_TEXT;
  };

  const renderHomeScreenPrimerUI = (
    closeFunc,
    enterFunc: { (): void } | null,
    buttonStyle: {} | null = null
  ) => (
    <>
      <img
        className={classes.closeIcon}
        alt={ConstantMapping[clientName].PRIMER_CLOSE_IMAGE_ALT_TEXT}
        src={ImagesMapping[clientName].iconCloseWhite}
        onClick={closeFunc}
      />
      <div
        className={`${classes.primerWelcomeTextOne} ${
          isChristmas && classes.christmasPrimerText
        }`}
      >
        {textOne}
      </div>
      <div className={classes.primerWelcomeTextTwo}>{textTwo}</div>
      <div className={classes.primerButtonWrapper}>
        <Button
          variant="contained"
          className={classes.primerButton}
          onClick={enterFunc ? enterFunc : closeFunc}
          style={{ ...buttonStyle }}
        >
          {buttonText()}
        </Button>
      </div>
    </>
  );

  const RenderComponentElement = ({ children }) => {
    return (
      <div
        className={classes.primerContainer}
        style={{
          backgroundImage: setImage()
        }}
      >
        {children}
      </div>
    );
  };

  const getV2WImages = (): JSX.Element => {
    return (
      <>
        {cms_v2w_images.v2w_full_overlay_background_IMAGEURL !== "" && (
          <img
            className={classes.visitToWin_HeartImg}
            style={{ marginTop: "-19px", height: "100vh" }}
            alt={ConstantMapping[clientName].VISIT_TO_WIN_MOCK_ENTRIES_IMG}
            src={cms_v2w_images.v2w_full_overlay_background_IMAGEURL}
          ></img>
        )}
        <img
          className={classes.visitToWin_Logo}
          src={ImagesMapping[clientName].visit_To_Win_Logo}
          alt={ConstantMapping[clientName].VISIT_TO_WIN_LOGO}
        ></img>

        <img
          className={classes.visitToWin_HeartImg}
          alt={ConstantMapping[clientName].VISIT_TO_WIN_MOCK_ENTRIES_IMG}
          src={
            cms_v2w_images.v2w_full_overlay_popup_IMAGEURL !== ""
              ? cms_v2w_images.v2w_full_overlay_popup_IMAGEURL
              : checkChristmas
              ? ImagesMapping[clientName].iwVisit_To_Win_FullScreen_Heart
              : ImagesMapping[clientName].visit_To_Win_FullScreen_Heart
          }
        ></img>
        {/*checkChristmas && (
          <img
            className={classes.visitToWin_HeartImg}
            style={{ marginTop: "-26px" }}
            alt={ConstantMapping[clientName].VISIT_TO_WIN_MOCK_ENTRIES_IMG}
            src={ImagesMapping[clientName].iwVisit_To_Win_Snowflakes_Heart}
          ></img>
        )*/}
      </>
    );
  };

  const RenderMultipleImageComponentElement = ({ children }) => {
    return (
      <div className={classes.multiImagePrimerContainer}>
        <div className={classes.multiImagePrimerScreen}>{getV2WImages()}</div>
        <div
          style={{
            marginBottom: "20px",
            alignItems: "center",
            textAlign: "center"
          }}
        >
          {children}
        </div>
      </div>
    );
  };

  const RenderMultipleImageConversedComponentElement = () => {
    return (
      <div className={classes.multiImageCovergedPrimerContainer}>
        <div className={classes.multiImageCovergedPrimerScreen}>
          <img
            alt=""
            style={{ height: "11rem", width: "11rem" }}
            src={ImagesMapping[clientName].vfTogetherRewaredRoundIcon}
          />
        </div>
        <div
          style={{
            //marginBottom: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            marginTop: "7rem"
          }}
        >
          <img
            className={classes.closeIcon}
            alt={ConstantMapping[clientName].PRIMER_CLOSE_IMAGE_ALT_TEXT}
            src={ImagesMapping[clientName].iconCloseWhite}
            onClick={close}
          />
          <div className={classes.primerCovergedTextOne}>
            {ConstantMapping[clientName].CONVERGED_HEADER_TITLE}
          </div>
          <div className={classes.primerCovergedTextContainer}>
            <div className={classes.primerCovergedTextTwoFirst}>
              {ConstantMapping[clientName].CONVERGED_HEADER_TITLE_SUBHEADER1}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <img
                src={ImagesMapping[clientName].vfWhiteIcon}
                alt=""
                style={{ height: "3rem", width: "3rem" }}
              ></img>
              <div className={classes.primerCovergedTextTwoSubContainer}>
                <div className={classes.primerCovergedTextTwoSecond}>
                  {
                    ConstantMapping[clientName]
                      .CONVERGED_HEADER_TITLE_SUBHEADER2
                  }
                </div>
                <div className={classes.primerCovergedTextTwoSecondSub}>
                  {
                    ConstantMapping[clientName]
                      .CONVERGED_HEADER_TITLE__SUBHEADER3
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const showUI = () => {
    if (showPrimerType === "VMRLITE") {
      const vmrLitePrimerScreen = renderHomeScreenPrimerUI(closeVMRLite, null);
      return (
        <RenderComponentElement>{vmrLitePrimerScreen}</RenderComponentElement>
      );
    } else if (showPrimerType === "VISITTOWIN") {
      const visitToWinPrimerScreen = renderHomeScreenPrimerUI(
        closeVisitToWin,
        enterVisitToWinOfferDetailsPage,
        { color: "#e60000" }
      );
      return (
        <RenderMultipleImageComponentElement>
          {visitToWinPrimerScreen}
        </RenderMultipleImageComponentElement>
      );
    }
    if (showPrimerType === "CONVERGED") {
      return <RenderMultipleImageConversedComponentElement />;
    } else {
      const normalPrimerScreen = renderHomeScreenPrimerUI(close, null);
      return (
        <RenderComponentElement>{normalPrimerScreen}</RenderComponentElement>
      );
    }
  };

  return <>{showUI()}</>;
};

const mapStateToProps = (state: any) => {
  const applicationFlags = ApplicationFlag.getInsatnce("", state).props;
  const appConfigintroScreen = AppConfig.getInsatnce(
    "intro-screen",
    state
  )?.props;
  const userData = UserDetails.getInsatnce("", state)?.props;
  const cms_v2w_images = CMSImages.getInsatnce("", state)?.props
    .CMS_visitToWinConfigurations;
  const props = {
    applicationFlags,
    userData,
    appConfigintroScreen,
    cms_v2w_images
  };
  return props;
};
export default connect(mapStateToProps)(HomeScreenPrimer);
