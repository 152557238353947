import { createMuiTheme } from "@material-ui/core";
import VodafoneRegular from "Configurations/Clients/vodafone/Assets/Fonts/vodafonerg-webfont.ttf";
import VodafoneBold from "Configurations/Clients/vodafone/Assets/Fonts/vodafonerg-webfont-bold.ttf";

const vodafone = {
  fontFamily: "VodafoneRg",
  src: `
    local('VodafoneRg'),
    local('VodafoneRg'),
    url(${VodafoneRegular}) format('truetype')
  `
};

const vodafoneBold = {
  fontFamily: "VodafoneRgBold",
  fontStyle: "normal",
  fontWeight: "normal",
  src: `
    local('VodafoneRg-Bold'),
    local('VodafoneRg-Bold'),
    url(${VodafoneBold}) format('truetype')
  `
};

export const VodafoneTheme = createMuiTheme({
  palette: {
    common: { black: "#000000", white: "#ffffff" },
    background: { paper: "#d2c7c7", default: "#ffffff" },
    primary: {
      main: "#e60000",
      dark: "#bd0000",
      light: "#e57373",
      contrastText: "#ffffff"
    },
    secondary: {
      main: "#ffffff",
      contrastText: "#000000",
      dark: "#333333",
      light: "#444444"
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#ffffff"
    },
    text: {
      primary: "#000000",
      secondary: "#ffffff",
      disabled: "#000000",
      hint: "#000000"
    },
    divider: "#d3d3d3"
  },
  typography: {
    fontFamily: "VodafoneRg, VodafoneRgBold",
    h1: {
      fontSize: 38
    },
    h2: {
      fontSize: 34
    },
    h3: {
      fontSize: 22,
      lineHeight: 1,
      fontFamily: "VodafoneRgBold"
    },
    h4: {
      fontSize: 22,
      fontWeight: "normal",
      lineHeight: 1
    },
    h5: {
      fontSize: 18,
      lineHeight: 1,
      fontFamily: "VodafoneRgBold"
    },
    h6: {
      fontSize: 18
    },
    subtitle1: {
      fontSize: 16
    },
    subtitle2: {
      fontSize: 12
    }
  },
  spacing: 4,
  shape: {
    borderRadius: 9
  },
  shadows: [
    "none",
    "rgba(0, 0, 0, 0.25) 0px 5px 20px 0px",
    "rgba(0, 0, 0, 0.25) 0px 2px 10px 0px",
    "rgba(0,0,0,0.1) 0px 3px 6px",
    "none",
    "rgba(0, 176, 202, 0.3) 0px 5px 20px 0px",
    "0px 5px 20px 0px",
    "rgba(230, 0, 0, 0.3) 0px 5px 15px -5px",
    "rgba(230, 0, 0, 0.3) 0px 5px 20px 0px",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    ""
  ],

  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [vodafone, vodafoneBold]
      }
    }
  }
});
