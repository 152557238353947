//@ts-nocheck

import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      marginRight: theme.spacing(-5),
      height: "100vh",
      "@media (max-width : 960px)": {
        marginRight: theme.spacing(0)
      }
    },
    header: {
      "& .makeStyles-toolbar-21": {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
      }
    },
    contentWrapper: {
      marginTop: theme.spacing(-11.25),
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column"
    },
    wrapper: {
      display: "flex",
      flexDirection: "column"
    },
    mainContent: {
      position: "relative",
      boxSizing: "border-box",
      marginTop: "8vh",
      marginBottom: theme.spacing(-6),
      overflowX: "scroll",
      overflowY: "scroll",
      marginLeft: theme.spacing(3),
      "@media (max-height: 320px)": {
        marginTop: theme.spacing(0)
      }
    },
    content: {
      display: "flex",
      height: "100vh",
      background: `linear-gradient(rgb(216, 216, 216), rgb(255, 255, 255))`,
      justifyContent: "center",
      minHeight: "100vh",
      flexDirection: "column",
      flex: "1 1 100%"
    },
    textWrapper: {
      display: "flex",
      fontSize: "16px",
      alignItems: "flex-start",
      flexDirection: "column",
      color: theme.palette.secondary.light,
      margin: "0vh 5vw",
      flex: "1 0 auto"
    },
    buttonWrapper: {
      flex: "0 0 17%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      height: "100px",
      alignItems: "center",
      "@media (min-width: 960px)": {
        margin: "3vh 8vw"
      }
    },
    profileButtonOff: {
      color: theme.palette.primary.main,
      textTransform: "none",
      padding: theme.spacing(0),
      fontSize: theme.typography.h6.fontSize,
      marginTop: theme.spacing(4.5),
      lineHeight: "1",
      "& .MuiButton-label": {
        display: "flex",
        justifyContent: "center"
      }
    },
    profileButtonOn: {
      width: theme.spacing(50),
      height: theme.spacing(12),
      fontSize: theme.typography.h6.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: "none",
      fontFamily: theme.typography.h3.fontFamily,
      boxShadow: theme.shadows[4],
      "&.Mui-disabled": {
        color: theme.palette.primary.contrastText
      }
    },
    heading: {
      margin: theme.spacing(1, 0, 1.5),
      fontFamily: theme.typography.h3.fontFamily
    },
    descriptionText: {
      marginTop: theme.spacing(0),
      fontSize: theme.typography.h6.fontSize,
      lineHeight: "22px"
    },
    imageWrapper: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      flex: "2 1 50%"
    },

    profileIcon: {
      maxWidth: "90%",
      margin: "5% 0 0 5%",
      position: "relative",
      zIndex: 0
    },

    hairdryerIcon: {
      zIndex: 3,
      width: "100%",
      margin: "30% 0 0",
      position: "absolute",
      transform: "scale3d(1,1,1)"
    },
    notaboneIcon: {
      zIndex: 2,
      width: "95%",
      margin: "47% 0 0 2.5%",
      position: "absolute",
      transform: "scale3d(0.8,0.8,0.8)",
      opacity: 0.9
    },
    reallyIcon: {
      zIndex: 1,
      width: "90%",
      margin: "63% 0 0 5%",
      position: "absolute",
      transform: "scale3d(0.6,0.6,0.6)",
      opacity: 0.2
    },

    backphone: {
      width: "90%",
      position: "relative",
      margin: "5% 0 0 5%",
      zIndex: 0
    },

    card1: { zIndex: 3, width: "100%", margin: "30% 0 0" },
    card2: { zIndex: 2, width: "95%", margin: "47% 0 0 2.5%" },
    card3: { zIndex: 1, width: "90%", margin: "63% 0 0 5%" },
    card: {
      position: "absolute",
      transition: "all 250ms ease-in-out"
    },

    pos_1_card1: {
      transform: "scale3d(1,1,1)",
      marginTop: "10%",
      opacity: 0,
      zIndex: 5
    },
    pos_1b_card1: {
      transform: "scale3d(0.6,0.6,0.6)",
      marginTop: "59%",
      opacity: 0,
      transitionDuration: "0ms",
      zIndex: 1
    },
    pos_1c_card1: {
      transform: "scale3d(0.6,0.6,0.6)",
      marginTop: "59%",
      opacity: 0.2,
      zIndex: 2
    },
    pos_1_card2: {
      transform: "scale3d(1,1,1)",
      marginTop: "30%",
      opacity: 1,
      zIndex: 4
    },
    pos_1b_card2: {
      transform: "scale3d(1,1,1)",
      marginTop: "30%",
      opacity: 1,
      zIndex: 4
    },
    pos_1c_card2: {
      transform: "scale3d(1,1,1)",
      marginTop: "30%",
      opacity: 1,
      zIndex: 4
    },

    pos_1_card3: {
      transform: "scale3d(0.8,0.8,0.8)",
      marginTop: "47%",
      opacity: 0.9,
      zIndex: 3
    },
    pos_1b_card3: {
      transform: "scale3d(0.8,0.8,0.8)",
      marginTop: "47%",
      opacity: 0.9,
      zIndex: 3
    },
    pos_1c_card3: {
      transform: "scale3d(0.8,0.8,0.8)",
      marginTop: "47%",
      opacity: 0.9,
      zIndex: 3
    },

    pos_2_card2: {
      transform: "scale3d(1,1,1)",
      marginTop: "10%",
      opacity: 0,
      zIndex: 5
    },

    pos_2_card3: {
      transform: "scale3d(1,1,1)",
      marginTop: "30%",
      opacity: 1,
      zIndex: 4
    },
    pos_2b_card3: {
      transform: "scale3d(1,1,1)",
      marginTop: "30%",
      opacity: 1,
      zIndex: 4
    },
    pos_2c_card3: {
      transform: "scale3d(1,1,1)",
      marginTop: "30%",
      opacity: 1,
      zIndex: 4
    },

    pos_2_card1: {
      transform: "scale3d(0.8,0.8,0.8)",
      marginTop: "45%",
      opacity: 0.9,
      zIndex: 3
    },
    pos_2b_card1: {
      transform: "scale3d(0.8,0.8,0.8)",
      marginTop: "45%",
      opacity: 0.9,
      zIndex: 3
    },
    pos_2c_card1: {
      transform: "scale3d(0.8,0.8,0.8)",
      marginTop: "45%",
      opacity: 0.9,
      zIndex: 3
    },

    pos_2b_card2: {
      transform: "scale3d(0.6,0.6,0.6)",
      marginTop: "59%",
      opacity: 0,
      transitionDuration: "0ms",
      zIndex: 1
    },
    pos_2c_card2: {
      transform: "scale3d(0.6,0.6,0.6)",
      marginTop: "59%",
      opacity: 0.2,
      zIndex: 2
    },

    pos_3_card3: {
      transform: " scale3d(1,1,1)",
      marginTop: "10%",
      opacity: 0,
      zIndex: 5
    },

    pos_3_card1: {
      transform: "scale3d(1,1,1)",
      marginTop: "30%",
      opacity: 1,
      zIndex: 4
    },
    pos_3b_card1: {
      transform: "scale3d(1,1,1)",
      marginTop: "30%",
      opacity: 1,
      zIndex: 4
    },
    pos_3c_card1: {
      transform: "scale3d(1,1,1)",
      marginTop: "30%",
      opacity: 1,
      zIndex: 4
    },

    pos_3_card2: {
      transform: "scale3d(0.8,0.8,0.8)",
      marginTop: "47%",
      opacity: 0.9,
      zIndex: 3
    },
    pos_3b_card2: {
      transform: "scale3d(0.8,0.8,0.8)",
      marginTop: "47%",
      opacity: 0.9,
      zIndex: 3
    },
    pos_3c_card2: {
      transform: "scale3d(0.8,0.8,0.8)",
      marginTop: "47%",
      opacity: 0.9,
      zIndex: 3
    },

    pos_3b_card3: {
      transform: "scale3d(0.6,0.6,0.6)",
      marginTop: "61%",
      opacity: 0,
      transitionDuration: "0ms",
      zIndex: 1
    },
    pos_3c_card3: {
      transform: "scale3d(0.6,0.6,0.6)",
      marginTop: "61%",
      opacity: 0.2,
      zIndex: 2
    }
  })
);
