//@ts-nocheck

import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterContainer: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      paddingBottom: "55px",
      position: "fixed"
    },
    filterText: {
      display: "flex",
      justifyContent: "center",
      margin: "10px",
      "& p": {
        fontSize: "18px",
        fontWeight: "bold",
        margin: "10px 0px",
        width: "85%"
      }
    },
    filterMainText: {
      display: "flex",
      justifyContent: "center",
      margin: "10px",
      "& p": {
        fontSize: "20px",
        fontWeight: "bold",
        margin: "10px"
      }
    },
    cuisineFilterText: {
      display: "flex",
      justifyContent: "center",
      margin: "10px",
      "& p": {
        fontSize: "18px",
        fontWeight: "bold",
        margin: "10px 12px 10px 90px",
        width: "80%"
      }
    },
    filterName: {
      flex: "1",
      textAlign: "center"
    },
    filterArrow: {
      height: "18px",
      width: "20px",
      marginRight: "5vw !important"
    },
    clearText: {
      alignSelf: "center",
      fontSize: "18px",
      width: "15%",
      fontWeight: "bold",
      position: "absolute",
      right: "-2%",
    },
    cuisineClearText: {
      alignSelf: "center",
      fontSize: "18px",
      width: "20%",
      fontWeight: "bold"
    },
    filterWwrapper: {
      padding: "12px",
      display: "flex",
      alignItems: "center",
      "& img": {
        margin: "2px 10px"
      },
      "& p": {
        fontWeight: "bold",
        fontSize: "18px",
        margin: "0px"
      },
      "& span": {
        fontSize: "16px",
        textOverflow: "ellipsis",
        overflow: "hidden"
      }
    },
    filtersContent: {
      display: "flex",
      flexDirection: "column",
      flex: "1",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    customCheckboxContainer: {
      display: "inline-block",
      position: "relative",
      width: "20px",
      height: "20px",
      cursor: "pointer",

      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
        borderRadius: "4px",
        border: "2px solid #ccc"
      },

      "&::after": {
        content: '""',
        position: "absolute",
        display: "none",
        width: "6px",
        height: "12px",
        border: "solid black",
        borderWidth: "0 2px 2px 0",
        top: "3px",
        left: "7px",
        transform: "rotate(45deg)"
      },

      '& input[type="checkbox"]:checked + &::after': {
        display: "block"
      }
    },
    customCheckbox: {
      height: "20px",
      width: "20px",
      border: "1px solid gray",
      borderRadius: "10px",
      "&:: after": {
        content: "",
        // position: absolute;
        // display: "none",
        width: "6px",
        height: "12px",
        border: "solid black",
        borderWidth: " 0 2px 2px 0",
        top: " 3px",
        left: "7px",
        transform: "rotate(45deg)"
      }
    },

    buttonContainer: {
      flexDirection: "column",
      justifyContent: "flex-end",
      display: "flex",
      flex: 1
    },
    cuisineFilterContainer: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      paddingBottom: "55px"
    },
    cuisineButtonContainer: {
      flexDirection: "column",
      justifyContent: "flex-end",
      display: "flex",
      flex: 1,
      position: "fixed",
      bottom: "10px",
      width: "96%",
      marginLeft: "2%"
    },
    buttonStyle: {
      borderRadius: "4px",
      margin: "0px 5vw",
      textTransform: "none",
      fontWeight: "bold",
      fontSize: "18px"
    },
    clearButtonStyle: {
      borderRadius: "4px",
      margin: "20px 5vw",
      textTransform: "none",
      fontWeight: "bold",
      fontSize: "18px",
      color: "#000"
    },
    formAapplyButton: {
      color: "#ffffff",
      backgroundColor: "#e60000",
      borderRadius: "4px",
      margin: "0px 12px",
      textTransform: "none",
      fontWeight: "bold",
      fontSize: "18px",
      height: "43.5px",
      border: "none",
      fontFamily: "VodafoneRg, sans-serif"
    },
    availOptions: {
      margin: "12px 18px",
      display: "flex",
      alignItems: "center",
      "& label": {
        fontWeight: "bold",
        fontSize: "18px",
        flex: "1"
      },
      "& input": {
        height: "22px",
        width: "10%",
      },
      "& input[type = checkbox]": {
        accentColor: "#e60000"
      }
      // "& input[type=checkbox]": {
      //   appearance: "none",
      //   backgroundColor: "#fff",
      //   margin: 0,
      //   font: "inherit",
      //   color: "currentColor",
      //   width: "1.6em",
      //   height: " 1.6em",
      //   border: "0.15em solid currentColor",
      //   borderRadius: "12px",
      //   transform: "translateY(-0.075em)",
      //   display: "grid",
      //   placeContent: "center"
      // },
      // "& input[type=checkbox]::before": {
      //   width: "0.6em",
      //   height: "0.6em",
      //   content: "open-quote",
      //   borderRadius: "20px",
      //   transform: "scale(0)",
      //   transition: "120ms transform ease-in-out",
      //   boxShadow: "inset 1em 1em #5a5ad9",
      //   backgroundColor: "CanvasText"
      // },

      // "& input[type=checkbox]:checked::before": {
      //   transform: "scale(1)"
      // }
    },
    cuisineContent: {
      margin: "10px 18px",
      "& p": {
        fontSize: "18px",
        fontWeight: "bold"
      }
    },
    cuisienCard: {
      borderRadius: "8px",
      border: "1px solid #dadcdc",
      background: "#dadcdc",
      height: "62px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "18px",
      fontWeight: "bold"
    }
  })
);
