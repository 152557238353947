import React from "react";
import { useStyles } from "./styles";
import { Dialog, Typography, Button } from "@material-ui/core";
import { PreClaimDialogTypes } from "../../Models/Interfaces/PreClaimDialogTypes";
import { isArray } from "lodash";
import { ThemeMapping } from "Configurations/ThemeMapping";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { loadEvents, previousPages } from "Utils/GeneralUtils";

interface IProps {
  claimAdvice: any | undefined;
  openDialog: boolean;
  type: PreClaimDialogTypes;
  clientName: string;
  handleCloseDialog(): void;
  confirmCallback(): void;
  getButtonText?(): string;
  title?: string;
  customOfferType?: any;
  isMembershipExpired?: boolean;
}

const PreClaimDialog: React.FC<IProps> = ({
  openDialog,
  type,
  claimAdvice,
  clientName,
  handleCloseDialog,
  confirmCallback,
  getButtonText,
  title,
  customOfferType,
  isMembershipExpired
}) => {
  const classes = useStyles();
  const isTimer = type === PreClaimDialogTypes.TIMER;
  //@ts-ignore
  const theme = ThemeMapping[clientName];

  const contentOptions = {};
  contentOptions[PreClaimDialogTypes.SHAREABLE] = {
    heading: ConstantMapping[clientName].PRECLAIM_SHAREABLE,
    buttonOk: null,
    buttonCancel: "Back"
  };
  contentOptions[PreClaimDialogTypes.TIMER] = {
    heading: ConstantMapping[clientName].PRECLAIM_TIMER,
    buttonOk: null,
    buttonCancel: "Back"
  };
  contentOptions[PreClaimDialogTypes.PRIZEDRAW] = {
    heading: ConstantMapping[clientName].PRECLAIM_PRIZEDRAW,
    buttonOk: null,
    buttonCancel: "Cancel"
  };
  contentOptions[PreClaimDialogTypes.OTHER] = {
    heading: ConstantMapping[clientName].PRECLAIM_OTHER,
    buttonOk: null,
    buttonCancel: "Cancel"
  };
  contentOptions[PreClaimDialogTypes.GIFTHELP] = {
    heading: ConstantMapping[clientName].PRECLAIM_GIFTING_HEADING,
    buttonOk: ConstantMapping[clientName].PRECLAIM_GIFTING_OK,
    buttonCancel: "Close"
  };
  contentOptions[PreClaimDialogTypes.LOADER] = {
    heading: null,
    buttonOk: null,
    buttonCancel: null
  };
  contentOptions[PreClaimDialogTypes.EATLOCAL] = {
    heading:
      customOfferType === "Coffee Club"
        ? isMembershipExpired
          ? ConstantMapping[clientName].PRECLAIM_RENEW_COFFEE_CLUB_HEADING
          : ConstantMapping[clientName].PRECLAIM_COFFEE_CLUB_HEADING
        : ConstantMapping[clientName].PRECLAIM_EAT_LOCAL_HEADING,
    buttonOk: null,
    buttonCancel: "Back"
  };

  let buttonText: string | null = null;
  if (getButtonText) {
    buttonText = getButtonText();
  }

  const renderHeader = () => {
    return (
      <Typography className={classes.dialogHeading}>
        {contentOptions[type].heading}
      </Typography>
    );
  };

  const renderClaimAdvice = () => {
    if (claimAdvice) {
      let advice = isArray(claimAdvice) ? claimAdvice : [claimAdvice];
      return (
        <>
          {advice.map(item => (
            <div
              className={classes.dialogBody}
              dangerouslySetInnerHTML={{
                __html: item
              }}
            />
          ))}
        </>
      );
    }
  };

  const renderActionButtons = (dialogType: string) => {
    const color = isTimer ? "secondary" : "primary";

    return (
      <div className={classes.dialogActionButtonContainer}>
        <Button
          variant="contained"
          color={color}
          className={
            type === PreClaimDialogTypes.EATLOCAL
              ? `${classes.dialogActionButtonFirst} ${classes.backBtn_Style}`
              : dialogType === "LOADER"
              ? classes.dialogActionButtonFirst
              : classes.firstButton
          }
          onClick={closePreclaimDialogBox}
        >
          <Typography>{contentOptions[type].buttonCancel}</Typography>
        </Button>
        <Button
          variant="contained"
          color={color}
          className={
            dialogType === "LOADER"
              ? classes.dialogActionButtonLast
              : classes.secondButton
          }
          onClick={handlePreclaimDialogBox}
        >
          <Typography noWrap>
            {contentOptions[type].buttonOk || buttonText}
          </Typography>
        </Button>
      </div>
    );
  };

  const renderContent = () => {
    switch (type) {
      case PreClaimDialogTypes.LOADER:
        return (
          <img
            style={{ height: "25vh" }}
            alt="logo"
            src={ImagesMapping[clientName].loaderSvg}
          />
        );
      case PreClaimDialogTypes.SHAREABLE:
      case PreClaimDialogTypes.PRIZEDRAW:
      case PreClaimDialogTypes.OTHER:
      case PreClaimDialogTypes.GIFTHELP:
      case PreClaimDialogTypes.EATLOCAL:
        return (
          <div className={classes.dialogContainer}>
            {renderHeader()}
            {renderClaimAdvice()}
            {renderActionButtons("LOADER")}
          </div>
        );
      case PreClaimDialogTypes.TIMER:
        return (
          <div className={classes.dialogContainer}>
            <div className={classes.dialogHeaderIcon}>
              <img
                style={{ height: "28vw" }}
                alt="logo"
                src={ImagesMapping[clientName].timerSvg}
              />
            </div>
            {renderHeader()}
            {renderClaimAdvice()}
            {renderActionButtons("TIMER")}
          </div>
        );
    }
  };

  const closePreclaimDialogBox = () => {
    const state = {
      onLoadParams: {
        show_view: false,
        event_name: "PageInteraction",
        event_action: "button",
        event_label: contentOptions[type].buttonCancel,
        page_subsection: "offers",
        page_name: `${ConstantMapping[clientName].TEALIUM_PAGE_PREFIX}${title}${ConstantMapping[clientName].TEALIUM_PAGE_LIMITED_TIME_OVERLAY}`
        // same_page_event: true
      }
    };
    loadEvents(previousPages, { state });
    handleCloseDialog();
  };

  const handlePreclaimDialogBox = () => {
    const state = {
      onLoadParams: {
        show_view: false,
        event_name: "PageInteraction",
        event_action: "button",
        event_label: contentOptions[type].buttonOk || buttonText,
        page_subsection: "offers",
        page_name: `${ConstantMapping[clientName].TEALIUM_PAGE_PREFIX}${title}${ConstantMapping[clientName].TEALIUM_PAGE_LIMITED_TIME_OVERLAY}`
        // same_page_event: true
      }
    };
    loadEvents(previousPages, { state });
    confirmCallback();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={closePreclaimDialogBox}
      PaperProps={{
        classes: { root: classes.dialogOpenAimation },
        style: {
          backgroundColor: isTimer
            ? theme.palette.primary.main
            : theme.palette.secondary.main,
          width: "100%",
          padding: "5vw",
          margin: "38px",
          color: isTimer
            ? theme.palette.primary.contrastText
            : theme.palette.secondary.light
        }
      }}
      TransitionProps={{
        style: {
          background: "rgba(0, 0, 0, 0.4)"
        }
      }}
      disableBackdropClick={true}
    >
      {renderContent()}
    </Dialog>
  );
};

export default PreClaimDialog;
