import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Header } from "gce-cxinteracts-ui-components-library";
import { ConstantMapping } from "../../Configurations/ConstantsMapping";
import { getClientName, getImageDetails } from "Utils/GeneralUtils";
import { useHistory } from "react-router-dom";
import { useStyles } from "./styles";
import { Button } from "@material-ui/core";
import UserDetails from "Models/ReduxModels/UserDetails";
import { useParams } from "react-router-dom";
import ApplicationFlag, {
  IApplication
} from "Models/ReduxModels/ApplicationFlag";
import { Settings, ISettings } from "Models/ReduxModels/Settings";
import { ImagesMapping } from "Configurations/ImagesMapping";

interface IProps {
  match: any;
  userDetails: UserDetails;
  applicationFlags: IApplication;
  settings: ISettings;
}

interface ParamTypes {
  type: string;
}

const IFrame: React.FC<IProps> = (props: any) => {
  let classes = useStyles();
  const history = useHistory();
  const [showIFrame, setShowIFrame] = useState(true);
  const [showError, setShowError] = useState(false);
  const [url, setUrl] = useState("");
  const [apierrorMessage, setApiErrorMessage] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const { type } = useParams<ParamTypes>();
  const { applicationFlags, settings } = props;
  const clientName = getClientName();
  // const msisdn = props.userDetails?.paygEncryptedMSISDN;
  const root = document.getElementById("root");

  useEffect(() => {
    resetScroll();
  }, []);
  const resetScroll = () => {
    if (root) {
      root.scrollTop = 0;
    }
  };

  const checkApi = async url => {
    const response = await fetch(url, { method: "options" });
    if (response?.status === 200) {
      setShowIFrame(true);
      setShowError(false);
    } else {
      setShowIFrame(false);
      setShowError(true);
      setShowLoader(false);
    }
  };

  useEffect(() => {
    let url = "";
    const EATLOCAL_ERROR_MESSAGE =
      type === "eatlocal" ? "EATLOCAL_ERROR_MESSAGE" : "COFFECLUB_ERROR";
    if (type === "eatlocal") {
      url = `${settings?.clientDetails?.eatLocal}`;
      setUrl(url);
      setApiErrorMessage(EATLOCAL_ERROR_MESSAGE);
      setTimeout(() => checkApi(url), 50);
    } else if (type === "coffeeClub") {
      url = `${settings?.clientDetails?.coffeeClub}`;
      setUrl(url);
      setApiErrorMessage(EATLOCAL_ERROR_MESSAGE);
      setTimeout(() => checkApi(url), 50);
    }
  }, []);

  const goToBackPage = () => {
    new ApplicationFlag({
      id: "",
      ...applicationFlags
    }).$save();
    window.history.back();
  };

  const redirectToHome = () => {
    new ApplicationFlag({
      id: "",
      ...applicationFlags,
      isShowFooter: true
    }).$save();
    setTimeout(() => history && history.push("/home"), 500);
  };

  const showHeader = () => (
    <Header
      headerText=""
      isHeaderInverted={false}
      svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
      clientLogoPrimary={getImageDetails(
        ImagesMapping[clientName].clientLogoPrimary,
        ConstantMapping[clientName].CLIENT_ICON_TEXT
      )}
      clientLogoSecondary={getImageDetails(
        ImagesMapping[clientName].clientLogoSecondary,
        ConstantMapping[clientName].CLIENT_ICON_TEXT
      )}
      onSvgSelection={() => goToBackPage()}
      onLogoSelection={() => redirectToHome()}
      isScrollHeader={false}
    />
  );

  const loadIFrame = () => (
    <iframe
      src={url}
      allowFullScreen={true}
      className={classes.iframe}
      onLoad={() => setShowLoader(false)}
      title="External frame"
    />
  );

  const showButtons = () => (
    <>
      <Button
        className={classes.retry}
        variant="contained"
        color="primary"
        onClick={() => checkApi(url)}
      >
        Retry
      </Button>
      <Button
        className={classes.textButtonOff}
        onClick={() => history.push("/myRewards")}
      >
        Back to VeryMe Rewards
      </Button>
    </>
  );

  const showErrorMessage = () => (
    <div className={`${classes.errorMessage} ${classes.apiErrorMsg}`}>
      <div>
        <p>
          {
            ConstantMapping[clientName].EXTERNAL_API_ERROR_MESSAGE[
              apierrorMessage
            ].LINE1
          }
        </p>
        <p>
          {
            ConstantMapping[clientName].EXTERNAL_API_ERROR_MESSAGE[
              apierrorMessage
            ].LINE2
          }
        </p>
      </div>
      <div className={classes.buttonWrapper}>{showButtons()}</div>
    </div>
  );

  return (
    <>
      {showHeader()}
      {showLoader && (
        <div className={classes.waitLoader}>
          <img
            style={{ height: "20vh" }}
            alt="logo"
            src={ImagesMapping[clientName].waitLoaderSvg}
          />
        </div>
      )}
      {showIFrame && (
        <div className={classes.iframeContainer}>{loadIFrame()}</div>
      )}
      {showError && showErrorMessage()}
    </>
  );
};

const mapStateToProps = (state: any) => {
  const settings = Settings.getInsatnce("", state).props;
  let userDetails = UserDetails.getInsatnce("", state)?.props;
  return {
    userDetails,
    settings
  };
};

export default connect(mapStateToProps)(IFrame);
