import { BaseModel } from "ReduxStore/index";

export interface IScroll {
  id: any;
  scrollCurrentPosition?: any;
}

export default class Scrolls extends BaseModel<IScroll> {
  static resource = "Scrolls";
}
