//@ts-nocheck

import { makeStyles, Theme, createStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      background: `linear-gradient(rgb(216, 216, 216), rgb(255, 255, 255))`
    },
    header: {
      "& .makeStyles-toolbar-20": {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
      }
    },

    imageContainer: {},

    heroImage: {
      width: "90%",
      margin: "5% 0 0 5%"
    },
    circleImage: {
      position: "absolute",
      width: "41%",
      margin: "37% 0 0 30%",
      zIndex: 2,
      transition: "all 400ms 700ms ease-in-out"
    },
    trafficImg: {
      position: "absolute",
      width: "29%",
      margin: "39% 0 0 40%",
      zIndex: 2
    },
    trafficImage: {
      width: "62%",
      margin: "28.5% 0 0 19%",
      position: "absolute",
      borderTopLeftRadius: "7px",
      borderTopRightRadius: "7px"
    },
    contentMainContainer: {
      display: "flex",
      flexDirection: "column"
    },

    heading: {
      margin: theme.spacing(1, 0, 2.25),
      fontFamily: theme.typography.h3.fontFamily,
      lineHeight: "1.2"
    },
    descriptionText: {
      marginTop: theme.spacing(0),
      fontSize: theme.typography.h6.fontSize,
      lineHeight: "22px"
    },

    trafficButtonOff: {
      color: theme.palette.primary.main,
      textTransform: "none",
      padding: theme.spacing(0),
      fontSize: theme.typography.h6.fontSize,
      marginTop: theme.spacing(4.5),
      lineHeight: "1",
      "& .MuiButton-label": {
        display: "flex",
        justifyContent: "center"
      }
    },
    trafficButtonOn: {
      width: theme.spacing(50),
      height: theme.spacing(12),
      fontSize: theme.typography.h6.fontSize,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: "none",
      fontFamily: theme.typography.h3.fontFamily,
      boxShadow: theme.shadows[4],
      "&.Mui-disabled": {
        color: theme.palette.primary.contrastText
      }
    },

    wrapper: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      background: `linear-gradient(rgb(216, 216, 216), rgb(255, 255, 255))`,
      marginBottom: theme.spacing(-5),
      marginTop: theme.spacing(-13)
    },

    content: {
      display: "flex",
      height: "100vh",
      background: `linear-gradient(rgb(216, 216, 216), rgb(255, 255, 255))`,
      justifyContent: "center",
      minHeight: "100vh",
      flexDirection: "column",
      flex: "1 1 100%"
    },

    contentWrapper: {
      marginTop: theme.spacing(-11.25),
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column"
    },

    imageWrapper: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      flex: "2 1 50%"
    },

    textWrapper: {
      display: "flex",
      fontSize: "16px",
      alignItems: "flex-start",
      flexDirection: "column",
      color: theme.palette.secondary.light,
      margin: "0vh 5vw",
      flex: "1 0 auto"
    },

    buttonWrapper: {
      flex: "0 0 17%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      height: "100px",
      alignItems: "center",
      "@media (min-width: 960px)": {
        margin: "3vh 8vw"
      }
    },

    loaded: {
      opacity: "1 !important"
    }
  })
);
