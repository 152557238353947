import React, { Fragment, useEffect, useState } from "react";
import { Header } from "gce-cxinteracts-ui-components-library";
import Button from "@material-ui/core/Button";
import { useStyles } from "./styles";
import { ConstantMapping } from "../../../Configurations/ConstantsMapping";
import { ClientModel } from "../../../Models/ReduxModels/ClientDetails";
import { connect } from "react-redux";
import { History } from "history";
import UserProfile, {
  UserProfileDetails
} from "../../../Models/ReduxModels/UserProfileModel";
import { userOnboardingRoutes } from "../../../Configurations/Clients/vodafone/Routes";
import {
  iterateHierarchy,
  getImageDetails,
  getClientName,
  redirectToNextRoute
} from "../../../Utils/GeneralUtils";
import {
  logEvents,
  EVENT_TYPE,
  pushAllEventsToServer
} from "Services/EventService";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { FunctionMapper } from "Configurations/FunctionMapping";

interface IProps {
  history: History;
  clientData: any;
  userProfileData: UserProfileDetails;
}
const ProfilePermissionPage: React.FC<IProps> = props => {
  let classes = useStyles();
  const initalClass = {
    position_class_card1: "",
    position_class_card2: "",
    position_class_card3: ""
  };

  const clientName = getClientName();
  const { history, clientData, userProfileData } = props;
  const [cssClass, setCssClass] = useState(initalClass);
  let accessToken = clientData?.token;
  const onLoadParams = {
    page_subsection: "permissions"
  };

  useEffect(() => {
    logEvents({ eventType: EVENT_TYPE.ONBOARDING_PROFILE_PERMISSION_LANDED });
    pushAllEventsToServer();
  }, []);

  const onClickStartButton = () => {
    new UserProfile({
      id: "local",
      isFirstUse: userProfileData?.isFirstUse,
      dob: userProfileData?.dob,
      interests: userProfileData?.interests,
      permissions: {
        ...userProfileData?.permissions,
        profiling: true
      }
    }).$save();
    nextPage(true);
  };

  const onClickDontAllowLink = () => {
    new UserProfile({
      id: "local",
      isFirstUse: userProfileData?.isFirstUse,
      dob: userProfileData?.dob,
      interests: userProfileData?.interests,
      permissions: {
        ...userProfileData?.permissions,
        profiling: false
      }
    }).$save();
    nextPage(false);
  };

  const nextPage = (permissionOpted: boolean) => {
    const nextRoute = redirectToNextRoute("profile");
    logEvents({ eventType: EVENT_TYPE.NEXT_PAGE_ONBOARDING });
    logEvents({
      eventType: EVENT_TYPE.ONBOARDING_PROFILE_PERMISSION_EXITED,
      profile: permissionOpted
    });
    pushAllEventsToServer();
    history.push(nextRoute, { onLoadParams });
  };

  const pos_1 = {
    position_class_card1: classes.pos_1_card1,
    position_class_card2: classes.pos_1_card2,
    position_class_card3: classes.pos_1_card3
  };
  const pos_1b = {
    position_class_card1: classes.pos_1b_card1,
    position_class_card2: classes.pos_1b_card2,
    position_class_card3: classes.pos_1b_card3
  };

  const pos_1c = {
    position_class_card1: classes.pos_1c_card1,
    position_class_card2: classes.pos_1c_card2,
    position_class_card3: classes.pos_1c_card3
  };

  const pos_2 = {
    position_class_card1: classes.pos_2_card1,
    position_class_card2: classes.pos_2_card2,
    position_class_card3: classes.pos_2_card3
  };
  const pos_2b = {
    position_class_card1: classes.pos_2b_card1,
    position_class_card2: classes.pos_2b_card2,
    position_class_card3: classes.pos_2b_card3
  };

  const pos_2c = {
    position_class_card1: classes.pos_2c_card1,
    position_class_card2: classes.pos_2c_card2,
    position_class_card3: classes.pos_2c_card3
  };
  const pos_3 = {
    position_class_card1: classes.pos_3_card1,
    position_class_card2: classes.pos_3_card2,
    position_class_card3: classes.pos_3_card3
  };
  const pos_3b = {
    position_class_card1: classes.pos_3b_card1,
    position_class_card2: classes.pos_3b_card2,
    position_class_card3: classes.pos_3b_card3
  };
  const pos_3c = {
    position_class_card1: classes.pos_3c_card1,
    position_class_card2: classes.pos_3c_card2,
    position_class_card3: classes.pos_3c_card3
  };

  const toggleClasses = () => {
    setTimeout(() => {
      setCssClass(pos_1);
    }, 500);
    setTimeout(() => {
      setCssClass(pos_1b);
    }, 1000);
    setTimeout(() => {
      setCssClass(pos_1c);
    }, 1050);
    setTimeout(() => {
      setCssClass(pos_2);
    }, 3500);
    setTimeout(() => {
      setCssClass(pos_2b);
    }, 4010);
    setTimeout(() => {
      setCssClass(pos_2c);
    }, 4050);
    setTimeout(() => {
      setCssClass(pos_3);
    }, 6500);
    setTimeout(() => {
      setCssClass(pos_3b);
    }, 7010);
    setTimeout(() => {
      setCssClass(pos_3c);
    }, 7050);
    setTimeout(() => {
      toggleClasses();
    }, 8500);
  };

  return (
    <Fragment>
      <div className={classes.mainContainer}>
        <Header
          className={classes.header}
          isHeaderInverted={false}
          clientLogoPrimary={getImageDetails(
            ImagesMapping[clientName].clientLogoPrimary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          clientLogoSecondary={getImageDetails(
            ImagesMapping[clientName].clientLogoSecondary,
            ConstantMapping[clientName].CLIENT_ICON_TEXT
          )}
          isScrollHeader={false}
          primaryLogoTabIndex={-1}
          secondaryLogoTabIndex={-1}
        />
        <div className={classes.contentWrapper}>
          <div className={classes.content}>
            <div
              className={classes.imageWrapper}
              style={{ position: "relative" }}
            >
              <div style={{ display: "block" }}>
                <img
                  alt=""
                  src={ImagesMapping[clientName].hairdryerIcon}
                  className={`${classes.hairdryerIcon} ${classes.card} ${classes.card1}  ${cssClass.position_class_card1}`}
                />
                <img
                  alt=""
                  src={ImagesMapping[clientName].notaboneIcon}
                  className={`${classes.notaboneIcon} ${classes.card} ${classes.card2}  ${cssClass.position_class_card2}`}
                />
                <img
                  alt=""
                  src={ImagesMapping[clientName].reallyIcon}
                  className={`${classes.reallyIcon} ${classes.card} ${classes.card3}  ${cssClass.position_class_card3}`}
                />
                <img
                  alt="SummaryHeroImg"
                  src={ImagesMapping[clientName].SummaryHeroImg}
                  className={`${classes.profileIcon}`}
                  onLoad={() =>
                    setTimeout(() => {
                      toggleClasses();
                    }, 1500)
                  }
                />
              </div>
            </div>
            <div className={classes.textWrapper}>
              <h2 className={classes.heading}>
                {ConstantMapping[clientName].PROFILE_PERMISSION_HEADING}
              </h2>
              <p className={classes.descriptionText}>
                {ConstantMapping[clientName].PROFILE_PERMISSION_DESC}
              </p>
            </div>
            <div className={classes.buttonWrapper}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.profileButtonOn}
                  onClick={onClickStartButton}
                >
                  {ConstantMapping[clientName].TURN_ON_PROFILING_BUTTON_TEXT}
                </Button>
                <Button
                  className={classes.profileButtonOff}
                  onClick={onClickDontAllowLink}
                >
                  {ConstantMapping[clientName].TURN_OFF_PROFILING_BUTTON_TEXT}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  let clientData = ClientModel.getInsatnce("", state).props;
  let userProfileData = UserProfile.getInsatnce("local", state)?.props;
  return {
    clientData,
    userProfileData
  };
};

export default connect(mapStateToProps)(ProfilePermissionPage);
