//SMS-Notification
//Traffic-Network

export const OnBoardingConstants = Object.freeze({
    WELCOME_SUBHEADING: "VeryMe Rewards is a loyalty programme just for Vodafone customers. Here you’ll find exclusive offers, giveaways and prize draws. <br><br>Whatever you're looking for, there’s something to love at VeryMe Rewards.",
    WELCOME_HEADING:"Welcome to VeryMe Rewards",
    NOTIFICATION_HEADING:"Never miss out on a reward",
    NOTIFICATION_SUBHEADING:"Get text alerts for new rewards, including our weekly #FeelgoodFriday giveaways.",
    LOCATION_HEADING:"Rewards near you",
    LOCATION_SUBHEADING:"We’ll send you nearby rewards you’ll love based on where you use your phone on our network.",
    PROFILING_HEADING:"More rewards",
    PROFILING_SUBHEADING:"By turning on profiling, we’ll be able to see the offers that you love (and the ones you don’t) which will help us when choosing our future rewards.",
    NETWORK_HEADING:"Rewards built for you",
    NETWORK_SUBHEADING:"Get tailored offers that suit you by sharing how you use your phone on our network.",
    SUMMARY_HEADING:"You’re all set",
    SUMMARY_SUBHEADING:"You’re ready to enjoy a world of rewards! <br><br> You can change your privacy settings at any point through the My Vodafone app.",
})

export const membershipConstants = Object.freeze({
    WELCOME_SUBHEADING: "Ready to Eat Local?",
})