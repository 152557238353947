import React, { Fragment, useEffect, useState } from "react";
import {
  getClientName,
  getImageDetails,
  isEmptyObject
} from "Utils/GeneralUtils";
import { Header } from "gce-cxinteracts-ui-components-library";
import ApplicationFlag from "Models/ReduxModels/ApplicationFlag";
import { ImagesMapping } from "Configurations/ImagesMapping";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useStyles } from "./styles";
import { Divider } from "@material-ui/core";
import RestoLocalBFilterModal, {
  IRestoLocalBlendFilter
} from "Models/ReduxModels/RestoLocalBlendFilters";
import RestaurantsList, {
  IList
} from "Models/ReduxModels/RestaurantCoffeeModel";
import FilteredRestaurants from "Models/ReduxModels/FilteredRestaurants";

interface IProps {
  applicationFlags: any;
  restaurantDtails: IList;
  filterDetails: IRestoLocalBlendFilter;
}

const OfferTypeFilter: React.FC<IProps> = props => {
  const { applicationFlags, restaurantDtails, filterDetails } = props;
  const clientName = getClientName();
  const history = useHistory();
  let classes = useStyles();
  const [offerTypeList, setOfferTypeList] = useState([]);
  const [clear, setClear] = useState(false);
  const renderedNames: any = [];

  useEffect(() => {
    setTimeout(() => {
      if (!filterDetails) {
        setClear(false);
      } else if (filterDetails?.offerType?.length) {
        let offers = filterDetails?.offerType;
        setClear(true);
        if (offers?.length) {
          offers?.forEach(offer => {
            const stringWithoutWhitespace = offer.trimStart();
            const ele: any = document.getElementById(stringWithoutWhitespace);
            if (ele) {
              ele.checked = true;
            }
          });
        }
      } else {
        setClear(false);
      }
    }, 100);
  }, [filterDetails]);

  useEffect(() => {
    if (!isEmptyObject(restaurantDtails)) {
      let newList = Object.assign(restaurantDtails);
      let updatedList: any = [];
      Object.entries(newList).map(([key, value]) => {
        let val = value;
        if (val !== "") {
          updatedList.push(val);
        }
      });
      setOfferTypeList(updatedList);
    }
  }, [restaurantDtails]);

  const redirectToHome = () => {
    new RestoLocalBFilterModal({
      id: "",
      offerType: [],
      cuisine: "",
      availability: "",
      previousFilters: {}
    }).$save();
    let list = {};
    new FilteredRestaurants({
      id: "",
      ...list
    }).$save();
    new ApplicationFlag({
      id: "",
      ...applicationFlags,
      isShowFooter: true,
      isRedirectedFromGiftingSuccessPage: false,
      eatLocalCoffeClubID: 0,
      eatLocalCoffeClubOffer: {},
      selectedShopOrResto: {}
    }).$save();
    setTimeout(() => history && history.push("/home"), 500);
  };

  const goBack = () => {
    setTimeout(() => window.history.back(), 500);
  };

  const renderSuggestions = (item: any) => {
    const offerTypeName = item?.offers[0]?.name;
    if (renderedNames.includes(offerTypeName)) {
      return null;
    } else renderedNames.push(offerTypeName);
    return (
      <>
        <div
          id="offerOptions"
          className={classes.availOptions}
          key={`${item?.id}`}
        >
          <label htmlFor={item?.offers[0]?.name}>{item?.offers[0]?.name}</label>
          <input
            type="checkbox"
            name="offerType"
            id={item?.offers[0]?.name}
            value={item?.offers[0]?.name}
            onClick={e => {
              const selectedOffer: any = document.querySelectorAll(
                'input[name="offerType"]:checked'
              );
              if (selectedOffer?.length) {
                setClear(true);
              } else {
                setClear(false);
              }
            }}
          />
        </div>
        <Divider />
      </>
    );
  };

  const handleClick = e => {
    e.preventDefault();

    const selectedOffer: any = document.querySelectorAll(
      'input[name="offerType"]'
    );

    if (selectedOffer) {
      const checkedValues: any = [];
      selectedOffer.forEach(checkbox => {
        if (checkbox.checked) {
          checkedValues.push(checkbox.value);
        }
      });

      new RestoLocalBFilterModal({
        id: "",
        ...filterDetails,
        offerType: checkedValues,
        cuisine: filterDetails?.cuisine,
        availability: filterDetails?.availability
      }).$save();
    }
    history.push("/custom-filters");
  };

  const handleClear = e => {
    e.preventDefault();
    const selectedOffer: any = document.querySelectorAll(
      'input[name="offerType"]'
    );

    if (selectedOffer) {
      selectedOffer.forEach(checkbox => {
        checkbox.checked = false;
      });
    }
    setClear(false);
  };

  return (
    <Fragment>
      <Header
        headerText=""
        isHeaderInverted={true}
        svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
        clientLogoPrimary={getImageDetails(
          ImagesMapping[clientName].clientLogoPrimary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        clientLogoSecondary={getImageDetails(
          ImagesMapping[clientName].clientLogoSecondary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        onSvgSelection={goBack}
        onLogoSelection={redirectToHome}
        isScrollHeader={false}
      />
      <form>
        <div className={classes.filterContainer}>
          <div className={classes.filterText}>
            <p className={classes.filterName}>Offer type</p>
            {
              <span className={classes.clearText} onClick={handleClear}>
                {clear ? "Clear" : ""}
              </span>
            }
          </div>
          <Divider />

          {offerTypeList?.map(element => {
            return renderSuggestions(element);
          })}
          <div className={classes.buttonContainer}>
            <input
              className={classes.formAapplyButton}
              type="submit"
              value="Apply"
              onClick={handleClick}
            />
          </div>
        </div>
      </form>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  let applicationFlags = ApplicationFlag.getInsatnce("", state)?.props;
  let restaurantDtails = RestaurantsList.getInsatnce("", state)?.props;
  let filterDetails = RestoLocalBFilterModal.getInsatnce("", state)?.props;

  return {
    applicationFlags,
    filterDetails,
    restaurantDtails
  };
};

export default connect(mapStateToProps)(OfferTypeFilter);
