import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { getClientName, getImageDetails } from "Utils/GeneralUtils";
import { useStyles } from "../styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import SearchIcon from "../../../Configurations/Clients/vodafone/Assets/Membership/search.png";
import RewardsIcon from "../../../Configurations/Clients/vodafone/Assets/Membership/rewards.png";
import MembershipIcon from "../../../Configurations/Clients/vodafone/Assets/Membership/membership.png";
import ClaimedOffers from "Models/ReduxModels/ClaimedOffersModel";
import ApplicationFlag from "Models/ReduxModels/ApplicationFlag";
import {
  EVENT_TYPE,
  logEvents,
  pushAllEventsToServer
} from "Services/EventService";
import { isOfferCodeExpired } from "Utils/offerUtils";
import { checkOfferExpiry } from "../Membership/MembershipUtils";
import { footerUseStyles } from "./styles";

interface IProps {
  applicationFlags?: any;
  claimedOfferDetails?: any;
  offerId?: any;
}

const eatLocal = "Eat Local";

const EatLocalFooter: React.FC<IProps> = (props: IProps) => {
  const { applicationFlags, claimedOfferDetails, offerId } = props;
  const history = useHistory();
  let footerClasses = footerUseStyles();
  const [isMemberActive, setIsMemberActive] = useState("");

  const handleMembershipRedirection = e => {
    let offer = applicationFlags?.eatLocalCoffeClubOffer;
    const isMemberActivate = checkOfferExpiry(claimedOfferDetails, {
      offerId
    });

    if (offer?.addType === eatLocal) {
      logEvents({
        eventType: EVENT_TYPE.EAT_LOCAL_MEMBERSHIP_CLICK,
        offerId: offer?.offerId
      });
    } else {
      logEvents({
        eventType: EVENT_TYPE.COFFEE_CLUB_MEMBERSHIP_CLICK,
        offerId: offer?.offerId
      });
    }

    const isExpired = isOfferCodeExpired(
      applicationFlags?.eatLocalCoffeClubOffer
    );

    let claimedOffer: any;
    if (claimedOfferDetails) {
      let claimedOfferKeys = Object.keys(claimedOfferDetails);
      if (claimedOfferKeys.length > 1) {
        claimedOffer = Object.values(claimedOfferDetails)?.find(
          (obj: any) => obj?.offerId == offerId
        );
      }
    }
    if (isMemberActivate && !isExpired && claimedOffer) {
      if (offer?.addType === eatLocal) {
        logEvents({
          eventType: EVENT_TYPE.SHOW_EAT_LOCAL_CARD,
          offerId: offer?.offerId
        });
      } else {
        logEvents({
          eventType: EVENT_TYPE.SHOW_LOCAL_BLEND_COFFEE_CLUB_CARD,
          offerId: offer?.offerId
        });
      }

      // creating anchor and clicking for go to that page.
      const claimedOfferDetailsObj = JSON.stringify(claimedOffer);
      const path = `${window?.location?.origin}/use-card?claimedOfferDetails=${claimedOfferDetailsObj}`;
      window.history.pushState({}, "", path);
      window.dispatchEvent(new PopStateEvent("popstate"));

      // history.push({
      //   pathname: "/use-card",
      //   state: { claimedOfferDetails: claimedOffer }
      // });
    } else {
      if (offer?.addType === eatLocal) {
        logEvents({
          eventType: EVENT_TYPE.SHOW_EAT_LOCAL_MEMBERSHIP_SCREEN,
          offerId: offer?.offerId
        });
      } else {
        logEvents({
          eventType: EVENT_TYPE.SHOW_COFFEE_CLUB_MEMBERSHIP_SCREEN,
          offerId: offer?.offerId
        });
      }

      // creating anchor and clicking for go to that page.
      const path = `${window?.location?.origin}/membership?offerId=${(
        offerId || ""
      )?.toString()}`;
      window.history.pushState({}, "", path);
      window.dispatchEvent(new PopStateEvent("popstate"));

      // history.push({
      //   pathname: "/membership",
      //   state: { offerId: offerId }
      // });
    }
    pushAllEventsToServer();
  };

  const handleRedirectToHome = e => {
    setTimeout(() => {
      new ApplicationFlag({
        id: "",
        ...applicationFlags,
        isShowFooter: true,
        eatLocalCoffeClubID: 0,
        eatLocalCoffeClubOffer: {},
        selectedShopOrResto: ""
      }).$save();
    }, 250);

    setTimeout(() => history && history.push("/home"), 100);
  };

  return (
    <Fragment>
      <BottomNavigation
        showLabels
        className={footerClasses.bottomNav}
        value={isMemberActive}
      >
        <BottomNavigationAction
          className={footerClasses.bottomNavAction}
          // component={Link}
          // to="/search-custom-list"
          icon={<img src={SearchIcon} alt="SearchIcon" />}
        />
        <BottomNavigationAction
          className={footerClasses.bottomNavAction}
          onClick={e => handleMembershipRedirection(e)}
          icon={<img src={MembershipIcon} alt="MembershipIcon" />}
        />
        <BottomNavigationAction
          className={footerClasses.bottomNavAction}
          onClick={e => handleRedirectToHome(e)}
          icon={<img src={RewardsIcon} alt="RewardsIcon" />}
        />
      </BottomNavigation>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  let claimedOfferDetails = ClaimedOffers.getInsatnce("", state)?.props;
  let applicationFlags = ApplicationFlag.getInsatnce("", state).props;

  return {
    claimedOfferDetails,
    applicationFlags
  };
};

export default connect(mapStateToProps)(EatLocalFooter);
