import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: "100%",
      overflow: "auto !important",
      position: "fixed",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0
    }
  })
);
