//@ts-nocheck
import UserDetails, { IUserDetails } from "Models/ReduxModels/UserDetails";
import React from "react";
import { connect } from "react-redux";
import ErrorPage from "Routes/ErrorPage/ErrorPage";

interface IState {
  hasError: boolean;
}

interface IProps extends React.ReactChildren {
  userDetails: IUserDetails;
}

class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { userDetails } = this.props;
    console.error("APP_ERROR", error, userDetails?.userId);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }
    return this.props.children;
  }
}

const mapStateToProps = state => {
  const userDetails = UserDetails.getInsatnce("", state)?.props;
  return { userDetails };
};

export default connect(mapStateToProps)(ErrorBoundary);
