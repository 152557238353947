import React, { useEffect, useState } from "react";
import {
  AnimationHelper,
  Header,
  OfferCard
} from "gce-cxinteracts-ui-components-library";
import { Button, Typography, useTheme } from "@material-ui/core";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { spring } from "react-motion";
import { ConstantMapping } from "Configurations/ConstantsMapping";
import { PreClaimDialogTypes } from "Models/Interfaces/PreClaimDialogTypes";
import plusIcon from "Configurations/Clients/vodafone/Assets/Images/plusButton@2x.png";
import minusIcon from "Configurations/Clients/vodafone/Assets/Images/minusButton@2x.png";
import EatLocalModel, { EatLocal } from "Models/ReduxModels/EatLocalModel";
import FavouriteOffers, {
  IFavouriteOffers
} from "Models/ReduxModels/FavouriteOffersModel";
import Offers, {
  IOffers,
  ImageClassifier,
  Offer,
  RedemptionExpiryModel
} from "Models/ReduxModels/OffersModel";
import moment from "moment";
import {
  getClientName,
  getImageDetails,
  iOSDevice,
  loadEvents,
  previousPages
} from "Utils/GeneralUtils";
import { useStyles } from "./styles";
import {
  getOfferImage,
  isFavoriteOffer,
  addRemoveFavouriteOffers,
  isObjectEmpty,
  getOfferBadgeDetails,
  checkClaimedDetailsForLiteUser,
  isOfferCodeExpired,
  updateClaimedOffersFromApi,
  getLatestClaimDetailsByClaimId
} from "Utils/offerUtils";
import PreClaimDialog from "./PreClaimDialog";
import { claimOfferGraphQLCall } from "Services/OfferSerivice";
import ClaimedOffers, {
  IClaimedOffers
} from "Models/ReduxModels/ClaimedOffersModel";
import SortedClaimedIds, {
  ClaimIds
} from "Models/ReduxModels/SortedClaimedIds";
import { showSnackBar } from "Utils/SnackBarUtils";
import Merchants, { IMerchant } from "Models/ReduxModels/Merchants";
import SortedFavouriteOfferIds, {
  OfferIds
} from "Models/ReduxModels/SortedFavouriteOfferIds";
import ApplicationFlag, {
  IApplication
} from "Models/ReduxModels/ApplicationFlag";
import { ImagesMapping } from "Configurations/ImagesMapping";
import {
  logEvents,
  EVENT_TYPE,
  pushAllEventsToServer
} from "Services/EventService";
import UserDetails, { IUserDetails } from "Models/ReduxModels/UserDetails";

interface IProps {
  eatLocalInfo: EatLocal;
  claimedOffers: IClaimedOffers;
  match: any;
  location: any;
  sortedClaimedIds: ClaimIds;
  offers: IOffers;
  favorites: IFavouriteOffers;
  merchants: IMerchant;
  favouriteOfferIds: OfferIds;
  applicationFlags: IApplication;
  userData: IUserDetails;
}

const EatLocalOffer: React.FC<IProps> = (props: IProps) => {
  const {
    eatLocalInfo,
    claimedOffers,
    sortedClaimedIds,
    offers,
    merchants,
    favorites,
    favouriteOfferIds,
    applicationFlags,
    userData
  } = props;
  const history = useHistory();
  let classes = useStyles();
  const theme = useTheme();
  const { id } = props.match.params;
  const [showEnterAnime, setShowEnterAnime] = useState(false);
  const [showLeaveAnime, setShowLeaveAnime] = useState(false);
  const [showCard, setCardVisibility] = useState(true);
  const [expand, collapse] = useState(false);
  const [showPreClaimPopup, setShowPreClaimPopup] = useState(false);
  const [showSavedOfferAnimation, setSavedOfferAnimation] = useState(-1);
  const [showEatLocalSuccess, setShowEatLocalSuccess] = useState(false);
  const [showLoader, setLoaderState] = useState(false);
  const [reclaimMembership, setReclaimMembership] = useState(false);

  const clientName = getClientName();
  const root = document.getElementById("root");
  let offer = offers[id];
  const { appAccessLevel } = userData;
  // let vmrLiteUserStatus = isVMRLiteUser || (appAccessLevel=== "PAYG_LITE") ?  offer.giveToLiteUser : true; commented out

  const eatLocal = "Eat Local";
  const coffeeClub = "Coffee Club";

  let offerData = Object.values(claimedOffers);
  offerData.pop();

  let eatLocalOfferDetails: any = offerData.find(item => {
    if (moment(item?.expiryDateTime, "DD/MM/YYYY").isDST()) {
      item.expiryDateTime = moment(item?.expiryDateTime).subtract(1, "hour");
    }
    if (item?.offerId == id) {
      return item;
    }
  });

  useEffect(() => {
    setShowEnterAnime(true);
    resetScroll();
    if (offer?.addType === eatLocal) {
      logEvents({
        eventType: EVENT_TYPE.EATLOCAL_DISPLAYED,
        offerId: offer.offerId
      });
    } else {
      logEvents({
        eventType: EVENT_TYPE.COFFEE_CLUB_DISPLAYED,
        offerId: offer.offerId
      });
    }
    pushAllEventsToServer();
  }, []);

  const liteUserCheck = () => {
    let liteUserAccess: any =
      appAccessLevel === "PAYG_PLUS_LITE" || appAccessLevel === "PAYG_LITE";
    if (liteUserAccess) {
      return checkClaimedDetailsForLiteUser(offer);
      // return offer.giveToLiteUser
    } else {
      return true;
    }
  };

  let vmrLiteUserStatus = liteUserCheck();

  const resetScroll = () => {
    if (root) {
      root.scrollTop = 0;
    }
  };

  const goBack = () => {
    new ApplicationFlag({
      id: "",
      ...applicationFlags,
      isShowFooter: true,
      isRedirectedFromGiftingSuccessPage: false,
      offerId: 0
    }).$save();
    setShowLeaveAnime(true);
    setShowEnterAnime(false);
    setTimeout(() => history.push("/home"), 500);
  };

  const redirectToHome = () => {
    new ApplicationFlag({
      id: "",
      ...applicationFlags,
      isShowFooter: true
    }).$save();
    setTimeout(() => history && history.push("/home"), 500);
  };

  const renderCard = () => {
    const { offerId } = offer;
    return (
      <div style={{ position: "relative" }} id="cardContainer">
        <OfferCard
          mode="full"
          imageDetails={getOfferImage(
            ImageClassifier.OFFER_IMAGE_LARGE,
            offer?.images
          )}
          logoDetails={getImageDetails(
            merchants[offer?.merchantId]?.imageUrl,
            ""
          )}
          showFavorites={true}
          title={offer?.title || ""}
          badge={getOfferBadgeDetails(offer, "large")}
          isFavorite={isFavoriteOffer(offerId)}
          showSavedAnimation={showSavedOfferAnimation === offerId}
          options={{
            cardActionAltText: {
              saveAltText: ConstantMapping[clientName].SAVE_ALT_TEXT,
              unsaveAltText: ConstantMapping[clientName].UNSAVE_ALT_TEXT
            }
          }}
          isBlurred={false}
          informationStripDetails={undefined}
          showAnimation={true}
          animationProps={{
            showEnterAnimation: showEnterAnime,
            showExitAnimation: showLeaveAnime
          }}
          onActionClicked={() => {
            if (offer?.addType === eatLocal) {
              logEvents({
                eventType: EVENT_TYPE.TOGGLE_EAT_LOCAL_FAVOURITE,
                offerId: id
              });
            } else {
              logEvents({
                eventType: EVENT_TYPE.TOGGLE_COFFEE_CLUB_FAVOURITE,
                offerId: id
              });
            }
            addRemoveFavouriteOffers(
              offerId,
              favorites,
              favouriteOfferIds
            ).then((data: any) => {
              data?.add && setSavedOfferAnimation(offerId);
              setTimeout(() => {
                showSavedOfferAnimation === offerId &&
                  setSavedOfferAnimation(-1);
              }, 800);
              if (offer?.addType === eatLocal) {
                logEvents({
                  eventType: EVENT_TYPE.TOGGLE_EAT_LOCAL_FAVOURITE_SUCCESS,
                  offerId: id
                });
              } else {
                logEvents({
                  eventType: EVENT_TYPE.TOGGLE_COFFEE_CLUB_FAVOURITE_SUCCESS,
                  offerId: id
                });
              }
              pushAllEventsToServer();
            });
          }}
        ></OfferCard>
      </div>
    );
  };

  const titleDefaultStyles = {
    opacity: 0,
    marginTop: -40
  };

  const titleEnterStyles = {
    marginTop: spring(0),
    opacity: spring(1)
  };

  const titleLeaveStyles = {
    marginTop: spring(-40),
    opacity: spring(0)
  };

  const buttonDefaultStyles = {
    marginBottom: -100,
    opacity: 0
  };
  const buttonEnterStyles = {
    marginBottom: spring(0, { stiffness: 400 }),
    opacity: spring(1)
  };
  const buttonExitStyles = {
    opacity: spring(0)
  };

  const renderDescription = () => {
    let description = offer?.description;
    return (
      <div className={classes.descriptionText}>
        {renderConvertedText(description, "18px")}
      </div>
    );
  };

  const reFetchConditions = (updatedOffers, claimedId, newClaimedOffers) => {
    let offerClaimed =
      getLatestClaimDetailsByClaimId(claimedId, newClaimedOffers) || null;
    try {
      updateClaimedOffersFromApi(
        updatedOffers,
        offer,
        newClaimedOffers,
        offerClaimed
      );
    } catch (error: any) {
      if (error?.networkError) {
        history.push("/error-500");
      } else {
        showSnackBar();
      }
    }
  };

  const renderKeyTerms = () => {
    let termsAndConditions = offer?.termsAndConditions;
    if (offer?.termsAndConditionsUrl) {
      return (
        <>
          {renderConvertedText(termsAndConditions, "14px")}
          <p>
            {ConstantMapping[clientName].TERMS_AND_CONDITIONS_URL_TEXT}
            <a
              href={offer?.termsAndConditionsUrl}
              className={classes.termsAndConditionsUrl}
            >
              here
            </a>
          </p>
        </>
      );
    } else {
      return renderConvertedText(termsAndConditions, "14px");
    }
  };

  // const renderKeyTermsSection = () => {
  //   return (
  //     <div className={classes.keyTermsContainer}>
  //       <h2 className={classes.heading}>
  //         {ConstantMapping[clientName].KEY_TERMS_HEADING_TEXT}
  //       </h2>
  //       {renderKeyTerms()}
  //     </div>
  //   );
  // };

  const renderAnchorTag = () => {
    const termAndCondition = document.getElementById("termsCondition");
    const anchor = termAndCondition?.getElementsByTagName("a") || [];
    if (anchor?.length > 0) {
      for (const button of anchor as any) {
        if (!button.href.includes("external")) {
          let value = button.href;
          button.href = `external:${value}`;
          button.target = "_system";
        }
      }
    }
  };

  const renderExpandedContent = () => {
    setTimeout(() => {
      renderAnchorTag();
    }, 10);
    return <div className={classes.tcText}>{renderKeyTerms()}</div>;
  };

  const renderTermsAndConditions = () => {
    return (
      <div id="termsCondition">
        <h2 className={classes.heading}>
          <div className={classes.tcWrapper}>
            <div>{ConstantMapping[clientName].TERMS_AND_CONDITIONS_TEXT}</div>
            <button
              type="button"
              tabIndex={0}
              className={classes.buttonDefault}
              onClick={() => collapse(!expand)}
              aria-label="toggle terms and conditions"
            >
              <img
                src={expand ? minusIcon : plusIcon}
                alt={ConstantMapping[clientName].EXPAND_TERMS_ALT_TEXT}
                className={expand ? classes.minusIcon : classes.plusIcon}
              />
            </button>
          </div>
        </h2>
        {expand ? renderExpandedContent() : ""}
      </div>
    );
  };

  const renderConvertedText = (info: any, fontSize: string) => {
    return (
      <div
        className={classes.richText}
        dangerouslySetInnerHTML={{ __html: info }}
        style={{ fontSize: fontSize }}
      />
    );
  };

  const getButtonStyles = buttonType => {
    const styles = {
      ACTIVATE: {
        backgroundColor: theme.palette.primary.main,
        boxShadow: theme.shadows[4]
      },
      VIEW_CARD: {
        backgroundColor: "#00b0ca",
        boxShadow: "0px 5px 20px 0px rgba(0, 176, 202, 0.3)"
      },
      SEARCH_RESTAURANT: {
        backgroundColor: theme.palette.primary.main,
        boxShadow: theme.shadows[4],
        marginTop: "40px"
      }
    };
    return styles[buttonType];
  };

  const handleExternalLinkClick = () => {
    let url = `external:${ConstantMapping[clientName].VMRLite_TopUp_URL}`;
    logEvents({
      eventType: EVENT_TYPE.CONFIRM_VMR_LITE_TOP_UP_BUTTON,
      offerId: id
    });
    if (iOSDevice()) {
      url = `external:${ConstantMapping[clientName].VMRLite_TopUp_URL}`;
    }
    const state = {
      onLoadParams: {
        show_view: false,
        event_name: "PageInteraction",
        event_action: "button",
        event_label: ConstantMapping[clientName].VMRLite_TopUp_Button,
        page_name: `${ConstantMapping[clientName].TEALIUM_PAGE_PREFIX}${offer?.title}>claim screen`,
        page_subsection: "offers"
      }
    };
    loadEvents(previousPages, { state });
    window.open(`${url}`, "_blank");
    pushAllEventsToServer();
  };

  const renderRestrictedOfferSection = () => {
    return (
      <div
        className={`${classes.keyTermsContainer} ${classes.restrictedTermContainer}`}
      >
        <div className={classes.restrictedSpacing}>
          <h2 className={classes.heading} style={{ opacity: 0.3 }}>
            {ConstantMapping[clientName].KEY_TERMS_HEADING_TEXT}
          </h2>
          <div>
            <h3 className={classes.restrictedHeading}>
              {ConstantMapping[clientName].VMRLite_RESTRICTED_OFFER_HEADING}
            </h3>
            <p className={classes.restrictedText}>
              {appAccessLevel === "PAYG_PLUS_LITE"
                ? ConstantMapping[clientName].PAYGPLUS_RESTRICTED_OFFER_TEXT_ONE
                : ConstantMapping[clientName].VMRLite_RESTRICTED_OFFER_TEXT_ONE}
            </p>
            <p className={classes.restrictedText}>
              {ConstantMapping[clientName].VMRLite_RESTRICTED_OFFER_TEXT_TWO}
            </p>
            <div
              className={classes.buttonWrapper}
              style={{ justifyContent: "center" }}
            >
              <Button
                variant="contained"
                className={`${classes.redButton} ${classes.restrictedRedBtn}`}
                style={{
                  backgroundColor: theme.palette.primary.main,
                  boxShadow: theme.shadows[4]
                }}
                onClick={handleExternalLinkClick}
              >
                {appAccessLevel === "PAYG_PLUS_LITE"
                  ? ConstantMapping[clientName].PAYGPLUS_UPGRADE_BUTTON
                  : ConstantMapping[clientName].VMRLite_TopUp_Button}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleActivateClick = async () => {
    if (offer?.addType === eatLocal) {
      logEvents({ eventType: EVENT_TYPE.ACTIVATE_EAT_LOCAL, offerId: id });
    } else {
      logEvents({ eventType: EVENT_TYPE.ACTIVATE_COFFEE_CLUB, offerId: id });
    }
    try {
      setLoaderState(true);
      let res = await claimOfferGraphQLCall(id);
      let claimedOffer = res.data?.claimOffer;
      let sortedclaimedIds: number[] = sortedClaimedIds?.claimIds;
      if (claimedOffer) {
        if (offer?.addType === eatLocal) {
          logEvents({
            eventType: EVENT_TYPE.CONFIRM_ACTIVATE_EAT_LOCAL_SUCCESS,
            offerId: id
          });
        } else {
          logEvents({
            eventType: EVENT_TYPE.CONFIRM_ACTIVATE_COFFEE_CLUB_SUCCESS,
            offerId: id
          });
        }
        let reclaimObject = {};
        Object.keys(claimedOffers).map(val => {
          if (claimedOffers[val]?.offerId !== +id) {
            reclaimObject[val] = claimedOffers[val];
          }
        });
        let currentClaimedOffer: any = {
          claimedId: claimedOffer?.id,
          offerId: claimedOffer?.offerId,
          attributes: claimedOffer?.attributes,
          byAgent: claimedOffer?.byAgent,
          claimDateTime: new Date(claimedOffer?.claimDateTime),
          expiryDateTime: claimedOffer?.expiryDateTime
            ? new Date(claimedOffer?.expiryDateTime)
            : undefined,
          offerType: claimedOffer?.offerType,
          redemptionCode: claimedOffer?.redemptionCode
        };
        if (offers[id].claimedIds && offers[id].claimedIds.length > 0) {
          let idx = sortedclaimedIds.indexOf(offers[id].claimedIds[0]);
          sortedclaimedIds.splice(idx, 1);
          setReclaimMembership(true);
        }
        sortedclaimedIds.splice(0, 0, claimedOffer.id);
        let newClaimedOffers = reclaimObject || {};
        newClaimedOffers[currentClaimedOffer.claimedId] = currentClaimedOffer;
        let updatedOffers = offers;
        updatedOffers[id].claimedIds = [claimedOffer.id];

        new Offers({
          id: "",
          ...updatedOffers
        }).$save();
        new ClaimedOffers({
          id: "",
          ...newClaimedOffers
        }).$save();
        new SortedClaimedIds({
          id: "",
          claimIds: sortedclaimedIds
        }).$save();

        setShowPreClaimPopup(false);
        setShowEatLocalSuccess(true);
        setTimeout(() => {
          reFetchConditions(
            updatedOffers,
            updatedOffers[id].claimedIds,
            newClaimedOffers
          );
        }, 150);
        if (offer?.addType === eatLocal) {
          logEvents({
            eventType: EVENT_TYPE.CONFIRM_ACTIVATE_EAT_LOCAL,
            offerId: id
          });
          logEvents({
            eventType: EVENT_TYPE.SHOW_EAT_LOCAL_MEMBERSHIP_DETAILS_DIALOG,
            offerId: id
          });
        } else {
          logEvents({
            eventType: EVENT_TYPE.CONFIRM_ACTIVATE_COFFEE_CLUB,
            offerId: id
          });
          logEvents({
            eventType: EVENT_TYPE.SHOW_COFFEE_CLUB_MEMBERSHIP_DETAILS_DIALOG,
            offerId: id
          });
        }
      }
      pushAllEventsToServer();
    } catch (error: any) {
      if (error?.networkError) {
        history.push("/error-500");
      } else {
        setShowPreClaimPopup(false);
        setShowEatLocalSuccess(false);
        showSnackBar();
      }
      if (offer?.addType === eatLocal) {
        logEvents({
          eventType: EVENT_TYPE.CONFIRM_ACTIVATE_EAT_LOCAL_ERROR,
          offerId: id
        });
      } else {
        logEvents({
          eventType: EVENT_TYPE.CONFIRM_ACTIVATE_COFFEE_CLUB_ERROR,
          offerId: id
        });
      }
      pushAllEventsToServer();
    } finally {
      setLoaderState(false);
    }
  };

  const navigateToRestaurantJourney = async offer => {
    const tealiumState = {
      onLoadParams: {
        show_view: false,
        event_name: "PageInteraction",
        event_action: "button",
        event_label: `Seach ${
          offer.addType == eatLocal ? "Restaurant" : "Local"
        }`,
        page_name: `${ConstantMapping[clientName].TEALIUM_PAGE_PREFIX}${offer?.title}>search restaurant screen`,
        page_subsection: "offers"
      }
    };
    setTimeout(() => {
      new ApplicationFlag({
        id: "",
        ...applicationFlags,
        eatLocalCoffeClubID: id,
        eatLocalCoffeClubOffer: offer
      }).$save();
      if (offer?.addType === eatLocal) {
        logEvents({
          eventType: EVENT_TYPE.EAT_LOCAL_SEARCH_JOURNEY_START,
          offerId: id
        });
      } else {
        logEvents({
          eventType: EVENT_TYPE.COFFE_CLUB_SEARCH_JOURNEY_START,
          offerId: id
        });
      }

      if (!isObjectEmpty(eatLocalOfferDetails)) {
        if (offer?.addType === eatLocal) {
          logEvents({
            eventType: EVENT_TYPE.EAT_LOCAL_SEARCH_AFTER_ACTIVATION,
            offerId: id
          });
        } else {
          logEvents({
            eventType: EVENT_TYPE.COFFEE_CLUB_SEARCH_AFTER_ACTIVATION,
            offerId: id
          });
        }
      } else {
        if (offer?.addType === eatLocal) {
          logEvents({
            eventType: EVENT_TYPE.EAT_LOCAL_SEARCH_BEFORE_ACTIVATION,
            offerId: id
          });
        } else {
          logEvents({
            eventType: EVENT_TYPE.COFFEE_CLUB_SEARCH_BEFORE_ACTIVATION,
            offerId: id
          });
        }
      }
      pushAllEventsToServer();

      history.push("/search-screen", {
        offerId: id,
        onLoadParams: tealiumState
      });
      loadEvents(previousPages, { tealiumState });
    }, 100);
  };

  const isMemberShipExpired =
    (!isObjectEmpty(eatLocalOfferDetails) && isOfferCodeExpired(offer)) ||
    false;
  const memberStyle = isMemberShipExpired ? "flex-end" : "space-between";
  const expiredCoffeeClubPreClaim =
    "Wake up and smell the discounts with Local Blend. Reactivate your membership to enjoy 25% off barista-made drinks at local coffee houses and cafes.<br><br>Your membership will be active for 12 months.";

  return (
    <>
      <Header
        headerText=""
        isHeaderInverted={true}
        svgProps={ConstantMapping[clientName].SVG.headerSvgStyle}
        clientLogoPrimary={getImageDetails(
          ImagesMapping[clientName].clientLogoPrimary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        clientLogoSecondary={getImageDetails(
          ImagesMapping[clientName].clientLogoSecondary,
          ConstantMapping[clientName].CLIENT_ICON_TEXT
        )}
        onSvgSelection={goBack}
        onLogoSelection={redirectToHome}
        isScrollHeader={false}
      />
      <div className={classes.wrapper}>
        {showCard && renderCard()}
        <div className={classes.divider}></div>
        {showCard && (
          <>
            <AnimationHelper
              defaultStyles={titleDefaultStyles}
              enterStyles={titleEnterStyles}
              exitStyles={titleLeaveStyles}
              showEnterAnimation={showEnterAnime}
              showExitAnimation={showLeaveAnime}
              enterAnimationDelay={650}
              exitAnimationDelay={100}
              units={{
                marginTop: "px"
              }}
            >
              <div
                className={
                  appAccessLevel !== "PAYG_PLUS_LITE" || "PAYG_LITE"
                    ? classes.mainContainer
                    : classes.restrictedContainer
                }
              >
                {renderDescription()}
                {vmrLiteUserStatus
                  ? !offer.termsAndConditions
                    ? ""
                    : renderTermsAndConditions()
                  : renderRestrictedOfferSection()}
              </div>
              {vmrLiteUserStatus && (
                <div
                  className={classes.buttonContainer}
                  style={{ marginTop: "20px" }}
                >
                  <Button
                    variant="contained"
                    className={classes.redButton}
                    style={getButtonStyles("SEARCH_RESTAURANT")}
                    onClick={() => {
                      navigateToRestaurantJourney(offer);
                    }}
                  >
                    {offer?.addType === coffeeClub
                      ? "Search Local"
                      : "Search restaurants"}
                  </Button>
                </div>
              )}
            </AnimationHelper>
          </>
        )}
      </div>
      {showLoader && (
        <PreClaimDialog
          openDialog={showLoader}
          type={PreClaimDialogTypes.LOADER}
          clientName={clientName}
          confirmCallback={() => {}}
          handleCloseDialog={() => {}}
          claimAdvice={""}
          title={offer?.title}
        />
      )}
      <PreClaimDialog
        openDialog={showPreClaimPopup}
        type={PreClaimDialogTypes.EATLOCAL}
        claimAdvice={
          offer?.addType === coffeeClub && isMemberShipExpired
            ? expiredCoffeeClubPreClaim
            : offer?.preClaimAdvice
        }
        clientName={clientName}
        handleCloseDialog={() => {
          setShowPreClaimPopup(false);
          if (offer?.addType === eatLocal) {
            logEvents({
              eventType: EVENT_TYPE.CANCEL_EAT_LOCAL_ACTIVATION,
              offerId: id
            });
          } else {
            logEvents({
              eventType: EVENT_TYPE.CANCEL_COFFEE_CLUB_ACTIVATION,
              offerId: id
            });
          }

          pushAllEventsToServer();
        }}
        confirmCallback={() => {
          handleActivateClick();
        }}
        getButtonText={() => {
          return "Activate";
        }}
        title={offer?.title}
        customOfferType={offer?.addType}
        isMembershipExpired={isMemberShipExpired}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  let eatLocalInfo = EatLocalModel.getInsatnce("", state)?.props;
  let claimedOffers = ClaimedOffers.getInsatnce("", state)?.props;
  const sortedClaimedIds = SortedClaimedIds.getInsatnce("", state)?.props;
  const offers = Offers.getInsatnce("", state)?.props;

  const favorites = FavouriteOffers.getInsatnce("", state)?.props;
  const merchants = Merchants.getInsatnce("", state)?.props;
  const favouriteOfferIds = SortedFavouriteOfferIds.getInsatnce(
    "",
    state
  )?.props;
  const applicationFlags = ApplicationFlag.getInsatnce("", state).props;
  const userData = UserDetails.getInsatnce("", state)?.props;
  return {
    eatLocalInfo,
    claimedOffers,
    sortedClaimedIds,
    offers,
    favorites,
    merchants,
    favouriteOfferIds,
    applicationFlags,
    userData
  };
};

export default connect(mapStateToProps)(EatLocalOffer);
